import { NavigationState, PartialState } from '@react-navigation/core'

declare type ResultState = PartialState<NavigationState> & {
  state?: ResultState;
};

export const getDeepestRoute = (state: ResultState): PartialState<NavigationState> => {
  if (state.routes[0].state) {
    return getDeepestRoute(state.routes[0].state)
  }
  return state.routes[0]
}
