import { FC } from 'react'
import { Dimensions } from 'react-native'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { useMediaQuery } from '~/hooks/mediaQuery'

type OverlayInnerContainerType = {
  size?: 'auto' | 'small' | 'medium' | 'large' | 'full'
}

export const OverlayInnerContainer:FC<OverlayInnerContainerType> = ({ size = 'auto', children }) => {
  // console.log('-> OverlayInnerContainer', size)

  const { height, width } = Dimensions.get('screen')
  // console.log('width/height', width, height)
  const {
    md, lg, xl,
  } = useMediaQuery()

  let tw = 'flex-col justify-between p-0'

  switch (size) {
  case 'auto':
    if (xl) {
      tw += ' w-208 h-168'
    } else if (lg) {
      tw += ' w-192 h-168'
    } else if (md) {
      tw += ' w-96 h-168'
    } else {
      tw += ' w-96 h-168'
    }
    break
  case 'small':
    tw += ' w-96 h-96'
    break
  case 'medium':
    tw += ' w-96 h-168'
    break
  case 'large':
    tw += ' w-192 h-128'
    break
  case 'full':
    tw += ' w-full h-full'
    break

  default:
    throw new Error(`unknown size ${size}`)
  }

  // console.log('OverlayInnerContainer', { size, tw })

  return <SafeAreaView tw={tw} style={{ maxHeight: height, maxWidth: width }}>{children}</SafeAreaView>
}
