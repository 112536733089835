import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle as farCircle, faCircleCheck as farCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight, faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'

import { View } from '../containers/View'
import { Icon } from '../icons/Icon'
import { IconProps } from '../icons/Icon/IconProps.d'

type ListItemRightAccessoryProps = {
  icon?: IconProps
  iconStyle?: string
}

/**
 * We add a few common icons to the library (just in case)
 */
library.add(faChevronRight, faCircleCheck, farCircleCheck, farCircle)

const accessoryContainerBaseTw = 'ml-2 justify-center items-center text-gray-500 px-2'

/**
 * RightAccessory is one of Chevron, Icon (checkmark), Number, Sparkline etc...
 */
export const ListItemRightAccessory: FC<ListItemRightAccessoryProps> = ({ icon, iconStyle }) => {
  // console.log('-> ListItemRightAccessory', icon, iconStyle)

  if (icon) {
    return <View tw={accessoryContainerBaseTw}><Icon {...icon} tw={iconStyle} /></View>
  }

  return null
}
