import { FC, memo, MouseEventHandler } from 'react'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'

import { LoadingIndicator } from '../Loading/LoadingIndicator' // from 'react-file-utils';

export type LoadMoreButtonProps = {
  /** onClick handler load more button. Pagination logic should be executed in this handler. */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /** If true, LoadingIndicator is displayed instead of button */
  refreshing?: boolean;
};

const UnMemoizedLoadMoreButton: FC<LoadMoreButtonProps> = (props) => {
  const { children = 'Load more', onClick, refreshing } = props

  return (
    <View>
      <Button
        testID="load-more-button"
        disabled={refreshing}
        onPress={onClick}
        >
        {refreshing ? <LoadingIndicator /> : children}
      </Button>
    </View>
  )
}

export const LoadMoreButton = memo(
  UnMemoizedLoadMoreButton,
) as typeof UnMemoizedLoadMoreButton
