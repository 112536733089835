import { library } from '@fortawesome/fontawesome-svg-core'
import { faFaceSmile, faPaperclip } from '@fortawesome/pro-regular-svg-icons'

// import { QuotedMessagePreview as DefaultQuotedMessagePreview } from 'stream-chat-react/dist/components/MessageInput/QuotedMessagePreview'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { View } from '~/elements/containers/View'

// import { Popable } from '~/elements/overlays/popable'
import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete'
import { useComponentContext } from '../context/ComponentContext'
import { useMessageInputContext } from '../context/MessageInputContext'
import { SendButton as DefaultSendButton } from './icons'

// import { FileUploadButton, ImageDropzone } from 'react-file-utils'

library.add(faPaperclip, faFaceSmile)

export const MessageInputFlat = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>() => {
  // const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext< At, Ch, Co, Ev, Me, Re, Us>('MessageInputFlat')
  // const { t } = useTranslationContext('MessageInputFlat')

  const {
    // closeEmojiPicker,
    // cooldownInterval,
    cooldownRemaining,
    // emojiPickerIsOpen,
    // handleEmojiKeyDown,
    handleSubmit,
    // isUploadEnabled,
    // maxFilesLeft,
    // openEmojiPicker,
    // setCooldownRemaining,
    // uploadNewFiles,
  } = useMessageInputContext<At, Ch, Co, Ev, Me, Re, Us>('MessageInputFlat')

  const {
    // CooldownTimer = DefaultCooldownTimer,
    // QuotedMessagePreview = DefaultQuotedMessagePreview,
    SendButton,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageInputFlat')

  // const containerClass = `str-chat__input-flat ${SendButton ? 'str-chat__input-flat--send-button-active' : null} ${quotedMessage ? 'str-chat__input-flat-quoted' : null}`

  return (
    <View
      // className={containerClass}
      tw="bg-surface-1 w-full items-center px-4"
      >
      <View tw="w-full max-w-7xl py-3">
        {/* <ImageDropzone
          accept={acceptedFiles}
          disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
          handleFiles={uploadNewFiles}
          maxNumberOfFiles={maxFilesLeft}
          multiple={multipleUploads}
        > */}
        {/* {quotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />} */}
        <View
          // className='str-chat__input-flat-wrapper'
          tw="flex-row items-center"
          >
          <View
            // className='str-chat__input-flat--textarea-wrapper'
            tw="flex-row flex-grow items-center"
            >
            {/* {isUploadEnabled && <UploadsPreview />} */}

            {/* Emoji Select Wrapper */}
            {/* <Popable
              action="hover"
              position="top"
              caretPosition="left"
              style={{ marginLeft: 48 }}
              content={emojiPickerIsOpen ? t('Close emoji picker') : t('Open emoji picker')}
              //   className='str-chat__emojiselect-wrapper'
              onPress={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
              onKeyDown={handleEmojiKeyDown}

            >
              {cooldownRemaining ? (
                <View
                  //   className='str-chat__input-flat-cooldown'
                >
                  <CooldownTimer
                    cooldownInterval={cooldownInterval}
                    setCooldownRemaining={setCooldownRemaining}
                  />
                </View>
              ) : (
                <View tw="p-2">
                  <Icon name="face-smile" variant="regular"/>
                </View>
              )}
            </Popable> */}

            {/* <EmojiPicker /> */}

            {/* File upload wrapper */}
            {/* {isUploadEnabled && !cooldownRemaining && (

              <Popable
                // className='str-chat__fileupload-wrapper'
                testID='fileinput'
                action="hover"
                position="top"
                caretPosition="left"
                style={{ marginLeft: 48 }}
                content={maxFilesLeft ? t('Attach files') : t("You've reached the maximum number of files")}
              >

                <FileUploadButton
                  accepts={acceptedFiles}
                  disabled={maxFilesLeft === 0}
                  handleFiles={uploadNewFiles}
                  multiple={multipleUploads}
                >
                  <View tw="p-2">
                    <Icon name="paperclip" variant="regular"/>
                  </View>
                </FileUploadButton>
              </Popable>
            )} */}

            <ChatAutoComplete />

          </View>
          {!cooldownRemaining && <SendButton sendMessage={handleSubmit} />}
        </View>
        {/* </ImageDropzone> */}
      </View>
    </View>
  )
}

/**
 * This input has been added as there is no easy way to pass information from channel list to message input
 *
 */
export const MessageInputFlatNotReady = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>() => {
  const {
    SendButton = DefaultSendButton,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageInputFlat')

  return (
    <View
      tw="bg-surface-1 w-full items-center px-4"
      >
      <View tw="w-full max-w-7xl py-3">
        <View tw="flex-row items-center">
          <View tw="flex-row flex-grow items-center">
            <ChatAutoComplete />
          </View>
          <SendButton
            disabled
            onPress={() => undefined}
          />
        </View>
      </View>
    </View>
  )
}
