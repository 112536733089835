import AsyncStorage from '@react-native-async-storage/async-storage'
// import * as RNLocalize from 'react-native-localize'
import * as Localization from 'expo-localization'
import { ModuleType } from 'i18next'

import { localStorageKeys } from '~/hooks/storage'

import {
  getSupportedLanguage,
  isSupportedLanguage,
} from './languages'

export default {
  type: 'languageDetector' as ModuleType,
  async: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  async detect(callback: (language: string) => void) {

    let language

    try {
      language = await AsyncStorage.getItem(localStorageKeys.I18N_LNG)

      // Clean stale unsupported language in localStorage
      if (language && !isSupportedLanguage(language)) {
        await AsyncStorage.removeItem(localStorageKeys.I18N_LNG)
      }

    } catch (error) {
      console.error(error)
    }

    if (!language) {
      language = Localization.locale
    }

    return callback(getSupportedLanguage(language))
  },
  async cacheUserLanguage(language: string) {
    const exisitingValue = await AsyncStorage.getItem(localStorageKeys.I18N_LNG)
    if (exisitingValue !== language) {
      try {
        // console.log('Will cache language in local storage:', language)
        await AsyncStorage.setItem(localStorageKeys.I18N_LNG, language)
      } catch (error) {
        console.error(error)
      }
    }
  },
}
