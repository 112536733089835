import { StackActions } from '@react-navigation/native'
import { FC } from 'react'
import { Text, View, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { Pressable } from '~/elements/containers/Pressable'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { AspectRatio } from '~/elements/images/DoubleSvgImage/DoubleSvgImage.enums'
import { ImageProps } from '~/elements/images/Image'
import { getNearestAspectRatio } from '~/theme/helpers'

type LargeTileItemType = {
  // id: string
  // slug: string
  width: string // % of parent width
  maxWidth: number // max width over which tile doesnt grow
  aspectRatio: AspectRatio
  title: string
  // description: string
  // iconName: string
  background: ImageProps
  illustration: ImageProps
  backgroundColor: string
  style: ViewStyle
  // navigation: {
  //   screen: string
  //   params: NavigationProp
  // }
  navigationAction?:any
  navigateTo?:string
}

/**
 *
 * Note: will not work on native; need adaptation with React native SVG (should be easy)
 * TODO: extract SvgImage with object-cover (background) and object-contain (front illustration)
 * TODO: make it working seemlessly with images
 * TODO add Text position : 'above' | 'below' | 'top' | 'bottom'
 */
export const LargeTileItem: FC<LargeTileItemType> = (props) => {
  // console.log('LargeTileItem', props)
  const tailwind = useTailwind()

  const {
    title,
    width,
    background,
    illustration,
    backgroundColor,
    maxWidth = 483.5,
    aspectRatio = getNearestAspectRatio(29 / 16),
    style,
    navigationAction,
    navigateTo,
  } = props

  // const backgroundVerticalMargin = (aspectW - aspectH) / 2 / aspectH

  return (
    <View
      testID="large-tile"
      style={{
        ...tailwind('pb-8 px-2'),
        width,
        maxWidth,
        ...style,
      }}
      >
      <Pressable
        action={navigationAction ? StackActions.push(navigationAction.screen, navigationAction.params) : undefined} // necessary to get category title in Screen header
        // action={CommonActions.navigate(navigationAction.screen, navigationAction.params)}
        to={navigateTo}
        >

        <DoubleSvgImage
          aspectRatio={aspectRatio}
          foreground={illustration}
          background={background}
          backgroundColor={backgroundColor}
        />

        <View testID="tile-footer" style={tailwind('h-16 py-2 flex flex-row w-full justify-between content-center')}>
          <Text>{title}</Text>
        </View>
      </Pressable>
    </View>
  )
}
