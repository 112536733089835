import { FC, memo, MouseEventHandler } from 'react'

export type MessageNotificationProps = {
  /** button click event handler */
  onClick: MouseEventHandler;
  /** Whether or not to show notification */
  showNotification: boolean;
};

const UnMemoizedMessageNotification: FC<MessageNotificationProps> = (props) => {
  const { children, onClick, showNotification = true } = props

  if (!showNotification) {
    return null
  }

  return (
    <button
      type="button"
      className="str-chat__message-notification"
      data-testid="message-notification"
      onClick={onClick}
      >
      {children}
    </button>
  )
}

export const MessageNotification = memo(
  UnMemoizedMessageNotification,
) as typeof UnMemoizedMessageNotification
