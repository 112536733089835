import classNames from 'classnames'
import { FC } from 'react'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
// import { ButtonProps } from '~/elements/buttons/Button.d'
// import { IconProps } from '~/elements/icons/Icon/IconProps.d'

type searchSectionHeadingType = {
  eyebrow?: string
  title: string
  description?: string
  // accessoryRight?: IconProps | ButtonProps
  tw?:string
}

const eyebrowTw = ''
const titleTw = 'font-sans text-sm font-semibold leading-normal text-gray-500 uppercase'
const descriptionTw = 'text-sm leading-relaxed text-gray-500'

const baseContainerTw = 'mt-6'

export const SearchSectionHeading: FC<searchSectionHeadingType> = ({
  tw, title, description, eyebrow,
}) => (
  <View tw={classNames(baseContainerTw, tw)}>
    {eyebrow && <Text tw={eyebrowTw}>{eyebrow}</Text>}
    {title && <Text tw={titleTw}>{title}</Text>}
    {description && <Text tw={descriptionTw}>{description}</Text>}
  </View>
)
