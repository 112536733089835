import {
  BaseSyntheticEvent, ComponentType, createContext, FC, PropsWithChildren, useContext,
} from 'react'
import type {
  AppSettingsAPIResponse, Channel, Mute, StreamChat,
} from 'stream-chat'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
  UnknownType,
} from '../stream.types'
import { getDisplayName } from './utils/getDisplayName'

type CSSClasses =
  | 'chat'
  | 'chatContainer'
  | 'channel'
  | 'channelList'
  | 'message'
  | 'messageList'
  | 'thread'
  | 'threadList'
  | 'virtualMessage'
  | 'virtualizedMessageList';

export type CustomClasses = Partial<Record<CSSClasses, string>>;

export type ChatContextValue<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  client: StreamChat<At, Ch, Co, Ev, Me, Re, Us>;
  closeMobileNav: () => void;
  getAppSettings: () => Promise<AppSettingsAPIResponse<Co>> | null;
  mutes: Mute<Us>[];
  openMobileNav: () => void;
  setActiveChannel: (
    newChannel?: Channel<At, Ch, Co, Ev, Me, Re, Us>,
    watchers?: { limit?: number; offset?: number },
    event?: BaseSyntheticEvent,
  ) => void;
  useImageFlagEmojisOnWindows: boolean;
  channel?: Channel<At, Ch, Co, Ev, Me, Re, Us>;
  customClasses?: CustomClasses;
  navOpen?: boolean;
};

export const ChatContext = createContext<ChatContextValue | undefined>(undefined)

export const ChatProvider = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>({
    children,
    value,
  }: PropsWithChildren<{
  value: ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>;
}>) => (
  <ChatContext.Provider value={(value as unknown) as ChatContextValue}>
    {children}
  </ChatContext.Provider>
  )

export const useChatContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    componentName?: string,
  ) => {
  const contextValue = useContext(ChatContext)

  if (!contextValue) {
    console.warn(
      // eslint-disable-next-line max-len
      `The useChatContext hook was called outside of the ChatContext provider. Make sure this hook is called within a child of the Chat component. The errored call is located in the ${componentName} component.`,
    )

    return {} as ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>
  }

  return (contextValue as unknown) as ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>
}

/**
 * Typescript currently does not support partial inference so if ChatContext
 * typing is desired while using the HOC withChatContext the Props for the
 * wrapped component must be provided as the first generic.
 */
export const withChatContext = <
  P extends UnknownType,
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    Component: ComponentType<P>,
  ): FC<Omit<P, keyof ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>>> => {
  const WithChatContextComponent = (
    props: Omit<P, keyof ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>>,
  ) => {
    const chatContext = useChatContext<At, Ch, Co, Ev, Me, Re, Us>()

    return <Component {...(props as P)} {...chatContext} />
  }
  WithChatContextComponent.displayName = `WithChatContext${getDisplayName(Component)}`
  return WithChatContextComponent
}
