import noop from 'lodash/noop'
import { RefObject, useEffect } from 'react'
import { Platform } from 'react-native'

export const useMobileNavigation = (
  channelListRef: RefObject<HTMLDivElement>,
  navOpen: boolean,
  closeMobileNav?: () => void,
) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          closeMobileNav
        && channelListRef.current
        && !channelListRef.current.contains(event.target as Node)
        && navOpen
        ) {
          closeMobileNav()
        }
      }

      document.addEventListener('click', handleClickOutside)

      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
    return noop()
  }, [channelListRef, closeMobileNav, navOpen])
}
