import {
  DocumentReference, DocumentSnapshot, FirestoreError, QuerySnapshot,
} from 'firebase/firestore'
import { UseQueryResult } from 'react-query'

import { ArrayDocsQueryResult, GetSingleDocQueryResult, HasDocQueryResult } from '~/definitions/firestore.queries'

/**
 * Helpers to convert React-Query-Firebase response into a simili GraphQL like QueryResult, or AsyncReact to some extend
 * GraphQL useQuery => https://www.apollographql.com/docs/react/data/queries/#result
 * ReactAsync state => https://docs.react-async.com/api/state
 */

export const withGetDocResultAdapter = <T>({
  isLoading, data, error,
}: UseQueryResult<DocumentSnapshot<T>, FirestoreError>):GetSingleDocQueryResult<T> => ({
    isLoading,
    error,
    data,
  })

export const withSingleDocQueryResultAdapter = <T>({
  isLoading, data, error,
}: UseQueryResult<QuerySnapshot<T>, FirestoreError>): GetSingleDocQueryResult<T> => ({
    isLoading,
    error,
    data: (!data || data?.size === 0) ? undefined : data.docs[0],
  })

export const withArrayDocQueryResultAdapter = <T>({
  isLoading, data, error,
}: UseQueryResult<QuerySnapshot<T>, FirestoreError>):ArrayDocsQueryResult<T> => ({
    isLoading,
    error,
    data: data?.docs,
  })

export const withTakeFirstResultAdapter = <T>({
  data, isLoading, error,
}: UseQueryResult<QuerySnapshot<T>, FirestoreError>): GetSingleDocQueryResult<T> => (
    {
      isLoading,
      error,
      data: (data && data.size > 0) ? data.docs[0] : undefined,
    }
  )

export const withHasResultAdapter = <T>({
  data, isLoading, error,
}:UseQueryResult<QuerySnapshot<T>, FirestoreError>):HasDocQueryResult => (
    {
      isLoading,
      error,
      data: (data && data.size > 0),
    }
  )

export const getKeysFromRef = (ref: DocumentReference):Array<string> => {
  if (!ref) {
    return []
  }
  const arr = ref.path.split('/')
  arr.shift()
  return arr
}

export const getIdentifiersFromRef = (ref: DocumentReference|null):Array<string> => {
  if (!ref) {
    return []
  }
  return getKeysFromRef(ref).filter((_, index) => index % 2 !== 0)
}
