import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeftLong,
  faChevronLeft, faEllipsis,
  // faBoxArchive,
  faFolderArrowDown, faFolderArrowUp,
  faGear,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'

library.add(faXmark, faChevronLeft, faEllipsis, faGear, faArrowLeftLong, faFolderArrowDown, faFolderArrowUp)

export const iconFamilyVariant = 'regular'
export const iconSize = 13

export const baseTwClasses = 'flex bg-surface-1 hover:bg-gray-100 rounded-full items-center justify-center'
