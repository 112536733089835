import { useLinkTo } from '@react-navigation/native'
import classNames from 'classnames'
import { ComponentType, memo, useRef } from 'react'
import { Channel } from 'stream-chat'

import { AvatarProps } from '~/elements/avatar/Avatar.d'
import { Badge } from '~/elements/badge/Badge'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useDateFormat } from '~/hooks/dateFns'
import { getTimestampFromIsoString } from '~/utils/firebase.firestore'

import { StreamMessage } from '../context/ChannelStateContext'
import { ChatContextValue } from '../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'
import { ChannelAvatars } from './ChannelAvatars'
// import { Avatar } from '~/elements/avatar/Avatar'

export const channelPreviewContainerTw = 'flex-row justify-start bg-surface-1 p-4 mb-2 rounded-2xl'

export type ChannelPreviewUIComponentProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = ChannelPreviewProps<At, Ch, Co, Ev, Me, Re, Us> & {
  /** If the component's channel is the active (selected) Channel */
  active?: boolean;
  /** Image of Channel to display */
  displayImage?: string;
  /** Title of Channel to display */
  displayTitle?: string;
  /** The last message received in a channel */
  lastMessage?: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>;
  /** Latest message preview to display, will be a string or JSX element supporting markdown. */
  latestMessage?: string | JSX.Element;
  /** Number of unread Messages */
  unread?: number;
};

export type ChannelPreviewProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  /** Comes from either the `channelRenderFilterFn` or `usePaginatedChannels` call from [ChannelList](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelList/ChannelList.tsx) */
  channel: Channel<At, Ch, Co, Ev, Me, Re, Us>;
  /** Current selected channel object */
  activeChannel?: Channel<At, Ch, Co, Ev, Me, Re, Us>;
  /** Custom UI component to display user avatar, defaults to and accepts same props as: [Avatar](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Avatar/Avatar.tsx) */
  Avatar?: ComponentType<AvatarProps>;
  /** Forces the update of preview component on channel update */
  channelUpdateCount?: number;
  key?: string;
  /** Custom UI component to display the channel preview in the list, defaults to and accepts same props as: [ChannelPreviewMessenger](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx) */
  Preview?: ComponentType<ChannelPreviewUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>>;
  /** Setter for selected Channel */
  setActiveChannel?: ChatContextValue<At, Ch, Co, Ev, Me, Re, Us>['setActiveChannel'];
  /** Object containing watcher parameters */
  watchers?: { limit?: number; offset?: number };
};

const UnMemoizedChannelPreviewMessenger = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: ChannelPreviewUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    active,
    channel,
    // displayImage,
    displayTitle,
    latestMessage,
    lastMessage,
    setActiveChannel,
    unread,
    watchers,
  } = props

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null)
  const linkTo = useLinkTo()

  const activeTw = active ? '' : ''
  const unreadTw = unread && unread >= 1 ? '' : ''

  // const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
    linkTo(`/conversations/${channel.id}`)
  }

  // console.log('lastMessage', lastMessage)

  const createdAt = lastMessage?.created_at
  // console.log('createdAt', createdAt)

  const time = useDateFormat(getTimestampFromIsoString(createdAt), 'time')

  const isChannelArchived = channel?.data.status === 'inactive'

  const mainTextColor = isChannelArchived ? 'text-basic-base' : 'text-basic-darkest'
  const secondaryTextColor = isChannelArchived ? 'text-basic-lighter' : 'text-basic-base'

  return (
    <Pressable
      tw={classNames(channelPreviewContainerTw, activeTw, unreadTw)}
      //   className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      testID="channel-preview-button"
      onPress={onSelectChannel}
      >
      <ChannelAvatars channel={channel} />
      <View tw="flex-grow flex-col">
        <View tw="flex-row items-center">
          <Text tw={classNames('flex-1 w-1 text-sm font-medium', mainTextColor)} numberOfLines={1}>{displayTitle}</Text>
          {!!unread && unread > 0 && <Badge color="danger" value={unread} tw="text-surface-1 py-1 ml-2" />}
        </View>
        <View tw="mt-1 flex-row items-center">
          {/* Adding w-1 to force text wrap here. @see https://jorgecolonconsulting.com/react-native-text-wrap/ */}
          <Text tw={classNames('flex-1 w-1 text-xs', secondaryTextColor)} numberOfLines={1}>{latestMessage}</Text>
          {!!time && time.length > 0 && <Text tw={classNames('text-xs ml-2', secondaryTextColor)}>{time}</Text>}
        </View>
      </View>
    </Pressable>
  )
}

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreviewMessenger = memo(
  UnMemoizedChannelPreviewMessenger,
) as typeof UnMemoizedChannelPreviewMessenger
