import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { EventTileSimple } from '~/components/panels/tiles/EventTile'
import { ScrollView } from '~/elements/containers/ScrollView'

import { EventsProps } from './EventsIncoming'

export const UserPastEvents: FC<EventsProps> = ({ events }) => {
  const { t } = useTranslation(['events'])

  return (
    <>
      <SectionHeading title={t('events:past.title')} tw="px-4 md:px-8" />
      <CarouselSectionContainer tw="pr-0 md:pr-8">
        <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-2 flex flex-row">
          {events && events.map((event) => (
            <EventTileSimple
              key={event.id}
              title={event?.model?.shortTitle || event?.model?.title}
              sessions={event.sessions}
              navigateTo={`/events/${event.id}`}
            />
          ))}
        </ScrollView>
      </CarouselSectionContainer>
      {/* TODO later: limit to x tiles first and add cta "see more past events" t('events:past.cta') */}
    </>
  )
}
