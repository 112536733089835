import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { View } from '~/elements/containers/View'

type TwoColumnsSectionContainerProps = {
  /**
   * Container tw
   */
  tw?: string
  mainTw?: string
  secondaryTw?: string

  Secondary?: ReactNode
  reverse?:boolean

  // secondaryMaxWidth?: 'md' | 'sm'
}

/**
 * A container that displays a second column on large screens
 */
export const SectionTwoColContainer: FC<TwoColumnsSectionContainerProps> = ({
  tw, Secondary, children, reverse = false, mainTw, secondaryTw,
}) => {

  const flexDirectionTw = reverse ? 'flex-row-reverse' : 'flex-row'

  const containerTw = classNames('', flexDirectionTw, tw)
  const primaryColumnTw = classNames('', mainTw)
  const secondaryColumnTw = classNames('', secondaryTw)

  return (
    <View tw={containerTw}>
      <View tw={primaryColumnTw} style={{ flexGrow: 2, flexBasis: 0 }}>{children}</View>
      <View tw={secondaryColumnTw} style={{ flexGrow: 1, flexBasis: 0 }}>{ Secondary ? <Secondary /> : null}</View>
    </View>
  )
}
