interface DefaultFeatureConfig {
  [key: string]: boolean | string
}

/**
 * Here is entrypoint to locally define our features configuration
 * Based on firebase remoteConfig, entry could either be:
 * - a boolean
 * - a number
 * - a serialized JSON (string)
 * Serialized JSON should be encapsulated with JSON.stringify()
 * See features hook to how cast is done accordingly
 */
export const defaultFeatureConfig: DefaultFeatureConfig = {
  enableMenuItemMyTrack: true,
  enableChatFeature: false,
  enableDeveloperMode: false,
  chat: JSON.stringify({
    isMenuItemDisplayed: true,
    isEnabled: true,
    isArchiveEnabled: true,
  }),
} as const
