import classNames from 'classnames'
import { FC } from 'react'

import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { ButtonColor } from '../buttons/Button.d'
import { Image, ImageProps } from '../images/Image'
import { AvatarShapeType, AvatarSizeType } from './Avatar.d'
import { getAvatarBackgroundColorString, getAvatarShapeString, getAvatarSizeString } from './tw.helpers'

type AvatarImageProps = {
  size: AvatarSizeType
  shape: AvatarShapeType
  color: ButtonColor
  image: ImageProps
  tw?: string,
}

export const AvatarImage:FC<AvatarImageProps> = ({
  size, shape, color, image, tw,
}) => {
  // console.log('-> AvatarImage', size, tw)

  const newTw = classNames(
    getAvatarBackgroundColorString(color),
    getAvatarSizeString(size),
    getAvatarShapeString(shape),
    tw,
  )

  // remove text color from tw
  const { nonTextTw } = getTextRelatedClasses(newTw)

  // console.log('AvatarImage / newTw', nonTextTw)

  return <Image {...image} tw={nonTextTw} />
}
