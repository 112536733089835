import { FC, ReactNode } from 'react'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { StructuredText } from '~/elements/text/StructuredText'
import { Text } from '~/elements/text/Text'

import { ReaderFooterProps } from '../BottomNavBar'

type CheckpointLayoutProps = ReaderFooterProps & {
  title: string
  content: ReactNode
}
export const CheckpointLayout: FC<CheckpointLayoutProps> = ({ title, content }) => (
  <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
    <TwoColGridSection>
      <ContentPlayerScrollView bgColor="yellow-100">
        <Text tw="text-center" category="h1">{title}</Text>
        <StructuredText data={content} />
      </ContentPlayerScrollView>
    </TwoColGridSection>
  </FullOverlayContainer>
)
