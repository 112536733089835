export const Collections = {
  CONTENTS: 'contents',
  CREDITS: 'credits',
  CAMPAIGNS: 'campaigns',
  CUSTOMERS: 'customers',
  INVITEES: 'invitees',
  MEETINGS: 'meetings',
  GROUP_MEETINGS: 'group_meetings',
  GROUP_MEETINGS_SESSIONS: 'group_meetings_sessions',
  USER_GROUP_MEETINGS_SESSIONS: 'user_group_meetings_sessions',
  EVALUATIONS: 'evaluations',
  TRACKS: 'tracks',
  LISTS: 'lists',
  ITEMS: 'items',
  USERS: 'users',
  VENDORS: 'vendors',
  INVOICES: 'invoices',
  PAYMENTS: 'payments',
  CONVERSATIONS: 'conversations',
} as const

export type Collection = typeof Collections[keyof typeof Collections]
