import { useLinkTo } from '@react-navigation/native'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CONVERSATION_MINDER_SLUG } from 'react-native-dotenv'

import { LoadingIndicator } from '~/components/chat'
import { Avatar } from '~/elements/avatar/Avatar'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDatoSvg } from '~/hooks/dato.assets'
import { useContractorBySlug } from '~/hooks/dato.contractors'
import { shadowStyle } from '~/theme/shadows'
import { getFullName } from '~/utils/people.helpers'

import { ConversationCardProps } from './ConversationCard.d'

export const ConversationCard: FC<ConversationCardProps> = ({ conversationId, tw }) => {
  const isConversationStarted = typeof conversationId === 'string'
  const { data } = useDatoSvg(isConversationStarted ? '42354374' : '42907841')
  const linkTo = useLinkTo()
  const { t } = useTranslation(['home'])

  const { isLoading: minderLoading, data: minderRecord } = useContractorBySlug(CONVERSATION_MINDER_SLUG)

  if (minderLoading) {
    return <LoadingIndicator />
  }
  const { picture } = minderRecord.contractor.minder
  const fullName = getFullName(minderRecord.contractor.minder)

  const goToConversation = () => {
    if (isConversationStarted) {
      linkTo(`/conversations/${conversationId}`)
    } else {
      linkTo('/conversations/new')
    }
  }

  return (
    <View tw={`flex-1 w-full p-8 mt-6 items-center rounded-xl bg-white ${tw}`} style={shadowStyle}>
      { !isConversationStarted && data && <Image {...data.upload} tw="w-48" /> }
      { isConversationStarted && picture && <Avatar title={fullName} image={picture} tw="border-2 border-white bg-brand-lighter w-48 h-48 mb-8" />}

      {!isConversationStarted
        ? (
          <>
            <Text
              tw="font-serif text-lg text-gray-900 mb-2 text-center font-semibold"
              >
              {t('home:cards.conversation.title')}
            </Text>
            <Text
              tw="text-gray-500 mb-6 text-center"
              >
              {`${t('home:cards.conversation.description')} ${new Date()}`}
            </Text>
          </>
        ) : (
          <>
            <Text
              tw="font-serif text-lg font-medium text-gray-900 text-center font-semibold"
              >
              {fullName}
            </Text>
            <Text
              tw="text-gray-500 mb-4 text-center"
              >
              {t('home:cards.conversation.minderWork')}
            </Text>
            <Text
              tw="text-gray-500 text-center text-s mb-6"
              >
              {`${t('home:cards.conversation.lastMessageOn')} ${new Date()}`}
            </Text>
          </>
      )}
      <Button
        tw="w-full"
        size="l"
        title={!isConversationStarted ? t('home:cards.conversation.ctaConversationNotStarted') : t('home:cards.conversation.ctaConversationStarted')}
        onPress={goToConversation}
      />
    </View>
  )
}
