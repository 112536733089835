import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { useCustomerCampaignByUserTrackDoc } from '~/hooks/firestore.customers.campaigns'
import { useUser } from '~/hooks/user.context'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { MeetingReschedulingScreen } from '~/screens/MeetingReschedulingScreen/MeetingReschedulingScreen'
import { MeetingSchedulingScreen } from '~/screens/MeetingSchedulingScreen/MeetingSchedulingScreen'
import { MinderScreen } from '~/screens/MinderScreen/MinderScreen'
import { MyTrackScreen } from '~/screens/MyTrackScreen/MyTrackScreen'
import { MyTrackScreenNotAllowed } from '~/screens/MyTrackScreen/MyTrackScreenNotAllowed'
import { NewTypeformScreen } from '~/screens/NewTypeformScreen/NewTypeformScreen'
import { SessionScreen } from '~/screens/SessionScreen/SessionScreen'

import { MyTrackStackParamList } from './MyTrackStackParamList'

const MyTrackStack = createStackNavigator<MyTrackStackParamList>()

export const MyTrackNavigator = () => {
  // console.log('-> MyTrackNavigator')

  const { userTrackDoc } = useUser()
  const { data: customerCampaign } = useCustomerCampaignByUserTrackDoc(userTrackDoc)
  const hasTrackAccessLevel = customerCampaign?.data()?.plan.trackAccessLevel

  const MyTrackAllowedGroupScreen = useCallback(() => (
    <MyTrackStack.Group>
      <MyTrackStack.Screen
        name="MyTrack"
        component={MyTrackScreen}
        options={{ headerShown: false }}
      />
      <MyTrackStack.Screen
        name="Session"
        component={SessionScreen}
        options={{
          headerShown: true,
          headerTransparent: true,
          // eslint-disable-next-line react/no-unstable-nested-components
          headerLeft: () => <GoBackHeaderButton />,
          header: MainLayoutHeader,
        }}
      />
      <MyTrackStack.Screen
        name="Minder"
        component={MinderScreen}
        options={{
          headerShown: true,
          headerTransparent: true,
          // eslint-disable-next-line react/no-unstable-nested-components
          headerLeft: () => <GoBackHeaderButton />,
          header: MainLayoutHeader,
        }}
      />
      <MyTrackStack.Screen
        name="Scheduling"
        component={MeetingSchedulingScreen}
        options={{
          headerShown: false,
        }}
      />
      <MyTrackStack.Screen
        name="Rescheduling"
        component={MeetingReschedulingScreen}
        options={{
          headerShown: false,
        }}
      />
      <MyTrackStack.Screen
        name="NewTypeform"
        component={NewTypeformScreen}
        options={{
          headerShown: false,
        }}
      />
    </MyTrackStack.Group>
  ), [])

  const MyTrackNotAllowedGroupScreen = useCallback(() => (
    <MyTrackStack.Group>
      <MyTrackStack.Screen
        name="MyTrackNotAllowed"
        component={MyTrackScreenNotAllowed}
        options={{ headerShown: false }}
      />
    </MyTrackStack.Group>
  ), [])

  return (
    <ErrorBoundary
      fallback={<ErrorScreen />}
      location="track"
      >
      <Suspense fallback={<LoadingScreen />}>
        <MyTrackStack.Navigator>
          {hasTrackAccessLevel
            ? MyTrackAllowedGroupScreen()
            : MyTrackNotAllowedGroupScreen()}
        </MyTrackStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
