import { FC } from 'react'

import { HotContentCardProps } from '~/components/cards/HotContent/HotContentCard.d'
import { WrappedHotContentCard } from '~/components/cards/HotContent/WrappedHotContentCard'
import { View } from '~/elements/containers/View'
import { useContentById } from '~/hooks/dato.content'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

const chooseTypeHotContent = (contentFromDato) => {
  const type = 'article'
  const imgData = {
    foreground: contentFromDato.coverMain,
    background: contentFromDato.coverBackground,
    backgroundColor: contentFromDato.coverBackgroundColor,
  }
  return { type, imgData, title: contentFromDato.title }
}

export const HotContentCard: FC<HotContentCardProps> = ({ tw }) => {
  const { isLoading, error, data: dataFromDato } = useContentById('65440507')

  if (isLoading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }
  if (!dataFromDato || !dataFromDato.content) {
    return <View />
  }
  const { type, title, imgData } = chooseTypeHotContent(dataFromDato.content)

  return (
    <WrappedHotContentCard
      type={type}
      title={title}
      imgData={imgData}
      tw={tw}
    />
  )
}
