import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBookmark,
  faCircleCheck,
} from '@fortawesome/pro-light-svg-icons'
import { useRoute } from '@react-navigation/core'
import { useLinkTo } from '@react-navigation/native'
import get from 'lodash/get'
import { FC, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import analytics from '~/analytics'
import { MultipleChoiceField } from '~/components/forms/multiple-choice/MultipleChoiceField'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Rating } from '~/elements/input/Rating'
import Overlay from '~/elements/overlays/Overlay'
import { Text } from '~/elements/text/Text'
import { useFormattedContentById } from '~/hooks/dato.content'
import { getHexColorFromTwColor } from '~/theme/helpers'

library.add(faCircleCheck, faBookmark)

export type OutroCtaSectionProps = Record<string, never>

interface RatingSegmentConversion {
  [key: number]: number
}
const ratingSegmentConversion: RatingSegmentConversion = {
  1: -1,
  2: -0.5,
  3: 0,
  4: 0.5,
  5: 1,
}
// ref segment rating https://segment.com/docs/connections/destinations/catalog/recombee-ai/

export const OutroCtaSection: FC<OutroCtaSectionProps> = ({ height }) => {
  const linkTo = useLinkTo()
  const route = useRoute()
  const contentId = get(route, 'params.contentId')
  const { data: dataContent } = useFormattedContentById(contentId)
  const authorSlug = dataContent?.author?.slug || null

  const { t } = useTranslation(['player', 'common'])

  const goodRatingOptions = [
    { key: 'topic', label: t('player:outro.ratingOverlay.ratingOptions.topic') },
    { key: 'length', label: t('player:outro.ratingOverlay.ratingOptions.length') },
    { key: 'detail', label: t('player:outro.ratingOverlay.ratingOptions.detail') },
    { key: 'style', label: t('player:outro.ratingOverlay.ratingOptions.style') },
    { key: 'narrator', label: t('player:outro.ratingOverlay.ratingOptions.narrator') },
    { key: 'easy', label: t('player:outro.ratingOverlay.ratingOptions.easy') },
    { key: 'stickiness', label: t('player:outro.ratingOverlay.ratingOptions.stickiness') },
  ]

  const badRatingOptions = [
    { key: 'topic', label: t('player:outro.ratingOverlay.ratingOptions.topic') },
    { key: 'too-short', label: t('player:outro.ratingOverlay.ratingOptions.tooShort') },
    { key: 'too-long', label: t('player:outro.ratingOverlay.ratingOptions.tooLong') },
    { key: 'detail', label: t('player:outro.ratingOverlay.ratingOptions.detail') },
    { key: 'style', label: t('player:outro.ratingOverlay.ratingOptions.style') },
    { key: 'narrator', label: t('player:outro.ratingOverlay.ratingOptions.narrator') },
    { key: 'hard', label: t('player:outro.ratingOverlay.ratingOptions.hard') },
    { key: 'stickiness', label: t('player:outro.ratingOverlay.ratingOptions.stickiness') },
  ]

  const [isModalVisible, setModalVisible] = useState(false)

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      rating: undefined,
      feedbacks: [],
    },
  })

  const rating = methods.watch('rating')
  const ratingCondition = rating !== undefined && rating > 0
  const ratingOptions = ratingCondition ? goodRatingOptions : badRatingOptions
  const ratingOverlayQuestion = ratingCondition ? t('player:outro.ratingOverlay.goodRatingQuestion') : t('player:outro.ratingOverlay.badRatingQuestion')

  type FormDataProps = {
    rating: number
    feedbacks: Array<string>
  }

  const onSubmit = (data: FormDataProps) => {
    // console.log(contentId, data.rating, data.feedbacks, authorSlug)
    analytics.track('content-reviewed', {
      itemId: contentId,
      rating: data.rating,
      feedbacks: data.feedbacks,
      author: authorSlug,
    })
    linkTo('/library')
  }

  return (
    <>
      <View tw="w-full max-w-xlp p-2 items-center justify-center" style={{ height }}>
        <View tw="flex-col">
          <Button
            tw="w-60"
            title={t('player:outro.ctaSaveComplete')}
            onPress={() => setModalVisible(true)}
            icon={{ name: 'circle-check' }}
            iconRight={false}
            justify="start"
          />
        </View>
      </View>

      {/* Rating overlay */}
      <Overlay isVisible={isModalVisible}>
        <Overlay.Title tw="mt-4 px-4 md:px-6 lg:px-12">{t('player:outro.ratingOverlay.title')}</Overlay.Title>
        <Overlay.Content scrollEnabled>
          <View tw="p-4 mt-4 px-4 md:px-6 lg:px-12">
            <View>
              <Text tw="mb-4">{t('player:outro.ratingOverlay.description')}</Text>
            </View>
            <View tw="w-full">
              <FormProvider {...methods}>
                {/* typescript alert about FormProvider accepting just one child was detected on one of our computers, no functional problem so fix later */}
                <Controller
                  name="rating"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <View tw="w-full mb-8 flex-row">
                      <Rating // futurely replaced by Custom Star Rating
                        defaultRating={0}
                        showRating={false}
                        selectedColor={getHexColorFromTwColor('orange-500')}
                        onFinishRating={(userRating: number) => {
                          field.onChange(ratingSegmentConversion[userRating])
                        }}
                      />
                    </View>
                  )}
                />
                {rating !== undefined && (
                  <View tw="w-full">
                    <Text tw="mb-4">{ratingOverlayQuestion}</Text>
                    <MultipleChoiceField name="feedbacks" color="basic" options={ratingOptions} />
                  </View>
                )}
              </FormProvider>
            </View>

          </View>
        </Overlay.Content>
        <Overlay.Footer>
          <View tw="flex flex-row justify-around mb-4 md:px-6 lg:px-12">
            <Button color="basic" title={t('player:outro.ratingOverlay.submit')} onPress={methods.handleSubmit(onSubmit)} disabled={rating === undefined} />
            {rating === undefined && <Button color="control" to="/library" title={t('player:outro.ratingOverlay.skip')} />}
          </View>
        </Overlay.Footer>
      </Overlay>

    </>
  )
}
