import classNames from 'classnames'
import { FC } from 'react'

import { getInitials } from '../../utils/people.helpers'
import { ButtonColor } from '../buttons/Button.d'
import { View } from '../containers/View'
import { Text } from '../text/Text'
import { AvatarShapeType, AvatarSizeType } from './Avatar.d'
import { getAvatarInitialsClassesFromProps } from './tw.helpers'

export type InitialsProps = {
  size?: AvatarSizeType
  shape?: AvatarShapeType
  color?: ButtonColor
  name?:string
  tw?:string
}

const baseContainerTw = 'items-center justify-center'
const baseTextTw = ''

export const Initials: FC<InitialsProps> = ({
  size = 'small', shape = 'circular', color = 'brand', name, tw,
}) => {
  const initials = getInitials(name)

  const { textTw, containerTw } = getAvatarInitialsClassesFromProps({
    size, color, shape, tw,
  })

  return (
    <View tw={classNames(containerTw, baseContainerTw)}>
      <Text tw={classNames(textTw, baseTextTw)}>{initials}</Text>
    </View>
  )
}
