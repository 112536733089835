import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native-elements'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { View } from '~/elements/containers/View'
// import { getFullName } from '~/elements/avatar/people.helpers'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { TileList } from '~/elements/list/TileList'
import { useAllContentsForCategoryById } from '~/hooks/dato.content.groups'
import { LibraryStackParamList } from '~/navigators/LibraryStackParamList'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { getNearestAspectRatio } from '~/theme/helpers'

export type CategoryScreenProps = StackScreenProps<LibraryStackParamList, 'Category'>

export const CategoryScreen:FC<CategoryScreenProps> = ({ route }) => {
  // console.log('-> CategoryScreen')
  // console.log('route', route)

  const { t } = useTranslation(['common'])
  //   const searchClient = useAlgoliaClient()

  const id = route.params['id-slug'].split('-')[0]
  const { isLoading, error, data } = useAllContentsForCategoryById(id)

  // console.log('route.params', route.params)

  if (isLoading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  const { allContents, contentGroup } = data

  // console.log('allContents', allContents)
  // console.log('contentGroup', contentGroup)

  if (!allContents) {
    return <LoadingScreen />
  }

  // override page header
  // navigation.setParams({title: contentGroup.title})

  // console.log('contentGroup', contentGroup)

  const items = allContents.map((item) => ({
    id: item?.id,
    title: item?.title,
    description: item?.excerpt?.length > 1 ? item.excerpt : undefined,
    illustration: {
      background: item?.coverBackground,
      foreground: item?.coverMain,
      backgroundColor: item?.coverBackgroundColor,
      format: item?.coverMainFormat,
    },
    // metadata: item?.author ? `${upperFirst(t('common:media.byAuthor'))} ${getFullName(item?.author)}` : undefined,
  }))

  // console.log('items', items)

  return (
    <MainContainer scrollEnabled>
      <BasicSectionContainer tw="md:mt-20 px-0 sm:px-4 md:px-8">

        {contentGroup && (
          <DoubleSvgImage
            aspectRatio={getNearestAspectRatio(376 / 180)}
            background={contentGroup.background}
            foreground={contentGroup.illustration}
            backgroundColor={contentGroup.backgroundColor}
            tw="sm:rounded-2xl mb-0"
            >
            <View tw="absolute right-3 bottom-3 bg-surface-1 opacity-50 p-2 rounded-xl">
              <Text>{`${items.length} ${t('common:glossary.article', { count: items.length })}`}</Text>
            </View>
          </DoubleSvgImage>
        )}

      </BasicSectionContainer>

      <ScreenHeadingSection title={contentGroup.title} description={contentGroup.description} tw="mt-4 md:mt-4" />

      <BasicSectionContainer tw="">
        <TileList data={items} tileSettings={{ orientation: 'landscape' }} />
      </BasicSectionContainer>
    </MainContainer>
  )
}

// Note: should replace all queries with https://github.com/datocms/react-datocms#live-real-time-updates to get live updates
