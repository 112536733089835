import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ProfileCard } from '~/components/panels/cards/ProfileCard'

export type ProfileCtaProps = Record<string, never>

export const PersonalityTestCta: FC<ProfileCtaProps> = () => {
  const { t } = useTranslation(['profile'])

  const url = '/evaluations'
  const title = t('profile:personality.ctaTitle')
  const description = t('profile:personality.description')

  return (
    <BasicSectionContainer tw="my-4">
      <ProfileCard type="personality" to={url} title={title} description={description} imgId="43919457" />
    </BasicSectionContainer>
  )
}
