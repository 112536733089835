/**
 * Navigation icons
 */
import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faSomething as falSomething,
// } from '@fortawesome/pro-light-svg-icons'
import {
  faBell as farBell,
  faBooks as farBooks,
  faBrain as farBrain,
  faCalendarWeek as farCalendarWeek,
  faCircleUser as farCircleUser,
  faComments as farComments,
  faCompass as farCompass,
  faGear as farGear,
  faHouse as farHouse,
  faMagnifyingGlass as farMagnifyingGlass,
  faMessageLines as farMessageLines,
  faStar as farStar,
  faUser as farUser,
  faUserChart as farUserChart,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBell,
  faBooks,
  faBrain,
  faCalendarWeek,
  faCircleUser,
  faComments,
  faCompass,
  faGear,
  faHouse,
  faMagnifyingGlass,
  faMessageLines,
  faStar,
  faUser,
  faUserChart,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  // Light style imports
  // falSomething
  // Regular style imports
  farBell,
  farBrain,
  farComments,
  farGear,
  farHouse,
  farMessageLines,
  farMagnifyingGlass,
  farStar,
  farUser,
  farCircleUser,
  farBooks,
  farCalendarWeek,
  farCompass,
  farUserChart,
  // Solid style imports
  faBell,
  faBrain,
  faComments,
  faGear,
  faHouse,
  faMessageLines,
  faMagnifyingGlass,
  faUser,
  faCircleUser,
  faStar,
  faBooks,
  faCalendarWeek,
  faCompass,
  faUserChart,
)
