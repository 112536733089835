import classNames from 'classnames'
import { FC } from 'react'

import { ButtonProps } from './Button.d'
import { getBaseButtonClasses, getBaseButtonIconClasses } from './button.helpers'
import { TwButton } from './TwButton'

export const Button: FC<ButtonProps> = ({
  tw = '',
  icon = {},
  variant = 'filled',
  color = 'basic',
  size = 'base',
  disabled,
  iconRight = true,
  rounded = true,
  block = false,
  justify = 'center',
  title = '',
  ...props
}) => {
  // console.log('-> Button', title, color, tw)
  /**
   * We transform all style related props into Tailwind classes
   * that we in turn pass to our low level TwButton component
   */
  const styleRelatedProps = {
    icon, variant, color, size, disabled, iconRight, rounded, block, justify,
  }
  const baseContainerClasses = getBaseButtonClasses(styleRelatedProps)
  // console.log('baseContainerClasses', baseContainerClasses)

  const baseIconClasses = getBaseButtonIconClasses({
    ...styleRelatedProps,
    hasTitle: title.length > 0,
  })

  let iconProps
  if (icon) {
    iconProps = {
      ...icon,
      tw: classNames(baseIconClasses, icon.tw),
    }
  }
  // console.log('iconProps', iconProps)

  const nextTw = classNames(baseContainerClasses, tw)
  // console.log(nextTw)

  return (
    <TwButton
      {...props}
      title={title}
      tw={nextTw}
      icon={iconProps}
      iconRight={iconRight}
      disabled={disabled}
    />
  )
}
