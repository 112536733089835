import classNames from 'classnames'
import { FC } from 'react'

import { Text } from '~/elements/text/Text'

import { TextProps } from './Text.d'

export const contentPageHeadingBaseTw = 'prose mt-2 mb-5 leading-snug text-center text-xl font-serif'

export const ContentPageHeading: FC<TextProps> = ({ tw, ...props }) => (
  <Text
// className="prose"
    tw={classNames(contentPageHeadingBaseTw, tw)}
    category="h2"
    {...props}
  />
)

export const contentPageParagraphBaseTw = 'prose leading-relaxed'

export const ContentPageParagraph: FC<TextProps> = ({ tw, markdown, ...props }) => (
  <Text
    // className="prose"
    markdown={markdown === undefined ? true : markdown}
    tw={classNames(contentPageParagraphBaseTw, tw)}
    category="p"
    {...props}
  />
)

export const contentPageMetadataBaseTw = 'prose mb-5 text-sm leading-none'

export const ContentPageMetadata: FC<TextProps> = ({ tw, ...props }) => <Text className="prose" tw={classNames(contentPageMetadataBaseTw, tw)} category="p" {...props} />
