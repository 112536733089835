import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { IconButton } from '~/elements/buttons/IconButton'
import { View } from '~/elements/containers/View'
import { useMediaQuery } from '~/hooks/mediaQuery'
// import { useFeatureConfigString } from '~/hooks/remoteConfig.wrapper'
import { useGenericChannels } from '~/hooks/stream.channels'
import { ChatStackParamList } from '~/navigators/ChatStackParamList'

import { ChatNoConversationScreen } from '../ChatNoConversationScreen/ChatNoConversationScreen'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import { ChannelCategoryType } from './ChannelCategoryType'
import { ConversationListSection } from './ConversationListSection'

export type MainScreenProps = StackScreenProps<ChatStackParamList, 'Main'>

export type NewChatType = {
  name: string
  initials: string
  chatId: string
  type: ChannelCategoryType
  description: string
  replyTime: string
}

const ChatConversationLists = () => {

  // const { status, data: chatRemoteConfig } = useFeatureConfigString('chat')
  const {
    isLoading: isLoadingGenericChannels,
    channels,
  } = useGenericChannels()

  // if (status === 'loading') {
  //   return null
  // }

  if (isLoadingGenericChannels) {
    return <LoadingScreen />
  }

  if (channels.length === 0) {
    return <ChatNoConversationScreen />
  }

  // const {
  //   isArchiveEnabled,
  // } = (chatRemoteConfig && JSON.parse(chatRemoteConfig)) || {}

  return (
    <BasicSectionContainer>
      <ConversationListSection status="active" />
      {/* {isArchiveEnabled && <ConversationListSection status="inactive" /> } */}
    </BasicSectionContainer>
  )
}

export const ChatMainScreen:FC<MainScreenProps> = () => {
  const { t } = useTranslation(['chat'])
  const { lg } = useMediaQuery()

  return (
    <MainContainer scrollEnabled>
      <View tw="flex-row mb-8 justify-between items-center w-full max-w-xlp pr-4 md:pr-8">
        <ScreenHeadingSection
          title={t('chat:main.heading.title')}
          description={t('chat:main.heading.description')}
          tw="w-max"
        />
        <IconButton icon={{ name: 'plus' }} color="brand" size={lg ? 'xl' : 'l'} to="/conversations/new" tw="flex mt-12 md:mt-20" />
      </View>
      <ChatConversationLists />
    </MainContainer>
  )
}
