import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'

console.log('useAsyncStorage')

export const localStorageKeys = {
  MAGIC_EMAIL: 'magicEmailLastSent', // {emailHash}-{timestamp}
  CAROUSEL_EVALUATION: 'hasUserSeenEvaluationPlayerCarousel',
  CAROUSEL_WELCOME: 'hasSeenWelcomeCarousel', // boolean
  EMAIL: 'userEmail', // email
  LAST_SIGN_IN_TIME: 'lastSigninTime', // Date
  EMAIL_STRATEGY: 'signInEmailStrategy',
  I18N_LNG: 'i18nextLng', // fr | en
  USER_SEGMENTS: 'user_segments', // Array<number> (FIXME)
}

const dateList = [localStorageKeys.LAST_SIGN_IN_TIME]

export type AsyncStorageKey = typeof localStorageKeys[keyof typeof localStorageKeys];

type LocalStorageType = string | boolean| number | null | undefined | Date

type AsyncStorageResponse = {
  isLoading:boolean
  value: LocalStorageType
  setItem: (newValue: LocalStorageType) => void
  removeItem: () => void
}

const isNumeric = (str: string):boolean => {
  if (typeof str !== 'string') {
    return false
  } // we only process strings!

  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str) && !isNaN(parseFloat(str))
}

/**
 * hook that synchronize local storage with async storage
 * TODO: align/migrate to react-query
 */
export const useAsyncStorage = (storageKey:AsyncStorageKey): AsyncStorageResponse => {

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [stateValue, setStateValue] = useState<LocalStorageType>()

  const expectDate = dateList.includes(storageKey)

  const updateStateFromStorage = async (updateLoading = true) => {

    // console.log('updateStateFromStorage', storageKey)

    if (updateLoading) {
      setIsLoading(true)
    }
    const item = await AsyncStorage.getItem(storageKey)
    // console.log('item in storage:', item)

    let parsedItem: LocalStorageType = item

    if (item === undefined) {
      parsedItem = undefined
    } else if (item === null) {
      parsedItem = null
    } else if (item === 'true') {
      parsedItem = true
    } else if (item === 'false') {
      parsedItem = false
    } else if (expectDate) {
      parsedItem = new Date(parseInt(item, 10))
    } else if (isNumeric(item)) {
      parsedItem = parseFloat(item)
    }

    // console.log('from local storage', parsedItem, typeof parsedItem)

    setStateValue(parsedItem)

    if (updateLoading) {
      setIsLoading(false)
    }

  }

  /**
   * Record new value in local storage
   */
  const setItem = async (val: LocalStorageType) => {

    if (val !== stateValue) {
      setIsLoading(true)
      if (val === null || val === undefined) {
        // console.log('will remove local value')
        await AsyncStorage.removeItem(storageKey)
      } else if (expectDate) {
        await AsyncStorage.setItem(storageKey, val.getTime().toString())
      } else {
        // console.log('will set local value to', val)
        await AsyncStorage.setItem(storageKey, val.toString())
      }
      // update read value
      await updateStateFromStorage(false)
      setIsLoading(false)
    } else {
      // console.log('skipping writing to local storage as same value already')
    }
  }

  useEffect(() => {
    // console.log('initial read from local storage')
    updateStateFromStorage()

    return () => {
      // console.log('unmounting')
    }
    // purposly ignored updateStateFromStorage
  }, [])

  const removeItem = async () => {
    if (stateValue) {
      setIsLoading(true)
      await AsyncStorage.removeItem(storageKey)
      await updateStateFromStorage(false)
      setIsLoading(false)
    } else {
      // console.log('skipping writing to local storage as same value already')
    }
  }

  // console.log('result', value)

  return {
    value: stateValue,
    isLoading,
    setItem,
    removeItem,
  }
}
