import { ButtonSize } from '~/elements/buttons/Button.d'
import { useMediaQuery } from '~/hooks/mediaQuery'

export const useBtnSizeFromVertBp = (): ButtonSize => {
  const mq = useMediaQuery()
  if (mq.mdv) {
    return 'base'
  }
  if (mq.smv) {
    return 'sm'
  }
  return 'xs'
}

export const useTitleSizeFromVertBp = () => {
  const mq = useMediaQuery()
  if (mq.xlv) {
    return 'text-xl'
  }
  if (mq.lgv) {
    return 'text-lg'
  }
  if (mq.mdv || mq.smv) {
    return 'text-base'
  }
  return 'text-base'

}

export const useDescriptionSizeFromVertBp = () => {
  const mq = useMediaQuery()
  if (mq.xlv || mq.lgv) {
    return 'text-base'
  }

  if (mq.mdv || mq.smv) {
    return 'text-sm'
  }
  return 'text-xs'

}
