import { MotiView } from 'moti'
import { ComponentProps, FC } from 'react'
import { useTailwind } from 'tailwind-rn'

type AnimatedViewProps = ComponentProps<typeof MotiView> & {tw?:string}

export const AnimatedView:FC<AnimatedViewProps> = ({ tw, style, ...props }) => {
  const tailwind = useTailwind()
  return <MotiView {...props} style={{ ...tailwind(tw), ...style }} />
}
