import { ComponentType, memo } from 'react'

import { Avatar as DefaultAvatar } from '~/elements/avatar/Avatar'
import { AvatarProps } from '~/elements/avatar/Avatar.d'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import type { StreamMessage } from '../context/ChannelStateContext'
import { isDayOrMoment, useTranslationContext } from '../context/TranslationContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'

export type EventComponentProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  /** Message object */
  message: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>;
  /** Custom UI component to display user avatar, defaults to and accepts same props as: [Avatar](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Avatar/Avatar.tsx) */
  Avatar?: ComponentType<AvatarProps>;
};

/**
 * Component to display system and channel event messages
 */
const UnMemoizedEventComponent = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: EventComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const { Avatar = DefaultAvatar, message } = props

  const { tDateTimeParser } = useTranslationContext('EventComponent')
  const {
    created_at = '', event, text, type,
  } = message

  const dateFormatter = (date: string | Date, format: string) => {
    const parsedDate = tDateTimeParser(date)
    const formattedDate = isDayOrMoment(parsedDate) ? parsedDate.format(format) : parsedDate
    return formattedDate
  }

  if (type === 'system') {
    return (
      <View>
        <View>
          <View />
          <Text>{text}</Text>
          <View />
        </View>
        <Text>
          {dateFormatter(created_at, 'dddd')}
          at
          {' '}
          {dateFormatter(created_at, 'hh:mm A')}
        </Text>
      </View>
    )
  }

  if (event?.type === 'member.removed' || event?.type === 'member.added') {
    const name = event.user?.name || event.user?.id
    const sentence = `${name} ${
      event.type === 'member.added' ? 'has joined the chat' : 'was removed from the chat'
    }`

    return (
      <View>
        <Avatar image={event.user?.image} name={name} user={event.user} />
        <Text>
          {sentence}
          {dateFormatter(created_at, 'LT')}
        </Text>
      </View>
    )
  }

  return null
}

export const EventComponent = memo(
  UnMemoizedEventComponent,
) as typeof UnMemoizedEventComponent
