import { FC } from 'react'
import { LinearProgress as RnLinearProgress, LinearProgressProps } from 'react-native-elements'

export type ProgressBarProps = LinearProgressProps
/**
 * See
 * https://reactnativeelements.com/docs/linearprogress
 * https://necolas.github.io/react-native-web/docs/progress-bar/
 */
export const ProgressBar: FC<LinearProgressProps> = (props) => <RnLinearProgress {...props} />
