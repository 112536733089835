import { yupResolver } from '@hookform/resolvers/yup'
import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useAuthSignInWithEmailAndPassword } from '@react-query-firebase/auth'
// import { browserLocalPersistence, setPersistence } from 'firebase/auth'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import * as Yup from 'yup'

import { EmailInput } from '~/components/forms/input-groups/EmailInput'
import { PasswordInput } from '~/components/forms/input-groups/PasswordInput'
// import { UserCredential } from '~/definitions/firebase.auth.types'
import { Button } from '~/elements/buttons/Button'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { localStorageKeys, useAsyncStorage } from '~/hooks/storage'
import { auth } from '~/navigators.shared/app.firebase.client'
import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'

import { AuthLayout } from '../../components/layouts/containers/AuthLayout'

export type SignInScreenProps = StackScreenProps<NonAuthStackParamList, 'SignIn'>

type TitleType = 'auth:signin.title' | 'auth:signin.error.title'
type DescriptionType = 'auth:signin.description' | 'auth:signin.error.codeExpired' | 'auth:signin.error.unexpected'

const SignInScreen:FC<SignInScreenProps> = ({ route }) => {
  // console.log('-> SignInScreen')

  // console.log('route', route)

  const {
    params: {
      email: emailFromParams,
      origin,
    } = {},
  } = route

  // console.log('-> SignInScreen')
  const mutation = useAuthSignInWithEmailAndPassword(auth)
  const { removeItem: removeUserEmail } = useAsyncStorage(localStorageKeys.EMAIL)

  const forcePasswordMode = false

  const { t } = useTranslation(['auth', 'forms', 'onboarding'])
  const { lg } = useMediaQuery()

  const linkTo = useLinkTo()

  const [title, setTitle] = useState<TitleType>('auth:signin.title')
  const [description, setDescription] = useState<DescriptionType>('auth:signin.description')

  const email = emailFromParams ? decodeURIComponent(emailFromParams) : ''

  const schema = Yup.object().shape({
    email: Yup.string().email(t('forms:email.invalid')).required(t('forms:string.required')),
    password: Yup.string(),
  })

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { email, password: '' },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (origin) {
      setTitle('auth:signin.error.title')
      if (origin === 'code-expired') {
        setDescription('auth:signin.error.codeExpired')
      } else if (origin === 'unexpected') {
        setDescription('auth:signin.error.unexpected')
      }
    }
  }, [origin])

  const signIn = async (values) => {
    // console.log('Pressed sign in button')
    if (forcePasswordMode) {
      // console.log('Signing in with email and password')
      // with password, we directly login
      if (Platform.OS === 'web') {
        // await setPersistence(auth, browserLocalPersistence)
        // console.log('Persistence set')
      }
      mutation.mutate({ email: values.email, password: values.password })
      // console.log('Login wit pwd OK')
      await removeUserEmail()
    } else {
      // console.log('Will call linkTo')
      const encodedEmail = encodeURIComponent(values.email)
      linkTo(`/auth/link?email=${encodedEmail}&origin=signin`)
    }
  }

  return (
    <AuthLayout title={t(title)} description={t(description)} showLanguageSelector>
      <FormProvider {...methods}>
        <EmailInput
          name="email"
          label={t('auth:signin.emailLabel')}
          placeholder={t('auth:signin.emailPlaceholder')}
          align={lg ? 'left' : 'center'}
          onEnterKeyPress={methods.handleSubmit(signIn)}
        />
        {forcePasswordMode && (
          <PasswordInput
            name="password"
            label={t('auth:signin.passwordLabel')}
            placeholder={t('auth:signin.passwordPlaceholder')}
            align={lg ? 'left' : 'center'}
            onEnterKeyPress={methods.handleSubmit(signIn)}
          />
        )}
        <Button
          onPress={methods.handleSubmit(signIn)}
          title={t('auth:signin.primaryCta')}
          color="basic"
          size="xl"
          tw="mt-12"
        />
      </FormProvider>
      <Button
        variant="ghost"
        to="/auth/register"
        title={t('auth:signin.secondaryCta')}
        size="xl"
      />
    </AuthLayout>
  )
}

export default SignInScreen
