import {
  Value as RemoteConfigValue,
} from 'firebase/remote-config'
import { FIREBASE_LOCAL_REMOTE_CONFIG } from 'react-native-dotenv'
import {
  UseQueryResult,
} from 'react-query'

import { defaultFeatureConfig } from '~/constants/featureConfig'

import {
  useRemoteConfigAll, useRemoteConfigBoolean, useRemoteConfigNumber, useRemoteConfigString,
} from './remoteConfig'

export type FeatureConfigAllValues = {
  [key: string]: RemoteConfigValue | FeatureConfigValue;
};

console.log('remoteConfig.wrapper (web)')

// Custom class that mimic RemoteConfigValue behavior for local use
class FeatureConfigValue {

  value: string | number | boolean

  constructor(value: string | number | boolean) {
    this.value = value
  }

  /**
   * Gets the value as a boolean.
   *
   * The following values (case insensitive) are interpreted as true:
   * "1", "true", "t", "yes", "y", "on". Other values are interpreted as false.
   */
  asBoolean() {
    return ['1', 1, 'true', true, 'yes', 'y', 'on'].includes(this.value)
  }

  /**
   * Gets the value as a number. Comparable to calling <code>Number(value) || 0</code>.
   */
  asNumber() {
    return Number(this.value) || 0
  }

  /**
   * Gets the value as a string.
   */
  asString() {
    return this.value ? this.value.toString() : undefined
  }

  toString() {
    return this.value
  }
}

type ValueToType<T> = (value: FeatureConfigValue) => T | undefined

const asBoolean: ValueToType<boolean> = (value: FeatureConfigValue) => value.asBoolean()
const asNumber: ValueToType<number> = (value: FeatureConfigValue) => value.asNumber()
const asString: ValueToType<string> = (value: FeatureConfigValue) => value.asString()

function localConfigFunction<T>(key: string, valueToType: ValueToType<T>): UseQueryResult<T, unknown> {
  const data = new FeatureConfigValue(defaultFeatureConfig[key])

  return {
    status: 'success',
    data: valueToType(data) as T,
    error: undefined,
  }
}

function localAllConfigFunction<T>(): UseQueryResult<T, unknown> {
  const data = Object.keys(defaultFeatureConfig).reduce((featureConfig, defaultFeatureConfigKey) => ({
    ...featureConfig,
    [defaultFeatureConfigKey]: new FeatureConfigValue(defaultFeatureConfig[defaultFeatureConfigKey]),
  }), {}) as T

  return {
    status: 'success',
    data,
    error: undefined,
  }
}

function useConfig<T>(
  remoteConfigFunction: (key: string, userQueryOptions:any) => UseQueryResult<T, unknown>,
  key: string,
  valueToType: ValueToType<T>,
): UseQueryResult<T, unknown> {
  return FIREBASE_LOCAL_REMOTE_CONFIG === 'true' ? localConfigFunction<T>(key, valueToType) : remoteConfigFunction(key, undefined)
}

function useConfigAll<T>(
  remoteConfigFunction: (userQueryOptions:any) => UseQueryResult<T, unknown>,
): UseQueryResult<T, unknown> {
  return FIREBASE_LOCAL_REMOTE_CONFIG === 'true' ? localAllConfigFunction<T>() : remoteConfigFunction(undefined)
}

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `string` from a FeatureConfig parameter.
 * As useRemoteConfigString will return undefined when a ConfigValue is not remotely defined,
 * useFeatureConfigString data should be safe typed with value && JSON.parse(value) || {} when used for a serialized JSON value to avoid parsing an undefined value
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigString = (key: string): UseQueryResult<string, unknown> => useConfig<string>(useRemoteConfigString, key, asString)

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `number` from a FeatureConfig parameter.
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigNumber = (key: string): UseQueryResult<number, unknown> => useConfig<number>(useRemoteConfigNumber, key, asNumber)

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `boolean` from a FeatureConfig parameter.
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigBoolean = (key: string): UseQueryResult<boolean, unknown> => useConfig<boolean>(useRemoteConfigBoolean, key, asBoolean)

/**
 * Convenience method similar to useRemoteConfigValue. Returns all FeatureConfig parameters.
 */
export const useFeatureConfigAll = (): UseQueryResult<FeatureConfigAllValues, unknown> => useConfigAll<FeatureConfigAllValues>(useRemoteConfigAll)
