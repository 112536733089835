/**
 * Auth navigator handles different auth screens
 */

import { createStackNavigator } from '@react-navigation/stack'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

// import { Text } from 'react-native-elements'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { MagicLinkAwaitScreen } from '~/screens/MagicLinkAwaitScreen/MagicLinkAwaitScreen'
import { MagicLinkReturnScreen } from '~/screens/MagicLinkReturnScreen/MagicLinkReturnScreen'
import ResetPasswordScreen from '~/screens/ResetPasswordScreen/ResetPasswordScreen'
import SignInScreen from '~/screens/SignInScreen/SignInScreen'
import { SignUpNotAllowedScreen } from '~/screens/SignUpNotAllowedScreen/SignUpNotAllowedScreen'
import SignUpScreen from '~/screens/SignUpScreen/SignUpScreen'

import { NonAuthStackParamList } from './NonAuthStackParamList.d'

const Stack = createStackNavigator<NonAuthStackParamList>()

const NonAuthNavigator = () => {
  // console.log('-> NonAuthNavigator')

  useTranslation(['auth', 'forms'])

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="SignIn"
          component={SignInScreen}
          options={{
            title: 'Sign in',
            // When logging out, a pop animation feels intuitive
            // animationTypeForReplace: signInCheckResult.signedIn ? 'pop' : 'push',
          }}
        />
        <Stack.Screen name="SignUp" component={SignUpScreen} />
        <Stack.Screen name="MagicLinkAwait" component={MagicLinkAwaitScreen} />
        <Stack.Screen name="MagicLinkReturn" component={MagicLinkReturnScreen} />
        <Stack.Screen name="NotAllowed" component={SignUpNotAllowedScreen} />
        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
      </Stack.Navigator>
    </Suspense>
  )
}

export default NonAuthNavigator
