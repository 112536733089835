import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { FC, KeyboardEvent } from 'react'
import {
  FieldName, FieldValues, RegisterOptions, useController, useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TextInput } from '~/elements/input/TextInput'
import { TextInputProps } from '~/elements/input/TextInputProps'
import { getHexColorFromTwColor } from '~/theme/helpers'

const testEmailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export type EmailInputProps<
  TFieldValues extends FieldValues = FieldValues
  > = TextInputProps & {
    name:FieldName<TFieldValues>
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >
    defaultValue?:string
    align?:'left' | 'right' | 'center'
    onEnterKeyPress:() =>void
  }

export const EmailInput:FC<EmailInputProps> = ({
  name,
  rules = {},
  defaultValue = '',
  onEnterKeyPress,
  align = 'left',
  helperTw,
  wrapperTw,
  labelTw,
  containerTw,
  inputTw,
  placeholderTextColor,
  ...rest
}) => {

  const { control, setError, clearErrors } = useFormContext()

  const {
    field: {
      onChange, onBlur, name: fieldName, value, ref,
    },
    fieldState: { invalid, error },
    // formState: { touchedFields, dirtyFields },

  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  })

  const { t } = useTranslation(['forms'])

  const validateEmail = async (val:string) => {

    if (setError) {
      if (!testEmailregex.test(val || '') && val.length > 5) {
        // console.log('Not a valid email (regex)')
        setError(fieldName, { type: 'regex', message: t('forms:email.invalid') })
      } else if (error) {
        clearErrors(fieldName)
      }
    }
    // Note: deliverability check only perfomed on submit to avoid multiple calls

    onChange(val)

  }

  const debouncedValidateEmail = debounce(validateEmail, 25)

  // console.log('fieldState', invalid, isTouched, error)

  return (
    <TextInput
      errorMessage={invalid ? error?.message : undefined}
      testID={name}
      onChangeText={(val:string) => debouncedValidateEmail(val)} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={fieldName} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      autoFocus
      keyboardType="email-address"
      textContentType="emailAddress"
      autoCompleteType="email"
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && onEnterKeyPress) {
          onEnterKeyPress()
        }
      }}
      {...rest}
      // custom styles for email input
      wrapperTw={classNames('w-full', wrapperTw)}
      helperTw={classNames(`text-${align}`, helperTw)}
      labelTw={classNames(`font-normal text-sm leading-relaxed text-gray-500 text-${align}`, labelTw)}
      containerTw={classNames('border-gray-300', containerTw)}
      inputTw={classNames(`bg-transparent text-${align}`, inputTw)}
      placeholderTextColor={classNames(getHexColorFromTwColor('gray-300'), placeholderTextColor)}
    />

  )
}
