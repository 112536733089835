import { useEffect, useState } from 'react'
import type { Channel } from 'stream-chat'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'

export const useIsChannelMuted = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    channel: Channel<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('useIsChannelMuted')

  const [muted, setMuted] = useState(channel.muteStatus())

  useEffect(() => {
    const handleEvent = () => setMuted(channel.muteStatus())

    client.on('notification.channel_mutes_updated', handleEvent)
    return () => client.off('notification.channel_mutes_updated', handleEvent)
  }, [muted])

  return muted
}
