import { getObjectFitPositionFromTw } from './tw.gatsbyImages.helpers'

export const getResizeMode = (tw = '') => {
  const { objectFit, objectPosition, tw: nextTw } = getObjectFitPositionFromTw(tw)

  if (!objectFit && !objectPosition) {
    return { resizeMode: undefined, tw: nextTw }
  }

  switch (objectFit) {
  case 'contain':
  case 'cover':
    return { resizeMode: objectFit, tw: nextTw }
  default:
    break
  }

  switch (objectPosition) {
  case 'center':
    return { resizeMode: objectPosition, tw: nextTw }
  default:
    break
  }

  throw new Error(`unhandled objectFit/objectPosition ${objectFit}/${objectPosition}`)

}
