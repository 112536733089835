import { Text } from '~/elements/text/Text'

import { CursorViz } from './CursorViz'

export const Viz3dCursor = ({
  eyebrow, linkedValues, evaluationDoc, maxVal, getRatio, getCoupleFromArr,
}) => (
  <>
    {eyebrow && <Text tw="text-orange-700 uppercase mb-4">{eyebrow}</Text>}
    {true && getCoupleFromArr(linkedValues.map((o) => o.key)).map(([key1, key2]) => (
      <CursorViz
        key={`${key1}-${key2}`}
        evaluationDoc={evaluationDoc}
        dim1={linkedValues.find((o) => o.key === key1)}
        dim2={linkedValues.find((o) => o.key === key2)}
        maxVal={maxVal}
        getRatio={getRatio}
      />
    ))}
  </>
)
