import { createStackNavigator } from '@react-navigation/stack'
import { Suspense } from 'react'

import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { CategoryScreen } from '~/screens/CategoryScreen/CategoryScreen'
import { ExploreContentScreen } from '~/screens/ExploreContentScreen/ExploreContentScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { LibraryStackParamList } from './LibraryStackParamList'

const LibraryStack = createStackNavigator<LibraryStackParamList>()
const HeaderLeft = () => <GoBackHeaderButton label={false} color="control" to="/library" />

// console.log('-> LibraryNavigator')
export const LibraryNavigator = () => (
  <ErrorBoundary
    fallback={<ErrorScreen />}
    location="library"
    >
    <Suspense fallback={<LoadingScreen />}>
      <LibraryStack.Navigator initialRouteName="Explore">
        <LibraryStack.Screen
          name="Explore"
          component={ExploreContentScreen}
          options={{ headerShown: false }}
        />
        <LibraryStack.Screen
          name="Category"
          component={CategoryScreen}
          options={{
              headerShown: true,
              headerTransparent: true,
              headerLeft: HeaderLeft,
              header: MainLayoutHeader,
          }}
        />
      </LibraryStack.Navigator>
    </Suspense>
  </ErrorBoundary>
)
