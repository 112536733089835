/**
 * Content categories icons
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBlender as fatBlender,
  faBrainCircuit as fatBrainCircuit,
  faCompass as fatCompass,
  faFaceNoseSteam as fatFaceNoseSteam,
  faFamily as fatFamily,
  faFish as fatFish,
  faHouseLaptop as fatHouseLaptop,
  faPenField as fatPenField,
  faPeople as fatPeople,
  faPersonDigging as fatPersonDigging,
  faPersonHiking as fatPersonHiking,
  faPersonSnowboarding as fatPersonSnowboarding,
  faPersonToPortal as fatPersonToPortal,
  faSmoking as fatSmoking,
  faToolbox as fatToolbox,
  faToothbrush as fatToothbrush,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBlender,
  faBrainCircuit,
  faCompass,
  faFaceNoseSteam,
  faFamily,
  faFish,
  faHouseLaptop,
  faPenField,
  faPeople,
  faPersonDigging,
  faPersonHiking,
  faPersonSnowboarding,
  faPersonToPortal,
  faSmoking,
  faToolbox,
  faToothbrush,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faPenField,
  faPersonSnowboarding,
  faToolbox,
  faFamily,
  faPeople,
  faBlender,
  faHouseLaptop,
  faPersonDigging,
  faPersonHiking,
  faFaceNoseSteam,
  faFish,
  faToothbrush,
  faPersonToPortal,
  faSmoking,
  faBrainCircuit,
  faCompass,
  fatBlender,
  fatBrainCircuit,
  fatCompass,
  fatFaceNoseSteam,
  fatFamily,
  fatFish,
  fatHouseLaptop,
  fatPenField,
  fatPeople,
  fatPersonDigging,
  fatPersonHiking,
  fatPersonSnowboarding,
  fatPersonToPortal,
  fatSmoking,
  fatToolbox,
  fatToothbrush,
)
