import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { FC } from 'react'
import { useTailwind } from 'tailwind-rn'

import { Pressable, PressableProps } from '~/elements/containers/Pressable'
import { getFontSizePt } from '~/theme/helpers'
import { getFontSizeTw } from '~/utils/tailwind/tw.texts.helpers'

import { faPrefixes, faSizeMap } from './Icon/faConstants'
import { IconProps } from './Icon/IconProps.d'

export const LowLevelIcon = ({
  pack = 'fa', name, variant = 'solid', size, sizeNum, tw, ...props
}: IconProps) => {
  const tailwind = useTailwind()
  // console.log('Icon', name, size, sizeNum)

  if (pack === 'fa') {
    // console.log('icon name', name)

    // If size explicitely given, it sets fontSize and size is then 1em
    const sizeEnum = size
    let nextSizeNum = sizeNum
    const iconStyle = {}

    const twStyle = tailwind(tw)
    // console.log(`twStyle`, twStyle)

    /**
     * Interpret Tailwind classes
     */
    if (tw) {
      // we get icon size from height
      if (twStyle.height && !sizeNum) {
        nextSizeNum = twStyle.height
        delete twStyle.height
      }

      // Text color
      if (twStyle.color && !iconStyle.color) {
        iconStyle.color = twStyle.color
        delete twStyle.color
      }

      // Background color
      if (twStyle.backgroundColor && !iconStyle.backgroundColor) {
        iconStyle.backgroundColor = twStyle.backgroundColor
        delete twStyle.backgroundColor
      }
    }

    const { result: fSizeTw } = getFontSizeTw(tw)
    const fSize = getFontSizePt(fSizeTw || 'text-base')
    // console.log('fSize', fSize)
    if (size) {
      nextSizeNum = fSize * faSizeMap[size]
    }

    if (!nextSizeNum) {
      nextSizeNum = fSize
    }

    if (nextSizeNum && !sizeEnum) {
      // console.log('Explicit icon size', sizeNum)
      // iconStyle.fontSize = sizeNum
    }

    // console.log('Will render FontAwesomeIcon with following props:')
    // console.log('icon', [faPrefixes[variant], name])
    // console.log('size', nextSizeNum)
    // console.log('props', props)

    return <FontAwesomeIcon icon={[faPrefixes[variant], name]} size={nextSizeNum} style={iconStyle} {...props} />
  }

  return null
}

export const Icon:FC<IconProps & Omit<PressableProps, 'children'>> = ({ name, onPress, ...props }) => {
  if (!name) {
    console.warn('No icon name provided. Props:', props)

    return null
  }

  if (onPress) {
    // Wraps with a Pressable
    return (
      <Pressable onPress={onPress}>
        <Icon name={name} {...props} />
      </Pressable>
    )
  }

  // TODO "raised => add a circle"
  // TODO reverse => use inverse on font-awesome

  return <LowLevelIcon name={name} {...props} />

}
