import { useTranslation } from 'react-i18next'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'

// import { ContentAuthorBox } from './ContentAuthorBox'
import { ContentCoverDesktopTitleBox } from './ContentCoverDesktopTitleBox'
import { ContentDesktopCover } from './ContentDesktopCover'

export const ContentCoverDesktopScreen = (props) => {
  const { t } = useTranslation(['player'])
  const {
    id, title, illustration,
  } = props

  const SecondaryComponent = (
    <ContentDesktopCover illustration={illustration} />
  )

  return (
    <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
      <TwoColGridSection
        padded
        Secondary={SecondaryComponent}
        >
        <View tw="flex-col justify-between px-4 pb-8">
          {/* {author && author.picture && author.picture.responsiveImage && (
            <ContentAuthorBox author={author} metadata={metadata} backgroundColor={coverBackgroundColor} tw="justify-start mb-4" />
          )} */}
          <ContentCoverDesktopTitleBox title={title} containerTw="mb-8" textTw="text-left" />
          <View tw="flex flex-row justify-start">
            <Button
              title={t('player:start')}
              to={`/content/${id}/page/1`}
              hookKey="ArrowRight"
            />
          </View>
        </View>

      </TwoColGridSection>
    </FullOverlayContainer>
  )
}
