// import { useLinkTo } from '@react-navigation/native'
// import { openBrowserAsync } from 'expo-web-browser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'
// import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'

type ErrorScreenProps = {
  // handleBackToSignIn?: () => void
  // error ?: Error
}

export const ErrorScreen:FC<ErrorScreenProps> = () => {

  // const linkTo = useLinkTo()
  const { t } = useTranslation(['common'])
  // const auth = useAuth()

  return (
    <SafeAreaView tw="flex-1">
      <View tw="flex-col justify-around w-full p-16">

        <View tw="items-center mb-12">
          <Icon name="circle-info" sizeNum={60} />
          <Text tw="text-3xl font-semibold text-center">{t('common:errorPage.genericTitle')}</Text>
          <Text tw="font-normal text-center text-sm">{t('common:errorPage.genericDescription')}</Text>
        </View>
        <View tw="max-w-3xl">
          {/* <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.reset')}
            onPress={() => {
              resetError()
            }}
          /> */}
          {/* <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.comeBackHome')}
            onPress={() => {
              linkTo('/')
            } }
          /> */}
          {/* <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.contactUs')}
            onPress={() => {
              openBrowserAsync('mailto:hello@mindday.com')
            }}
          /> */}
        </View>
      </View>

    </SafeAreaView>
  )
}
