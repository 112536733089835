import { useRoute } from '@react-navigation/core'

import { useEvaluationContext } from '~/context/EvaluationContext'

import { EvaluationPlayerDimensionScreen } from './Page.ScoreDimension'
import { EvaluationPlayerSectionScreen } from './Page.Section'

/**
 * TODO adapt StructuredText renderer for non web platfroms
 */
const EvaluationPlayerScreenWrapper = () => {
  const { data: { doc, model: { toc, slug } } } = useEvaluationContext()

  // console.log('toc', toc)

  const route = useRoute()
  // console.log('Generic Page route', route)

  if (!toc) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, key, ...props } = toc[Number(route.params.pageNb) - 1]
  // console.log(`props`, props)

  if (__typename === 'ScoreDimensionGroupRecord') {
    return <EvaluationPlayerSectionScreen {...props} id={key} />
  }
  if (__typename === 'ScoreDimensionRecord') {
    return <EvaluationPlayerDimensionScreen {...props} evaluationSlug={slug} dimensionKey={key} evaluationDoc={doc} />
  }

  throw new Error(`unknown page type ${__typename}`)
}

export default EvaluationPlayerScreenWrapper
