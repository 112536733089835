import type { TranslationLanguages } from 'stream-chat'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import { useComponentContext } from '../context/ComponentContext'
import { useMessageContext } from '../context/MessageContext'
import { useTranslationContext } from '../context/TranslationContext'
// import { Avatar as DefaultAvatar } from '~/elements/avatar/Avatar'

export const QuotedMessage = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>() => {
  const { Attachment } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>(
    'QuotedMessage',
  )
  const { isMyMessage, message } = useMessageContext<At, Ch, Co, Ev, Me, Re, Us>('QuotedMessage')
  const { userLanguage } = useTranslationContext('QuotedMessage')

  // const Avatar = ContextAvatar || DefaultAvatar

  const { quoted_message } = message
  if (!quoted_message) {
    return null
  }

  const quotedMessageText = quoted_message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`]
    || quoted_message.text

  const quotedMessageAttachment = quoted_message?.attachments?.length ? quoted_message.attachments[0] : null

  if (!quotedMessageText && !quotedMessageAttachment) {
    return null
  }

  return (
    <>
      <View tw={`mb-4 ${isMyMessage() ? 'bg-surface-1' : ''}`}>
        {/* {quoted_message.user && (
          <Avatar
            image={quoted_message.user.image}
            name={quoted_message.user.name || quoted_message.user.id}
            size={20}
            user={quoted_message.user}
          />
        )} */}
        <View tw="justify-end bg-gray-100 max-w-sm">
          {quotedMessageAttachment && <Attachment attachments={[quotedMessageAttachment]} />}
          <Text>{quotedMessageText}</Text>
        </View>
      </View>
      {message.attachments?.length && message.quoted_message ? (
        <Attachment attachments={message.attachments} />
      ) : null}
    </>
  )
}
