import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignalBarsSlash } from '@fortawesome/pro-solid-svg-icons'
import { FC, memo } from 'react'

import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Image, ImageProps } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

import { useTranslationContext } from '../context/TranslationContext'
// import placeholder from '../../assets/str-chat__connection-error.svg'
import { LoadingChannels } from '../Loading/LoadingChannels'

library.add(faSignalBarsSlash)

export type ChatDownProps = {
  /** The type of error */
  type: string;
  /** The image url for this error */
  image?: ImageProps;
  /** The error message to show */
  text?: string;
};

const UnMemoizedChatDown: FC<ChatDownProps> = (props) => {
  const { text, type = 'Error', image } = props

  const { t } = useTranslationContext('ChatDown')

  return (
    // className='str-chat__down'
    <View tw="">
      <LoadingChannels />
      {/* str-chat__down-main */}
      <View tw="">
        <Image testID="chatdown-img" {...image} />
        <Icon testID="chatdown-icon" name="signal-bars-slash" variant="solid" size="2x" tw="bg-base rounded-full" />
        <Text tw="">{type}</Text>
        <Text tw="">{text || t('Error connecting to chat, refresh the page to try again.')}</Text>
      </View>
    </View>
  )
}

/**
 * A simple indicator that chat functionality isn't available, triggered when the Chat API is unavailable or your network isn't working.
 */
export const ChatDown = memo(UnMemoizedChatDown) as typeof UnMemoizedChatDown
