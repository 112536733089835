import { SearchResponse } from '@algolia/client-search'
import head from 'lodash/head'
import omit from 'lodash/omit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { connectHitInsights } from 'react-instantsearch-dom'
import { connectInfiniteHits } from 'react-instantsearch-native'
import { FlatList } from 'react-native'
import aa from 'search-insights'
import { useTailwind } from 'tailwind-rn'

import { ContentRecord } from '~/definitions/aloglia'

import { Hit } from './Hit'

const localizedKeys = ['title', 'excerpt', 'readTime', 'wordCount']

type InfiniteHitsProps = SearchResponse<ContentRecord> &{
  categories:Array<any>
}

const HitWithInsights = connectHitInsights(aa)(Hit)

/**
 * Content search results list presenter
 *
 * TODO: handle infinite loading
 */
const InfiniteHits:FC<InfiniteHitsProps> = ({
  hits, categories,
}) => {
  // console.log('InfiniteHits', hits)

  const { i18n } = useTranslation()
  const tailwind = useTailwind()

  // We use a FlatList as the list could grow
  return (
    <FlatList
      data={hits.map((item) => ({
        ...omit(item, [...Object.keys(item).filter((key) => localizedKeys.some((p) => key.startsWith(`${p}_`))), 'cover']),
        illustration: {
          foreground: { ...item?.cover?.main, format: item?.cover?.main.url.split('.').pop() },
          background: { ...item?.cover?.background, format: item?.cover?.background.url.split('.').pop() },
          backgroundColor: item?.cover?.backgroundColor,
          format: item?.cover?.format,
        },
        ...localizedKeys.reduce((acc, key) => ({ ...acc, [key]: item[`${key}_${i18n.language}`] || item[`${key}_fr`] }), {}),
        category: categories?.find((o) => o.id === head(item.categories)?.id),
      }))}
      keyExtractor={(item) => item.objectID}
      // ItemSeparatorComponent={() => <View  />}
      // onEndReached={() => hasMore && refineNext()}
      renderItem={({ item }) => <HitWithInsights hit={item} />}
      style={tailwind('overflow-visible mt-4 md:mt-12 lg:mt-20')}
    />
  )
}

export default connectInfiniteHits(InfiniteHits)
