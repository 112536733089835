import { useFirestoreDocument, UseFirestoreHookOptions, useFirestoreQuery } from '@react-query-firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import { meetingStatus, meetingStatusDetails } from '~/constants/firestore.meetings'
import { DocumentReference, EventuallyDocumentReference } from '~/definitions/firebase.firestore.types'
import { MeetingData } from '~/definitions/firestore.meeting'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getMeetingRef } from '~/queries/firestore.helpers'
import {
  getFirstMeetingsByParticipantsQuery,
  getMeetingByCalendlyIdQuery,
  getMeetingsByStatusQuery,
  getMeetingsForTrack,
  getUserOrientationMeetingsByTrackQuery,
  getUserOrientationMeetingsQuery,
  nextPlannedMeetingsQuery,
  pastMeetingsQuery,
} from '~/queries/firestore.meetings'

import { ArrayDocsQueryResult, GetSingleDocQueryResult } from '../definitions/firestore.queries'
import { useDurationFormat } from './dateFns'
import {
  getKeysFromRef,
  withArrayDocQueryResultAdapter,
  withGetDocResultAdapter,
  withSingleDocQueryResultAdapter,
  withTakeFirstResultAdapter,
} from './firestore.hooks.helpers'
import { useCurrentUserRef } from './firestore.users'
import { useDurationToTimestamp, useNow } from './time'
import { useUser } from './user.context'

const useNextPlannedMeetingsQuery = (
  limit:number,
  options: UseFirestoreHookOptions = { subscribe: true },
) => {
  const userRef = useCurrentUserRef()
  // console.log(`userRef.path`, userRef.path)
  const now = useNow()
  const queryVar = { userRef, now }
  const queryOpt = { limit }
  const query = nextPlannedMeetingsQuery(queryVar, queryOpt)

  const keys = [Collections.MEETINGS, queryVar, queryOpt]
  return useFirestoreQuery<MeetingData>(keys, query, options)
}

export const useCurrentUserNextPlannedSessions = (
  limit = 1,
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserNextSessions')
  const res = useNextPlannedMeetingsQuery(limit, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserNextPlannedSession = (
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserNextSessions')
  const res = useNextPlannedMeetingsQuery(1, options)

  return withTakeFirstResultAdapter(res)
}

export const useCurrentUserPastSessions = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef()
  const now = useNow()
  const queryVar = { userRef, now }
  const queryOpt = { limit: 3 }
  const query = pastMeetingsQuery(queryVar, queryOpt)
  const keys = [Collections.MEETINGS, queryVar, queryOpt]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserDoneSessions = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef()
  const queryVar = { userRef, status: meetingStatus.DONE }
  const queryOpt = { limit: 3 }
  const query = getMeetingsByStatusQuery(queryVar, queryOpt)
  const keys = [Collections.MEETINGS, queryVar, queryOpt]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useMeetingByCalendlyId = (
  calendlyId: string,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<MeetingData> => {
  // console.log('-> useMeetingByCalendlyId')
  const queryVar = { calendlyId }
  const query = getMeetingByCalendlyIdQuery(queryVar)
  const keys = [Collections.MEETINGS, queryVar]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withSingleDocQueryResultAdapter(res)
}

export const useCurrentUserLatestTrackMeetings = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  const { userTrackDoc } = useUser()
  const queryVar = { trackRef: userTrackDoc.ref || null }
  const query = getMeetingsForTrack(queryVar)
  const keys = [Collections.MEETINGS, queryVar]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserOrientationMeetings = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserOrientationMeetings')
  const userRef = useCurrentUserRef()
  const queryVar = { userRef }
  const query = getUserOrientationMeetingsQuery(queryVar)
  const keys = [Collections.MEETINGS, queryVar]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useMeetingByRef = (
  ref:EventuallyDocumentReference<MeetingData>,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<MeetingData> => {
  const keys = getKeysFromRef(ref)
  const res = useFirestoreDocument<MeetingData>(keys, ref, options)

  return withGetDocResultAdapter(res)

}

export const useMeetingById = (meetingId?: string): GetSingleDocQueryResult<MeetingData> => {
  // console.log('-> useMeetingById')
  const ref = getMeetingRef(meetingId)

  return useMeetingByRef(ref)
}

export const useCurrentUserTrackOrientationMeeting = (
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<MeetingData> => {

  const { userTrackDoc } = useUser()
  // console.log('current user ref ', data?.ref.path)
  const queryVar = { trackRef: userTrackDoc.ref || null }
  const query = getUserOrientationMeetingsByTrackQuery(queryVar)
  const keys = [Collections.MEETINGS, queryVar]
  const result = useFirestoreQuery<MeetingData>(keys, query, options)
  // console.log('results', result?.data?.docs.map((doc) => console.log(`yup-${doc.id}`)).join(', '))

  return withSingleDocQueryResultAdapter(result)
}

export const useGetMeetingsForTrack = (
  trackRef: DocumentReference<UserTrackData>,
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<MeetingData> => {
  const queryVar = { trackRef }
  const query = getMeetingsForTrack(queryVar)
  const keys = [Collections.MEETINGS, queryVar]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  const queryRes = withArrayDocQueryResultAdapter(res)

  if (queryRes.data) {
    return {
      ...queryRes,
      data: queryRes.data.filter((doc) => meetingStatusDetails[doc.data().status].countAgainstPlan === true),
    }
  }

  return queryRes
}

export const useGetFirstMeetingWithMinder = (
  minder : string| null = null,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef() || null
  // console.log('useGetFirstMeetingWithMinder', minder, userRef?.path)
  const queryVar = { userRef, minder }
  const queryOpt = { limit: 1 }
  const query = getFirstMeetingsByParticipantsQuery(queryVar, queryOpt)
  const keys = [Collections.MEETINGS, queryVar, queryOpt]
  const res = useFirestoreQuery<MeetingData>(keys, query, options)

  return withSingleDocQueryResultAdapter(res)
}

export const useGetDurationSinceFirstMeetingWithMinder = (
  minder:string,
  options: UseFirestoreHookOptions = { subscribe: true },
) => {
  const { isLoading, error, data: firstMeetingDoc } = useGetFirstMeetingWithMinder(minder, options)

  const since = firstMeetingDoc?.data()?.start_time
  // console.log('since', since)

  const duration = useDurationToTimestamp(since)
  // console.log('duration', duration);
  const format = Object.entries(duration).map(([key, val]) => ({ key, val })).filter((o) => o.val > 0)[0]?.key
  const durationStr = useDurationFormat(duration, { format: [format] })

  return { isLoading, error, data: duration.isFuture ? durationStr : null }
}
