// Not working as well b/c of ref ?
import { NavigationAction } from '@react-navigation/native'
import { GestureResponderEvent, Text, TextProps } from 'dripsy'
import {
  FC, MouseEvent, ReactNode, useRef,
} from 'react'

import { useStateAwareTailwind } from '~/hooks/tailwind'

export type LinkProps = {
  to: string
  action?: NavigationAction
  target?: string
  onPress?: (e: MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent) => void
} & (TextProps & {
  children: ReactNode
})

export const Link: FC<LinkProps> = ({
  children, to, style, ...restProps
}) => {
  const ref = useRef(null)
  const tailwind = useStateAwareTailwind(ref)

  const base = 'text-sm text-white active:text-brand-lightest font-medium rounded-md bg-surface-1 bg-opacity-0 px-3 py-2 hover:bg-opacity-10'

  return (
    <Text
      accessibilityRole="link"
      href={to}
      draggable={false}
      onPress={() => {
        // console.log('Pressed')
      }}
      tabIndex={0}
      ref={ref}
      // aria-current={link.active ? 'page' : 'false'}
      style={{
        ...tailwind(base),
        ...style,
      }}
      {...restProps}
      >
      {children}
    </Text>
  )

  // return <RnLink to={to} ref={ ref} {...restProps} >{children}</RnLink>
}
