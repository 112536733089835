import { ComponentType, memo } from 'react'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
// import { Avatar as DefaultAvatar } from '~/elements/avatar/Avatar'
import { AvatarProps } from '~/elements/avatar/Avatar.d'
import { View } from '~/elements/containers/View'
import { Popable } from '~/elements/overlays/popable'

import { useChatContext } from '../context/ChatContext'
import { useMessageContext } from '../context/MessageContext'
import { useTranslationContext } from '../context/TranslationContext'
import { LoadingIndicator } from '../Loading/LoadingIndicator'
import { DeliveredCheckIcon } from './icons'
import { getReadByTooltipText } from './utils'

export type MessageStatusProps = {
  Avatar?: ComponentType<AvatarProps>;
  messageType?: string;
};

const UnMemoizedMessageStatus = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
  // props: MessageStatusProps,
  ) => {
  // const { Avatar: propAvatar, messageType = 'simple' } = props

  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('MessageStatus')
  // const { Avatar: contextAvatar } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageStatus')
  const {
    isMyMessage, lastReceivedId, message, readBy, threadList,
  } = useMessageContext< At, Ch, Co, Ev, Me, Re, Us>('MessageStatus')
  const { t } = useTranslationContext('MessageStatus')

  // const Avatar = propAvatar || contextAvatar || DefaultAvatar

  if (!isMyMessage() || message.type === 'error') {
    return null
  }

  const justReadByMe = readBy?.length === 1 && readBy[0].id === client.user?.id

  if (message.status === 'sending') {
    return (
      <Popable
        // testID='message-status-sending'
        // className={`str-chat__message-${messageType}-status`}
        content={t('Sending...')}
        >
        <LoadingIndicator />
      </Popable>
    )
  }

  if (readBy?.length && !threadList && !justReadByMe) {
    // const lastReadUser = readBy.filter((item) => item.id !== client.user?.id)[0]

    return (
      <Popable
        // testID='message-status-read-by'
        // className={`str-chat__message-${messageType}-status`}
        content={getReadByTooltipText(readBy, t, client)}
        >
        {/* <Avatar
          image={lastReadUser.image}
          name={lastReadUser.name || lastReadUser.id}
          size={15}
          user={lastReadUser}
        /> */}
        {readBy.length > 2 && (
          <View
            // className={`str-chat__message-${messageType}-status-number`}
            testID="message-status-read-by-many"
            >
            {readBy.length - 1}
          </View>
        )}
      </Popable>

    )
  }

  if (message.status === 'received' && message.id === lastReceivedId && !threadList) {
    return (
      <Popable
        // className={`str-chat__message-${messageType}-status`}
        // testID='message-status-received'
        content={t('Delivered')}
        >
        <DeliveredCheckIcon />
      </Popable>
    )
  }

  return null
}

export const MessageStatus = memo(UnMemoizedMessageStatus) as unknown as typeof UnMemoizedMessageStatus
