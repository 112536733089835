import { DrawerContentOptions } from '@react-navigation/drawer'
import { DrawerDescriptorMap } from '@react-navigation/drawer/lib/typescript/src/types'
import {
  CommonActions,
  DrawerActions,
  DrawerNavigationState,
  ParamListBase,
  useLinkBuilder,
  useNavigation,
} from '@react-navigation/native'
import { ReactElement, ReactNode } from 'react'

import DrawerItem from './DrawerItem'

type DrawerItemListProps = Omit<DrawerContentOptions, 'contentContainerStyle' | 'style'> & {
  state: DrawerNavigationState<ParamListBase>
  // navigation: DrawerNavigationHelpers
  descriptors: DrawerDescriptorMap
}

/**
 * Component that renders the navigation list in the drawer.
 */
export default function DrawerItemList({
  state,
  descriptors,
  activeTintColor,
  inactiveTintColor,
  activeBackgroundColor,
  inactiveBackgroundColor,
  itemStyle,
  labelStyle,
}: DrawerItemListProps) {
  // console.log('DrawerItemList/labelStyle', labelStyle)
  const buildLink = useLinkBuilder()
  const navigation = useNavigation()

  return (state.routes.map((route, i) => {
    const focused = i === state.index
    const { title, drawerLabel, drawerIcon } = descriptors[route.key].options

    // TODO only go reset one level on each successive press (ex page > content > library)
    const onPressDrawerItem = () => {
      // console.log('state', state)
      // console.log('route', route)

      // reset child state if already within that stack
      if (state.routes[state.index]?.name === route?.name) {
        // console.log('should reset')

        // new state is taken from existing but without child navigation for current stack
        const nextState = { ...state }
        nextState.routes[state.index] = { ...nextState.routes[state.index], params: undefined }

        delete nextState.routes[state.index].state

        // console.log(`nextState`, nextState)

        navigation.reset(nextState)
      } else {
        // console.log('should NOT reset')
        navigation.dispatch({
          ...(focused
            ? DrawerActions.closeDrawer()
            : CommonActions.navigate(route.name)),
          target: state.key,
        })
      }
    }

    let label
    if (drawerLabel !== undefined) {
      label = drawerLabel
    } else {
      label = title !== undefined ? title : route.name
    }

    return (
      <DrawerItem
        key={route.key}
        testID={route.key}
        label={label}
        icon={drawerIcon}
        focused={focused}
        activeTintColor={activeTintColor}
        inactiveTintColor={inactiveTintColor}
        activeBackgroundColor={activeBackgroundColor}
        inactiveBackgroundColor={inactiveBackgroundColor}
        labelStyle={labelStyle}
        style={itemStyle}
        to={buildLink(route.name, route.params)}
        onPress={onPressDrawerItem}
      />
    )
  }) as ReactNode) as ReactElement
}
