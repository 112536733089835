import { library } from '@fortawesome/fontawesome-svg-core'
import * as far from '@fortawesome/pro-regular-svg-icons'
import * as fas from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import { FC } from 'react'

import { ContainedIcon } from '~/elements/icons/ContainedIcon'
import {
  getLightBgColorForColorShade, getTextColorForColorShade,
} from '~/theme/helpers'

import { ColorShade } from '../colors/ColorShades'
import { View } from '../containers/View'

type DatoIconType = {
  name:string
  style: 'regular' | 'solid'
  categoryColor: ColorShade
}

/**
 * Renders a Dato block Icon within Structured Text
 */
export const DatoIcon:FC<DatoIconType> = ({ name, style, categoryColor }) => {

  const iconId = camelCase(`fa-${name}`)
  const icon = style === 'solid' ? fas[iconId] : far[iconId]
  if (icon) {
    library.add(icon)
  } else {
    // eslint-disable-next-line no-console
    console.warn(`undefined icon ${name} for style ${style}`)
  }
  return (
    <View tw="w-full flex-row justify-center">
      <ContainedIcon
        testID={`contained-icon-${name}`}
        icon={{
          variant: style,
          name,
          tw: getTextColorForColorShade(categoryColor),
          sizeNum: 30,
        }}
        tw={classNames('flex-row rounded-full h-16 w-16 justify-center items-center', getLightBgColorForColorShade(categoryColor))}
      />
    </View>
  )
}
