import uniqBy from 'lodash/uniqBy'
import { Dispatch, SetStateAction, useEffect } from 'react'
import type { Channel, Event } from 'stream-chat'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'
import { getChannel } from '../utils'

export const useNotificationMessageNewListener = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
    customHandler?: (
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
    event: Event<At, Ch, Co, Ev, Me, Re, Us>,
  ) => void,
    allowNewMessagesFromUnfilteredChannels = true,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>(
    'useNotificationMessageNewListener',
  )

  useEffect(() => {
    const handleEvent = async (event: Event<At, Ch, Co, Ev, Me, Re, Us>) => {
      if (customHandler && typeof customHandler === 'function') {
        customHandler(setChannels, event)
      } else if (allowNewMessagesFromUnfilteredChannels && event.channel?.type) {
        const channel = await getChannel(client, event.channel.type, event.channel.id)
        setChannels((channels) => uniqBy([channel, ...channels], 'cid'))
      }
    }

    client.on('notification.message_new', handleEvent)

    return () => {
      client.off('notification.message_new', handleEvent)
    }
  }, [customHandler])
}
