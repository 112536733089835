/**
 * Screen wrapping a sub home navigator: containing all Chat stack
 */

import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'

import { ChatStackParamList } from '~/navigators/ChatStackParamList'

import { ChatNavigator } from '../../navigators/ChatNavigator'

export type HomeChatScreenProps = StackScreenProps<ChatStackParamList>

export const HomeChatScreen: FC<HomeChatScreenProps> = () => <ChatNavigator />
