import noop from 'lodash/noop'
import { FC, useEffect } from 'react'
import { Platform } from 'react-native'

import type { BackdropProps } from './Backdrop.d'

const Backdrop:FC<BackdropProps> = ({
  childrenRef,
  onPress,
  popoverRef,
  visible,
}) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handler = (event: any) => {
        if (
          !popoverRef?.current?.contains(event.target)
        && !childrenRef?.current?.contains(event.target)
        && visible
        ) {
          onPress()
        }
      }

      document.addEventListener('mousedown', handler)

      return () => document.removeEventListener('mousedown', handler)
    }
    return noop()
  }, [visible, onPress, popoverRef, childrenRef])

  return null
}
export default Backdrop
