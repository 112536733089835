import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { WrappedHotContentCardProps } from '~/components/cards/HotContent/WrappedHotContentCard.d'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { Text } from '~/elements/text/Text'
import { getHexColorFromTwColor } from '~/theme/helpers'
import { shadowLightStyle } from '~/theme/shadows'

export const WrappedHotContentCard: FC<WrappedHotContentCardProps> = ({
  title, imgData, type, tw,
}) => {
  const { t } = useTranslation(['home'])

  return (
    <Pressable tw={`flex-row p-4 mt-6 items-center rounded-xl bg-white ${tw}`} style={shadowLightStyle}>
      <DoubleSvgImage
        tw="h-16 w-16 rounded-xl mr-4"
        aspectRatio="1:1"
        background={imgData.background}
        foreground={imgData.foreground}
        backgroundColor={imgData.backgroundColor}
      />
      <View tw="h-16 flex-shrink items-start">
        <Text tw="font-serif text-gray-900 mb-1 font-semibold">{title}</Text>
        <View tw="flex-row items-center">
          <Text tw="font-serif text-brand-base mr-1">{t(`home:cards.hotContent.${type}.cta`)}</Text>
          <Icon color={getHexColorFromTwColor('brand-base')} name="arrow-right-long" variant="regular" />
        </View>
      </View>
    </Pressable>
  )
}
