import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { getNearestAspectRatio } from '~/theme/helpers'

/**
 * Cover page for our content player
 */
export const ContentDesktopCover = ({ illustration }) => {
  const cardContainerTw = 'w-full rounded-2xl'

  return (
    <DoubleSvgImage
      aspectRatio={getNearestAspectRatio(369 / 512)}
      {...illustration}
      tw={cardContainerTw}
    />
  )
}
