import { FC } from 'react'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { View } from '~/elements/containers/View'
import { ContentPageHeading } from '~/elements/text/Player.FontTypes'

import { ReaderFooterProps } from '../BottomNavBar'

type SectionLayoutProps = ReaderFooterProps & {
  title: string
  // theme?: string
}
export const SectionLayout: FC<SectionLayoutProps> = ({ title }) => (
  <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
    <TwoColGridSection>
      <ContentPlayerScrollView>
        <View tw="h-128 justify-center">
          <ContentPageHeading>{title}</ContentPageHeading>
        </View>
      </ContentPlayerScrollView>
    </TwoColGridSection>
  </FullOverlayContainer>
)
