import { LinearGradient } from 'expo-linear-gradient'
import { useTailwind } from 'tailwind-rn'

import { getLinearGradientStartEndPoint } from '~/elements/containers/gradient.helpers'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { getHexColorFromTwColor } from '~/theme/helpers'

const Track = () => {
  const { startPoint, endPoint } = getLinearGradientStartEndPoint('bg-gradient-to-r')

  const tailwind = useTailwind()

  return (
    <LinearGradient
      colors={['transparent', getHexColorFromTwColor('gray-300'), getHexColorFromTwColor('gray-300'), 'transparent']}
      style={tailwind('h-1 rounded-full w-full')}
      start={startPoint}
      end={endPoint}
      locations={[0, 0.3, 0.7]}
    />
  )
}

const Cursor = ({ position }) => <View tw="bg-brand-base h-6 w-6 rounded-full absolute" style={{ left: position }} />

export const CursorViz = ({
  eyebrow, dim1, dim2, maxVal, evaluationDoc, getRatio,
}) => {
  const val1 = evaluationDoc.get(`scores.${dim1.key}`)
  // console.log('val1', val1)
  const val2 = evaluationDoc.get(`scores.${dim2.key}`)
  // console.log('val2', val2)
  const value = getRatio(val1, val2, maxVal)

  // console.log('value', value)

  const position = `${Math.round(value * 100)}%`
  // console.log('position', position)

  return (
    <>
      {eyebrow && <Text tw="text-orange-700 uppercase mb-8 mt-2">{eyebrow}</Text>}
      <View tw="flex-row mb-4">
        <View tw="flex-none w-32 text-right"><Text tw="mr-4">{`${dim1.name} (${val1})`}</Text></View>
        <View tw="flex-grow justify-center items-center">
          <Track />
          <Cursor position={position} />
        </View>
        {/* <Slider
          thumbTintColor={getColor('brand-base')}
          minimumTrackTintColor={getColor('gray-300')}
          maximumTrackTintColor={getColor('gray-300')}
          disabled
          minimumValue={0}
          maximumValue={1}
          value={value}
        /> */}
        <View tw="flex-none  w-32 text-left"><Text tw="ml-4">{`${dim2.name} (${val2})`}</Text></View>
      </View>
    </>
  )
}
