import classNames from 'classnames'
import { FC } from 'react'
import slugify from 'slugify'

import ListItem from '~/elements/list/ListItem'
import { ListItemProps } from '~/elements/list/ListItem.d'

import { View } from '../containers/View'

export type ListProps = {
  data: Array<ListItemProps>

  /**
   * Tailwind classes for List container
   */
  tw?:string

  /**
   * tailwind classes that will be passed to the ListItem container
   */
  listItemStyle?: string

  /**
   * tailwind classes that will be passed to the underlying Title text
   */
  listItemTitleStyle?: string
  /**
   * tailwind classes that will be passed to the underlying Description text
   */
  listItemDescriptionStyle?: string

  /**
   * tailwind classes that will be passed to the underlying Eyebrow text
   */
  listItemEyebrowStyle?: string

  /**
   * tailwind classes that will be passed to the underlying Button component
   */
  listItemCtaStyle?: string

  /**
   * tailwind classes that will be passed to the underlying Icon component
   */
  listItemIconStyle?: string

  /**
   * A set of properties applicable to all items
   */
  iconPosition?: 'left' | 'right'

  disabled?: boolean

}

export const baseContainerStyleTw = 'mb-4'

/**
 * A list that isnt as smart as a flat list
 */
export const List: FC<ListProps> = ({
  data, tw,
  listItemStyle, listItemTitleStyle,
  listItemDescriptionStyle, listItemEyebrowStyle,
  listItemCtaStyle, listItemIconStyle,
  iconPosition: listIconPosition,
  disabled: listDisabled,
}) => (
  <View tw={classNames(baseContainerStyleTw, tw)}>
    {data.map(({ iconPosition, disabled, ...item }, i) => (
      <ListItem
        key={slugify(item.title || i.toString())}
        {...item}
        iconPosition={listIconPosition || iconPosition}
        disabled={listDisabled || disabled}
        isFirst={i === 0}
        isLast={i === (data.length - 1)}
        tw={listItemStyle}
        titleStyle={listItemTitleStyle}
        descriptionStyle={listItemDescriptionStyle}
        eyebrowStyle={listItemEyebrowStyle}
        ctaStyle={listItemCtaStyle}
        iconStyle={listItemIconStyle}
      />
    ))}
  </View>
)
