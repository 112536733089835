import { BaseSyntheticEvent } from 'react'
import type { User } from 'stream-chat'

import type { StreamMessage } from '../../context/ChannelStateContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'
import type { ReactEventHandler } from '../types'

export type UserEventHandler<Us extends DefaultUserType<Us> = DefaultUserType> = (
  event: BaseSyntheticEvent,
  user: User<Us>,
) => void;

export const useUserHandler = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    message?: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>,
    eventHandlers?: {
    onUserClickHandler?: UserEventHandler<Us>;
    onUserHoverHandler?: UserEventHandler<Us>;
  },
  ): {
  onUserClick: ReactEventHandler;
  onUserHover: ReactEventHandler;
} => ({
    onUserClick: (event) => {
      if (typeof eventHandlers?.onUserClickHandler !== 'function' || !message?.user) {
        return
      }
      eventHandlers.onUserClickHandler(event, message.user)
    },
    onUserHover: (event) => {
      if (typeof eventHandlers?.onUserHoverHandler !== 'function' || !message?.user) {
        return
      }

      eventHandlers.onUserHoverHandler(event, message.user)
    },
  })
