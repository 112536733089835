import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connectSearchBox } from 'react-instantsearch-native'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { TextInput } from '~/elements/input/TextInput'
import { ActivityIndicator } from '~/elements/progress/ActivityIndicator'

import { SuggestedSearchQueries } from './SuggestedSearchQueries'

library.add(faMagnifyingGlass)

/**
 * Execute search from 3 characters
 * Should match https://www.algolia.com/doc/api-reference/widgets/search-box/react/
 * only debounce is implemented should try a mix of debounce + throttle
 */
class SearchBox extends Component {
  // console.log(`SearchBox`, this.props.currentRefinement)

  timerId = null

  constructor(props) {
    super(props)
    this.state = { value: props.currentRefinement }
  }

  // ({ currentRefinement, refine, ...props })

  onChangeDebounced = (value) => {
    const { refine, delay } = this.props

    clearTimeout(this.timerId)
    this.timerId = setTimeout(() => refine(value), delay)

    this.setState(() => ({
      value,
    }))
  }

  render() {
    const { value } = this.state

    const {
      isSearchStalled, isSearchActive, t, onFocus, onCancel,
    } = this.props

    // console.log(`this.props`, this.props)

    return (
      <>
        <View tw="flex-row mb-2">
          <View tw="flex-row w-full justify-center">
            <TextInput
              onChangeText={this.onChangeDebounced}
              value={value}
              placeholder={t('library:explore.search.placeholder')}
              leftIcon={{
                name: 'magnifying-glass',
                variant: 'regular',
              }}
              leftIconContainerTw="px-4"
              // eslint-disable-next-line react/no-unstable-nested-components
              RightComponent={() => (isSearchStalled ? <ActivityIndicator /> : null)}
              // RightComponent={() => <ActivityIndicator /> }
              wrapperTw="mb-0 mt-0 flex-grow"
              containerTw="border rounded-full bg-surface-1 mt-0"
              inputTw="bg-transparent py-4 focus:border focus:border-2 rounded-full"
              clearButtonMode="while-editing"
              onFocus={onFocus}
            />
            {isSearchActive && (
              <Button
                title={t('library:explore.cancel')}
                onPress={onCancel}
                variant="ghost"
                tw="py-0"
              />
            )}
          </View>
        </View>
        {isSearchActive && <SuggestedSearchQueries refine={this.onChangeDebounced} currentRefinement={value} /> }
      </>
    )
  }
}

const SearchBoxWithresults = withTranslation('library')(SearchBox)

export default connectSearchBox(SearchBoxWithresults)
