import cleanDeep from 'clean-deep'
import last from 'lodash/last'
import omit from 'lodash/omit'

import { ContentRecommendationType } from './ContentRecommendationType'

const keysToLocalize = ['excerpt', 'title', 'description', 'readTime', 'wordCount', 'slug']
const keysToParse = ['coverMain', 'coverBackground']

const parseUrl = (input) => {
  const [base, query] = input.split('?')
  const params = query.split('&').reduce((acc, kv) => {
    const [key, value] = kv.split('=')
    acc[key] = value

    return acc
  }, {})

  return { base, params }
}

const parseCoverMain = (input) => {

  if (!input) {
    return undefined
  }

  const { base, params } = parseUrl(input)

  return {
    coverMain: {
      url: base,
      width: Number(params?.w),
      height: Number(params?.h),
      format: last(base.split('.')),
    },
    coverMainFormat: params?.format,
  }
}

const parseCoverBackground = (input) => {
  if (!input) {
    return undefined
  }
  const { base, params } = parseUrl(input)

  return {
    coverBackground: {
      url: base,
      width: Number(params?.w),
      height: Number(params?.h),
      format: last(base.split('.')),
    },
    coverBackgroundColor: params?.color,
  }
}

export const mapLocalisedRecommendation = (item, lng, languages): ContentRecommendationType => {
  if (item.error) {
    throw new Error(item.error)
  }
  // console.log('item', item)

  return item.recomms.map((o) => {
    // console.log('o', o)
    let lngNonAvailable = false
    const res = cleanDeep({
      recommId: item.recommId,
      id: o.id,
      ...omit(o.values, Object.keys(o.values).filter((key) => keysToParse.includes(key) || keysToLocalize.some((token) => key.startsWith(token)))),

      // parse some keys that efectively embbed multiple values
      ...parseCoverMain(o.values.coverMain),
      ...parseCoverBackground(o.values.coverBackground),

      // only return local value
      ...keysToLocalize.reduce((acc, key) => {
        let requestedValue = o.values[`${key}_${lng}`]
        if (!requestedValue) {
          // fallback to any other listed key
          const substituteLng = languages.filter((fkey) => fkey !== lng).find((lang) => o.values[`${key}_${lang}`] !== undefined)
          if (substituteLng) {
            requestedValue = o.values[`${key}_${substituteLng}`]
            lngNonAvailable = true
          }
        }
        acc[key] = requestedValue

        return acc
      }, {}),
    })
    if (lngNonAvailable) {
      res.metadata = { hasSubstitutedLanguage: true }
    }

    if (!res.categories) {
      res.categories = []
    }

    // console.log('res', res)

    return res
  })
}
