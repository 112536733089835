import { getHexColorFromTwColor } from '~/theme/helpers'

export const cardBasicShadowStyle = {
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 10 },
  shadowOpacity: 0.03,
  shadowRadius: 40,
  elevation: 40,
}

export const cardBrandShadowStyle = {
  shadowColor: getHexColorFromTwColor('yellow-300'),
  shadowOffset: { width: 0, height: 16 },
  shadowOpacity: 0.3,
  shadowRadius: 32,
  elevation: 32,
}
