import { useNavigation, useRoute } from '@react-navigation/core'
import { useLinkTo } from '@react-navigation/native'

// path starting with /content/99999999
export const contentPlayerPathRegex = /^[/](content)[/]\d{8,10}/

export const useSmartGoBack = (to, onPress) => {

  const navigation = useNavigation()
  const route = useRoute()
  const linkTo = useLinkTo()

  if (!to && !onPress) {

    const parentNavigationStack = navigation.getParent()

    return () => {
      if (parentNavigationStack && parentNavigationStack.canGoBack()) {
        parentNavigationStack.goBack()
      } else if (navigation.canGoBack()) {
        navigation.goBack()
      } else
      // fallback on mounting expected underlying stack
      if ((new RegExp(contentPlayerPathRegex)).test(route.path)) {
        linkTo('/library')
      } else if (route.path === '/preferences') {
        linkTo('/')
      } else if (route.path === '/onboarding') {
        linkTo('/')
      } else {
        if (__DEV__) {
          console.warn(`Should configure fallback goback redirect for ${route.path}`)
        }
        linkTo('/')
      }
    }
  }

  return () => {
    if (onPress) {
      onPress()
    } else {
      linkTo(to)
    }
  }

}
