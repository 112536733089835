import last from 'lodash/last'

import { getPaddingAdaptedValue } from '~/theme/helpers'

export const getGenericForRegex = (tw = '', regexP:RegExp): {result:string|undefined, remaining:string} => {

  const doubleSpaceTw = tw.replace(/\s+/g, '  ')

  const searchRes = doubleSpaceTw.match(regexP)
  if (!searchRes) {
    return { result: undefined, remaining: tw }
  }

  const resultArr = searchRes.map((key) => key.trim())
  // console.log('searchRes', resultArr)

  // remove result from input
  const remaining = doubleSpaceTw.split('  ').filter((key) => !resultArr.includes(key)).join(' ')

  return { result: resultArr.join(' '), remaining }
}

// matchs all string that start with w- or a modifier
export const getWidthTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?w-([(a-z)|\d|]+|\d\/\d{1,2})(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

// matchs all string that start with h-
export const getHeightTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?h-([(a-z)|\d|]+|\d\/\d{1,2})(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

// matchs all string that start with w- or h-
export const getWidthHeightTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?(w|h)-([(a-z)|\d|]+|\d\/\d{1,2})(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

// matchs all string that start with shadow-
export const getShadowTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?shadow-[a-z0-9/-]+(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getBgOpacityTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?(bg|opacity)-[a-z0-9/-]+(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getBorderRingTw = (tw:string) => {
  // console.log('getBorderRingTw for', tw)
  const regexP = /(^|\s)([a-z]+:)?(border|ring)(-[a-z0-9-]+)?(\s|$)/g
  const res = getGenericForRegex(tw, regexP)
  // console.log('BorderRing res', res)
  return res
}

export const getPaddingMarginTw = (tw:string) => {
  const regexP = /(^|\s)([a-z]+:)?-?(p|m|px|py|mx|my)-([a-z0-9/-]+|auto)(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getAbsolutePlacementFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?(inset|top|bottom|left|right)-[a-z0-9.-]+(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getPositionFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?(static|fixed|absolute|relative|sticky)(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getTransformFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?-?(scale|rotate|translate|skew|origin)-[a-z0-9./-]+(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getGridFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?(row|col)-(auto|start|span|end)[a-z0-9.-]+(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getFlexFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?(justify|content|items)-(start|end|center|between|around|evenly|baseline|stretch)(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getSelfFlexFromTw = (tw = '') => {
  const regexP = /(^|\s)([a-z]+:)?((justify)-)?(items|self)-(start|end|center|stretch|auto|baseline)(\s|$)/g
  return getGenericForRegex(tw, regexP)
}

export const getRoundedTw = (tw:string) => {
  // console.log('-> getRoundedTw for', tw)

  // rounded classes
  const regexP = /(^|\s)rounded(-[a-z0-9/-]+)?(\s|$)/g
  const { result, remaining } = getGenericForRegex(tw, regexP)
  // console.log('rounding classes', result)

  if (!result) {
    return { result, remaining }
  }

  const regexPadding = /(^|\s)(p)-[0-9]+(\s|$)/g
  const searchRes = tw.match(regexPadding)
  const paddingVal = last(searchRes)?.trim()
  // console.log('padding value', paddingVal)

  // we add nearest rounded value for image
  const imgRoundingTw = getPaddingAdaptedValue(result, paddingVal)
  // console.log('inner rounding classes', imgRoundingTw)

  const res = {
    result,
    remaining: `${remaining} ${imgRoundingTw}`,
  }
  // console.log('getRoundedTw res', res)
  return res

}

// Sort what's for the container and what's for the embedded image
export const getImageContainerTw = (tw:string|undefined) => {
  // console.log('-> getImageContainerTw', tw)

  if (!tw || tw.length === 0) {
    return {
      imgTw: '',
      containerTw: '',
    }
  }

  let imgTw = tw
  let containerTw = ''

  /**
     * Width and Height belong to the container
     */
  const { result: whRes, remaining: whRem } = getWidthHeightTw(imgTw)
  if (whRes) {
    containerTw += ` ${whRes}`
  }
  imgTw = whRem

  /**
   * bg and opacity belong to the container
   */
  const { result: bgRes, remaining: bgRem } = getBgOpacityTw(imgTw)
  if (bgRes) {
    containerTw += ` ${bgRes}`
  }
  imgTw = bgRem

  /**
   * border and ring belong to the container
   */
  const { result: brRes, remaining: brRem } = getBorderRingTw(imgTw)
  if (brRes) {
    containerTw += ` ${brRes}`
  }
  imgTw = brRem

  /**
   * shadows belong to the container
   */
  const { result: shRes, remaining: shRem } = getShadowTw(imgTw)
  if (shRes) {
    containerTw += ` ${shRes}`
  }
  imgTw = shRem

  /**
   * rounding belong to the container but should be adapted to the image based on the padding
   */
  const { result: roundingRes, remaining: roundingRem } = getRoundedTw(imgTw)
  if (roundingRes) {
    containerTw += ` ${roundingRes}`
  }
  imgTw = roundingRem

  /**
   * padding and margins belong to the container
   */
  const { result: pmRes, remaining: pmRem } = getPaddingMarginTw(imgTw)
  if (pmRes) {
    containerTw += ` ${pmRes}`
  }
  imgTw = pmRem

  /**
   * position belong to the container
   */
  const { result: positionRes, remaining: positionRem } = getPositionFromTw(imgTw)
  if (positionRes) {
    containerTw += ` ${positionRes}`
  }
  imgTw = positionRem

  /**
   * absolute placement belong to the container
   */
  const { result: placeRes, remaining: placeRem } = getAbsolutePlacementFromTw(imgTw)
  if (placeRes) {
    containerTw += ` ${placeRes}`
  }
  imgTw = placeRem

  // console.log('containerTw before', containerTw)
  // console.log('imgTw before', imgTw)
  /**
   * Transformations belong to the container
   */
  const { result: transRes, remaining: transRem } = getTransformFromTw(imgTw)
  if (transRes) {
    containerTw += ` ${transRes}`
  }
  imgTw = transRem
  // console.log('containerTw after', containerTw)
  // console.log('imgTw after', imgTw)

  /**
   * Grid belong to the container
   */
  const { result: gridRes, remaining: gridRem } = getGridFromTw(imgTw)
  if (gridRes) {
    containerTw += ` ${gridRes}`
  }
  imgTw = gridRem

  /**
   * Flex belong to the container
   */
  const { result: flexRes, remaining: flexRem } = getFlexFromTw(imgTw)
  if (flexRes) {
    containerTw += ` ${flexRes}`
  }
  imgTw = flexRem

  const { result: flexSelfRes, remaining: flexSelfRem } = getSelfFlexFromTw(imgTw)
  if (flexSelfRes) {
    containerTw += ` ${flexSelfRes}`
  }
  imgTw = flexSelfRem

  return {
    imgTw: imgTw.replace(/\s\s+/g, ' ').trim(),
    containerTw: containerTw.replace(/\s\s+/g, ' ').trim(),
  }

}
