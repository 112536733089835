import orderBy from 'lodash/orderBy'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ContentTile } from '~/components/panels/tiles/ContentTile'
import { ScrollView } from '~/elements/containers/ScrollView'
import { useCategories } from '~/hooks/dato.content.groups'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { shadowLightStyle } from '~/theme/shadows'

export const CategoriesSection = () => {
  const { data } = useCategories()

  const { t } = useTranslation(['home'])
  if (!data) {
    return <LoadingScreen />
  }

  const items = orderBy(data.allContentGroups.filter((item) => item.nbContent > 0).map((item) => ({
    id: item.id,
    title: item.title,
    description: item.description,
    nbContent: item.nbContent,
    illustration: {
      backgroundColor: item.backgroundColor,
      background: item.background,
      foreground: item.illustration,
    },
    navigateTo: `/category/${`${item.id}-${item.slug}`}`,
  })), (o) => o.nbContent, 'desc')
  //   console.log('items', items)

  return (
    <>
      <SectionHeading title={t('home:categories.title')} tw="px-4 md:px-8" />
      <CarouselSectionContainer tw="px-0 sm:px-4 md:px-8 -mt-2 mb-4">
        <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-4 pb-4 pt-8 flex flex-row bg-surface-1 sm:rounded-2xl" style={shadowLightStyle}>
          {items.map((item, index) => (
            <ContentTile
              key={item?.id || `placeholder-${index}`}
              {...item}
              size="small"
              isFirst={index === 0}
              isLast={index === (items.length - 1)}
            />
          ))}
        </ScrollView>
      </CarouselSectionContainer>
    </>
  )
}
