import { render } from 'datocms-structured-text-to-plain-text'
import sum from 'lodash/sum'
import readingTime from 'reading-time'

import { ContentRecord } from '~/__generated__/graphql.queries'

type ContentType = {
  title: string
  author: {
    firstName: string
    lastName: string
  }
  excerpt: string
  intro: string
  content: Array<ChapterType>
}
type ChapterType = {
  __typename:string
  title: string
  content: string
}

type ReadStat = {
  words:number
  seconds:number
  minutes:number
}

export type ContentContextType = {
  isLoading:boolean
  error:Error | undefined
  data?:Omit<ContentRecord, 'content'> & {
    toc:Array<(ChapterType | ContentType) & {
      metadata: ReadStat
    }>
    metadata: ReadStat
  }
}

/**
 * For each page type, extract readable content
 */
export const computeReadMetadata = (content:ContentRecord['content']|undefined) => content?.map((item) => {
  // console.log('-> computeReadMetadata', item)
  // eslint-disable-next-line no-underscore-dangle
  switch (item.__typename) {
  case 'ContentPageRecord': {
    const itemContent = `${item.title} ${render(item.content)}`
    const stats = readingTime(itemContent)

    return stats
  }
  case 'ContentSectionRecord': {
    const itemContent = item.title
    const stats = readingTime(itemContent || '')

    return stats
  }
  case 'ContentCheckpointRecord': {
    const itemContent = `${item.title} ${render(item.content)}`
    const stats = readingTime(itemContent)

    return stats
  }
  case 'ContentScaleRecord': {
    let itemContent = item.name
    itemContent += ` ${item.before}`
    itemContent += ` ${item.after}`
    itemContent += ` ${item.item?.title}`
    itemContent += ` ${item.item?.values.map((o) => o.label).join(' ')}`
    const stats = readingTime(itemContent || '')
    // console.log(`Stats for ContentScaleRecord`, stats)

    return stats
  }
  case 'ContentToolRecord': {
    let itemContent = item.name
    itemContent += ` ${item.before}`
    itemContent += ` ${item.after}`
    itemContent += ` ${item.item?.title}`
    itemContent += ` ${item.item?.description}`
    itemContent += ` ${item.item?.instructions}`
    const stats = readingTime(itemContent || '')
    // console.log(`Stats for ContentScaleRecord`, stats)

    return stats
  }

  // Illustration just has a description as readable content
  case 'ContentIllustrationRecord': {
    return readingTime(item.description || '')
  }

  default:
    // eslint-disable-next-line no-underscore-dangle
    throw new Error(`unknown content type ${item.__typename}`)
  }
})

export const getGlobalReadMetadata = (content) => {
  const metadata = computeReadMetadata(content)

  return {
    words: sum(metadata?.map((item) => item.words)),
    minutes: sum(metadata?.map((item) => item.minutes)),
    seconds: sum(metadata?.map((item) => item.seconds)),
  }
}
