import classNames from 'classnames'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

export const ContentCoverDesktopTitleBox = ({ title, containerTw, textTw }) => (
  <View tw={containerTw}>
    <Text tw={classNames('text-2xl font-semibold font-serif', textTw)}>{title}</Text>
  </View>
)
