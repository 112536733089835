import { memo } from 'react'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import { isDayOrMoment, useTranslationContext } from '../context/TranslationContext'

// str-chat__date-separator
const dateSeparatorTw = 'flex-row items-center'

// str-chat__date-separator-line
const dateSeparatorLineTw = 'border-b border-gray-300 flex-grow'

// str-chat__date-separator-date
export const dateSeparatorDateTw = 'font-medium uppercase text-gray-500 text-xs px-2'

export type DateSeparatorProps = {
  /** The date to format */
  date: Date;
  /** Override the default formatting of the date. This is a function that has access to the original date object. */
  formatDate?: (date: Date) => string;
  /** Set the position of the date in the separator, options are 'left', 'center', 'right', @default right */
  position?: 'left' | 'center' | 'right';
  /** If following messages are not new */
  unread?: boolean;
};

const UnMemoizedDateSeparator = (props: DateSeparatorProps) => {
  const {
    date, formatDate, position = 'right', unread,
  } = props

  const { t, tDateTimeParser } = useTranslationContext('DateSeparator')

  if (typeof date === 'string') {
    return null
  }

  const parsedDate = tDateTimeParser(date.toISOString())

  // eslint-disable-next-line no-nested-ternary
  const formattedDate = formatDate
    ? formatDate(date)
    : isDayOrMoment(parsedDate)
      ? parsedDate.calendar()
      : parsedDate

  return (
    <View tw={dateSeparatorTw}>
      {(position === 'right' || position === 'center') && (
        <View tw={dateSeparatorLineTw} />
      )}
      <Text tw={dateSeparatorDateTw}>
        {unread ? `${t('New')} - ${formattedDate}` : formattedDate}
      </Text>
      {(position === 'left' || position === 'center') && (
        <View tw={dateSeparatorLineTw} />
      )}
    </View>
  )
}

/**
 * A simple date separator between messages.
 */
export const DateSeparator = memo(UnMemoizedDateSeparator) as typeof UnMemoizedDateSeparator
