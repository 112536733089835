import { Timestamp } from 'firebase/firestore'

import { Timestamp as TimestampType } from '~/definitions/firebase.firestore.types'
import { GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import {
  addMinutes, compareAsc, compareDesc, isBefore,
} from '~/utils/dateFns/common.helpers'

// helper to determine if event session is in past, still not dynamically buy taking endAt instead of startAt
export const getIsPast = (startAt: TimestampType, duration: number) => {
  const endAt = addMinutes(startAt, duration)
  const isPast = isBefore(Timestamp.fromDate(endAt), Timestamp.now())
  return isPast
}

export const selectIncomingRegisteredEvents = (events: GroupMeetingWithSessions[] | null) => {
  if (!events) {
    return []
  }

  return events
    .filter((event) => {
      const eventDuration = event?.model?.duration
      const isRegisteredAndIncoming = event.sessions.some((session) => !(getIsPast(session.startAt, eventDuration)) && session.isRegistered)

      return isRegisteredAndIncoming
    })
    .sort((eventA, eventB) => compareAsc(eventA.sessions[0].startAt, eventB.sessions[0].startAt))
}

export const selectIncomingEvents = (events: GroupMeetingWithSessions[] | null) => {
  if (!events) {
    return []
  }

  return events
    .filter((event) => {
      const eventDuration = event?.model?.duration
      const isIncoming = event.sessions.some((session) => !(getIsPast(session.startAt, eventDuration)))

      return isIncoming
    })
    .sort((eventA, eventB) => {

      const incomingEventA = eventA.sessions.length > 1
        ? eventA.sessions.sort((sessionA, sessionB) => compareAsc(sessionA.startAt, sessionB.startAt))[0]
        : eventA.sessions[0]

      const incomingEventB = eventB.sessions.length > 1
        ? eventB.sessions.sort((sessionA, sessionB) => compareAsc(sessionA.startAt, sessionB.startAt))[0]
        : eventB.sessions[0]

      return compareAsc(incomingEventA.startAt, incomingEventB.startAt)
    })
}

export const selectPastConferences = (events: GroupMeetingWithSessions[] | null) => {
  if (!events) {
    return []
  }

  return events
    .filter((event) => {
      const eventDuration = event?.model?.duration
      // eslint-disable-next-line no-underscore-dangle
      const isPastConference = event.__typename === 'ConferenceInstanceRecord' && event.sessions.some((session) => getIsPast(session.startAt, eventDuration)
      && !!(session.replay_link))

      return isPastConference
    })
    .sort((eventA, eventB) => compareDesc(eventA.sessions[0].startAt, eventB.sessions[0].startAt))
}

export const selectPastRegisteredEvents = (events: GroupMeetingWithSessions[] | null) => {
  if (!events) {
    return []
  }

  return events
    .filter((event) => {
      const eventDuration = event?.model?.duration
      /* eslint no-underscore-dangle: 0 */
      const isRegisteredAndPast = event.sessions.some((session) => event.__typename !== 'ConferenceInstanceRecord'
      && getIsPast(session.startAt, eventDuration) && session.isRegistered)

      return isRegisteredAndPast
    })
    .sort((eventA, eventB) => compareDesc(eventA.sessions[0].startAt, eventB.sessions[0].startAt))
}
