import { useFirestoreDocument, UseFirestoreHookOptions, useFirestoreQuery } from '@react-query-firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import { GetSingleDocQueryResult } from '~/definitions/firestore.queries'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getUserTrackRef } from '~/queries/firestore.helpers'
import {
  findLatestActiveUserTracksQuery, findLatestUserTracksQuery, findUserTrackByConversationIdQuery,
} from '~/queries/firestore.userTracks'

import { getKeysFromRef, withGetDocResultAdapter, withTakeFirstResultAdapter } from './firestore.hooks.helpers'
// import { useUser } from './user.context'

export const useUserTrackDoc = (uid: string, campaignId: string, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<UserTrackData> => {
  const ref = getUserTrackRef(uid, campaignId)
  const keys = getKeysFromRef(ref)
  const res = useFirestoreDocument<UserTrackData>(keys, ref, options)

  return withGetDocResultAdapter(res)
}

export const useUserTrackDocByConversationId = (conversationId: string, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<UserTrackData> => {
  // console.log('-> useUserTrackDocByConversationId', conversationId)
  const queryVar = { conversationId }
  const query = findUserTrackByConversationIdQuery(queryVar)
  const keys = [Collections.USERS, Collections.TRACKS, queryVar]
  const queryRes = useFirestoreQuery<UserTrackData>(keys, query, options)

  return withTakeFirstResultAdapter(queryRes)
}

export const useCurrentUserLatestActiveTrackDoc = (uid: string, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<UserTrackData> => {
  // console.log('-> useCurrentUserLatestActiveTrackDoc')
  // console.log('uid', uid)
  const queryVar = { uid }
  const queryOpt = { limit: 1 }
  const query = findLatestActiveUserTracksQuery(queryVar, queryOpt)
  const keys = [Collections.USERS, Collections.TRACKS, queryVar, queryOpt]
  const queryRes = useFirestoreQuery<UserTrackData>(keys, query, options)

  // console.log('queryRes', queryRes.data?.docs.map((doc) => console.log(`track id : ${doc.id}`)))

  return withTakeFirstResultAdapter(queryRes)
}

export const useCurrentUserLatestTrackDoc = (uid: string, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<UserTrackData> => {
  const queryVar = { uid }
  const queryOpt = { limit: 1 }
  const query = findLatestUserTracksQuery(queryVar, queryOpt)
  const keys = [Collections.USERS, Collections.TRACKS, queryVar, queryOpt]
  const queryRes = useFirestoreQuery<UserTrackData>(keys, query, options)

  // console.log('queryRes', queryRes.data?.docs.map((doc) => console.log(`track id : ${doc.id}`)))

  return withTakeFirstResultAdapter(queryRes)
}
