import { FC } from 'react'
import { AirbnbRating, TapRatingProps } from 'react-native-elements'

export type RatingProps = Pick<TapRatingProps,
'size'
| 'count'
| 'reviews'
| 'showRating'
| 'reviewColor'
| 'reviewSize'
| 'defaultRating'
| 'starContainerStyle'
| 'onFinishRating'
| 'isDisabled'
| 'selectedColor'
| 'starImage'
>

/**
 * Wrapper for AirbnbRating component
 * @see https://reactnativeelements.com/docs/airbnbrating
 */
export const Rating: FC<RatingProps> = (props) => <AirbnbRating {...props} />
