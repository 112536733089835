import { FC } from 'react'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer, CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ContentTileProps } from '~/components/panels/tiles/ContentTileProps.d'
import { TileList, TileSettingType } from '~/elements/list/TileList'
import { useCategories } from '~/hooks/dato.content.groups'
import { useRecommendedItems } from '~/hooks/recombee'
import { RecommendItemsToUserOptions, UseRecommendationScenario } from '~/vendors/recombee/recombee'

export type RecommendedContentFromScenarioProps = {
  nbItems: number
  scenario: UseRecommendationScenario
  title: string
  description: string
  tileSettings: TileSettingType
  tw?: string
} & Partial<RecommendItemsToUserOptions>

export const RecommendedContentWithData = ({
  title, description, data, tileSettings, tw,
}) => {

  // console.log('-> RecommendedContentWithData', data, tileSettings)

  const getCategoriesResp = useCategories()

  // console.log('Recombee res', data)

  // const categories = compact(data.map(item => item.categories[0]))
  // console.log(`categories`, categories)

  // { isLoading, error, data: { allContentGroups } }

  // console.log(`getCategoriesResp.data`, getCategoriesResp.data)

  // map data to the standard list item props
  const dataWithCategories: Array<ContentTileProps & { recommId: string }> = data
    .map((item) => {
      if (!item) {
        return item
      }

      return ({
        ...item,
        category: getCategoriesResp?.data ? getCategoriesResp.data.allContentGroups.find((o) => o.id === item.categories[0]) : undefined,
      })
    })
    .map((item) => {
      if (!item) {
        return item
      }

      return ({
        id: item.id,
        title: item.title,
        description: item.excerpt,
        illustration: {
          foreground: item.coverMain,
          background: item.coverBackground,
          backgroundColor: item.coverBackgroundColor,
          format: item.coverMainFormat,
        },
        recommId: item.recommId,
      })
    })

  if (dataWithCategories.length === 0) {
    return null
  }

  // console.log('dataWithCategories', dataWithCategories)

  /**
   * Small and medium cards are included in a carousel container
   */
  if (tileSettings.size === 'small' || tileSettings.size === 'medium') {
    return (
      <>
        {(title || description) && <SectionHeading title={title} description={description} tw="px-4 md:px-8" />}
        <CarouselSectionContainer tw="mt-2">
          <TileList data={dataWithCategories} tileSettings={tileSettings} />
        </CarouselSectionContainer>
      </>
    )
  }

  return (
    <>
      <SectionHeading title={title} description={description} tw="px-4 md:px-8" />
      <BasicSectionContainer tw={tw}>
        <TileList data={dataWithCategories} tileSettings={tileSettings} />
      </BasicSectionContainer>
    </>
  )
}

export const RecommendedContentFromScenario: FC<RecommendedContentFromScenarioProps> = ({
  nbItems, scenario, filter, booster, ...rest
}) => {
  // const { i18n } = useTranslation(['common'])
  // console.log('i18n.language', i18n.language)

  const options = {
    filter,
    booster,
  }
  const { data } = useRecommendedItems(nbItems, scenario, options)

  if (!data) {
    return null
  }

  return <RecommendedContentWithData data={data} {...rest} />
}
