export const debounceInterval = 500
// const throttleInterval = 750
export const minQueryLengthToExecuteSearch = 3
// const debounceUpToNbCharacters = 7

// _language has been added to be able to replace all substring when its use by main object and one of its sub key
// cf pages_language.header_language
export const searchableAttributesWithLanguagePlaceholder = [
  'title_language',
  'excerpt_language',
  'categories.title_language',
  'pages_language.header_language',
]
