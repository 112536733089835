import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { meetingTypes } from '~/constants/firestore.meetings'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { SvgById } from '~/elements/images/SvgById'
import { Text } from '~/elements/text/Text'

export const EMPTY_EVALUATIONS_ILLUSTRATION_ID = '8759608'

export const MyTrackEmptyScreen = () => {
  // console.log('-> MyTrackEmptyScreen')

  // Get translations
  const { t } = useTranslation(['track'])

  return (
    <MainContainer scrollEnabled tw="">
      <BasicSectionContainer tw="">
        {/* <Text tw="text-xl mt-16 text-center" category="h2">{t('empty.heading')}</Text> */}
        <View tw="flex-col items-center">
          <SvgById assetId="29307768" tw="py-12 w-full" />
          <Text tw="text-center text-xl mb-2 font-semibold font-serif">{t('track:empty.heading')}</Text>
          <Text tw="text-center text-gray-500 mb-2">{t('track:empty.noMinderYet')}</Text>
          <Text tw="text-center text-gray-500 mb-9">{t('track:empty.assignmentInfo')}</Text>
          <Button
            tw="mt-4"
            title={t('track:empty.ctaNextTask')}
            color="brand"
            to={`/sessions/${meetingTypes.ORIENTATION}/new`}
          />
        </View>
      </BasicSectionContainer>
    </MainContainer>
  )
}
