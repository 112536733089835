import { httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'

import { localStorageKeys, useAsyncStorage } from '~/hooks/storage'
import { functionsUS } from '~/navigators.shared/app.firebase.client'
// import { customerIoSegmentsIds } from '~/vendors/customer.io/customers.segments'

/**
 * TODO use React Query Firebase to automatically cache result and avoid local storage
 * @see https://react-query-firebase.invertase.dev/functions/querying
 * useFunctionsQuery(["segments", uid], functionsUS, "httpGetCustomerSegment", {uid});
 */
export const useCustomerIoUserSegments = (uid: string) => {

  const [isCustomerIoLoading, setCustomerIoLoading] = useState<boolean>(false)
  const [localError, setLocalError] = useState<Error>()
  const [segments, setSegments] = useState([])
  // console.log(segments)

  const { value: storageSegments, setItem } = useAsyncStorage(localStorageKeys.USER_SEGMENTS)

  /**
   * Load segments from localStorage, if any preexisting, to speed up app launch
   */
  useEffect(() => {
    if (storageSegments) {
      setSegments(JSON.parse(storageSegments))
    }
  }, [])

  /**
 * Load segments from CustomerIO so they are up-to-date
 */
  useEffect(() => {
    const getCustomerSegmentsContext = async () => {
      setCustomerIoLoading(true)
      try {
        const { data } = await httpsCallable(functionsUS, 'httpGetCustomerSegment')(uid)
        // console.log('user segments', data)

        if (data) {
          const customerSegmentsId = data.map((segm) => JSON.stringify(segm.id)) // we need it to be an array of string
          // TODO should map segments id that are env specific to their enum counterpart
          setSegments(customerSegmentsId)
          setItem(JSON.stringify(customerSegmentsId))
        }
      } catch (error) {
        setLocalError(error)
      }
      setCustomerIoLoading(false)
    }
    if (uid) {
      getCustomerSegmentsContext()
    }
    // purposly ignored functions
  }, [uid])

  return {
    isLoading: isCustomerIoLoading,
    error: localError,
    data: segments,
  }

}
