import { Dispatch, SetStateAction, useEffect } from 'react'
import type { Channel, Event } from 'stream-chat'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'

export const useNotificationRemovedFromChannelListener = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
    customHandler?: (
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
    event: Event<At, Ch, Co, Ev, Me, Re, Us>,
  ) => void,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>(
    'useNotificationRemovedFromChannelListener',
  )

  useEffect(() => {
    const handleEvent = (event: Event<At, Ch, Co, Ev, Me, Re, Us>) => {
      if (customHandler && typeof customHandler === 'function') {
        customHandler(setChannels, event)
      } else {
        setChannels((channels) => channels.filter((channel) => channel.cid !== event.channel?.cid))
      }
    }

    client.on('notification.removed_from_channel', handleEvent)

    return () => {
      client.off('notification.removed_from_channel', handleEvent)
    }
  }, [customHandler])
}
