/**
 * Wrap Cloud functions
 */

import { httpsCallable } from 'firebase/functions'

import { Functions } from '~/definitions/firebase.functions.types'

export type UpdateUserContentListInputType = {
  src: 'web-app' | 'missive' | 'ios-app' | 'android-app'
  uid: string
  itemId: string // DatoCMS item id
  action: 'add' | 'remove'
  list: 'next' | 'favorites' | 'history' | 'toolbox'
  agent: 'user' | 'minder' | 'care-manager' | 'system' // Who triggered that event ?
}

export const handleContentListEvent = (
  input: UpdateUserContentListInputType,
  functions: Functions,
) => httpsCallable(functions, 'httpUpdateUserContentList')(input)
