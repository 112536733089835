import classNames from 'classnames'

import { SwiperByNameQuery } from '~/__generated__/graphql.queries'
import { getBgColor, getTextColor } from '~/elements/colors/colors.helpers'
import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useDescriptionSizeFromVertBp, useTitleSizeFromVertBp } from '~/hooks/theme.breakpoint.vertical.helpers'

export const CarouselSlide = (item: SwiperByNameQuery) => {
  const mq = useMediaQuery()
  const titleSize = useTitleSizeFromVertBp()
  // console.log('titleSize',titleSize)
  const descSize = useDescriptionSizeFromVertBp()
  // console.log('descSize',descSize)
  // console.log('item', item.illustration)

  const titleTwClasses = classNames(
    'text-center font-semibold font-serif px-8',
    titleSize,
    getTextColor(item.titleColor || 'gray-900'),
  )
  const descriptionTwClasses = classNames('text-center px-8 mt-4 md:mt-8', descSize)
  const containerTw = classNames(
    'items-center flex-col justify-center w-full h-full',
    getBgColor(item.illustration.backgroundColor),
  )

  // eslint-disable-next-line no-nested-ternary
  const imgBottomMargin = mq.lgv ? 'mb-8' : (mq.smv ? 'mb-4' : 'mb-1')

  return (
    <SafeAreaView tw={containerTw}>
      {item.illustration && (
        <Image {...item.illustration.foreground} tw={classNames('h-2/5', imgBottomMargin)} />
        )}
      <View>
        {!!item.title && <Text tw={titleTwClasses}>{item.title}</Text>}
        {!!item.description && <Text tw={descriptionTwClasses}>{item.description}</Text>}
      </View>
    </SafeAreaView>
  )
}
