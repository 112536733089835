import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MeetingTypeType } from '~/definitions/firestore.meeting.properties'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'
import { useSessionBySlug } from '~/hooks/dato.sessions'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useNextSuggestedMeeting } from '~/hooks/user'

library.add(faCalendar)

type BookMeetingCardWithModelProps = {
  type: MeetingTypeType,
  trackId?: string,
  tw?: string,
}

const BookMeetingCardWithModel: FC<BookMeetingCardWithModelProps> = ({
  type, trackId, tw,
}) => {
  const { t } = useTranslation(['track'])

  const { lg } = useMediaQuery()

  const { isLoading, data } = useSessionBySlug(type)

  if (isLoading || !data) {
    return null
  }

  let url = `/sessions/${type}/new`

  if (trackId) {
    url += `?trackId=${trackId}`
  }

  const { title, excerpt } = data.session
  // console.log('data', data)

  if (lg) {
    // A Card on Large screens
    return (
      <View tw={classNames('rounded-2xl bg-surface-1 py-8 px-6 flex-col items-center', tw)}>
        <View tw="bg-orange-100 rounded-full w-20 h-20 text-orange-500 justify-center items-center mb-4">
          <Icon name="calendar" size="lg" variant="regular" />
        </View>
        <View>
          <Text tw="text-center text-xs uppercase font-medium text-brand-darker">{t('track:bookMeetingCard.eyebrow')}</Text>
          <Text tw="font-serif text-center font-medium text-base">{title}</Text>
          <Text tw="mt-1 text-center font-normal text-sm text-gray-700">{excerpt}</Text>
        </View>
        <Button
          title={t('track:main.schedule')}
          color="brand"
          size="sm"
          to={url}
          tw="mt-4"
        />
        {/* <Text tw="text-sm mt-1 text-gray-500  text-center">{t('main.only_one')}</Text> */}
      </View>
    )
  }

  // On small screens, we only return the CTA; not the Card
  return (
    <View tw="flex-row justify-center">
      <Button
        title={t('track:main.schedule')}
        color="brand"
        size="xl"
        to={url}
        tw="mt-4"
      />
    </View>
  )
}
type BookMeetingCardOrCtaProps = {
  trackId?:string,
  tw?:string
}
/**
 * A component that displays a CTA to take next meeting, when in such position
 * - It will not return anything if condition isnt met
 */
export const BookMeetingCardOrCta: FC<BookMeetingCardOrCtaProps> = ({ tw, trackId }) => {

  const { data } = useNextSuggestedMeeting()

  // console.log('data', data)

  if (!data || data.type === null) {
    return null
  }

  return <BookMeetingCardWithModel type={data.type} trackId={trackId} tw={tw} />
}
