import get from 'lodash/get'

import { useUser } from '~/hooks/user.context'

export const useContactDetailsData = (): boolean => {
  // console.log('-> useContactDetailsData')
  const { user } = useUser()
  // console.log('user', user)
  const hasUserCompletedDetails = !!get(user, 'phoneNumber')
  // console.log('hasUserCompletedDetails', hasUserCompletedDetails)
  return hasUserCompletedDetails
}
