import classNames from 'classnames'
import { FC } from 'react'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
// import { ButtonProps } from '~/elements/buttons/Button.d'
// import { IconProps } from '~/elements/icons/Icon/IconProps.d'

type ScreenHeadingType = {
  eyebrow?: string
  title: string
  description?: string
  // accessoryRight?: IconProps | ButtonProps
  tw?:string
}

const eyebrowTw = ''
const titleTw = 'font-serif font-semibold text-xl sm:text-2xl leading-normal text-gray-900 md:mb-1 lg:mb-2'
const descriptionTw = 'text-sm leading-relaxed text-gray-500'

const baseContainerTw = 'mt-12 md:mt-20 w-full max-w-xlp px-4 md:px-8'

/**
 * Heading for a whole screen/scene
 * Note: usually not part of the navigation header but directly with content
 */
export const ScreenHeadingSection
: FC<ScreenHeadingType> = ({
  tw, title, description, eyebrow,
}) => (
  <View tw={classNames(baseContainerTw, tw)}>
    {eyebrow && <Text tw={eyebrowTw}>{eyebrow}</Text>}
    {title && <Text tw={titleTw}>{title}</Text>}
    {description && <Text tw={descriptionTw}>{description}</Text>}
  </View>
)
