// Note: this file should be shared with similar file in backend project

export const meetingTypes = {
  ORIENTATION: 'orientation',
  FIRST_SESSION: 'mindday-first-session',
  SESSION: 'mindday-session',
} as const

export const meetingStatus = {
  TENTATIVE: 'tentative',
  DENIED: 'denied',
  PLANNED: 'planned',
  CANCELLED: 'cancelled',
  LATE_CANCELLED: 'late-cancelled',

  USER_NO_SHOW: 'no-show',
  MINDER_NO_SHOW: 'minder-no-show',

  /**
   * User late rescheduled.
   * A minder reschedule will always end up in planned
   */
  LATE_RESCHEDULED: 'late-rescheduled',
  DONE: 'done',
} as const

type MeetingStatusDetailsType = {
  invoicable: boolean,
  countAgainstPlan: boolean,
}

export const meetingStatusDetails: Record<string, MeetingStatusDetailsType> = {
  [meetingStatus.TENTATIVE]: {
    invoicable: false,
    countAgainstPlan: true,
  },
  [meetingStatus.DENIED]: {
    invoicable: false,
    countAgainstPlan: false,
  },
  [meetingStatus.PLANNED]: {
    invoicable: false,
    countAgainstPlan: true,
  },
  [meetingStatus.CANCELLED]: {
    invoicable: false,
    countAgainstPlan: false,
  },
  [meetingStatus.LATE_CANCELLED]: {
    invoicable: true,
    countAgainstPlan: false,
  },
  [meetingStatus.USER_NO_SHOW]: {
    invoicable: true,
    countAgainstPlan: false,
  },
  [meetingStatus.MINDER_NO_SHOW]: {
    invoicable: false,
    countAgainstPlan: false,
  },
  [meetingStatus.LATE_RESCHEDULED]: {
    invoicable: true,
    countAgainstPlan: false,
  },
  [meetingStatus.DONE]: {
    invoicable: true,
    countAgainstPlan: true,
  },
}
