import { Timestamp as fsTimestamp } from 'firebase/firestore'

import { Timestamp } from '~/definitions/firebase.firestore.types'

/**
 * Beware, this interpret input sometimes as UTC, sometimes as local...
 */
export const getTimestampFromIsoString = (dateIso:string|undefined):Timestamp|undefined => (dateIso ? fsTimestamp.fromDate(new Date(dateIso)) : undefined)

export const getTimestampFromDate = (date:Date | undefined):Timestamp|undefined => (date ? fsTimestamp.fromDate(date) : undefined)
