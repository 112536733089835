import type { NimbleEmojiIndex } from 'emoji-mart'
import { useMemo } from 'react'

import { useEmojiContext } from '../../context/EmojiContext'

export const useEmojiIndex = () => {
  const { emojiConfig, EmojiIndex } = useEmojiContext('useEmojiIndex')

  const { emojiData } = emojiConfig || {}

  // eslint-disable-next-line consistent-return
  const emojiIndex: NimbleEmojiIndex | undefined = useMemo(() => {
    if (EmojiIndex) {
      // @ts-expect-error type here isn't registering the constructor type
      return new EmojiIndex(emojiData)
    }
  }, [emojiData, EmojiIndex])

  return emojiIndex
}
