import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

export const BigNumberViz = ({ eyebrow, value, maxValue }) => (
  <>
    {eyebrow && <Text tw="text-orange-700 uppercase">{eyebrow}</Text>}
    <View tw="flex-row items-end">
      <Text tw="text-gray-900 text-4xl font-semibold -mb-4">{value}</Text>
      {maxValue && <Text tw="text-gray-500 text-lg ml-2">{`/${maxValue}`}</Text>}
    </View>
  </>
)
