import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { EventTileReplay } from '~/components/panels/tiles/EventTile'

import { EventsProps } from './EventsIncoming'

export const EventReplays: FC<EventsProps> = ({ events }) => {
  const { t } = useTranslation(['events'])

  return (
    <>
      <SectionHeading title={t('events:replays.title')} tw="px-4 md:px-8" />
      <BasicSectionContainer>
        {events && events.map((event) => (
          <EventTileReplay
            key={event.id}
            title={event?.model?.shortTitle || event?.model?.title}
            sessions={event.sessions}
            navigateTo={`/events/${event.id}`}
            image={event?.model?.illustration}
          />
        ))}
        {/* TODO later: limit to three first and add cta "see more replays" t('events:replays.cta') */}
      </BasicSectionContainer>
    </>
  )
}
