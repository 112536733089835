import { ComponentType } from 'react'

import { View } from '~/elements/containers/View'

import type { ChannelNotifications } from '../context/ChannelStateContext'
import { useTranslationContext } from '../context/TranslationContext'
import { ConnectionStatus } from './ConnectionStatus'
import { CustomNotification } from './CustomNotification'
import type { MessageNotificationProps } from './MessageNotification'

export type MessageListNotificationsProps = {
  hasNewMessages: boolean;
  MessageNotification: ComponentType<MessageNotificationProps>;
  notifications: ChannelNotifications;
  scrollToBottom: () => void;
};

export const MessageListNotifications = (props: MessageListNotificationsProps) => {
  const {
    hasNewMessages, MessageNotification, notifications, scrollToBottom,
  } = props

  const { t } = useTranslationContext('MessageListNotifications')

  return (
    <View>
      {notifications.map((notification) => (
        <CustomNotification active key={notification.id} type={notification.type}>
          {notification.text}
        </CustomNotification>
      ))}
      <ConnectionStatus />
      <MessageNotification onClick={scrollToBottom} showNotification={hasNewMessages}>
        {t('New Messages!')}
      </MessageNotification>
    </View>
  )
}
