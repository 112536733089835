import { ComponentType, FC, memo } from 'react'

import { Avatar as DefaultAvatar } from '~/elements/avatar/Avatar'
import { AvatarProps } from '~/elements/avatar/Avatar.d'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

export type UserItemProps = {
  /** The user */
  entity: {
    /** The parts of the Name property of the entity (or id if no name) that can be matched to the user input value.
     * Default is bold for matches, but can be overwritten in css.
     * */
    itemNameParts: { match: string; parts: string[] };
    /** Id of the user */
    id?: string;
    /** Image of the user */
    image?: string;
    /** Name of the user */
    name?: string;
  };
  /** Custom UI component to display user avatar, defaults to and accepts same props as: [Avatar](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Avatar/Avatar.tsx) */
  Avatar?: ComponentType<AvatarProps>;
};

/**
 * UI component for mentions rendered in suggestion list
 */
const UnMemoizedUserItem: FC<UserItemProps> = (props) => {
  const { Avatar = DefaultAvatar, entity } = props

  const hasEntity = Object.keys(entity).length
  const itemParts = entity?.itemNameParts

  const renderName = () => {
    if (!hasEntity) {
      return null
    }

    return (
      hasEntity
      && itemParts.parts.map((part, i) => (part.toLowerCase() === itemParts.match.toLowerCase() ? (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={`part-${i}`}>
          {part}
        </Text>
      ) : (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={`part-${i}`}>
          {part}
        </Text>
      )))
    )
  }

  return (
    <View>
      <Avatar image={entity.image} name={entity.name || entity.id} size={20} />
      <Text data-testid="user-item-name">
        {renderName()}
      </Text>
    </View>
  )
}

export const UserItem = memo(UnMemoizedUserItem) as typeof UnMemoizedUserItem
