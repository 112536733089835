// chat__message
export const messageTw = 'items-start justify-end p-0 w-full'

// str-chat__message--me
// @see https://github.com/GetStream/stream-chat-css/blob/b769255eb76a3d28dd5fc67215db9e4b5c9ea5e3/src/styles/Message.scss#L449
export const messageMeTw = 'items-end'

// str-chat__message-inner
export const messageInnerTw = 'max-w-md p-4 rounded-2xl rounded-bl bg-surface-1 text-sm text-gray-900 text-left mt-1'

export const messageMeInnerTw = 'rounded-bl-2xl rounded-br bg-brand-base text-surface-1'

// str-chat__message-simple-name
// @see https://github.com/GetStream/stream-chat-css/blob/b769255eb76a3d28dd5fc67215db9e4b5c9ea5e3/src/styles/Message.scss#L434
export const messageSimpleNameTw = 'font-medium'

/**
 * Status
 */
export const messageSendingTw = ''
export const messageDeletedTw = ''
export const messageReceivedTw = ''

/**
 * Types
 */

export const messageRegularTw = ''
export const messageEphemeralTw = ''
export const messageErrorTw = ''
export const messageReplyTw = ''
export const messageSystemTw = 'justify-center text-center p-4 text-gray-500 uppercase' // https://github.com/GetStream/stream-chat-css/blob/b769255eb76a3d28dd5fc67215db9e4b5c9ea5e3/src/styles/Message.scss#L297

/**
 *
 */
export const messageHasTextTw = ''
export const messageHasAttachementTw = ''
export const messageWithReactionsTw = ''
