import {
  limit, orderBy, query, where,
} from 'firebase/firestore'

import { userTrackStatus } from '~/constants/firestore.userTracks'
import { ByUidQueryParams, WithLimitQueryOptions } from '~/definitions/firestore.queries'

import { getUserTrackCol, getUserTrackColGroup } from './firestore.helpers'

export const findLatestActiveUserTracksQuery = (
  { uid }: ByUidQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getUserTrackCol(uid),
  where('status', '!=', userTrackStatus.COMPLETED),
  orderBy('status', 'asc'), // necessary to perform the query
  orderBy('createdAt', 'desc'),
  limit(limitOpt || 1),
)

export const findLatestUserTracksQuery = (
  { uid }: ByUidQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getUserTrackCol(uid),
  where('status', '!=', userTrackStatus.COMPLETED),
  orderBy('status', 'asc'), // necessary to perform the query
  orderBy('createdAt', 'desc'),
  limit(limitOpt || 1),
)

export const findUserTracksQuery = (
  { uid }: ByUidQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getUserTrackCol(uid),
  orderBy('createdAt', 'desc'),
  limit(limitOpt || 1),
)

type FindUserTrackByConversationIdQueryInputType = {
  conversationId: string
}

export const findUserTrackByConversationIdQuery = (
  { conversationId }: FindUserTrackByConversationIdQueryInputType,
) => query(
  getUserTrackColGroup(),
  where('vendors.missive.conversationId', '==', conversationId),
)
