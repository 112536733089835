/**
 * Go fetch translations from dato CMS
 */
import axios from 'axios'
import { DATOCMS_API_KEY } from 'react-native-dotenv'

export const datoAxiosConfig = {
  baseURL: 'https://site-api.datocms.com',
  headers: {
    Authorization: `Bearer ${DATOCMS_API_KEY}`,
    Accept: 'application/json',
  },
}

const defaultOpts = {
  // collectionName: 'i18n',
  // languageFieldName: 'lang',
  translationModelId: 966357,
  namespaceFieldName: 'namespace',
  dataFieldName: 'keys',
  // eslint-disable-next-line no-console
  readOnError: console.error,
  // eslint-disable-next-line no-console
  readMultiOnError: console.error,
  // eslint-disable-next-line no-console
  createOnError: console.error,
}

class Backend {
  services: any

  opts: {
    translationModelId?: number
    namespaceFieldName?: string
    dataFieldName?: string
    readOnError?: () => void
    readMultiOnError?: () => void
    createOnError?: () => void
  }

  i18nOpts: Record<string, unknown>

  MODNAME: string

  type: string

  axios: any

  debug: boolean

  /**
     * @param {*} services `i18next.services`
     * @param {object} backendOptions Backend Options
     * @param {string} [opts.collectionName='i18n'] Collection name for storing i18next data
     * @param {string} [opts.languageFieldName="lang"] Field name for language attribute
     * @param {string} [opts.namespaceFieldName="ns"] Field name for namespace attribute
     * @param {string} [opts.dataFieldName="data"] Field name for data attribute
     * @param {function} [opts.readOnError] Error handler for `read` process
     * @param {function} [opts.readMultiOnError] Error handler for `readMulti` process
     * @param {function} [opts.createOnError] Error handler for `create` process
     */
  constructor(services, backendOptions = {}, i18nextOptions = {}) {
    // console.log(`constructor for i18next Dato CMS backend`)
    this.services = services
    this.opts = backendOptions
    this.i18nOpts = i18nextOptions
    this.MODNAME = 'i18next-datocms-backend'

    this.init(services, backendOptions, i18nextOptions)
  }

  //     init: function(services, backendOptions, i18nextOptions) {
  //         /* use services and options */
  // }

  init(services, opts, i18nOpts) {
    // console.log(`init i18next Dato CMS backend`)
    this.services = services
    this.i18nOpts = i18nOpts
    this.type = 'backend'
    this.opts = { ...defaultOpts, ...this.opts, ...opts }
    this.axios = axios.create(datoAxiosConfig)
  }

  // TODO wrap this query with react-query in order to normalize query interface

  read(language, namespace, callback) {
    if (namespace !== 'translation' && callback) {
      // FIXME - workaround as no idea who's calling translation namespace...

      // console.log('-> read', namespace, language)
      // console.log('i18n', datoAxiosConfig.headers)

      const filterParamKey = `filter[fields][${this.opts.namespaceFieldName}][eq]`

      const query = {
        params: {
          'filter[type]': this.opts.translationModelId?.toString(),
          [filterParamKey]: namespace,
        },
      }
      // console.log(`query`, query)

      this.axios.get('/items', query)
        .then((res) => {
          try {
            // console.log(`res`, res)
            const { data: { data: [{ attributes: { keys: { [language]: dataStr } } }] } } = res
            const data = dataStr ? JSON.parse(dataStr) : {}
            // console.log(namespace + ' translations:', data)
            callback(null, (data) || {})

            return
          } catch (error) {
            if (this.debug) {
              console.warn(
                `${this.MODNAME}: Failed to find data for lang(${language}), ns(${namespace})`,
              )
            }
            callback('error', null)

            throw error

          }
        })
        .catch((error) => {
          //  console.log(error.toJSON())
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //  console.log(error.response.data)
            //  console.log(error.response.status)
            //  console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //  console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            //  console.log('Error', error.message)
          }
          //  console.log(error.config)
          this.opts.readOnError(error)
        })
    }
  }

  readMulti(languages, namespaces, callback) {
    //  console.log('-> readMulti')
    if (callback) {
      const filterParamKey = `filter[fields][${this.opts.namespaceFieldName}][eq]`
      const query = {
        params: {
          'filter[type]': this.opts.translationModelId?.toString(),
          [filterParamKey]: namespaces,
        },
      }
      // console.log(`query`, query)

      this.axios.get('/items', query)
        .then((res) => {
          try {
            //  console.log(`res`, res)
            const { data: { data: [{ attributes: { keys: dataStr } }] } } = res
            const data = dataStr ? JSON.parse(dataStr) : {}

            const multiReadResp = Object.keys(data).reduce((acc, lng) => ({ ...acc, [lng]: { translations: data[lng] } }), {})
            // console.log(namespaces + ' translations:', multiReadResp)
            callback(null, (multiReadResp) || {})

            return
          } catch (error) {
            if (this.debug) {
              console.warn(
                `${this.MODNAME}: Failed to find data for lang(${languages}), namespaces(${namespaces})`,
              )
            }
            callback('error', null)

            throw error
          }
        })
        .catch((error) => {
          //  console.log(error.toJSON())
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //  console.log(error.response.data)
            //  console.log(error.response.status)
            //  console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //  console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            //  console.log('Error', error.message)
          }
          //  console.log(error.config)
          this.opts.readOnError(error)
        })
    }
  }

  create() {
    const x = 'NOT IMPLEMENTED YET'
    if (x === 'NOT IMPLEMENTED YET') {
      this.opts.readOnError(x)
    }
  }
}

// https://www.i18next.com/misc/creating-own-plugins#make-sure-to-set-the-plugin-type
Backend.type = 'backend'

export default Backend
