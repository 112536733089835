import { FC, memo } from 'react'

import { Text } from '~/elements/text/Text'

import { useTranslationContext } from '../context/TranslationContext'

export type EmptyStateIndicatorProps = {
  /** List Type: channel | message */
  listType: 'channel' | 'message';
};

const UnMemoizedEmptyStateIndicator: FC<EmptyStateIndicatorProps> = (props) => {
  const { listType } = props

  const { t } = useTranslationContext('EmptyStateIndicator')

  if (listType === 'channel') {
    return <Text>{t('You have no channels currently')}</Text>
  }

  if (listType === 'message') {
    return null
  }

  return <Text>No items exist</Text>
}

export const EmptyStateIndicator = memo(
  UnMemoizedEmptyStateIndicator,
) as typeof UnMemoizedEmptyStateIndicator
