import { DrawerNavigationOptions } from '@react-navigation/drawer'
import { ReactNode } from 'react'

import { FeatureFlagType } from '~/definitions/featureConfig'
import { IconProps } from '~/elements/icons/Icon/IconProps.d'
import { FeatureConfigAllValues, useFeatureConfigAll } from '~/hooks/remoteConfig.wrapper'
import { PreferencesDrawerScreen } from '~/screens/PreferencesScreen/PreferencesScreen'

import { DiscoverNavigator } from './DiscoverNavigator'
import { EventsNavigator } from './EventsNavigator'
import { HomeNavigator } from './HomeNavigator'
import { LibraryNavigator } from './LibraryNavigator'
import { MenuParamList } from './MenuParamList'
import { MyTrackNavigator } from './MyTrackNavigator'
import { ProfileNavigator } from './ProfileNavigator'

type MenuItem = {
  key: string
  name: keyof MenuParamList
  component: ReactNode
  icon?: IconProps
  hidden?: boolean
  options?: DrawerNavigationOptions
  featureFlag?: FeatureFlagType
}

// items that are always in the menu, in both DrawerNavigator and BottomTabNavigator
const menuItems: Array<MenuItem> = [
  {
    key: 'home',
    name: 'HomeStack',
    component: HomeNavigator,
    icon: {
      name: 'message-lines',
    },
  },
  {
    key: 'myTrack',
    name: 'MyTrackStack',
    component: MyTrackNavigator,
    icon: {
      name: 'compass',
    },
  },
  {
    key: 'profile',
    name: 'ProfileStack',
    component: ProfileNavigator,
    icon: {
      name: 'user-chart',
    },
  },
]

// items that are in the menu only above or under md, that is, in DrawerNavigator or BottomTabNavigator
export const discoverItem = {
  key: 'discover',
  name: 'DiscoverStack',
  component: DiscoverNavigator,
  icon: {
    name: 'magnifying-glass',
  },
}
export const libraryItem = {
  key: 'library',
  name: 'LibraryStack',
  component: LibraryNavigator,
  icon: {
    name: 'books',
  },
}
export const eventsItem = {
  key: 'events',
  name: 'EventsStack',
  component: EventsNavigator,
  icon: {
    name: 'calendar-week',
  },
}
export const preferencesItem = {
  key: 'settings',
  name: 'Preferences',
  component: PreferencesDrawerScreen,
  icon: {
    name: 'gear',
  },
}

export const nonVisibleScreens = menuItems.filter((item) => item.hidden).map((item) => item.name)

const isMenuItemActive = (featureConfig: FeatureConfigAllValues, menuItem: MenuItem) => {
  if (menuItem.hidden) {
    return false
  }

  if (!menuItem.featureFlag) {
    return true
  }

  const featureConfigAsString = featureConfig[menuItem.featureFlag].asString()

  if (featureConfigAsString) {
    // Force default to true in case of featureConfig doesn't define this property yet
    const { isMenuItemDisplayed = true } = JSON.parse(featureConfigAsString)

    if (!isMenuItemDisplayed) {
      return false
    }
  }

  return true
}

export const useMenuItems = () => {

  const {
    status,
    data: featureConfig,
  } = useFeatureConfigAll()

  return {
    isLoading: status === 'loading',
    data: menuItems
      ? menuItems.filter((menuItem) => isMenuItemActive(featureConfig, menuItem))
      : undefined,
  }
}
