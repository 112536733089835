import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { EventScreen } from '~/screens/EventScreen/EventScreen'
import { EventsScreen } from '~/screens/EventsScreen/EventsScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { EventsStackParamList } from './EventsStackParamList'

const EventsStack = createStackNavigator<EventsStackParamList>()

export const EventsNavigator = () => {
  // console.log('-> EventsNavigator')
  const EventHeader = useCallback(() => <GoBackHeaderButton label={false} to="/events" />, [])

  return (
    <ErrorBoundary
      fallback={<ErrorScreen />}
      location="events"
      >
      <Suspense fallback={<LoadingScreen />}>
        <EventsStack.Navigator>
          <EventsStack.Screen
            name="Events"
            component={EventsScreen}
            options={{ headerShown: false }}
          />
          <EventsStack.Screen
            name="Event"
            component={EventScreen}
            options={{
              headerShown: true,
              headerTransparent: true,
              headerLeft: EventHeader,
              header: MainLayoutHeader,
            }}
          />
        </EventsStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
