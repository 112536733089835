import { createContext, PropsWithChildren, useContext } from 'react'

import type { TriggerSettings } from '../MessageInput/DefaultTriggerProvider'
import { CooldownTimerState } from '../MessageInput/hooks/useCooldownTimer'
import type {
  CommandsListState,
  MentionsListState,
  MessageInputHookProps,
  MessageInputState,
} from '../MessageInput/hooks/useMessageInputState'
import type { MessageInputProps } from '../MessageInput/MessageInput'
import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'

export type MessageInputContextValue<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
> = MessageInputState<At, Us> &
  MessageInputHookProps<At, Me, Us> &
  Omit<MessageInputProps<At, Ch, Co, Ev, Me, Re, Us, V>, 'Input'> &
  CooldownTimerState & { autocompleteTriggers?: TriggerSettings<Co, Us, V> } & CommandsListState &
  MentionsListState;

export const MessageInputContext = createContext<(MessageInputState & MessageInputHookProps) | undefined
  >(undefined)

export const MessageInputContextProvider = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>({
    children,
    value,
  }: PropsWithChildren<{
  value: MessageInputContextValue<At, Ch, Co, Ev, Me, Re, Us, V>;
}>) => (
  <MessageInputContext.Provider value={value as MessageInputContextValue}>
    {children}
  </MessageInputContext.Provider>
  )

export const useMessageInputContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>(
    componentName?: string,
  ) => {
  const contextValue = useContext(MessageInputContext)

  if (!contextValue) {
    console.warn(
      // eslint-disable-next-line max-len
      `The useMessageInputContext hook was called outside of the MessageInputContext provider. Make sure this hook is called within the MessageInput's UI component. The errored call is located in the ${componentName} component.`,
    )

    return {} as MessageInputContextValue<At, Ch, Co, Ev, Me, Re, Us, V>
  }

  return contextValue as MessageInputContextValue<At, Ch, Co, Ev, Me, Re, Us, V>
}
