import Color from 'color'
import orderBy from 'lodash/orderBy'

import { getHexColorFromTwColor } from '~/theme/helpers'

// TW classes
const testColors = ['surface-1', 'gray-900']

/**
 * based on a given backgrounc color, will determine if white or black is best contrasting color
 * base on https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
 * @param backgroundColor - TW color class
 */
export const getBestTextColor = (backgroundColor:string) => {
  const bgColor = Color(getHexColorFromTwColor(backgroundColor))
  const contrasts = testColors.map((color) => ({ color, contrast: bgColor.contrast(Color(getHexColorFromTwColor(color))) }))
  const orderedContrasts = orderBy(contrasts, (o) => o.contrast, 'desc')

  return orderedContrasts[0].color
}
