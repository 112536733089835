import classNames from 'classnames'
import { FC } from 'react'

import { useMediaQuery } from '~/hooks/mediaQuery'

import { Button } from '../Button'
import {
  baseTwClasses, iconFamilyVariant, iconSize,
} from './headerButtons.helpers'
import { HeaderIconButton } from './HeaderIconButton'
import { useSmartGoBack } from './useSmartGoBack'

/**
 * A responsive close button that is a label on large screens and just an icon on smal screens
 */

export const CloseHeaderButton: FC<CloseStackHeaderButtonProps> = ({
  to, onPress, label, tw, ...props
}) => {

  const { lg } = useMediaQuery()

  const goBack = useSmartGoBack(to, onPress)

  // default action is to close the stack

  // console.log('label', label)

  if (lg && label) {
    return (
      <Button to={to} onPress={goBack} color="control" title={label} tw={tw} />
    )
  }
  const newTw = classNames(tw, baseTwClasses)

  return (
    <HeaderIconButton
      {...props}
      tw={newTw}
      icon={{
        variant: iconFamilyVariant, sizeNum: iconSize, name: 'xmark',
      }}
      to={to}
      onPress={goBack}
    />
  )
}

export type CloseStackHeaderButtonProps = {
  /**
   *
   */
  to?:string

  onPress?: () => void

  /**
   * Label (desktop only)
   */
  label:string

  /**
   *
   */
  tw?:string
}
