/* eslint-disable max-len */
import {
  collection, collectionGroup, doc, getDoc, getDocs,
} from 'firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import {
  CollectionReference, DocumentReference, Query,
} from '~/definitions/firebase.firestore.types'
import { ConversationData } from '~/definitions/firestore.conversation'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import { GroupMeetingData } from '~/definitions/firestore.groupMeeting'
import { GroupMeetingSessionData } from '~/definitions/firestore.groupMeetingSession'
import { MeetingData } from '~/definitions/firestore.meeting'
import { UserData } from '~/definitions/firestore.user'
import { UserGroupMeetingSessionData } from '~/definitions/firestore.userGroupMeetingSession'
import { UserListData, UserListItemData } from '~/definitions/firestore.userListItem'
import { UserTrackData } from '~/definitions/firestore.userTrack'

import { firestore } from '../navigators.shared/app.firebase.client'

/**
 * Users
 */
export const getUserCol = ():CollectionReference<UserData> => collection(firestore, Collections.USERS) as CollectionReference<UserData>
export const getUserRef = (uid: string|undefined) => (!uid ? null : doc(getUserCol(), uid))
export const getUserDoc = (uid: string) => getDoc(getUserRef(uid) as DocumentReference<UserData>)

/**
 * User tracks
 */
export const getUserTrackCol = (uid: string):CollectionReference<UserTrackData> => collection(firestore, `${Collections.USERS}/${uid}/${Collections.TRACKS}`) as CollectionReference<UserTrackData>
export const getUserTrackRef = (uid: string|undefined, trackId: string|undefined) => ((!uid || !trackId) ? null : doc(getUserTrackCol(uid), trackId))
export const getUserTrackDoc = (uid: string, trackId: string) => getDoc(getUserTrackRef(uid, trackId) as DocumentReference<UserTrackData>)
export const getUserTrackColGroup = () => collectionGroup(firestore, Collections.TRACKS) as Query<UserTrackData>

/**
 * User lists
 */
export const getUserListCol = (uid: string):CollectionReference<UserListData> => collection(firestore, `${Collections.USERS}/${uid}/${Collections.LISTS}`) as CollectionReference<UserListData>
export const getUserListRef = (uid: string|undefined, listId: string|undefined) => ((!uid || !listId) ? null : doc(getUserListCol(uid), listId))
export const getUserListDoc = (uid: string, listId: string) => getDoc(getUserListRef(uid, listId) as DocumentReference<UserListData>)

/**
 * User list items
 */
export const getUserListItemCol = (uid: string, listId: string):CollectionReference<UserListItemData> => collection(firestore, `${Collections.USERS}/${uid}/${Collections.LISTS}/${listId}/${Collections.ITEMS}`) as CollectionReference<UserListItemData>
export const getUserListItemRef = (uid: string|undefined, listId: string|undefined, itemId: string|undefined) => ((!uid || !listId || !itemId) ? null : doc(getUserListItemCol(uid, listId), itemId))
export const getUserListItemDoc = (uid: string, listId: string, itemId: string) => getDoc(getUserListItemRef(uid, listId, itemId) as DocumentReference<UserListItemData>)

/**
 * Customers
 */

/**
  * Customer campaigns
  */
export const getCustomerCampaignColGroup = () => collectionGroup(firestore, Collections.CAMPAIGNS)

/**
 * Evaluations
 */
export const getEvaluationCol = ():CollectionReference<EvaluationData> => collection(firestore, Collections.EVALUATIONS) as CollectionReference<EvaluationData>
export const getEvaluationRef = (evaluationId: string|undefined) => (!evaluationId ? null : doc(getEvaluationCol(), evaluationId))
export const getEvaluationDoc = (evaluationId: string) => getDoc(getEvaluationRef(evaluationId) as DocumentReference<EvaluationData>)

/**
 * Conversations
 */
export const getConversationCol = ():CollectionReference<ConversationData> => collection(firestore, Collections.CONVERSATIONS) as CollectionReference<ConversationData>
export const getConversationRef = (conversationId: string|undefined) => (!conversationId ? null : doc(getConversationCol(), conversationId))
export const getConversationDoc = (conversationId: string) => getDoc(getConversationRef(conversationId) as DocumentReference<ConversationData>)

/**
 * Meetings
 */

export const getMeetingCol = ():CollectionReference<MeetingData> => collection(firestore, Collections.MEETINGS) as CollectionReference<MeetingData>
export const getMeetingRef = (meetingId: string|undefined) => (!meetingId ? null : doc(getMeetingCol(), meetingId))
export const getMeetingDoc = (meetingId: string) => (!meetingId ? undefined : getDoc(getMeetingRef(meetingId) as DocumentReference<MeetingData>))

/**
 * Events
 */
export const getGroupMeetingCol = ():CollectionReference<GroupMeetingData> => collection(firestore, Collections.GROUP_MEETINGS) as CollectionReference<GroupMeetingData>
export const getGroupMeetingRef = (eventId: string|undefined) => (!eventId ? null : doc(getGroupMeetingCol(), eventId))
export const getGroupMeetingDoc = (eventId: string) => (!eventId ? undefined : getDoc(getGroupMeetingRef(eventId) as DocumentReference<GroupMeetingData>))
export const getGroupMeetingSessionCol = (eventDocId: string) => collection(firestore, Collections.GROUP_MEETINGS, eventDocId, Collections.GROUP_MEETINGS_SESSIONS) as CollectionReference<GroupMeetingSessionData>
export const getGroupMeetingSessionsDoc = (eventDocId: string|null) => (!eventDocId ? undefined : getDocs<GroupMeetingSessionData>(getGroupMeetingSessionCol(eventDocId)))
export const getGroupMeetingSessionColGroup = () => collectionGroup(firestore, Collections.GROUP_MEETINGS_SESSIONS) as Query<GroupMeetingSessionData>

/**
 * User group meeting session
 */
export const getUserGroupMeetingSessionCol = () => collection(firestore, Collections.USER_GROUP_MEETINGS_SESSIONS) as CollectionReference<UserGroupMeetingSessionData>
