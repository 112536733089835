import { useTranslation } from 'react-i18next'

import { SiteLocale, useTypeformBySlugQuery } from '~/__generated__/graphql.queries'
import { EvaluationType } from '~/definitions/firestore.evaluation.properties'

export const useTypeformBySlug = (slug: EvaluationType) => {
  const { i18n } = useTranslation()

  return useTypeformBySlugQuery({ slug, locale: i18n.language as SiteLocale })
}
