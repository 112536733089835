export const routesConfig = {
  screens: {
    Auth: {
      path: '',
      screens: {
        Onboarding: {
          path: 'onboarding',
          screens: {
            Main: {
              path: '',
            },
            Welcome: {
              path: 'welcome',
            },
            RegistrationForm: {
              path: 'details',
            },
            RegistrationConfirmed: {
              path: 'complete',
            },
          },
        },
        Menu: {
          path: '',
          screens: {
            HomeStack: {
              path: '',
              screens: {
                Dashboard: {
                  path: '',
                },
                ChatStack: {
                  path: 'conversations',
                  screens: {
                    Main: {
                      path: '',
                    },
                    NewConversation: {
                      path: 'new',
                    },
                  },
                },
              },
            },
            MyTrackStack: {
              path: 'track',
              screens: {
                MyTrackNotAllowed: {
                  path: 'forbidden',
                },
                MyTrack: {
                  path: '',
                },
                Scheduling: {
                  path: 'sessions/:type/new',
                  exact: true,
                },
                Session: {
                  path: 'sessions/:meetingId',
                  exact: true,
                },
                Rescheduling: {
                  path: 'sessions/:meetingId/:action',
                  exact: true,
                },
                Minder: {
                  path: 'minders/:slug',
                  exact: true,
                },
              },
            },
            LibraryStack: {
              path: 'library',
              exact: true,
              screens: {
                Explore: {
                  path: '',
                },
                Category: {
                  path: 'category/:id-slug',
                  exact: true,
                },
              },
            },
            EventsStack: {
              path: 'events',
              exact: true,
              screens: {
                Events: {
                  path: '',
                },
                Event: {
                  path: ':id',
                },
              },
            },
            DiscoverStack: {
              path: 'discover',
              screens: {
                Discover: {
                  path: '',
                },
              },
            },
            ProfileStack: {
              path: 'profile',
              screens: {
                Profile: {
                  path: '',
                },
              },
            },
            Preferences: {
              path: 'preferences',
            },
          },
        },
        MyEvaluations: {
          path: 'evaluations',
        },
        PersonalityPlayer: {
          path: 'evaluations/:evaluationId',
          screens: {
            EvaluationPlayerCarousel: 'carousel',
            EvaluationPlayerPage: {
              path: 'page/:pageNb',
            },
            EvaluationPlayerOutro: 'outro',
          },
        },
        NewTypeform: {
          path: 'evaluations/:slug/new',
        },
        NewTypeformComplete: {
          path: 'evaluations/:slug/complete',
        },
        ContentPlayer: {
          path: 'content',
          screens: {
            Cover: {
              path: ':contentId',
            },
            Chapter: {
              path: ':contentId/page/:pageNb',
            },
            Outro: {
              path: ':contentId/outro',
            },
          },
        },
        Conversation: {
          path: 'conversations/:id',
        },
        Dev: {
          path: 'dev',
        },
        NotFound: '*',
      },
    },
    NonAuth: {
      path: 'auth',
      screens: {
        SignUp: {
          path: 'register',
        },
        SignIn: {
          path: 'signin',
        },
        MagicLinkAwait: {
          path: 'link',
        },
        MagicLinkReturn: {
          path: 'callback',
        },
        NotAllowed: {
          path: 'forbidden',
        },
        ResetPassword: {
          path: 'reset',
        },
      },
    },
  },
}
