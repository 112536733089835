import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '~/components/carousel/Carousel'
// import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'

/**
 * Only shown when loading onboarding stack for the very first time
 */

type WelcomeScreenProps = {
  onComplete: () => void
}

export const WelcomeScreen: FC<WelcomeScreenProps> = ({ onComplete }) => {
  const { t } = useTranslation(['onboarding', 'common'])
  return (
    <Carousel
      swiperKey="app-welcome-v2"
      tw="rounded-none flex-col"
      onLast={onComplete}
      buttonTitle={t('common:cta.continue')}
      buttonLastTitle={t('onboarding:welcome.cta')}
    />
  )
}
