import { getHexColorFromTwColor } from './helpers'

export const CustomRNElementsLightTheme = {
  colors: {
    primary: getHexColorFromTwColor('brand-base'),
    secondary: getHexColorFromTwColor('blue-500'),
    white: getHexColorFromTwColor('white'),
    black: getHexColorFromTwColor('black'),
    grey0: getHexColorFromTwColor('white'),
    grey1: getHexColorFromTwColor('gray-100'),
    grey2: getHexColorFromTwColor('gray-300'),
    grey3: getHexColorFromTwColor('gray-500'),
    grey4: getHexColorFromTwColor('gray-700'),
    grey5: getHexColorFromTwColor('gray-900'),
    greyOutline: getHexColorFromTwColor('gray-500'),
    searchBg: getHexColorFromTwColor('surface-1'), // white
    success: getHexColorFromTwColor('success-base'),
    error: getHexColorFromTwColor('danger-base'),
    warning: getHexColorFromTwColor('warning-base'),
    divider: getHexColorFromTwColor('brand-darker'),
  },
}
