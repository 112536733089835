import { differenceInDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ProfileCard } from '~/components/panels/cards/ProfileCard'
import { useGetCurrentUserLatestEvaluationByType } from '~/hooks/firestore.evaluations'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

export const MindexSection = () => {
  const { t } = useTranslation(['profile'])
  const { isLoading, error, data: who5EvaluationData } = useGetCurrentUserLatestEvaluationByType('WHO-5')

  const [currentStatus, setCurrentStatus] = useState<'non-init' | 'valid' | 'obsolete'>('non-init')
  const [mode, setMode] = useState<'cta' | 'result'>('cta')
  const [mindexScore, setMindexScore] = useState(0)

  useEffect(() => {

    const who5Data = who5EvaluationData?.data()

    if (!who5Data) {
      setCurrentStatus('non-init')
      setMode('cta')

    } else if (who5Data) {
      const { date, scores } = who5Data
      const daysDifference = differenceInDays(date.toDate(), new Date()) // returns negative number of days like -106
      const isMindexObsolete = (daysDifference <= -14) // TODO confirm obsolete delay, so far considered 2 weeks
      setMindexScore(scores.who5)
      if (!isMindexObsolete) {
        setCurrentStatus('valid')
        setMode('result')
      } else if (isMindexObsolete) {
        setCurrentStatus('obsolete')
        setMode('cta')
      }
    }
  }, [who5EvaluationData])

  const url = mode === 'cta' ? '/evaluations/WHO-5/new' : '/'
  const title = t('profile:mindex.title')
  const description = t('profile:mindex.cta.explanation')

  if (isLoading) {
    return <LoadingScreen />
  }
  if (error) {
    return null
  }
  return (
    <BasicSectionContainer tw="my-4">
      <ProfileCard type="mindex" to={url} title={title} description={description} imgId="39077892" mode={mode} status={currentStatus} score={mindexScore} />
    </BasicSectionContainer>
  )
}
