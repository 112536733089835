import { FC } from 'react'

import { DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { MeetingData } from '~/definitions/firestore.meeting'
import { View } from '~/elements/containers/View'
import { useCurrentUserDoneSessions } from '~/hooks/firestore.meetings'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { PastSessionCard } from '../../components/panels/cards/PastSessionCard'

type SessionsDoneProps = Record<string, never>

const getPastSessionCards = (data: DocumentSnapshot<MeetingData>[]) => data.map((doc) => {
  const {
    start_time,
    minder,
    name,
    type,
  } = doc.data() as MeetingData

  return (
    <PastSessionCard
      key={doc.id}
      meetingId={doc.id}
      minder={minder}
      name={name}
      start_time={start_time}
      type={type}
    />
  )
})

export const SessionsDoneSection: FC<SessionsDoneProps> = () => {
  const { isLoading, error, data } = useCurrentUserDoneSessions()
  // console.log('data', data)
  // const { t } = useTranslation(['track'])
  if (isLoading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  // console.log(`PastSessionsSection data`, data)

  if (!data || data.length === 0) {
    return null
  }

  return (
    <View>
      {getPastSessionCards(data)}
    </View>
  )
}
