import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { ContentPlayerProgressBar } from '~/screens/ContentPlayerScreen/ContentPlayerProgressBar'

import { CloseHeaderButton } from '../buttons/headerButtons/CloseHeaderButton'
import { FullOverlayHeader } from './FullOverlayHeader'

/**
 * A header for our content player stack
 * - has a Close button as right accessory
 * - transparent on small screens while surface-1 on full overlay mode (beyond md breakpoint)
 * - embed progress bar when full overlay mode
 */
export const ContentPlayerHeader = ({ options, props }) => {
  const { md } = useMediaQuery()
  const { t } = useTranslation(['common'])

  const [dimensions, setDimensions] = useState({ width: 960, height: 64 })

  // Progress bar width is 7/12 of the central header width
  const progressBarWidth = dimensions.width * (7 / 12)
  //   console.log('progressBarWidth', progressBarWidth)

  const HeaderLeft = useCallback(() => <ContentPlayerProgressBar style={{ width: progressBarWidth }} tw="bg-transparent h-full" />, [progressBarWidth])
  const HeaderRight = useCallback(() => <CloseHeaderButton to="/library" label={t('common:cta.later')} tw="mr-6 md:mr-4" />, [t])

  return (
    <FullOverlayHeader
      {...props}
      setDimensions={setDimensions}
      headerTransparent
      blur="default"
      options={{
        ...options,
        headerLeft: md ? HeaderLeft : null,
        headerRight: HeaderRight,
      }}

    />
  )
}
