import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import { FIREBASE_REMOTE_CONFIG_CACHE_HOURS } from 'react-native-dotenv'

import { defaultFeatureConfig } from '~/constants/featureConfig'

import { firebaseConfig } from './app.firebase.config'

console.log('firebaseConfig (web)')

const firebaseApp = initializeApp(firebaseConfig)

// const appCheckOptions = {
//   provider: new ReCaptchaEnterpriseProvider(FIREBASE_APP_CHECK_TOKEN),
//   isTokenAutoRefreshEnabled: true,
// }

// initializeAppCheck(firebaseApp, appCheckOptions)

export const analytics = getAnalytics(firebaseApp)

export const auth = getAuth(firebaseApp)

export const firestore = getFirestore(firebaseApp)

export const functionsEU = getFunctions(firebaseApp, 'europe-west3')

export const functionsUS = getFunctions(firebaseApp, 'us-central1')

export const remoteConfig = getRemoteConfig(firebaseApp)

// See https://firebase.google.com/docs/reference/js/remote-config.remoteconfigsettings
remoteConfig.settings = {
  minimumFetchIntervalMillis: FIREBASE_REMOTE_CONFIG_CACHE_HOURS * 60 * 60 * 1000,
  fetchTimeoutMillis: 1000,
}

// local state for init values
remoteConfig.defaultConfig = defaultFeatureConfig

// import {
//     connectFirestoreEmulator, initializeFirestore,
//   } from 'firebase/firestore'

// if (runLocalEmulator) {

//     connectAuthEmulator(auth, 'http://localhost:9099')
//     connectFunctionsEmulator(euFunctions, 'localhost', 5001)
//     connectFunctionsEmulator(usFunctions, 'localhost', 5001)
//   }
