import { createStackNavigator } from '@react-navigation/stack'
import {
  Suspense, useEffect, useState,
} from 'react'

import { localStorageKeys, useAsyncStorage } from '~/hooks/storage'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { RegistrationConfirmedScreen } from '~/screens/RegistrationConfirmedScreen/RegistrationConfirmedScreen'
import RegistrationFormScreen from '~/screens/RegistrationFormScreen/RegistrationFormScreen'
import { WelcomeScreen } from '~/screens/WelcomeScreen/WelcomeScreen'

import { TaskListStackParamList } from './TaskListStackParamList'

const TaskListStack = createStackNavigator<TaskListStackParamList>()

/**
 * A navigator that is called upon new session being started
 */
export const TaskListNavigator = () => {
  // console.log('-> TaskListNavigator')

  const {
    isLoading: isHasSeenWelcomeCarouselStorageLoading,
    value: hasSeenWelcomeCarouselStorageValue,
    setItem: setHasSeenWelcomeCarouselStorage,
  } = useAsyncStorage(localStorageKeys.CAROUSEL_WELCOME)

  const [hasSeenCarouselInSession, setHasSeenCarouselInSession] = useState<boolean>(false)

  useEffect(() => {
    if (hasSeenCarouselInSession) {
      setHasSeenWelcomeCarouselStorage(true)
    }
  }, [hasSeenCarouselInSession, setHasSeenWelcomeCarouselStorage])

  if (isHasSeenWelcomeCarouselStorageLoading) {
    return <LoadingScreen />
  }

  if (!hasSeenWelcomeCarouselStorageValue && !hasSeenCarouselInSession) {
    return (
      <WelcomeScreen onComplete={() => {
        setHasSeenCarouselInSession(true)
      }}
      />
    )
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <TaskListStack.Navigator
        screenOptions={{
          headerShown: false, // unskippable onboarding flow
        }}
        >
        <TaskListStack.Screen
          name="RegistrationForm"
          component={RegistrationFormScreen}
        />
        <TaskListStack.Screen
          name="RegistrationConfirmed"
          component={RegistrationConfirmedScreen}
        />
      </TaskListStack.Navigator>
    </Suspense>
  )
}
