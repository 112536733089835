import { FC } from 'react'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { View } from '~/elements/containers/View'
import { Image, ImageProps } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

import { ReaderFooterProps } from '../BottomNavBar'

type IllustrationLayoutProps = ReaderFooterProps & {
  illustration: ImageProps
  description: string
}

/**
 * A layout for an illustration section within content player
 */
export const IllustrationLayout: FC<IllustrationLayoutProps> = ({ illustration, description }) => (
  <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
    <TwoColGridSection tw="h-full">
      <ContentPlayerScrollView>
        <View tw="justify-center">
          <Image {...illustration} />
          <Text
            markdown
            tw="max-w-none justify-center"
            >
            {description}
          </Text>
        </View>
      </ContentPlayerScrollView>
    </TwoColGridSection>
  </FullOverlayContainer>
)
