type UserPersonalityTestScoreValue = 'aff' | 'agr' | 'anx' | 'col' | 'emp' | 'evi' | 'imp' | 'ins' | 'lex' | 'lin' | 'min' | 'neg' | 'opt' | 'pas'
type UpScore = Record<UserPersonalityTestScoreValue, number>

const threeSegmentsThreholds = [0.4, 0.8] // to account for unbalance of repartition

export const getRatio = (val1, val2, maxValue) => {
  // console.log('getRatio for ', val1, val2, maxValue)

  const val = Math.log((val1 + 1) / (val2 + 1)) / Math.log(maxValue + 1) // [-1,1]
  const ratio = 1 - ((val / 2) + 0.5)
  // console.log('ratio', ratio)

  return ratio
}

export const scoreRules = {
  'user-personalities': [
    {
      key: 'personality-empathy',
      criteria: [
        {
          key: 'personality-empathy-high',
          test: (scores: UpScore) => scores.emp >= 10,
        },
        {
          key: 'personality-empathy-low',
          test: (scores: UpScore) => scores.emp < 10,
        },
      ],
    },
    {
      key: 'personality-optimism-pessimism',
      criteria: [
        {
          key: 'personality-optimist',
          test: (scores: UpScore, maxVal:number) => getRatio(scores.opt, scores.neg, maxVal) <= threeSegmentsThreholds[0],
        },
        {
          key: 'personality-optimist-pessimist',
          test: (scores: UpScore, maxVal:number) => getRatio(scores.opt, scores.neg, maxVal) > threeSegmentsThreholds[0]
          && getRatio(scores.opt, scores.neg, maxVal) <= threeSegmentsThreholds[1],
        },
        {
          key: 'personality-pessimist',
          test: (scores: UpScore, maxVal:number) => getRatio(scores.opt, scores.neg, maxVal) > threeSegmentsThreholds[1],
        },
      ],
    },
    {
      key: 'personality-locus',
      criteria: [
        {
          key: 'personality-locus-external',
          test: (scores: UpScore) => scores.lin <= 10 && scores.lex >= 10,
        },
        {
          key: 'personality-locus-internal',
          test: (scores: UpScore) => scores.lin >= 10 && scores.lex <= 10,
        },
        {
          key: 'personality-locus-balanced',
          test: (scores: UpScore) => !((scores.lin <= 10 && scores.lex >= 10) || (scores.lin >= 10 && scores.lex <= 10)),
        },
      ],
    },
    {
      key: 'personality-affirmative-passive-aggressive',
      criteria: [
        {
          key: 'personality-affirmative',
          test: (scores: UpScore) => scores.aff > 10,
        },
        {
          key: 'personality-passive',
          test: (scores: UpScore) => scores.pas > 10,
        },
        {
          key: 'personality-aggressive',
          test: (scores: UpScore) => scores.agr > 10,
        },
      ],
    },
    {
      key: 'personality-anger',
      criteria: [
        {
          key: 'personality-anger-lowest',
          test: (scores: UpScore) => scores.col < 5,
        },
        {
          key: 'personality-anger-low',
          test: (scores: UpScore) => scores.col >= 5 && scores.col < 10,
        },
        {
          key: 'personality-anger-high',
          test: (scores: UpScore) => scores.col >= 10 && scores.col < 15,
        },
        {
          key: 'personality-anger-highest',
          test: (scores: UpScore) => scores.col >= 15,
        },
      ],
    },
    {
      key: 'personality-insecurity',
      criteria: [
        {
          key: 'personality-insecurity-low',
          test: (scores: UpScore) => scores.ins < 5,
        },
        {
          key: 'personality-insecurity-medium',
          test: (scores: UpScore) => scores.ins >= 5 && scores.ins < 10,
        },
        {
          key: 'personality-insecurity-high',
          test: (scores: UpScore) => scores.ins >= 10,
        },
      ],
    },

    {
      key: 'personality-anxiety',
      criteria: [
        {
          key: 'personality-anxiety-lowest',
          test: (scores: UpScore) => scores.anx < 5,
        },
        {
          key: 'personality-anxiety-low',
          test: (scores: UpScore) => scores.anx >= 5 && scores.anx < 10,
        },
        {
          key: 'personality-anxiety-high',
          test: (scores: UpScore) => scores.anx >= 10 && scores.anx < 15,
        },
        {
          key: 'personality-anxiety-highest',
          test: (scores: UpScore) => scores.anx >= 15,
        },
      ],
    },
    {
      key: 'personality-impulsivity',
      criteria: [
        {
          key: 'personality-impulsivity-lowest',
          test: (scores: UpScore) => scores.imp < 5,
        },
        {
          key: 'personality-impulsivity-low',
          test: (scores: UpScore) => scores.imp >= 5 && scores.imp < 10,
        },
        {
          key: 'personality-impulsivity-high',
          test: (scores: UpScore) => scores.imp >= 10 && scores.imp < 15,
        },
        {
          key: 'personality-impulsivity-highest',
          test: (scores: UpScore) => scores.imp >= 15,
        },
      ],
    },

    {
      key: 'personality-avoidance',
      criteria: [
        {
          key: 'personality-avoidance-lowest',
          test: (scores: UpScore) => scores.evi < 5,
        },
        {
          key: 'personality-avoidance-low',
          test: (scores: UpScore) => scores.evi >= 5 && scores.evi < 10,
        },
        {
          key: 'personality-avoidance-high',
          test: (scores: UpScore) => scores.evi >= 10 && scores.evi < 15,
        },
        {
          key: 'personality-avoidance-highest',
          test: (scores: UpScore) => scores.evi >= 15,
        },
      ],
    },

    {
      key: 'personality-mindfulness',
      criteria: [
        {
          key: 'personality-mindfulness-highest',
          test: (scores: UpScore) => scores.min >= 15,
        },
        {
          key: 'personality-mindfulness-high',
          test: (scores: UpScore) => scores.min >= 10 && scores.min < 15,
        },
        {
          key: 'personality-mindfulness-low',
          test: (scores: UpScore) => scores.min >= 5 && scores.min < 10,
        },
        {
          key: 'personality-mindfulness-lowest',
          test: (scores: UpScore) => scores.min < 5,
        },
      ],
    },
  ],
}
