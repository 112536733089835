import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { FC } from 'react'

// import * as Sentry from 'sentry-expo'
import { ErrorBoundaryProps } from './ErrorBoundary'

export const ErrorBoundary:FC<ErrorBoundaryProps> = ({
  fallback, location, children, ...props
}) => (
  <SentryErrorBoundary
    fallback={fallback}
    beforeCapture={(scope) => {
              scope.setTag('location', location)
    }}
    {...props}
    >
    {children}
  </SentryErrorBoundary>
)
