import { FC } from 'react'

import {
  LOGO_NO_PAD_BLACK, LOGO_NO_PAD_BRAND, LOGO_NO_PAD_WHITE, LogoColor,
} from '~/elements/images/Logo'

import { SvgById } from './SvgById'

type BrandLogoProps = {
    color: LogoColor
}

export const BrandLogo:FC<BrandLogoProps> = ({ color }) => {

  let assetId
  switch (color) {
  case 'brand':
    assetId = LOGO_NO_PAD_BRAND
    break
  case 'white':
    assetId = LOGO_NO_PAD_WHITE
    break
  case 'black':
    assetId = LOGO_NO_PAD_BLACK
    break
  default:
    throw new Error(`unknown brand color ${color}`)
  }

  return <SvgById assetId={assetId} />
}
