import { parseName } from 'humanparser'
import upperFirst from 'lodash/upperFirst'

import { ContractorRecord, MinderRecord } from '~/__generated__/graphql.queries'

export const getInitials = (fullName:string):string => {
  // eslint-disable-next-line prefer-regex-literals
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  const initials = [...fullName.matchAll(rgx)] || []

  return (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()
}

type PersonType = MinderRecord | ContractorRecord['minder']
export const getFullName = (person: PersonType | null): string|undefined => {
  if (!person) {
    return undefined
  }
  const { firstName, lastName } = person

  return `${upperFirst(firstName || '')} ${upperFirst(lastName || '')}`
}

export const getFirstName = (fullName?: string | null) => {
  if (fullName) {
    return parseName(fullName).firstName
  }
  return null
}
