import { WidthMediaQueryType } from '~/hooks/mediaQuery'
import {
  getRelevantFontFamilyClass, getRelevantFontItalicClass, getRelevantFontWeightClass, removeFontFamilyClasses, removeFontItalicClasses, removeFontWeightClasses,
} from '~/utils/tailwind/tw.texts.helpers'

import { TextSemanticType, TextVariantType } from './Text.d'

export const getFontFamily = (tw: string, mq: WidthMediaQueryType) => {
  let newTw = tw
  let fontFamily

  const fontFamilyClass = getRelevantFontFamilyClass(newTw, mq)
  const fontWeightClass = getRelevantFontWeightClass(newTw, mq)
  const fontItalicClass = getRelevantFontItalicClass(newTw, mq)

  if (fontFamilyClass === 'font-serif') {
    // Recoleta
    if (fontItalicClass === 'italic') {
      throw new Error('No license for Recoleta italic. Please use non italic..')
    }

    if (fontWeightClass === 'font-thin') {
      console.warn('should include Recoleta-Thin')
      // fontFamily ='Recoleta-Thin'
    } else if (fontWeightClass === 'font-extralight') {
      throw new Error('Recoleta extra light doesnt exist. Please use font-light..')
    } else if (fontWeightClass === 'font-light') {
      console.warn('should include Recoleta-Light')
      // fontFamily = 'Recoleta-Light'
    } else if (fontWeightClass === 'font-normal') {
      // console.warn('should include Recoleta-Regular'
      fontFamily = 'Recoleta-Regular'
    } else if (fontWeightClass === 'font-medium') {
      // console.warn('should include Recoleta-Medium'
      fontFamily = 'Recoleta-Medium'
    } else if (fontWeightClass === 'font-semibold') {
      // console.warn('should include Recoleta-SemiBold'
      fontFamily = 'Recoleta-SemiBold'
    } else if (fontWeightClass === 'font-bold') {
      console.warn('should include Recoleta-Bold')
      // fontFamily ='Recoleta-Bold'
    } else if (fontWeightClass === 'font-extrabold') {
      console.warn('should include Recoleta-ExtraBold')
      // fontFamily = 'Recoleta-ExtraBold'
    } else if (fontWeightClass === 'font-black') {
      console.warn('should include Recoleta-Black')
      // fontFamily = 'Recoleta-Black'
    } else {
      // fallback to normal
      fontFamily = 'Recoleta-Regular'
    }
  } else {
    // For Poppins, we need to define weight to load adequat font
    // eslint-disable-next-line no-lonely-if
    if (fontWeightClass === 'font-thin') {
      console.warn(`should include Poppins_100${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_100Thin_Italic' : 'Poppins_100Thin'
    } else if (fontWeightClass === 'font-extralight') {
      console.warn(`should include Poppins_200${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_200ExtraLight_Italic' : 'Poppins_200ExtraLight'
    } else if (fontWeightClass === 'font-light') {
      console.warn(`should include Poppins_300${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_300Light_Italic' : 'Poppins_300Light'
    } else if (fontWeightClass === 'font-normal') {
      // console.warn('should include Poppins_400' + (fontItalicClass === 'italic' ? '/italic':''))
      fontFamily = fontItalicClass === 'italic' ? 'Poppins_400Regular_Italic' : 'Poppins_400Regular'
    } else if (fontWeightClass === 'font-medium') {
      // console.warn('should include Poppins_500' + (fontItalicClass === 'italic' ? '/italic':''))
      fontFamily = fontItalicClass === 'italic' ? 'Poppins_500Medium_Italic' : 'Poppins_500Medium'
    } else if (fontWeightClass === 'font-semibold') {
      // console.warn('should include Poppins_600' + (fontItalicClass === 'italic' ? '/italic':''))
      fontFamily = fontItalicClass === 'italic' ? 'Poppins_600SemiBold_Italic' : 'Poppins_600SemiBold'
    } else if (fontWeightClass === 'font-bold') {
      console.warn(`should include Poppins_700${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_700Bold_Italic' : 'Poppins_700Bold'
    } else if (fontWeightClass === 'font-extrabold') {
      console.warn(`should include Poppins_800${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_800ExtraBold_Italic' : 'Poppins_800ExtraBold'
    } else if (fontWeightClass === 'font-black') {
      console.warn(`should include Poppins_900${fontItalicClass === 'italic' ? '/italic' : ''}`)
      // fontFamily = fontItalicClass === 'italic' ? 'Poppins_900Black_Italic' : 'Poppins_900Black'
    } else {
      // fallback to normal
      fontFamily = fontItalicClass === 'italic' ? 'Poppins_400Regular_Italic' : 'Poppins_400Regular'
    }
  }

  newTw = removeFontFamilyClasses(newTw)
  newTw = removeFontWeightClasses(newTw)
  newTw = removeFontItalicClasses(newTw)

  return ({
    fontFamily,
    newTw,
  })
}

// eslint-disable-next-line max-len
export const markdownStyleKeyList = ['root', 'codeBlock', 'codeInline', 'em', 'headingContainer', 'heading', 'heading1', 'heading2', 'heading3', 'heading4', 'heading5', 'heading6', 'hr', 'blockquote', 'list', 'listItem', 'listUnordered', 'listUnorderedItem', 'listUnorderedItemIcon', 'listOrdered', 'listOrderedItem', 'listOrderedItemIcon', 'paragraph', 'hardbreak', 'strong', 'table', 'tableHeader', 'tableHeaderCell', 'tableRow', 'tableRowCell', 'text', 'textGroup', 'strikethrough', 'link', 'blocklink', 'image']

export const getCategoryClassNames = (key: TextSemanticType = 'p') => {
  switch (key) {
  case 'h1':
    return 'text-4xl font-semibold font-serif'
  case 'h2':
    return 'text-3xl font-semibold font-serif'
  case 'h3':
    return 'text-2xl font-semibold font-serif'
  case 'h4':
    return 'text-xl font-semibold font-serif'
  case 'h5':
    return 'text-lg font-semibold font-serif'
  case 'h6':
    return 'text-lg font-semibold font-serif'
  case 's1':
    return 'text-base font-sans'
  case 's2':
    return 'text-sm font-sans'
  case 'c1':
    return 'text-base font-sans'
  case 'c2':
    return 'text-base font-sans'
  case 'label':
    return 'text-base font-sans'
  case 'p':
    return 'text-base font-sans'
  case 'p2':
    return 'text-sm font-sans'
  default:
    throw new Error(`unknown font category ${key}`)
  }
}

export const getVariantClassNames = (key: TextVariantType = 'default') => {
  switch (key) {
  case 'default':
    return 'text-gray-700'
  case 'hint':
    return 'text-gray-500'
  case 'alternative':
    return 'text-surface-1'
  case 'link':
    return 'text-brand-base'
  default:
    throw new Error(`unknown font variant ${key}`)
  }
}
