import classNames from 'classnames'
import { BlurTint } from 'expo-blur'
import { FC } from 'react'

import { BlurView } from '~/elements/containers/BlurView'
import { View } from '~/elements/containers/View'
import { getMarginRelatedClasses, getPositionRelatedClasses } from '~/utils/tailwind/tw.containers.helpers'

import { getIconBtnSizeTwClasses, IconButton, IconButtonProps } from '../IconButton'

const BLUR_RING_TW_WIDTH = 2

export type HeaderIconButtonProps = IconButtonProps & {
    blurTint?: BlurTint,
    blurIntensity?: number
}

/**
 * An IconButton wrapped with a blurview
 */
export const HeaderIconButton:FC<HeaderIconButtonProps> = ({
  tw = '', blurTint = 'default', blurIntensity = 80, size = 'xs', ...props
}) => {

  const sizeTw = getIconBtnSizeTwClasses(size, BLUR_RING_TW_WIDTH)
  // console.log('sizeTw', sizeTw)

  // margin classes should apply to the container
  const { positionTw, nonPositionTw } = getPositionRelatedClasses(tw)
  const { marginTw, nonMarginTw: iconButtonTw } = getMarginRelatedClasses(nonPositionTw)

  const baseContainerTw = 'flex items-center justify-center'

  const containerTw = classNames(baseContainerTw, positionTw, marginTw, sizeTw)
  // console.log('HeaderIconButton containerTw', containerTw)
  //   console.log('iconButtonTw', iconButtonTw)

  return (
    <View tw="overflow-hidden rounded-full">
      <BlurView tint={blurTint} intensity={blurIntensity} tw={containerTw}>
        <IconButton color="control" tw={iconButtonTw} {...props} size={size} />
      </BlurView>
    </View>
  )
}
