import classNames from 'classnames'
import { memo, useMemo } from 'react'
import type { TranslationLanguages } from 'stream-chat'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { StreamMessage } from '../context/ChannelStateContext'
import { useComponentContext } from '../context/ComponentContext'
import { useMessageContext } from '../context/MessageContext'
import { useTranslationContext } from '../context/TranslationContext'
import { isOnlyEmojis, renderText as defaultRenderText } from '../stream.utils'
import { QuotedMessage as DefaultQuotedMessage } from './QuotedMessage'
import { messageInnerTw, messageMeInnerTw } from './styles.tw'
import { messageHasAttachments } from './utils'

export type MessageTextProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
> = {
  message?: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>;
  isMyMessage?:boolean
};

const UnMemoizedMessageTextComponent = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>({ message: propMessage, isMyMessage }: MessageTextProps<At, Ch, Co, Ev, Me, Re, Us>) => {

  const { QuotedMessage = DefaultQuotedMessage } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageText')

  const {
    message: contextMessage,
    renderText = defaultRenderText,
    unsafeHTML,
  } = useMessageContext<At, Ch, Co, Ev, Me, Re, Us>('MessageText')

  const { t, userLanguage } = useTranslationContext('MessageText')

  const message = propMessage || contextMessage

  const hasAttachment = messageHasAttachments(message)

  const messageTextToRender = message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] || message.text

  const messageText = useMemo(() => renderText(messageTextToRender, message.mentioned_users), [
    message.mentioned_users,
    messageTextToRender,
  ])

  if (!messageTextToRender && !message.quoted_message) {
    return null
  }

  let innerTw = isMyMessage ? classNames(messageInnerTw, messageMeInnerTw) : classNames(messageInnerTw)

  // str-chat__message-text
  const wrapperTw = 'justify-end p-0'
  // str-chat__message-text-inner

  // hasAttachment ? ` str-chat__message-${theme}-text-inner--has-attachment` : ''
  if (hasAttachment) {
    innerTw += ''
  }

  if (isOnlyEmojis(message.text) && !message.quoted_message) {
    // ` str-chat__message-${theme}-text-inner--is-emoji`
    innerTw += ' bg-transparent px-0  text-3xl leading-none'

  }

  const { textTw, nonTextTw } = getTextRelatedClasses(innerTw)

  // str-chat__${theme}-message--error-message
  const messageErrorMessageTw = ''

  return (
    <View tw={wrapperTw}>
      <View
        tw={nonTextTw}
        testID="message-text-inner-wrapper"
        >
        {message.quoted_message && <QuotedMessage />}
        {message.type === 'error' && (
          <Text tw={messageErrorMessageTw}>{t('Error · Unsent')}</Text>
        )}
        {message.status === 'failed' && (
          <Text tw={messageErrorMessageTw}>
            {message.errorStatusCode !== 403
              ? t('Message Failed · Click to try again')
              : t('Message Failed · Unauthorized')}
          </Text>
        )}
        {unsafeHTML && message.html ? (
          <View dangerouslySetInnerHTML={{ __html: message.html }} />
        ) : (
          <View>
            <Text tw={textTw}>{messageText}</Text>
          </View>
        )}
      </View>
    </View>
  )
}

export const MessageText = memo(
  UnMemoizedMessageTextComponent,
) as typeof UnMemoizedMessageTextComponent
