import { useRoute } from '@react-navigation/native'
import classNames from 'classnames'
import get from 'lodash/get'
import { FC } from 'react'
import { ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { View } from '~/elements/containers/View'
import { ProgressBar } from '~/elements/progress/ProgressBar'
import { useFormattedContentById } from '~/hooks/dato.content'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { getHexColorFromTwColor } from '~/theme/helpers'

import { useCurrentProgress } from './ContentPlayerDataWrapper'

type ContentPlayerProgressBarProps = {

  style?: ViewStyle

  tw?:string
}

/**
 *
 */
export const ContentPlayerProgressBar:FC<ContentPlayerProgressBarProps> = ({ style, tw }) => {
  const route = useRoute()
  const contentId = get(route, 'params.contentId')
  const { isLoading, data } = useFormattedContentById(contentId)
  const { md } = useMediaQuery()
  const tailwind = useTailwind()

  const progress = useCurrentProgress(data)

  // console.log('route', route)
  if (isLoading || !data) {
    return null
  }

  const progressBarTw = md ? 'rounded-full h-2' : 'w-16 rounded-full'
  const progressBarStyle = md ? { ...tailwind(progressBarTw), ...style } : tailwind(progressBarTw)
  // console.log(`progressBarStyle`, progressBarStyle)

  // console.log('ratio', ratio)
  return (
    <View tw={classNames('w-full justify-center flex-grow', tw)} style={{ minHeight: 64, ...style }}>
      <View tw="h-4 justify-center items-center">
        <ProgressBar
          color={getHexColorFromTwColor(md ? 'brand-lightest' : 'brand-base')}
          trackColor={getHexColorFromTwColor(md ? 'gray-100' : 'gray-300')}
          value={Math.max(progress.ratio, 0.1)}
          variant="determinate"
          style={progressBarStyle}
        />
      </View>
    </View>
  )
}

ContentPlayerProgressBar.defaultProps = {

}
