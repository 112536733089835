import { CSSProperties } from 'react'
import { Style } from 'tailwind-rn'

import { computeAspectRatioFromAspecRatioClasses, getAspectRatioRelatedClasses } from '~/utils/tailwind/tw.aspectRatio.helpers'
import { getWidthHeightTw } from '~/utils/tailwind/tw.images.helpers'

/**
   * Aspect ratio is given by
   * 1) style
   * 2) tw classes
   * 3) direct props
   */
export const getAspectRatio = (tw:string, style:CSSProperties, width:number, height:number) => {
  // console.log('getAspectRatio', tw, style, width, height)
  const { aspectRatioTw = '', nonAspectRatioTw } = getAspectRatioRelatedClasses(tw)
  const aspectRatio = style.aspectRatio as unknown as number || computeAspectRatioFromAspecRatioClasses(aspectRatioTw) as number || width / height as number
  // console.log('aspectRatio', aspectRatio)
  return { aspectRatio, remaining: nonAspectRatioTw }
}

const getValFromOtherAndAR = (val:string | number |null, aspectRatio:number): null|number => {
  if (val === null) {
    throw new Error('val is null')
  }

  if (typeof val === 'string') {
    return null
  }
  return val * aspectRatio

}

/**
   * get width/height by order of precedence
   * 1) from style
   * 2) from tw classes
   * 3) from direct props
   */
export const getDimensions = (aspectRatio:number, tw:string, style:CSSProperties, width:number, height:number, tailwind:(cn:string)=> Style) => {
  let locWidth: number | string | undefined |null = style.width as number | string | undefined
  let locHeight: number |string | undefined|null = style.height as number | string | undefined

  /* case height or width given by style */
  if (locWidth === undefined && locHeight === undefined) {
    // height missing
    if (locWidth !== undefined && locHeight === undefined) {
      // console.log('Will compute height from aspectRatio')
      locHeight = getValFromOtherAndAR(locWidth, aspectRatio)
    } else if (locWidth === undefined && locHeight !== undefined) {
      // console.log('Will compute width from aspectRatio')
      locWidth = getValFromOtherAndAR(locHeight, aspectRatio)
    }
  }
  // console.log('width/height from style', locWidth, locHeight)
  /* case height or width given by tw */
  const { result = '', remaining } = getWidthHeightTw(tw)
  const twStyle = tailwind(result)
  if (twStyle.width || twStyle.height) {
    // console.log('twStyle', twStyle)
    if (locWidth === undefined && twStyle.width) {
      // console.log('Will get width from tw')
      locWidth = twStyle.width as (number|string)
    }
    if (locHeight === undefined && twStyle.height) {
      // console.log('Will get height from tw')
      locHeight = twStyle.height as (number|string)
    }

    if (locWidth !== undefined && locHeight === undefined) {
      // console.log('Will compute height from aspectRatio')
      locHeight = getValFromOtherAndAR(locWidth, aspectRatio)

    } else if (locWidth === undefined && locHeight !== undefined) {
      // console.log('Will compute width from aspectRatio')
      locWidth = getValFromOtherAndAR(locHeight, aspectRatio)
    }

  }

  // console.log('width/height after tw consideration', locWidth, locHeight)
  /* case height or width given by direct props */
  if (width || height) {
    if (locWidth === undefined && width !== undefined) {
      // console.log('Will get width from prop')
      locWidth = width
    }
    if (locHeight === undefined && height !== undefined) {
      // console.log('Will get height from prop')
      locHeight = height
    }

    if (locWidth !== undefined && locHeight === undefined) {
      // console.log('Will compute height from aspectRatio')
      locHeight = getValFromOtherAndAR(locWidth, aspectRatio)
    } else if (locWidth === undefined && locHeight !== undefined) {
      // console.log('Will compute width from aspectRatio')
      locWidth = getValFromOtherAndAR(locHeight, aspectRatio)
    }

  }

  return {
    height: locHeight,
    width: locWidth,
    remaining,
  }
}
