import isEqual from 'lodash/isEqual'
import last from 'lodash/last'

import { ObjectFit, ObjectPosition } from '~/elements/images/ImageProps'
import { getHexColorFromTwColor } from '~/theme/helpers'

type GetObjectFitPositionFromTwResult = {
  objectFit: ObjectFit | undefined,
  objectPosition: ObjectPosition| undefined,
  tw: string
}

export const getObjectFitPositionFromTw = (tw = ''): GetObjectFitPositionFromTwResult => {

  // cannot use regex here as it cannot map to either fit or position
  if (tw.includes('object-')) {

    let objectFit
    let objectPosition

    if (tw.includes('object-fill')) {
      objectFit = 'fill'
    }
    if (tw.includes('object-contain')) {
      objectFit = 'contain'
    }
    if (tw.includes('object-cover')) {
      objectFit = 'cover'
    }
    if (tw.includes('object-none')) {
      objectFit = 'none'
    }
    if (tw.includes('object-scale-down')) {
      objectFit = 'scale-down'
    }
    if (tw.includes('object-bottom')) {
      objectPosition = 'bottom'
    }
    if (tw.includes('object-center')) {
      objectPosition = 'center'
    }
    if (tw.includes('object-left')) {
      objectPosition = 'left'
    }
    if (tw.includes('object-left-bottom')) {
      objectPosition = 'left bottom'
    }
    if (tw.includes('object-left-top')) {
      objectPosition = 'left top'
    }
    if (tw.includes('object-right')) {
      objectPosition = 'right'
    }
    if (tw.includes('object-right-bottom')) {
      objectPosition = 'right bottom'
    }
    if (tw.includes('object-right-top')) {
      objectPosition = 'right top'
    }
    if (tw.includes('object-top')) {
      objectPosition = 'top'
    }

    // console.log(`objectPosition`, objectPosition)

    // remove string from imgClassname
    const regexP = /(^|\s)object-[(a-z)|-]+(\s|$)/g // matchs all string that start with object-
    const remainingTw = tw.replace(regexP, ' ').replace(regexP, ' ').replace(/\s\s+/g, ' ').trim()
    // console.log('imgClassName', imgClassName)

    return {
      objectFit,
      objectPosition,
      tw: remainingTw,
    }

  }

  return {
    objectFit: undefined,
    objectPosition: undefined,
    tw,
  }

}

export const getAbsoluteFromTw = (tw = '', style) => {

  const regexP = /(^|\s)absolute($|\s)/
  if (tw.match(regexP) !== null) {

    const nextStyle = style || {}

    nextStyle.position = 'absolute'
    const nextTw = tw.replace(regexP, ' ').replace(/\s\s+/g, ' ').trim()
    // console.log('className', className)
    return {
      style: nextStyle,
      tw: nextTw,
    }
  }
  return {
    style,
    tw,
  }
}

export const getAbsolutePlacementFromTw = (tw = '', style) => {

  const regexP = /(^|\s)(inset|top|bottom|left|right)-[a-z0-9.-]+(\s|$)/g
  if (tw.match(regexP) !== null) {

    const nextStyle = style || {}

    nextStyle.position = 'absolute'
    const nextTw = tw.replace(regexP, ' ').replace(/\s\s+/g, ' ').trim()
    // console.log('className', className)
    return {
      style: nextStyle,
      tw: nextTw,
    }
  }
  return {
    style,
    tw,
  }
}

// replace empty object with undefined
export const cleanUpStyle = (imgClassName, style, className) => ({
  imgClassName: imgClassName === '' ? undefined : imgClassName,
  style: isEqual(style, {}) ? undefined : style,
  className: className === '' ? undefined : className,
})

/**
 * Tranforms a bg-color into its corresponding hex color
 * TODO: handle modifiers such as md: hover:
 */
export const getBgColorFromTw = (tw = '') => {

  const regexP = /(^|\s)bg-[a-z]+-[a-z\d]+(\s|$)/g // matchs all string that start with bg-

  const searchRes = tw.match(regexP)

  if (searchRes) {
    const backgroundColor = getHexColorFromTwColor(last(searchRes))
    // remove string from imgClassname
    const remainingTw = tw.replace(regexP, ' ').replace(regexP, ' ').replace(/\s\s+/g, ' ').trim()
    // console.log('imgClassName', imgClassName)
    return {
      backgroundColor,
      tw: remainingTw,
    }
  }

  return {
    backgroundColor: undefined,
    tw,
  }

}
