import { MainColor } from './Color'
import { ColorShade, SemanticColorShade, SurfaceColorShade } from './ColorShades'

/**
 * We use helpers rather than dynamic variables so that the Tailwind JIT engine can successfully embed variants
 */

export const getBgColor = (color: MainColor | ColorShade | SemanticColorShade | SurfaceColorShade | 'transparent' | 'gray' | undefined) => {
  if (!color || color === 'transparent') {
    return 'bg-transparent'
  }

  switch (color) {

  /**
     * surface
     */
  case 'surface-1':
    return 'bg-surface-1'
  case 'surface-2':
    return 'bg-surface-2'
  case 'surface-3':
    return 'bg-surface-3'
  case 'surface-4':
    return 'bg-surface-4'

  /**
     * Brand
     */
  case 'brand-base':
    return 'bg-brand-base'
  case 'brand-lighter':
    return 'bg-brand-lighter'
  case 'brand-lightest':
    return 'bg-brand-lightest'
  case 'brand-white':
    return 'bg-brand-white'

  /**
     * Orange
     */
  case 'orange':
    return 'bg-orange-100'
  case 'orange-100':
    return 'bg-orange-100'
  case 'orange-300':
    return 'bg-orange-300'
  case 'orange-500':
    return 'bg-orange-500'
  case 'orange-700':
    return 'bg-orange-700'
  case 'orange-900':
    return 'bg-orange-900'

    /**
     * Purple
     */
  case 'purple':
    return 'bg-purple-100'
  case 'purple-100':
    return 'bg-purple-100'
  case 'purple-300':
    return 'bg-purple-300'
  case 'purple-500':
    return 'bg-purple-500'
  case 'purple-700':
    return 'bg-purple-700'
  case 'purple-900':
    return 'bg-purple-900'

    /**
     * Green
     */
  case 'green':
    return 'bg-green-100'
  case 'green-100':
    return 'bg-green-100'
  case 'green-300':
    return 'bg-green-300'
  case 'green-500':
    return 'bg-green-500'
  case 'green-700':
    return 'bg-green-700'
  case 'green-900':
    return 'bg-green-900'

    /**
     * Yellow
     */
  case 'yellow':
    return 'bg-yellow-100'
  case 'yellow-100':
    return 'bg-yellow-100'
  case 'yellow-300':
    return 'bg-yellow-300'
  case 'yellow-500':
    return 'bg-yellow-500'
  case 'yellow-700':
    return 'bg-yellow-700'
  case 'yellow-900':
    return 'bg-yellow-900'

    /**
     * Red
     */
  case 'red':
    return 'bg-red-100'
  case 'red-100':
    return 'bg-red-100'
  case 'red-300':
    return 'bg-red-300'
  case 'red-500':
    return 'bg-red-500'
  case 'red-700':
    return 'bg-red-700'
  case 'red-900':
    return 'bg-red-900'

    /**
 * Blue
 */
  case 'blue':
    return 'bg-blue-100'
  case 'blue-100':
    return 'bg-blue-100'
  case 'blue-300':
    return 'bg-blue-300'
  case 'blue-500':
    return 'bg-blue-500'
  case 'blue-700':
    return 'bg-blue-700'
  case 'blue-900':
    return 'bg-blue-900'

    /**
     * Teal
     */
  case 'teal':
    return 'bg-teal-100'
  case 'teal-100':
    return 'bg-teal-100'
  case 'teal-300':
    return 'bg-teal-300'
  case 'teal-500':
    return 'bg-teal-500'
  case 'teal-700':
    return 'bg-teal-700'
  case 'teal-900':
    return 'bg-teal-900'

    /**
     * Gray
     */
  case 'gray':
    return 'bg-gray-100'
  case 'gray-100':
    return 'bg-gray-100'
  case 'gray-300':
    return 'bg-gray-300'
  case 'gray-500':
    return 'bg-gray-500'
  case 'gray-700':
    return 'bg-gray-700'
  case 'gray-900':
    return 'bg-gray-900'

    /**
     * Semantic colors
     */
  case 'danger-darker':
    return 'bg-danger-darker'
  case 'danger-base':
    return 'bg-danger-base'
  case 'danger-lighter':
    return 'bg-danger-lighter'
  case 'info-darker':
    return 'bg-info-darker'
  case 'info-base':
    return 'bg-info-base'
  case 'info-lighter':
    return 'bg-info-lighter'
  case 'warning-darker':
    return 'bg-warning-darker'
  case 'warning-base':
    return 'bg-warning-base'
  case 'warning-lighter':
    return 'bg-warning-lighter'
  case 'success-darker':
    return 'bg-success-darker'
  case 'success-base':
    return 'bg-success-base'
  case 'success-lighter':
    return 'bg-success-lighter'

  default:
    throw new Error(`unhandled bg color ${color}`)
  }
}

export const getTextColor = (color: MainColor | ColorShade | SemanticColorShade | 'transparent' | 'gray' | undefined) => {
  if (!color || color === 'transparent') {
    return 'text-transparent'
  }

  switch (color) {

  /**
     * Brand
     */
  case 'brand-base':
    return 'text-brand-base'
  case 'brand-lighter':
    return 'text-brand-lighter'
  case 'brand-lightest':
    return 'text-brand-lightest'
  case 'brand-white':
    return 'text-brand-white'

  /**
     * Orange
     */
  case 'orange':
    return 'text-orange-100'
  case 'orange-100':
    return 'text-orange-100'
  case 'orange-300':
    return 'text-orange-300'
  case 'orange-500':
    return 'text-orange-500'
  case 'orange-700':
    return 'text-orange-700'
  case 'orange-900':
    return 'text-orange-900'

    /**
     * Purple
     */
  case 'purple':
    return 'text-purple-100'
  case 'purple-100':
    return 'text-purple-100'
  case 'purple-300':
    return 'text-purple-300'
  case 'purple-500':
    return 'text-purple-500'
  case 'purple-700':
    return 'text-purple-700'
  case 'purple-900':
    return 'text-purple-900'

    /**
     * Green
     */
  case 'green':
    return 'text-green-100'
  case 'green-100':
    return 'text-green-100'
  case 'green-300':
    return 'text-green-300'
  case 'green-500':
    return 'text-green-500'
  case 'green-700':
    return 'text-green-700'
  case 'green-900':
    return 'text-green-900'

    /**
     * Yellow
     */
  case 'yellow':
    return 'text-yellow-100'
  case 'yellow-100':
    return 'text-yellow-100'
  case 'yellow-300':
    return 'text-yellow-300'
  case 'yellow-500':
    return 'text-yellow-500'
  case 'yellow-700':
    return 'text-yellow-700'
  case 'yellow-900':
    return 'text-yellow-900'

    /**
     * Red
     */
  case 'red':
    return 'text-red-100'
  case 'red-100':
    return 'text-red-100'
  case 'red-300':
    return 'text-red-300'
  case 'red-500':
    return 'text-red-500'
  case 'red-700':
    return 'text-red-700'
  case 'red-900':
    return 'text-red-900'

    /**
 * Blue
 */
  case 'blue':
    return 'text-blue-100'
  case 'blue-100':
    return 'text-blue-100'
  case 'blue-300':
    return 'text-blue-300'
  case 'blue-500':
    return 'text-blue-500'
  case 'blue-700':
    return 'text-blue-700'
  case 'blue-900':
    return 'text-blue-900'

    /**
     * Teal
     */
  case 'teal':
    return 'text-teal-100'
  case 'teal-100':
    return 'text-teal-100'
  case 'teal-300':
    return 'text-teal-300'
  case 'teal-500':
    return 'text-teal-500'
  case 'teal-700':
    return 'text-teal-700'
  case 'teal-900':
    return 'text-teal-900'

    /**
     * Gray
     */
  case 'gray':
    return 'text-gray-100'
  case 'gray-100':
    return 'text-gray-100'
  case 'gray-300':
    return 'text-gray-300'
  case 'gray-500':
    return 'text-gray-500'
  case 'gray-700':
    return 'text-gray-700'
  case 'gray-900':
    return 'text-gray-900'

    /**
     * Semantic colors
     */
  case 'danger-darker':
    return 'text-danger-darker'
  case 'danger-base':
    return 'text-danger-base'
  case 'danger-lighter':
    return 'text-danger-lighter'
  case 'info-darker':
    return 'text-info-darker'
  case 'info-base':
    return 'text-info-base'
  case 'info-lighter':
    return 'text-info-lighter'
  case 'warning-darker':
    return 'text-warning-darker'
  case 'warning-base':
    return 'text-warning-base'
  case 'warning-lighter':
    return 'text-warning-lighter'
  case 'success-darker':
    return 'text-success-darker'
  case 'success-base':
    return 'text-success-base'
  case 'success-lighter':
    return 'text-success-lighter'

  default:
    throw new Error(`unhandled bg color ${color}`)
  }
}

export const getGradientFromColor = (color: MainColor | ColorShade | 'transparent' | undefined) => {
  if (!color || color === 'transparent') {
    return 'from-transparent'
  }

  switch (color) {

  /**
     * Brand
     */
  case 'brand-base':
    return 'from-brand-base'
  case 'brand-lighter':
    return 'from-brand-lighter'
  case 'brand-lightest':
    return 'from-brand-lightest'
  case 'brand-white':
    return 'from-brand-white'

    /**
     * Surfaces
     */
  case 'surface-1':
    return 'from-surface-1'
  case 'surface-2':
    return 'from-surface-2'
  case 'surface-3':
    return 'from-surface-3'
  case 'surface-4':
    return 'from-surface-4'
  /**
     * Orange
     */
  case 'orange':
    return 'from-orange-100'
  case 'orange-100':
    return 'from-orange-100'
  case 'orange-300':
    return 'from-orange-300'
  case 'orange-500':
    return 'from-orange-500'

    /**
     * Purple
     */

  case 'purple':
    return 'from-purple-100'
  case 'purple-100':
    return 'from-purple-100'
  case 'purple-300':
    return 'from-purple-300'
  case 'purple-500':
    return 'from-purple-500'

    /**
     * Green
     */
  case 'green':
    return 'from-green-100'
  case 'green-100':
    return 'from-green-100'
  case 'green-300':
    return 'from-green-300'
  case 'green-500':
    return 'from-green-500'

    /**
     * Yellow
     */
  case 'yellow':
    return 'from-yellow-100'
  case 'yellow-100':
    return 'from-yellow-100'
  case 'yellow-300':
    return 'from-yellow-300'
  case 'yellow-500':
    return 'from-yellow-500'

    /**
     * Red
     */
  case 'red':
    return 'from-red-100'

    /**
 * Blue
 */
  case 'blue':
    return 'from-blue-100'
  case 'blue-100':
    return 'from-blue-100'
  case 'blue-300':
    return 'from-blue-300'
  case 'blue-500':
    return 'from-blue-500'

    /**
     * Teal
     */
  case 'teal':
    return 'from-teal-100'
  case 'teal-100':
    return 'from-teal-100'
  case 'teal-300':
    return 'from-teal-300'
  case 'teal-500':
    return 'from-teal-500'

    /**
     * Gray
     */
  case 'gray':
    return 'from-gray-100'
  case 'gray-100':
    return 'from-gray-100'
  case 'gray-300':
    return 'from-gray-300'
  case 'gray-500':
    return 'from-gray-500'

    /**
     * White
     */
  case 'white':
    return 'from-white'

  default:
    throw new Error(`unhandled from- color ${color}`)
  }
}

export const getGradientToColor = (color: MainColor | ColorShade | 'transparent' | undefined) => {
  if (!color || color === 'transparent') {
    return 'to-transparent'
  }

  switch (color) {

  /**
     * Brand
     */
  case 'brand-base':
    return 'to-brand-base'
  case 'brand-lighter':
    return 'to-brand-lighter'
  case 'brand-lightest':
    return 'to-brand-lightest'
  case 'brand-white':
    return 'to-brand-white'
  /**
     * Orange
     */
  case 'orange':
    return 'to-orange-100'
  case 'orange-100':
    return 'to-orange-100'
  case 'orange-300':
    return 'to-orange-300'
  case 'orange-500':
    return 'to-orange-500'

    /**
     * Purple
     */
  case 'purple':
    return 'to-purple-100'
  case 'purple-100':
    return 'to-purple-100'
  case 'purple-300':
    return 'to-purple-300'
  case 'purple-500':
    return 'to-purple-500'

    /**
     * Green
     */
  case 'green':
    return 'to-green-100'
  case 'green-100':
    return 'to-green-100'
  case 'green-300':
    return 'to-green-300'
  case 'green-500':
    return 'to-green-500'

    /**
     * Yellow
     */
  case 'yellow':
    return 'to-yellow-100'
  case 'yellow-100':
    return 'to-yellow-100'
  case 'yellow-300':
    return 'to-yellow-300'
  case 'yellow-500':
    return 'to-yellow-500'

    /**
     * Red
     */
  case 'red':
    return 'to-red-100'
  case 'red-100':
    return 'to-red-100'
  case 'red-300':
    return 'to-red-300'
  case 'red-500':
    return 'to-red-500'

    /**
 * Blue
 */
  case 'blue':
    return 'to-blue-100'
  case 'blue-100':
    return 'to-blue-100'
  case 'blue-300':
    return 'to-blue-300'
  case 'blue-500':
    return 'to-blue-500'

    /**
     * Teal
     */
  case 'teal':
    return 'to-teal-100'
  case 'teal-100':
    return 'to-teal-100'
  case 'teal-300':
    return 'to-teal-300'
  case 'teal-500':
    return 'to-teal-500'

    /**
     * Gray
     */
  case 'gray':
    return 'to-gray-100'
  case 'gray-100':
    return 'to-gray-100'
  case 'gray-300':
    return 'to-gray-300'
  case 'gray-500':
    return 'to-gray-500'

  default:
    throw new Error(`unhandled to- color ${color}`)
  }
}

export const getShadowColor = (color: MainColor | 'brand' | 'gray' | ColorShade, opacity = 25) => {
  switch (color) {
  case 'brand':
  case 'brand-lighter':
  case 'brand-lightest':
  case 'brand-white':
    return `shadow-brand-lighter/${opacity}`
  case 'orange':
  case 'orange-100':
  case 'orange-300':
  case 'orange-500':
  case 'orange-700':
  case 'orange-900':
    return `shadow-orange-300/${opacity}`
  case 'yellow':
  case 'yellow-100':
  case 'yellow-300':
  case 'yellow-500':
  case 'yellow-700':
  case 'yellow-900':
    return `shadow-yellow-300/${opacity}`
  case 'red':
  case 'red-100':
  case 'red-300':
  case 'red-500':
  case 'red-700':
  case 'red-900':
  case 'pink':
  case 'pink-100':
  case 'pink-300':
  case 'pink-500':
  case 'pink-700':
  case 'pink-900':
    return `shadow-red-300/${opacity}`
  case 'purple':
  case 'purple-100':
  case 'purple-300':
  case 'purple-500':
  case 'purple-700':
  case 'purple-900':
    return `shadow-purple-300/${opacity}`
  case 'blue':
  case 'blue-100':
  case 'blue-300':
  case 'blue-500':
  case 'blue-700':
  case 'blue-900':
    return `shadow-blue-300/${opacity}`
  case 'teal':
  case 'teal-100':
  case 'teal-300':
  case 'teal-500':
  case 'teal-700':
  case 'teal-900':
    return `shadow-teal-300/${opacity}`
  case 'green':
  case 'green-100':
  case 'green-300':
  case 'green-500':
  case 'green-700':
  case 'green-900':
    return `shadow-green-300/${opacity}`
  case undefined:
  case 'gray':
  case 'gray-100':
  case 'gray-300':
  case 'gray-500':
  case 'gray-700':
  case 'gray-900':
    return `shadow-gray-300/${opacity}`
  default:
    throw new Error(`unhandled shadow color ${color}`)
  }
}

export const getBorderColor = (color: MainColor | ColorShade |SemanticColorShade| 'transparent' | 'gray' | undefined) => {
  if (!color || color === 'transparent') {
    return 'border-transparent'
  }

  switch (color) {

  /**
     * Brand
     */
  case 'brand-base':
    return 'border-brand-base'
  case 'brand-lighter':
    return 'border-brand-lighter'
  case 'brand-lightest':
    return 'border-brand-lightest'
  case 'brand-white':
    return 'border-brand-white'
  /**
     * Orange
     */
  case 'orange':
    return 'border-orange-100'
  case 'orange-100':
    return 'border-orange-100'
  case 'orange-300':
    return 'border-orange-300'
  case 'orange-500':
    return 'border-orange-500'

    /**
     * Purple
     */
  case 'purple':
    return 'border-purple-100'
  case 'purple-100':
    return 'border-purple-100'
  case 'purple-300':
    return 'border-purple-300'
  case 'purple-500':
    return 'border-purple-500'

    /**
     * Green
     */
  case 'green':
    return 'border-green-100'
  case 'green-100':
    return 'border-green-100'
  case 'green-300':
    return 'border-green-300'
  case 'green-500':
    return 'border-green-500'

    /**
     * Yellow
     */
  case 'yellow':
    return 'border-yellow-100'
  case 'yellow-100':
    return 'border-yellow-100'
  case 'yellow-300':
    return 'border-yellow-300'
  case 'yellow-500':
    return 'border-yellow-500'

    /**
     * Red
     */
  case 'red':
    return 'border-red-100'
  case 'red-100':
    return 'border-red-100'
  case 'red-300':
    return 'border-red-300'
  case 'red-500':
    return 'border-red-500'

    /**
 * Blue
 */
  case 'blue':
    return 'border-blue-100'
  case 'blue-100':
    return 'border-blue-100'
  case 'blue-300':
    return 'border-blue-300'
  case 'blue-500':
    return 'border-blue-500'

    /**
     * Teal
     */
  case 'teal':
    return 'border-teal-100'
  case 'teal-100':
    return 'border-teal-100'
  case 'teal-300':
    return 'border-teal-300'
  case 'teal-500':
    return 'border-teal-500'

    /**
     * Gray
     */
  case 'gray':
    return 'border-gray-100'
  case 'gray-100':
    return 'border-gray-100'
  case 'gray-300':
    return 'border-gray-300'
  case 'gray-500':
    return 'border-gray-500'

    /**
     * Semantic colors
     */
  case 'danger-darker':
    return 'border-danger-darker'
  case 'danger-base':
    return 'border-danger-base'
  case 'danger-lighter':
    return 'border-danger-lighter'
  case 'info-darker':
    return 'border-info-darker'
  case 'info-base':
    return 'border-info-base'
  case 'info-lighter':
    return 'border-info-lighter'
  case 'warning-darker':
    return 'border-warning-darker'
  case 'warning-base':
    return 'border-warning-base'
  case 'warning-lighter':
    return 'border-warning-lighter'
  case 'success-darker':
    return 'border-success-darker'
  case 'success-base':
    return 'border-success-base'
  case 'success-lighter':
    return 'border-success-lighter'

  default:
    throw new Error(`unhandled border- color ${color}`)
  }
}
