import classNames from 'classnames'
import { FC, useMemo } from 'react'

import { Animation } from '../images/Animation'

// import { ActivityIndicator as RnActivityIndicator, ActivityIndicatorProps as RnActivityIndicatorProps } from 'dripsy'

export type ActivityIndicatorProps = {
  tw?:string
  size?: 'small'|'medium'|'large'
}
/**
 *
 * See https://necolas.github.io/react-native-web/docs/activity-indicator/
 */
export const ActivityIndicator: FC<ActivityIndicatorProps> = ({ tw, size = 'medium' }) => {
  // console.log('-> ActivityIndicator')

  let sizeTw = ''
  if (size === 'small') {
    sizeTw = 'w-8 h-8'
  }
  if (size === 'medium') {
    sizeTw = 'w-16 h-16'
  }
  if (size === 'large') {
    sizeTw = 'w-32 h-32'
  }

  return useMemo(() => <Animation itemId="27665421" autoPlay loop tw={classNames(sizeTw, tw)} />, [])
}
