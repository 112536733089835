import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { DiscoverScreen } from '~/screens/DiscoverScreen/DiscoverScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { DiscoverStackParamList } from './DiscoverStackParamList'

const DiscoverStack = createStackNavigator<DiscoverStackParamList>()

export const DiscoverNavigator = () => {
  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])

  return (
    <ErrorBoundary
      fallback={<Fallback />}
      location="discover"
      >
      <Suspense fallback={<LoadingScreen />}>
        <DiscoverStack.Navigator>
          <DiscoverStack.Screen
            name="Discover"
            component={DiscoverScreen}
            options={{ headerShown: false }}
          />
        </DiscoverStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
