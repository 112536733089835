import { useRoute } from '@react-navigation/native'
import get from 'lodash/get'
import sum from 'lodash/sum'
import { useEffect } from 'react'

import analytics from '~/analytics'
import { useFormattedContentById } from '~/hooks/dato.content'

import { NoContentScreen } from '../NoContentScreen/NoContentScreen'

type ProgressType = {
  total: number
  current: number
  remaining: number
  ratio: number
} | undefined

const getCurrentProgress = (currentPage:number, toc, metadata):ProgressType => {
  if (!toc || !metadata) {
    return undefined
  }

  if (currentPage === Infinity) { // AKA if content arrived at outro
    return {
      total: metadata.minutes,
      current: metadata.minutes,
      remaining: 0,
      ratio: 1,
    }
  }

  // Progress as % of time to read
  const currentReadMinutes = currentPage === 0 ? 0 : sum(toc.slice(0, currentPage - 1).map((item) => item.metadata.minutes))
  // console.log(`currentReadMinutes`, currentReadMinutes)
  const progress = currentReadMinutes / metadata.minutes

  return {
    total: metadata.minutes,
    current: currentReadMinutes,
    remaining: metadata.minutes - currentReadMinutes,
    ratio: progress,
  }
}

export const useCurrentPage = ():number => {
  const route = useRoute()
  const { path } = route
  const pageNumber = get(route, 'params.pageNb')

  if (!path?.includes('/page/')) {
    if (path?.endsWith('/outro')) {
      return Infinity
    }
    // Cover page
    return 0
  }
  return pageNumber
}

export const useCurrentProgress = (content):ProgressType => {
  const currentPage = useCurrentPage()

  if (!content) {
    return undefined
  }

  return getCurrentProgress(currentPage, content.toc, content.metadata)
}

export const ContentPlayerDataWrapper = ({ contentId, children }) => {
  // console.log('-> ContentPlayerWrapper', contentId)

  const { isLoading, data } = useFormattedContentById(contentId)

  const progress = useCurrentProgress(data)

  useEffect(() => {
    if (data && progress) {
      analytics.track('playing-progressed', {
        itemId: data?.id,
        position: Math.round((progress?.current || 0) * 60),
        total_length: Math.round((progress?.total || 0) * 60),
      })
    }

  }, [data, progress])

  if (isLoading || !data) {
    return null
  }

  if (data.content.length === 0) {
    // console.log('Sorry no content to display...')

    return <NoContentScreen {...data} />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
