import { FC } from 'react'
import { WebView as RnWebView } from 'react-native-web-webview'
import { WebViewProps as RnWebViewProps } from 'react-native-webview'
import { useTailwind } from 'tailwind-rn'

export type WebViewProps = {
  tw?: string
  newWindow?: boolean|{ name: string, features: string}
  title ?: string
} & Pick<RnWebViewProps, 'source'|'onMessage'| 'scrollEnabled'| 'injectedJavaScript' | 'style'>

/**
 * The web version
 * @see https://github.com/react-native-web-community/react-native-web-webview
 */
export const WebView: FC<WebViewProps> = ({
  tw = '', style, ...props
}) => {
  const tailwind = useTailwind()
  const nextStyle = { ...tailwind(tw), ...style }

  return (
    <RnWebView {...props} style={nextStyle} />
  )
}
