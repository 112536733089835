import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import { CustomHeaderTitle } from '~/elements/headers/CustomHeaderTitle'
import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { ChatMainScreen } from '~/screens/ChatMainScreen/ChatMainScreen'
import { ChatNewConversationScreen } from '~/screens/ChatNewConversationScreen/ChatNewConversationScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { ChatStackParamList } from './ChatStackParamList'

const ChatStack = createStackNavigator<ChatStackParamList>()

export const ChatNavigator = () => {
  // console.log('-> ChatNavigator')

  const NewConversationHeader = useCallback(() => <CloseFullOverlayHeader tw="mr-6 mt-12 md:mt-0" to="/conversations" />, [])

  return (
    <ErrorBoundary
      fallback={<ErrorScreen />}
      location="conversations"
      >
      <Suspense fallback={<LoadingScreen />}>
        <ChatStack.Navigator>
          <ChatStack.Screen
            name="Main"
            component={ChatMainScreen}
            options={{
              headerShown: false,
              title: 'Conversations',
              headerTitle: CustomHeaderTitle,
            }}
          />

          <ChatStack.Screen
            name="NewConversation"
            component={ChatNewConversationScreen}
            options={{
              header: NewConversationHeader,
              headerShown: true,
            }}
          />
        </ChatStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
