import { Dispatch, SetStateAction, useEffect } from 'react'
import type { Channel, Event } from 'stream-chat'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'

export const useUserPresenceChangedListener = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('useUserPresenceChangedListener')

  useEffect(() => {
    const handleEvent = (event: Event<At, Ch, Co, Ev, Me, Re, Us>) => {
      setChannels((channels) => {
        const newChannels = channels.map((channel) => {
          if (!event.user?.id || !channel.state.members[event.user.id]) {
            return channel
          }

          const newChannel = channel // dumb workaround for linter
          newChannel.state.members[event.user.id].user = event.user

          return newChannel
        })

        return [...newChannels]
      })
    }

    client.on('user.presence.changed', handleEvent)

    return () => {
      client.off('user.presence.changed', handleEvent)
    }
  }, [])
}
