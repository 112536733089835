import { AspectRatio } from './DoubleSvgImage/DoubleSvgImage.enums'

export const getOrientationFromAspectRatio = (aspectRatio: AspectRatio) => {
  const [w, h] = aspectRatio.split(':').map((n) => Number(n))
  return w > h ? 'landscape' : 'portrait'
}

export const getAspectRatioValue = (aspectRatio: AspectRatio) => {
  const [w, h] = aspectRatio.split(':').map((n) => Number(n))
  return w / h
}
