import classNames from 'classnames'
import { FC } from 'react'

import { Timestamp } from '~/definitions/firebase.firestore.types'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useDateFormat } from '~/hooks/dateFns'

type DatePresenterProps = {
  date: Timestamp
  tw?: string
  textColor?:string
};

export const DatePresenter: FC<DatePresenterProps> = ({ date, tw, textColor }) => {

  const [day, month] = useDateFormat(date, 'viz').split(' ')

  const tColor = `text-${textColor}`

  return (
    <View tw={classNames('flex-col items-center', tw)}>
      <Text tw={classNames('text-xl', tColor)}>{day}</Text>
      <Text tw={classNames('text-sm uppercase', tColor)}>{month}</Text>
    </View>
  )
}

DatePresenter.defaultProps = {
  textColor: 'gray-900',
}
