import { useTranslation } from 'react-i18next'

import { SiteLocale, useContractorBySlugQuery } from '~/__generated__/graphql.queries'

export const useContractorBySlug = (slug?: string) => {
  const { i18n } = useTranslation()

  return useContractorBySlugQuery({ slug, locale: i18n.language as SiteLocale }, { enabled: !!slug })

}
