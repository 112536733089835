import { useFocusEffect } from '@react-navigation/core'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '~/components/carousel/Carousel'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { evaluationTypes } from '~/constants/firestore.evaluations'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { SvgById } from '~/elements/images/SvgById'
import Overlay from '~/elements/overlays/Overlay'
import { Text } from '~/elements/text/Text'
import { useAsyncStorage } from '~/hooks/storage'

const swiperKey = 'evaluation-player'

const localStorageKey = `hasUserSeen${upperFirst(camelCase(swiperKey))}Carousel`

/**
 * Section for when no personality test has been taken yet
 */
export const UserPersonalityInviteSection = () => {

  // Get translations
  const { t } = useTranslation(['common', 'my-evaluations'])

  const {
    isLoading: isHasUserSeenCarouselLoading,
    value: hasUserSeenCarousel,
    setItem: setHasSeenCarousel,
  } = useAsyncStorage(localStorageKey)

  const [overlayVisible, setOverlayVisible] = useState(false)

  // Only display overlay when screen is focused; not just mounted
  useFocusEffect(
    useCallback(() => {
      if (!isHasUserSeenCarouselLoading && !hasUserSeenCarousel) {
        setOverlayVisible(true)
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    }, [hasUserSeenCarousel, isHasUserSeenCarouselLoading]),
  )

  const onOverlayClose = useCallback(() => {
    setHasSeenCarousel(true)
    setOverlayVisible(false)
    // purposedly ignored setItem
  }, [])

  // Render
  return (

    <BasicSectionContainer tw="self-center items-center">
      <SvgById assetId="24317520" tw="py-12 w-1/3 sm:w-1/2" />
      <Text tw="my-2 text-center font-serif text-xl">{t('my-evaluations:empty.user-personality-prompt')}</Text>
      <Text tw="my-2 text-center text-gray-500 font-sans text-sm">{t('my-evaluations:empty.user-personality-description')}</Text>
      <View tw="flex flex-row justify-center mt-8">
        <Button
          color="brand"
          title={t('my-evaluations:empty.user-personality-cta')}
          to={`/evaluations/${evaluationTypes.USER_PERSONALITIES}/new`}
          size="xl" // xl = ~50px
        />
      </View>

      {swiperKey && (
        <Overlay isVisible={overlayVisible} onBackdropPress={onOverlayClose}>
          {/* <Overlay.Title><Text>Title</Text></Overlay.Title> */}
          <Overlay.Content>
            <Carousel
              swiperKey={swiperKey}
              onLast={onOverlayClose}
              buttonTitle={t('common:cta.continue')}
              buttonLastTitle={t('common:cta.close')}
            />
          </Overlay.Content>
        </Overlay>
      )}

    </BasicSectionContainer>

  )
}
