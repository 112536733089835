import { memo } from 'react'
import type { Action, Attachment } from 'stream-chat'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import type { ActionHandlerReturnType } from '../Message/hooks/useActionHandler'
import type { DefaultAttachmentType } from '../stream.types'

export type AttachmentActionsProps<
  At extends DefaultAttachmentType = DefaultAttachmentType
> = Attachment<At> & {
  /** A list of actions */
  actions: Action[];
  /** Unique id for action button key. Key is generated by concatenating this id with action value - {`${id}-${action.value}`} */
  id: string;
  /** The text for the form input */
  text: string;
  /** Click event handler */
  actionHandler?: ActionHandlerReturnType;
};

const UnMemoizedAttachmentActions = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: AttachmentActionsProps<At>,
) => {
  const {
    actionHandler, actions, id, text,
  } = props

  const handleActionClick = (
    name?: string,
    value?: string,
  ) => {
    if (actionHandler) {
      actionHandler(name, value)
    }
  }

  return (
    <View>
      <View>
        <Text key={0}>{text}</Text>
        {actions.map((action) => (
          <Button
            key={`${id}-${action.value}`}
            label={action.text}
            onPress={() => handleActionClick(action.name, action.value)}
          />
        ))}
      </View>
    </View>
  )
}

/**
 * A component for rendering the actions you can take on an attachment.
 */
export const AttachmentActions = memo(
  UnMemoizedAttachmentActions,
) as typeof UnMemoizedAttachmentActions
