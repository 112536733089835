import { PropsWithChildren } from 'react'
import type { Attachment } from 'stream-chat'

import { View } from '~/elements/containers/View'

import { Gallery as DefaultGallery } from '../Gallery/Gallery'
import type { DefaultAttachmentType } from '../stream.types'
import type { AttachmentProps } from './Attachment'
import { AttachmentActions as DefaultAttachmentActions } from './AttachmentActions'
import { Audio as DefaultAudio } from './Audio'
import { Card as DefaultCard } from './Card'
import { FileAttachment as DefaultFile } from './FileAttachment'

export const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/ogg', 'video/webm', 'video/quicktime']

export type GalleryAttachment<At extends DefaultAttachmentType = DefaultAttachmentType> = {
  images: Attachment<At>[];
  type: string;
};

export type AttachmentContainerProps<At extends DefaultAttachmentType = DefaultAttachmentType> = {
  attachment: Attachment<At> | GalleryAttachment<At>;
  componentType: string;
};

export type RenderAttachmentProps<At extends DefaultAttachmentType = DefaultAttachmentType> = Omit<
  AttachmentProps<At>,
  'attachments'
> & {
  attachment: Attachment<At>;
};

export type RenderGalleryProps<At extends DefaultAttachmentType = DefaultAttachmentType> = Omit<
  AttachmentProps<At>,
  'attachments'
> & {
  attachment: GalleryAttachment<At>;
};

export const isGalleryAttachmentType = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  output: Attachment<At> | GalleryAttachment<At>,
): output is GalleryAttachment<At> => (output as GalleryAttachment<At>).images != null

export const isAudioAttachment = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  attachment: Attachment<At>,
) => attachment.type === 'audio'

export const isFileAttachment = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  attachment: Attachment<At>,
) => attachment.type === 'file'
  || (attachment.mime_type
    // eslint-disable-next-line lodash/prefer-includes
    && SUPPORTED_VIDEO_FORMATS.indexOf(attachment.mime_type) === -1
    && attachment.type !== 'video')

export const isImageAttachment = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  attachment: Attachment<At>,
) => attachment.type === 'image' && !attachment.title_link && !attachment.og_scrape_url

export const isMediaAttachment = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  attachment: Attachment<At>,
// eslint-disable-next-line lodash/prefer-includes
) => (attachment.mime_type && SUPPORTED_VIDEO_FORMATS.indexOf(attachment.mime_type) !== -1)
  || attachment.type === 'video'

export const renderAttachmentWithinContainer = <
  At extends DefaultAttachmentType = DefaultAttachmentType
>(
    props: PropsWithChildren<AttachmentContainerProps<At>>,
  ) => {
  const { attachment, children, componentType } = props

  let extra = ''

  if (!isGalleryAttachmentType(attachment)) {
    // eslint-disable-next-line no-nested-ternary,@typescript-eslint/no-unused-vars
    extra = componentType === 'card' && !attachment?.image_url && !attachment?.thumb_url
      ? 'no-image'
      // eslint-disable-next-line lodash/prefer-get
      : attachment && attachment.actions && attachment.actions.length
        ? 'actions'
        : ''
  }

  return (
    <View
      key={`${isGalleryAttachmentType(attachment) ? '' : attachment?.id}-${
        attachment?.type || 'none'
      } `}
      >
      {children}
    </View>
  )
}

export const renderAttachmentActions = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const { actionHandler, attachment, AttachmentActions = DefaultAttachmentActions } = props

  if (!attachment.actions?.length) {
    return null
  }

  return (
    <AttachmentActions
      {...attachment}
      actionHandler={(name, value) => actionHandler?.(name, value)}
      actions={attachment.actions}
      id={attachment.id || ''}
      key={`key-actions-${attachment.id}`}
      text={attachment.text || ''}
    />
  )
}

export const renderGallery = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderGalleryProps<At>,
) => {
  const { attachment, Gallery = DefaultGallery } = props

  return renderAttachmentWithinContainer({
    attachment,
    children: <Gallery images={attachment.images || []} key="gallery" />,
    componentType: 'gallery',
  })
}

export const renderImage = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const {
    attachment,
    // Image
  } = props

  if (attachment.actions && attachment.actions.length) {
    return renderAttachmentWithinContainer({
      attachment,
      children: (
        <View key={`key-image-${attachment.id}`}>
          {/* {<Image {...attachment} />} */}
          {renderAttachmentActions(props)}
        </View>
      ),
      componentType: 'image',
    })
  }

  return renderAttachmentWithinContainer({
    attachment,
    // children: <Image {...attachment} key={`key-image-${attachment.id}`} />,
    componentType: 'image',
  })
}

export const renderCard = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const { attachment, Card = DefaultCard } = props

  if (attachment.actions && attachment.actions.length) {
    return renderAttachmentWithinContainer({
      attachment,
      children: (
        <View key={`key-image-${attachment.id}`}>
          <Card {...attachment} key={`key-card-${attachment.id}`} />
          {renderAttachmentActions(props)}
        </View>
      ),
      componentType: 'card',
    })
  }

  return renderAttachmentWithinContainer({
    attachment,
    children: <Card {...attachment} key={`key-card-${attachment.id}`} />,
    componentType: 'card',
  })
}

export const renderFile = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const { attachment, File = DefaultFile } = props

  if (!attachment.asset_url) {
    return null
  }

  return renderAttachmentWithinContainer({
    attachment,
    children: <File attachment={attachment} key={`key-file-${attachment.id}`} />,
    componentType: 'file',
  })
}

export const renderAudio = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const { attachment, Audio = DefaultAudio } = props

  return renderAttachmentWithinContainer({
    attachment,
    children: (
      <View key={`key-video-${attachment.id}`}>
        <Audio og={attachment} />
      </View>
    ),
    componentType: 'audio',
  })
}

export const renderMedia = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: RenderAttachmentProps<At>,
) => {
  const { attachment, Media } = props

  if (attachment.actions?.length) {
    return renderAttachmentWithinContainer({
      attachment,
      children: (
        <View
          key={`key-video-${attachment.id}`}
          >
          <View>
            <Media
              controls
              height="100%"
              url={attachment.asset_url}
              width="100%"
            />
          </View>
          {renderAttachmentActions(props)}
        </View>
      ),
      componentType: 'media',
    })
  }

  return renderAttachmentWithinContainer({
    attachment,
    children: (
      <View key={`key-video-${attachment.id}`}>
        <Media
          controls
          height="100%"
          url={attachment.asset_url}
          width="100%"
        />
      </View>
    ),
    componentType: 'media',
  })
}
