import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { Text, View } from 'react-native'

import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'

export type ResetPasswordScreenProps = StackScreenProps<NonAuthStackParamList, 'ResetPassword'>

const ResetPasswordScreen:FC<ResetPasswordScreenProps> = () => (
  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Text>ResetPassword Screen</Text>
  </View>
)

export default ResetPasswordScreen
