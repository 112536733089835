import { FC } from 'react'

import { Image } from '~/elements/images/Image'
import { useDatoSvg } from '~/hooks/dato.assets'

type SvgByIdProps = {
    /**
     * Asset id in Dato CMS
     */
    assetId:string

    tw?:string

    testID?:string
}

/**
 * A wrapper to display an SVG asset from Dato CMS
 *
 * TODO add skeleton while loading
 * TODO handle other platforms but web
 */
export const SvgById:FC<SvgByIdProps> = ({ testID, assetId, tw }) => {
  // console.log('-> SvgById', assetId)
  const { isLoading, data } = useDatoSvg(assetId)

  if (isLoading) {
    return null
  }

  return <Image {...data.upload} tw={tw} testID={testID} />

}
