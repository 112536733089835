/**
 * Navigator once authenticated
 */

// import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Chat } from '~/components/chat'
import { Streami18n } from '~/components/chat/i18n/Streami18n'
import { UserProvider } from '~/context/UserContext'
import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import { ChatConversationHeader } from '~/elements/headers/ConversationHeader'
import { useSigninCheck } from '~/hooks/auth'
import { useChatClient } from '~/hooks/chat'
import { useContactDetailsData } from '~/hooks/onboarding'
import { useFeatureConfigBoolean } from '~/hooks/remoteConfig.wrapper'
import NotFoundScreen from '~/screens.shared/NotFoundScreen/NotFoundScreen'
import { ChatConversationScreen } from '~/screens/ChatConversationScreen/ChatConversationScreen'
import { EvaluationPlayerScreen } from '~/screens/EvaluationPlayerScreen/EvaluationPlayer'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { MyEvaluationsScreen } from '~/screens/MyEvaluationsScreen/MyEvaluationsScreen'
import { NewFormThankYouScreen } from '~/screens/NewFormThankYouScreen/NewFormThankYouScreen'
import { NewTypeformScreen } from '~/screens/NewTypeformScreen/NewTypeformScreen'
import { PreferencesOverlayScreen } from '~/screens/PreferencesScreen/PreferencesScreen'

import { ContentPlayerNavigator } from './ContentPlayerNavigator'
import { MainStackParamList } from './MainStackParamList'
import { MenuNavigator } from './MenuNavigator'
import { TaskListNavigator } from './TaskListNavigator'

const MainStack = createStackNavigator<MainStackParamList>()

const PreferencesHeader = () => <CloseFullOverlayHeader headerRightTw="mr-6 mt-12" />

/**
 *
 * Note the initialRouteName isnt honored
 */
const MainNavigator = () => {
  // console.log('-> MainNavigator')
  const { status, data: isMyTrackEnabled } = useFeatureConfigBoolean('enableMenuItemMyTrack')
  // const { data: allConfig } = useRemoteConfigAll()
  const hasUserCompletedDetails = useContactDetailsData()
  // console.log('hasUserCompletedDetails')
  const { client, isLoading: chatClientLoading } = useChatClient()

  const { i18n } = useTranslation()
  const { t } = useTranslation(['common'])

  const NewTypeformHeader = useCallback(() => <CloseFullOverlayHeader label={t('common:cta.later')} headerRightTw="mr-6 md:mr-4" />, [t])

  const shouldHideUi = chatClientLoading || status === 'loading'
  if (shouldHideUi) {
    return <LoadingScreen />
  }

  // console.log('allConfig', allConfig)
  // console.log('-> MainNavigator', data, messageValue)

  // we load onboarding stack as initial route unless already completed
  let initialRouteName: keyof MainStackParamList = 'Onboarding'
  if (hasUserCompletedDetails || !isMyTrackEnabled) {
    initialRouteName = 'Menu'
  }
  const i18nInstance = new Streami18n({ language: i18n.language })

  // console.log('initialRouteName', initialRouteName)

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Chat client={client} i18nInstance={i18nInstance}>
        <MainStack.Navigator initialRouteName={initialRouteName}>
          <MainStack.Screen
            name="Onboarding"
            component={TaskListNavigator}
            options={{
              headerShown: false,
              presentation: 'modal',
            }}
          />
          <MainStack.Screen
            name="Menu"
            component={MenuNavigator}
            options={{
              headerShown: false,
            }}
          />
          <MainStack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{
              headerShown: false,
            }}
          />
          <MainStack.Screen
            name="MyEvaluations"
            component={MyEvaluationsScreen}
            options={{
              headerShown: false,
              headerTransparent: true,
              headerTitle: () => null,
            }}
          />
          <MainStack.Screen
            name="PersonalityPlayer"
            component={EvaluationPlayerScreen}
            options={{
              headerShown: false,
            }}
          />
          <MainStack.Screen
            name="NewTypeform"
            component={NewTypeformScreen}
            options={{
              headerShown: true,
              header: NewTypeformHeader,
            }}
          />

          <MainStack.Screen
            name="NewTypeformComplete"
            component={NewFormThankYouScreen}
            options={{
              headerShown: false,
            }}
          />
          <MainStack.Screen
            name="ContentPlayer"
            component={ContentPlayerNavigator}
            options={{
              headerShown: false,
              presentation: 'modal',
            }}
          />
          <MainStack.Screen
            name="Conversation"
            component={ChatConversationScreen}
            options={({ route }) => ({
              headerShown: true,
              // eslint-disable-next-line react/no-unstable-nested-components
              header: () => <ChatConversationHeader route={route} />,
            })}
          />
          <MainStack.Screen
            name="Preferences"
            component={PreferencesOverlayScreen}
            options={{
              headerShown: true,
              presentation: 'modal',
              header: PreferencesHeader,
            }}
          />
        </MainStack.Navigator>
      </Chat>
    </Suspense>
  )
}

const WrappedMainNavigator = () => {
  // console.log('-> WrappedMainNavigator')
  const { status, data: signInCheckResult } = useSigninCheck()
  // console.log('WrappedMainNavigator / user', signInCheckResult.user)

  if (status === 'loading' || signInCheckResult === false) {
    // console.log('Not signed in in yet')
    return <LoadingScreen />
  }
  // console.log('Signed in')

  return (
    <UserProvider>
      <MainNavigator />
    </UserProvider>
  )
}

export default WrappedMainNavigator
