import {
  ComponentType, createContext, FC, ForwardRefExoticComponent, PropsWithChildren, useContext,
} from 'react'

import type { AvatarProps } from '~/elements/Avatar/Avatar.d'

import type { AttachmentProps } from '../Attachment/Attachment'
// import type { SuggestionListHeaderProps } from '../AutoCompleteTextarea'
import type {
  SuggestionItemProps,
  SuggestionListProps,
} from '../ChatAutoComplete/ChatAutoComplete'
import type { DateSeparatorProps } from '../DateSeparator/DateSeparator'
import type { EmptyStateIndicatorProps } from '../EmptyStateIndicator/EmptyStateIndicator'
import type { EventComponentProps } from '../EventComponent/EventComponent'
import type { LoadingIndicatorProps } from '../Loading/LoadingIndicator'
import type { FixedHeightMessageProps } from '../Message/FixedHeightMessage'
import type { MessageDeletedProps } from '../Message/MessageDeleted'
import type { MessageOptionsProps } from '../Message/MessageOptions'
import type { MessageRepliesCountButtonProps } from '../Message/MessageRepliesCountButton'
import type { MessageStatusProps } from '../Message/MessageStatus'
import type { MessageTimestampProps } from '../Message/MessageTimestamp'
import type { MessageUIComponentProps, PinIndicatorProps } from '../Message/types'
import type { CooldownTimerProps } from '../MessageInput/hooks/useCooldownTimer'
// import type { SendButtonProps } from '../MessageInput/icons'
import type { MessageInputProps } from '../MessageInput/MessageInput'
import type { QuotedMessagePreviewProps } from '../MessageInput/QuotedMessagePreview'
import type { GiphyPreviewMessageProps } from '../MessageList/GiphyPreviewMessage'
import type { MessageListNotificationsProps } from '../MessageList/MessageListNotifications'
import type { MessageNotificationProps } from '../MessageList/MessageNotification'
import type { ReactionSelectorProps } from '../Reactions/ReactionSelector'
import type { ReactionsListProps } from '../Reactions/ReactionsList'
import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
  UnknownType,
} from '../stream.types'
import type { ThreadHeaderProps } from '../Thread/Thread'
import type { TypingIndicatorProps } from '../TypingIndicator/TypingIndicator'

export type ComponentContextValue<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
> = {
  Attachment: ComponentType<AttachmentProps<At>>;
  Message: ComponentType<MessageUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>>;
  AutocompleteSuggestionHeader?: any // ComponentType<SuggestionListHeaderProps>;
  AutocompleteSuggestionItem?: ComponentType<SuggestionItemProps<Co, Us>>;
  AutocompleteSuggestionList?: ComponentType<SuggestionListProps<Co, Us, V>>;
  Avatar?: ComponentType<AvatarProps>;
  CooldownTimer?: ComponentType<CooldownTimerProps>;
  DateSeparator?: ComponentType<DateSeparatorProps>;
  EditMessageInput?: ComponentType<MessageInputProps<At, Ch, Co, Ev, Me, Re, Us>>;
  EmojiIcon?: ComponentType;
  EmptyStateIndicator?: ComponentType<EmptyStateIndicatorProps>;
  FileUploadIcon?: ComponentType;
  GiphyPreviewMessage?: ComponentType<GiphyPreviewMessageProps<At, Ch, Co, Ev, Me, Re, Us>>;
  HeaderComponent?: ComponentType;
  Input?: ComponentType<MessageInputProps<At, Ch, Co, Ev, Me, Re, Us, V>>;
  LoadingIndicator?: ComponentType<LoadingIndicatorProps>;
  MessageDeleted?: ComponentType<MessageDeletedProps<At, Ch, Co, Ev, Me, Re, Us>>;
  MessageListNotifications?: ComponentType<MessageListNotificationsProps>;
  MessageNotification?: ComponentType<MessageNotificationProps>;
  MessageOptions?: ComponentType<MessageOptionsProps<At, Ch, Co, Ev, Me, Re, Us>>;
  MessageRepliesCountButton?: ComponentType<MessageRepliesCountButtonProps>;
  MessageStatus?: ComponentType<MessageStatusProps>;
  MessageSystem?: ComponentType<EventComponentProps<At, Ch, Co, Ev, Me, Re, Us>>;
  MessageTimestamp?: ComponentType<MessageTimestampProps<At, Ch, Co, Ev, Me, Re, Us>>;
  PinIndicator?: ComponentType<PinIndicatorProps<At, Ch, Co, Ev, Me, Re, Us>>;
  QuotedMessage?: ComponentType;
  QuotedMessagePreview?: ComponentType<QuotedMessagePreviewProps<At, Ch, Co, Ev, Me, Re, Us>>;
  ReactionSelector?: ForwardRefExoticComponent<ReactionSelectorProps<Re, Us>>;
  ReactionsList?: ComponentType<ReactionsListProps<Re, Us>>;
  SendButton?: any // ComponentType<SendButtonProps<At, Me, Us>>;
  ThreadHeader?: ComponentType<ThreadHeaderProps<At, Ch, Co, Ev, Me, Re, Us>>;
  ThreadInput?: ComponentType<MessageInputProps<At, Ch, Co, Ev, Me, Re, Us, V>>;
  ThreadStart?: ComponentType;
  TriggerProvider?: ComponentType;
  TypingIndicator?: ComponentType<TypingIndicatorProps>;
  VirtualMessage?: ComponentType<FixedHeightMessageProps<At, Ch, Co, Ev, Me, Re, Us>>;
};

export const ComponentContext = createContext<ComponentContextValue | undefined>(undefined)

export const ComponentProvider = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>({
    children,
    value,
  }: PropsWithChildren<{
  value: Partial<ComponentContextValue<At, Ch, Co, Ev, Me, Re, Us, V>>;
}>) => (
  <ComponentContext.Provider value={(value as unknown) as ComponentContextValue}>
    {children}
  </ComponentContext.Provider>
  )

export const useComponentContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>(
    componentName?: string,
  ) => {
  const contextValue = useContext(ComponentContext)

  if (!contextValue) {
    console.warn(
      // eslint-disable-next-line max-len
      `The useComponentContext hook was called outside of the ComponentContext provider. Make sure this hook is called within a child of the Channel component. The errored call is located in the ${componentName} component.`,
    )

    return {} as ComponentContextValue<At, Ch, Co, Ev, Me, Re, Us, V>
  }

  return contextValue as ComponentContextValue<At, Ch, Co, Ev, Me, Re, Us, V>
}

/**
 * Typescript currently does not support partial inference, so if ComponentContext
 * typing is desired while using the HOC withComponentContext, the Props for the
 * wrapped component must be provided as the first generic.
 */
export const withComponentContext = <
  P extends UnknownType,
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>(
    Component: ComponentType<P>,
  ): FC<Omit<P, keyof ComponentContextValue<At, Ch, Co, Ev, Me, Re, Us, V>>> => {
  const WithComponentContextComponent = (
    props: Omit<P, keyof ComponentContextValue<At, Ch, Co, Ev, Me, Re, Us, V>>,
  ) => {
    const componentContext = useComponentContext<At, Ch, Co, Ev, Me, Re, Us, V>()

    return <Component {...(props as P)} {...componentContext} />
  }

  WithComponentContextComponent.displayName = (
    Component.displayName
    || Component.name
    || 'Component'
  ).replace('Base', '')

  return WithComponentContextComponent
}
