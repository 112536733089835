import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowTurnDownRight, faCircleCheck, faCircleExclamation, faEllipsis, faFaceSmile, faPaperclip, faReplyAll,
} from '@fortawesome/pro-regular-svg-icons'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'

import { PinIndicatorProps } from './types'
// import type { PinIndicatorProps } from './types'

library.add(faEllipsis, faArrowTurnDownRight, faFaceSmile, faCircleCheck, faReplyAll, faCircleExclamation, faPaperclip)

export const ActionsIcon = () => <Icon name="ellipsis" variant="regular" size="sm" />

export const ReplyIcon = () => <Icon name="arrow-turn-down-right" variant="regular" size="sm" />

export const DeliveredCheckIcon = () => <Icon name="circle-check" variant="regular" size="sm" tw="text-success-base" />

export const ReactionIcon = () => <Icon name="face-smile" variant="regular" size="sm" />

export const ThreadIcon = () => <Icon name="reply-all" variant="regular" size="sm" />

export const ErrorIcon = () => <Icon name="circle-exclamation" variant="regular" size="sm" tw="text-danger-basis" />

export const PinIcon = () => <Icon name="paperclip" variant="regular" size="sm" />

export const PinIndicator = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>({ message, t }: PinIndicatorProps<At, Ch, Co, Ev, Me, Re, Us>) => {
  if (!message || !t) {
    return null
  }
  return (
    <View tw="justify-center">
      <PinIcon />
      <View tw="text-sm mb-0 ml-2 mt-0">
        {message.pinned_by ? `${t('Pinned by')} ${message.pinned_by?.name || message.pinned_by?.id}` : t('Message pinned')}
      </View>
    </View>
  )
}
