import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'

import { MyTrackStackParamList } from '~/navigators/MyTrackStackParamList'

import { MyTrackNotAllowedScreen } from '../MyTrackNotAllowedScreen/MyTrackNotAllowedScreen'

export type MyTrackScreenNotAllowedProps = StackScreenProps<MyTrackStackParamList, 'MyTrack'>

export const MyTrackScreenNotAllowed: FC<MyTrackScreenNotAllowedProps> = () => <MyTrackNotAllowedScreen />
