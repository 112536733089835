import type {
  Data as EmojiMartData,
  EmojiSheetSize,
  NimbleEmojiIndex,
  NimbleEmojiProps,
  NimblePickerProps,
} from 'emoji-mart'
// @ts-expect-error foo
import DefaultEmojiIndex from 'emoji-mart/dist/utils/emoji-index/nimble-emoji-index.js'
import {
  ComponentType, createContext, FC, lazy, PropsWithChildren, useContext,
} from 'react'

import type { UnknownType } from '../stream.types'

export type CommonEmoji = {
  custom: boolean;
  emoticons: string[] | [];
  short_names: string[] | [];
};

export type EmojiSetDef = {
  imageUrl: string;
  sheetColumns: number;
  sheetRows: number;
  sheetSize: EmojiSheetSize;
  spriteUrl: string;
};

export type MinimalEmoji = CommonEmoji &
  EmojiSetDef & {
    colons: string;
    id: string;
    name: string;
    sheet_x: number;
    sheet_y: number;
  };

export type EmojiConfig = {
  commonEmoji: CommonEmoji;
  defaultMinimalEmojis: MinimalEmoji[];
  emojiData: EmojiMartData;
  emojiSetDef: EmojiSetDef;
};

export type EmojiContextValue = {
  emojiConfig: EmojiConfig;
  Emoji?: ComponentType<NimbleEmojiProps>;
  EmojiIndex?: NimbleEmojiIndex;
  EmojiPicker?: ComponentType<NimblePickerProps>;
};

const DefaultEmoji = lazy(async () => {
  // @ts-expect-error foo
  const emoji = await import('emoji-mart/dist/components/emoji/nimble-emoji.js')
  return { default: emoji.default }
})

const DefaultEmojiPicker = lazy(async () => {
  // @ts-expect-error foo
  const emojiPicker = await import('emoji-mart/dist/components/picker/nimble-picker.js')
  return { default: emojiPicker.default }
})

export const EmojiContext = createContext<EmojiContextValue | undefined>(undefined)

export const EmojiProvider = ({
  children,
  value,
}: PropsWithChildren<{
  value: EmojiContextValue;
}>) => {
  const {
    Emoji = DefaultEmoji,
    emojiConfig,
    EmojiIndex = DefaultEmojiIndex,
    EmojiPicker = DefaultEmojiPicker,
  } = value

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const emojiContextValue: Required<EmojiContextValue> = {
    Emoji,
    emojiConfig,
    EmojiIndex,
    EmojiPicker,
  }

  return <EmojiContext.Provider value={emojiContextValue}>{children}</EmojiContext.Provider>
}

export const useEmojiContext = (componentName?: string) => {
  const contextValue = useContext(EmojiContext)

  if (!contextValue) {
    console.warn(
      // eslint-disable-next-line max-len
      `The useEmojiContext hook was called outside of the EmojiContext provider. Make sure this hook is called within a child of the Channel component. The errored call is located in the ${componentName} component.`,
    )

    return {} as Required<EmojiContextValue>
  }

  return contextValue as Required<EmojiContextValue>
}

/**
 * Typescript currently does not support partial inference, so if EmojiContext
 * typing is desired while using the HOC withEmojiContext, the Props for the
 * wrapped component must be provided as the first generic.
 */
export const withEmojiContext = <P extends UnknownType>(
  Component: ComponentType<P>,
): FC<Omit<P, keyof EmojiContextValue>> => {
  const WithEmojiContextComponent = (props: Omit<P, keyof EmojiContextValue>) => {
    const componentContext = useEmojiContext()

    return <Component {...(props as P)} {...componentContext} />
  }

  WithEmojiContextComponent.displayName = (
    Component.displayName
    || Component.name
    || 'Component'
  ).replace('Base', '')

  return WithEmojiContextComponent
}
