import { useEffect } from 'react'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'

export const useConnectionRecoveredListener = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    forceUpdate?: () => void,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('useConnectionRecoveredListener')

  useEffect(() => {
    const handleEvent = () => {
      if (forceUpdate) {
        forceUpdate()
      }
    }

    client.on('connection.recovered', handleEvent)

    return () => {
      client.off('connection.recovered', handleEvent)
    }
  }, [])
}
