import { forwardRef, ReactNode } from 'react'
import RnSwiper, { SwiperProps as RnSwiperProps } from 'react-native-web-swiper'
import { useTailwind } from 'tailwind-rn'

export type SwiperProps = {
  /**
     * Container style classes (as tailwind string)
     */
  containerTw?:string

  innerContainerTw?:string

  swipeAreaTw?:string

  slideWrapperTw?:string

  dotActiveTw ?:string

  children: ReactNode

} & Omit<RnSwiperProps, 'containerStyle' | 'innerContainerStyle' | 'swipeAreaStyle' | 'slideWrapperStyle' >

/**
 * See
 * https://www.npmjs.com/package/react-native-web-swiper
 */
export const Swiper = forwardRef<RnSwiper, SwiperProps>(({
  containerTw = '', innerContainerTw = '', swipeAreaTw = '', slideWrapperTw = '', dotActiveTw = '', controlsProps, ...props
}, ref) => {
  const tailwind = useTailwind()
  const containerStyle = tailwind(containerTw)
  const innerContainerStyle = tailwind(innerContainerTw)
  const swipeAreaStyle = tailwind(swipeAreaTw)
  const slideWrapperStyle = tailwind(slideWrapperTw)

  const newControlsProps = {
    ...controlsProps,
    dotActiveStyle: {
      ...controlsProps.dotActiveStyle,
      ...tailwind(dotActiveTw),
    },
  }

  return (
    <RnSwiper
      ref={ref}
      {...props}
      containerStyle={containerStyle}
      innerContainerStyle={innerContainerStyle}
      swipeAreaStyle={swipeAreaStyle}
      slideWrapperStyle={slideWrapperStyle}
      controlsProps={newControlsProps}
    />
  )
})
