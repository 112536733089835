import { FC } from 'react'

import { View } from '~/elements/containers/View'
import { getHexColorFromTwColor } from '~/theme/helpers'

import { EventCompleteInfo, EventCompleteInfoProps } from './EventCompleteInfo'

export const EventBannerInfo: FC<Omit<EventCompleteInfoProps, 'type'>> = ({ event, eventDate }) => {

  const cardModeStyle = {
    backgroundColor: event?.model?.illustration?.backgroundColor && getHexColorFromTwColor(event.model?.illustration?.backgroundColor),
  }
  return (
    <View style={cardModeStyle} tw="md:rounded-2xl flex-col items-center pt-8 pb-8 px-6">
      <EventCompleteInfo event={event} eventDate={eventDate} type="banner" />
    </View>
  )
}
