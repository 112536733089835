import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import {
  useEventsWithSessions,
} from '~/hooks/firestore.events'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useUser } from '~/hooks/user.context'
import { EventsStackParamList } from '~/navigators/EventsStackParamList'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { selectIncomingEvents, selectPastConferences, selectPastRegisteredEvents } from '~/selectors/events'

import { EventReplays } from './EventReplays'
import { EventsIncoming } from './EventsIncoming'
import { UserPastEvents } from './UserPastEvents'

type EventsScreenProps = StackScreenProps<EventsStackParamList, 'Events'>

export const EventsScreen: FC<EventsScreenProps> = () => {
  // console.log('-> EventsScreen')
  const { t } = useTranslation(['events'])
  const { md } = useMediaQuery()

  const { user } = useUser()
  // console.log('user', user)

  const { data: events } = useEventsWithSessions(user.uid)
  // console.log('events', events)

  const incomingEvents = selectIncomingEvents(events)
  const pastConferencesWithReplay = selectPastConferences(events)
  const pastRegisteredEvents = selectPastRegisteredEvents(events)

  if (!incomingEvents || !pastConferencesWithReplay || !pastRegisteredEvents) {
    return <LoadingScreen />
  }

  return (
    <MainContainer scrollEnabled>
      {md && <ScreenHeadingSection tw="mb-4 md:mt-20" title={t('events:main.heading.title')} description={t('events:main.heading.description')} />}
      {incomingEvents.length > 0 && <EventsIncoming events={incomingEvents} />}
      {/* Vote */}
      {pastConferencesWithReplay.length > 0 && <EventReplays events={pastConferencesWithReplay} />}
      {pastRegisteredEvents.length > 0 && <UserPastEvents events={pastRegisteredEvents} />}
    </MainContainer>
  )
}
