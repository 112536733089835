import { FC, forwardRef } from 'react'

import { ImageImg } from './Image/ImageImg'
import { ResponsiveWebImageProps } from './Image/ImageImg.web'
import { ImageSvg } from './Image/ImageSvg'
import { ImageSvgProps } from './Image/ImageSvg.web'
import { ImageFormat } from './ImageProps'

export type ImageProps = {
  format: ImageFormat
} & (ImageSvgProps | ResponsiveWebImageProps)

/**
 * A wrapper to display an image either as SVG or raster
 */
export const Image: FC<ImageProps> = forwardRef(({ format = 'svg', ...props }, ref) => {
  // console.log('-> Image', format, props)
  if (format === 'svg') {
    return <ImageSvg ref={ref} {...props as ImageSvgProps} />
  }

  return <ImageImg ref={ref} {...props as ResponsiveWebImageProps} />

})
