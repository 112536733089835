import { useTranslation } from 'react-i18next'

import { SiteLocale, useSessionSlugQuery } from '~/__generated__/graphql.queries'

export const useSessionBySlug = (slug: string) => {
  const { i18n } = useTranslation()

  return useSessionSlugQuery({
    slug,
    locale: i18n.language as SiteLocale,
  })
}
