import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import { FC } from 'react'

import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { View } from '../containers/View'
import { Icon } from '../icons/Icon'
import { Text } from '../text/Text'
import { BadgeProps } from './Badge.d'
import { getBadgeTwClassesFromColor, getBadgeTwClassesFromSize } from './tw.helpers'

library.add(faXmark)

const baseContainerTw = 'rounded-full justify-center'
const baseTextTw = ''

/**
 * Badges can be used as indicators, tags, notification count...
 *
 * See
 * https://tailwindui.com/components/application-ui/elements/badges
 * https://reactnativeelements.com/docs/badge
 */
export const Badge:FC<BadgeProps> = ({
  color = 'basic', size = 'small', value, removeButton = false, tw,
}) => {
  const badgeColorTw = getBadgeTwClassesFromColor(color)
  // console.log('badgeColorTw', badgeColorTw)
  const badgeSizeTw = getBadgeTwClassesFromSize(size)
  // console.log('badgeSizeTw', badgeSizeTw)
  const newTw = classNames(badgeColorTw, badgeSizeTw, tw)
  // console.log('Badge tw', newTw)

  const { textTw, nonTextTw } = getTextRelatedClasses(newTw)

  return (
    <View tw={classNames(nonTextTw, baseContainerTw)}>
      {size !== 'mini' && <Text tw={classNames(textTw, baseTextTw)}>{value}</Text>}
      {removeButton && <Icon variant="regular" name="xmark" />}
    </View>
  )
}
