import { FC, SyntheticEvent } from 'react'
// import Carousel, { Modal, ModalGateway, ViewType } from 'react-images';

// import { ModalImage } from './ModalImage';

/**
 * Small modal component
 */
export type ModalWrapperProps = {
  /** The images for the Carousel component */
  // images: ViewType[];
  /** Boolean for if modal is open */
  modalIsOpen: boolean;
  /** click event handler for toggling modal */
  toggleModal: (event: SyntheticEvent<HTMLButtonElement>) => void;
  /** The index for the component */
  index?: number;
};

// const { images, index, modalIsOpen, toggleModal } = props;
export const ModalComponent: FC<ModalWrapperProps> = () => null
// return (
//   <ModalGateway>
//     {modalIsOpen ? (
//       <Modal onClose={toggleModal}>
//         <Carousel
//           components={{
//             // @ts-expect-error
//             View: ModalImage,
//           }}
//           currentIndex={index}
//           views={images}
//         />
//       </Modal>
//     ) : null}
//   </ModalGateway>
// );
