import { useTranslation } from 'react-i18next'

import { SiteLocale, useAllContentByGroupQuery, useAllContentGroupsQuery } from '~/__generated__/graphql.queries'

export const useAllContentsForCategoryById = (categoryId: string) => {
  const { i18n } = useTranslation()

  return useAllContentByGroupQuery({ groupId: categoryId, locale: i18n.language as SiteLocale })
}

export const useCategories = () => {
  const { i18n } = useTranslation()

  const reqRes = useAllContentGroupsQuery({ locale: i18n.language as SiteLocale })

  // count nbContent per group
  // FIXME when we have more than 100 content, needs pagination and a separate hook likely
  if (reqRes.data) {
    // console.log(`reqRes.data`, reqRes.data)
    const nbContentPerGroup = reqRes.data.allContents.reduce((acc, content) => {
      content.groups.forEach((group) => {
        const groupId = group.id
        if (acc[groupId]) {
          acc[groupId] += 1
        } else {
          acc[groupId] = 1
        }
      })

      return acc
    }, {})

    const res = reqRes.data?.allContentGroups.map((item) => ({ ...item, nbContent: nbContentPerGroup[item.id] || 0 }))
    // console.log(`res`, res)

    return {
      isLoading: reqRes.isLoading,
      error: reqRes.error,
      data: { allContentGroups: res },
    }
  }

  return reqRes
}
