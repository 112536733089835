import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { MinderMobileCard } from '~/components/panels/cards/MinderMobileCard'
import { useCurrentUserMinderSlug } from '~/hooks/minder'

import { BookMeetingCardOrCta } from './BookMeetingCardOrCta'
import { MyTrackProps } from './MyTrackProps'
import { SessionPlannedSection } from './SessionPlannedSection'
import { SessionsDoneSection } from './SessionsDoneSection'

export const MyTrackMobileScreen: FC<MyTrackProps> = (props) => {
  // console.log('-> MyTrackMobileScreen', minder)
  const { isLoading, data } = useCurrentUserMinderSlug()
  const { t } = useTranslation(['track'])

  return (
    <MainContainer scrollEnabled tw="">
      <ScreenHeadingSection tw="mb-4" title={t('track:main.title')} description={t('track:main.description')} />
      <BasicSectionContainer tw="">
        {!isLoading && !!data && <MinderMobileCard slug={data} />}
        <SessionPlannedSection />
        <SessionsDoneSection />
        <BookMeetingCardOrCta {...props} tw="mt-4" />

      </BasicSectionContainer>
    </MainContainer>
  )
}
