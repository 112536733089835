export type StateModifier = 'hover'|'active'|'focus'

export type InteractionState = {
  hover?: boolean,
  active?: boolean,
  focus?: boolean,
}

// bg-gray-100 hover:bg-gray-200 => bg-gray-200
const getTwWithModifier = (tw:string, m:StateModifier) => {
  // console.log('-> getTwWithModifier', tw, m)

  if (!tw.includes(m)) {
    return tw
  }

  const arr = tw.split(' ')
  /**
     * We should remove any similar class that doesnt have this modifier
     */

  // list all cases
  const cases = arr.filter((c) => c.startsWith(`${m}:`))

  cases.forEach((c) => {
    const valToKeep = c.split(':')[1]

    const rootArr = valToKeep.split('-')
    rootArr.pop()
    const root = rootArr.join('-')

    // look for classes that need to be dismissed
    const toRemove = arr.filter((key) => key.startsWith(`${root}-`))

    // remove them from the original array
    toRemove.forEach((classToRemove) => {
      arr.splice(arr.indexOf(classToRemove), 1)
    })

    // replace the prefixed class by the active one
    arr.splice(arr.indexOf(c), 1, valToKeep)

  })

  return arr.join(' ')

}

const getTwWithoutModifier = (tw:string, m:StateModifier) => {
  // console.log('-> getTwWithoutModifier', tw, m)
  if (!tw.includes(m)) {
    return tw
  }

  return tw.split(' ').filter((c) => !c.startsWith(`${m}:`)).join(' ')

}

export const getTwForInteractionState = (tw: string, state: InteractionState) => {
  let newTw = tw

  Object.keys(state).forEach((m) => {
    if (state[m] === true) {
      newTw = getTwWithModifier(newTw, m)
    } else {
      newTw = getTwWithoutModifier(newTw, m)
    }
  })
  return newTw
}
