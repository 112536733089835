import { FC } from 'react'

import { View, ViewProps } from '../containers/View'
import { Icon } from './Icon'
import { IconProps } from './Icon/IconProps.d'

export type ContainedIconProps = ViewProps & {
  icon : IconProps
}

/**
 * An Icon wrapped in a View container
 * TODO: a higher level wrapper that understand styling related props (See Button, etc...)
 * - rounding
 * - size
 * - color
 */
export const ContainedIcon:FC<ContainedIconProps> = ({ icon, ...props }) => (
  <View {...props}>
    <Icon {...icon} />
  </View>
)
