import set from 'lodash/set'

/**
 * add user language as filter on word count to only return items available in that language
 */
export const getQueryWithLanguageFilter = (params, i18n) => {
  const reQLExp = `'wordCount_${i18n.language}' > 10`
  const newParams = { ...params }
  if (params.options?.filter) {
    // console.warn('TODO compose filters')
    // compose additionnal filter with existing ones
    const reQLArr = params.options.filter.split(' and ')
    reQLArr.push(`(${reQLExp})`)
    set(newParams, 'options.filter', reQLArr.join(' and '))
  } else {
    set(newParams, 'options.filter', reQLExp)
  }

  return newParams
}
