import { sanitizeUrl } from '@braintree/sanitize-url'
import { FC, memo } from 'react'

import { Link } from '~/elements/buttons/Link'

/**
 * Similar to a regular anchor tag, but it sanitizes the href value and prevents XSS
 */
export type SafeAnchorProps = {
  /** Set the className for the anchor tag element */
  tw?: string;
  /** Specifies that the target (href attribute) will be downloaded instead of navigating to a file */
  download?: boolean;
  /** Set the href attribute for the anchor tag element */
  href?: string;
  /** Set the rel attribute for the anchor tag element */
  rel?: string;
  /** Set the target attribute for the anchor tag element */
  target?: string;
};

const UnMemoizedSafeAnchor: FC<SafeAnchorProps> = (props) => {
  const {
    children, tw, download, href, rel, target,
  } = props
  if (!href) {
    return null
  }
  const sanitized = sanitizeUrl(href)
  return (
    <Link tw={tw} download={download} href={sanitized} rel={rel} target={target}>
      {children}
    </Link>
  )
}

export const SafeAnchor = memo(UnMemoizedSafeAnchor) as typeof UnMemoizedSafeAnchor
