import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent } from 'react-native'

import { Button } from '~/elements/buttons/Button'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Image, ImageProps } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

type PersonalityTileProps = {
  to: string
  title: string | null
  excerpt: string | null
  illustration: ImageProps
  orientation: 'landscape' | 'portrait'
}

export const PersonalityTile: FC<PersonalityTileProps> = ({
  to,
  title,
  excerpt,
  illustration,
  orientation,
}) => {

  const [imgDim, setImageDim] = useState()
  const pressableTw = ['bg-surface-1 rounded-xl p-6']
  let viewTw = ''
  const imageTw = ['w-24 sm:w-28']
  const titleTw = ['text-brand-base uppercase font-semibold']
  const excerptTw = ['font-medium font-serif text-sm sm:text-base leading-tight sm:leading-normal']
  let ctaTw = ''
  let numLines

  if (orientation === 'landscape') {
    pressableTw.push('max-w-xlp mb-4 relative overflow-hidden')
    imageTw.push('absolute -top-4 -right-4')
    viewTw = 'w-4/5'
    titleTw.push('mb-2')
    excerptTw.push('mb-4')
    ctaTw = 'w-min'
    numLines = 4
  } else if (orientation === 'portrait') {
    pressableTw.push('w-60 flex-col items-center mr-4')
    imageTw.push('my-4 w-1/2 aspect-h-1 aspect-w-1')
    viewTw = 'mt-4'
    titleTw.push('text-center mb-4 h-12')
    excerptTw.push('text-center mb-4')
    numLines = 2
  }

  const { t } = useTranslation(['common', 'player'])

  const onImageContainerResized = (e:LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout
    setImageDim({ height, width })
  }
  return (
    <Pressable tw={pressableTw.join(' ')} to={to}>
      <View tw={imageTw.join(' ')} onLayout={onImageContainerResized}>
        <Image {...illustration} tw={imageTw.join(' ')} style={imgDim} />
      </View>
      <View tw={viewTw}>
        <Text tw={titleTw.join(' ')} numberOfLines={2}>{title}</Text>
        <Text tw={excerptTw.join(' ')} numberOfLines={numLines}>{excerpt}</Text>
        <Button tw={ctaTw} size="l" title={t('common:cta.discover')} />
      </View>
    </Pressable>
  )
}
