import {
  limit,
  query,
  where,
} from 'firebase/firestore'

import { ByUserRefAndGroupMeetingSessionRefsQueryParams, WithLimitQueryOptions } from '~/definitions/firestore.queries'

import { getGroupMeetingCol, getGroupMeetingSessionColGroup, getUserGroupMeetingSessionCol } from './firestore.helpers'

export const getGroupMeetingById = (
  { id }: { id: string|null },
  { limit: limitOpt = 1 }: WithLimitQueryOptions,
) => query(
  getGroupMeetingCol(),
  where('vendors.livestorm.eventId', '==', id),
  limit(limitOpt),
)

export const getGroupMeetingSessionsByIds = (
  { ids }: { ids: Array<string|null> },
  { limit: limitOpt = 1 }: WithLimitQueryOptions,
) => query(
  getGroupMeetingSessionColGroup(),
  where('vendors.livestorm.eventId', 'in', ids),
  limit(limitOpt),
)

export const getUserGroupMeetingSessionsByRefs = (
  { userRef, groupMeetingSessionRefs }: ByUserRefAndGroupMeetingSessionRefsQueryParams,
) => query(
  getUserGroupMeetingSessionCol(),
  where('userRef', '==', userRef),
  where('groupMeetingSessionRef', 'in', groupMeetingSessionRefs),
)
