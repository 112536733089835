import {
  limit, orderBy, query, where,
} from 'firebase/firestore'

import { meetingStatus, meetingTypes } from '~/constants/firestore.meetings'
import {
  ByCalendlyIdQueryParams,
  ByUserRefNowQueryParams,
  ByUserRefQueryParams,
  ByUserRefStatusQueryParams,
  GetLatestMeetingsByParticipantsQueryParams,
  TrackRefInput,
  WithLimitQueryOptions,
} from '~/definitions/firestore.queries'

import { getMeetingCol } from './firestore.helpers'

export const nextPlannedMeetingsQuery = (
  { userRef, now }: ByUserRefNowQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getMeetingCol(),
  where('user', '==', userRef),
  where('status', '==', meetingStatus.PLANNED),
  where('start_time', '>=', now),
  limit(limitOpt || 20),
)

export const pastMeetingsQuery = (
  { userRef, now }: ByUserRefNowQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getMeetingCol(),
  where('user', '==', userRef),
  where('start_time', '<=', now),
  limit(limitOpt || 20),
)
export const getMeetingsByStatusQuery = (
  { userRef, status }: ByUserRefStatusQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getMeetingCol(),
  where('user', '==', userRef),
  where('status', '==', status),
  limit(limitOpt || 20),
)

export const getUserOrientationMeetingsQuery = (
  { userRef }: ByUserRefQueryParams,
) => query(
  getMeetingCol(),
  where('user', '==', userRef),
  where('type', '==', meetingTypes.ORIENTATION),
  where('status', 'in', [meetingStatus.PLANNED, meetingStatus.DONE]),
)

export const getUserOrientationMeetingsByTrackQuery = (
  { trackRef }: TrackRefInput,
) => query(
  getMeetingCol(),
  where('userTrack', '==', trackRef),
  where('type', '==', meetingTypes.ORIENTATION),
  where('status', 'in', [meetingStatus.PLANNED, meetingStatus.DONE]),
)

export const getMeetingByCalendlyIdQuery = (
  { calendlyId }: ByCalendlyIdQueryParams,
) => query(
  getMeetingCol(),
  where('vendors.calendly.eventId', '==', calendlyId),
)

export const getMeetingsForTrack = (
  { trackRef }:TrackRefInput,
) => query(
  getMeetingCol(),
  where('userTrack', '==', trackRef || null),
)

export const getFirstMeetingsByParticipantsQuery = (
  { userRef, minder }: GetLatestMeetingsByParticipantsQueryParams,
  { limit: limitOpt }: WithLimitQueryOptions,
) => query(
  getMeetingCol(),
  where('user', '==', userRef),
  where('minder', '==', minder),
  orderBy('start_time', 'asc'),
  limit(limitOpt || 20),
)
