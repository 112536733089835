import classNames from 'classnames'
import { FC } from 'react'

import { Pressable } from '../containers/Pressable'
import { ListItemProps } from './ListItem.d'
import { ListItemContent } from './ListItemContent'
import { ListItemLeftAccessory } from './ListItemLeftAccessory'
import { ListItemRightAccessory } from './ListItemRightAccessory'

const baseContainerTw = 'flex-row bg-surface-1 p-2 mb-0.5 hover:bg-gray-100'

/**
 * See
 * https://reactnativeelements.com/docs/ListItem
 * https://necolas.github.io/react-native-web/docs/lists/
 *
 * TODO: swipeable
 *
 */
const ListItem: FC<ListItemProps> = ({
  tw,
  isFirst, isLast,
  to, onPress,
  iconPosition = 'right', icon, iconStyle,
  avatar,
  cta, ctaStyle,
  eyebrow, eyebrowStyle,
  title, titleStyle,
  description, descriptionStyle,
}) => {
  // we add special classes to the item container if first or last item of the list
  let newTw = classNames(baseContainerTw, tw)
  if (isFirst) {
    newTw = classNames('rounded-t-lg', newTw)
  }
  if (isLast) {
    newTw = classNames('rounded-b-lg', newTw)
  }

  return (
    <Pressable tw={newTw} to={to} onPress={onPress}>
      <ListItemLeftAccessory
        icon={icon && iconPosition === 'left' ? icon : undefined}
        iconStyle={icon && iconPosition === 'left' ? iconStyle : undefined}
        avatar={avatar}
      />
      <ListItemContent
        eyebrow={eyebrow}
        eyebrowTw={eyebrowStyle}
        title={title}
        titleStyle={titleStyle}
        description={description}
        descriptionTw={descriptionStyle}
        cta={cta}
        ctaTw={ctaStyle}
      />
      <ListItemRightAccessory
        icon={icon && iconPosition === 'right' ? icon : undefined}
        iconStyle={icon && iconPosition === 'right' ? iconStyle : undefined}
      />
    </Pressable>
  )
}

export default ListItem
