import { useFirestoreDocument, UseFirestoreHookOptions, useFirestoreQuery } from '@react-query-firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import { DocumentReference, DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { CustomerCampaignData } from '~/definitions/firestore.customerCampaign'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getLatestAuthorizedCampaignByDomain } from '~/queries/firestore.customers'

import { GetSingleDocQueryResult } from '../definitions/firestore.queries'
import { getKeysFromRef, withGetDocResultAdapter, withSingleDocQueryResultAdapter } from './firestore.hooks.helpers'

export const useLatestAuthorizedCampaignByDomain = (domain: string, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<CustomerCampaignData> => {
  const queryVar = { domain }
  const query = getLatestAuthorizedCampaignByDomain(queryVar)
  const keys = [Collections.CAMPAIGNS, queryVar]
  const res = useFirestoreQuery<CustomerCampaignData>(keys, query, options)

  return withSingleDocQueryResultAdapter(res)
}

export const useCustomerCampaignByRef = (customerCampaignRef: DocumentReference<CustomerCampaignData>, options: UseFirestoreHookOptions = { subscribe: true }) => {
  const keys = getKeysFromRef(customerCampaignRef)
  const query = useFirestoreDocument(keys, customerCampaignRef, options)

  return withGetDocResultAdapter(query)
}

export const useCustomerCampaignByUserTrackDoc = (userTrackDoc: DocumentSnapshot<UserTrackData>, options: UseFirestoreHookOptions = { subscribe: true }) => {
  const customerCampaignRef = userTrackDoc.data()?.customerCampaign as DocumentReference<CustomerCampaignData>
  const keys = getKeysFromRef(customerCampaignRef)
  const query = useFirestoreDocument(keys, customerCampaignRef, options)

  return withGetDocResultAdapter(query)
}
