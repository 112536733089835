import { ButtonColor } from '../buttons/Button.d'
import { BadgeSize } from './Badge.d'

export const getBadgeTwClassesFromColor = (color: ButtonColor) => {
  const arr = []
  switch (color) {
  case 'brand':
    arr.push('bg-brand-lightest')
    arr.push('text-brand-base')
    break

  case 'success':
    arr.push('bg-success-base')
    arr.push('text-success-darker')
    break
  case 'info':
    arr.push('bg-info-base')
    arr.push('text-info-darker')
    break
  case 'warning':
    arr.push('bg-warning-base')
    arr.push('text-warning-darker')
    break
  case 'danger':
    arr.push('bg-danger-base')
    arr.push('text-danger-darker')
    break
  case 'basic':
    arr.push('bg-basic-lighter')
    arr.push('text-basic-darker')
    break
  case 'control':
    arr.push('bg-surface-1')
    arr.push('text-basic-darkest')
    break
  default:
    console.warn(`Need to specify classes for badge color ${color}`)
    break
  }

  return arr.join(' ')
}

export const getBadgeTwClassesFromSize = (size: BadgeSize) => {
  const arr = []
  switch (size) {
  case 'mini':
    arr.push('h-4 w-4 text-none')
    break

  case 'small':
    arr.push('text-xs py-2 px-3')
    break
  case 'large':
    arr.push('h-6 text-base py-2 px-4')
    break

  default:
    console.warn(`Need to specify classes for badge size/${size}`)
    break
  }

  return arr.join(' ')
}
