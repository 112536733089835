import classNames from 'classnames'

import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { ButtonColor } from '../buttons/Button.d'
import { AvatarBadgePositionType, AvatarShapeType, AvatarSizeType } from './Avatar.d'

export const getAvatarSizeString = (size: AvatarSizeType) => {
  switch (size) {
  case 'tiny':
    return 'h-6 w-6'
  case 'small':
    return 'h-8 w-8'
  case 'medium':
    return 'h-10 w-10'
  case 'large':
    return 'h-12 w-12'
  case 'xlarge':
    return 'h-14 w-14'
  case 'giant':
    return 'h-16 w-16'
  default:
    throw new Error(`unknown size ${size}`)
  }
}

export const getAvatarShapeString = (shape: AvatarShapeType) => {
  switch (shape) {
  case 'circular':
    return 'rounded-full'
  case 'rounded':
    return 'rounded-md'
  case 'none':
    return 'rounded-none'
  default:
    throw new Error(`unknown shape ${shape}`)
  }
}

export const getAvatarBadgePositionString = (position: AvatarBadgePositionType) => {
  switch (position) {
  case 'top':
    return 'absolute top-1.5 right-1.5'
  case 'bottom':
    return 'absolute bottom-1.5 right-1.5'
  default:
    throw new Error(`unknown position ${position}`)
  }
}

export const getAvatarBadgeSizeString = (size: AvatarSizeType) => {
  switch (size) {
  case 'tiny':
    return 'h-1.5 w-1.5'
  case 'small':
    return 'h-2 w-2'
  case 'medium':
    return 'h-2.5 w-2.5'
  case 'large':
    return 'h-3 w-3'
  case 'xlarge':
    return 'h-3.5 w-3.5'
  case 'giant':
    return 'h-4 w-4'
  default:
    throw new Error(`unknown size ${size}`)
  }
}

export const getAvatarInitialsTextSizeString = (size: AvatarSizeType) => {
  switch (size) {
  case 'tiny':
    return 'text-sm uppercase'
  case 'small':
    return 'text-sm uppercase'
  case 'medium':
    return 'text-base uppercase'
  case 'large':
    return 'text-base uppercase'
  case 'xlarge':
    return 'text-lg uppercase'
  case 'giant':
    return 'text-xl uppercase'
  default:
    throw new Error(`unknown size ${size}`)
  }
}

export const getAvatarBackgroundColorString = (color:ButtonColor) => {
  const arr = []
  switch (color) {
  case 'brand':
    arr.push('bg-brand-base')
    arr.push('text-brand-darker')
    break
  case 'success':
    arr.push('bg-success-base')
    arr.push('text-success-darker')
    break
  case 'info':
    arr.push('bg-info-base')
    arr.push('text-info-darker')
    break
  case 'warning':
    arr.push('bg-warning-base')
    arr.push('text-warning-darker')
    break
  case 'danger':
    arr.push('bg-danger-base')
    arr.push('text-danger-darker')
    break
  case 'basic':
    arr.push('bg-basic-base')
    arr.push('text-basic-darker')
    break
  case 'control':
    arr.push('bg-surface-1')
    arr.push('text-basic-darkest')
    break
  default:
    console.warn(`Need to specify classes for filled/${color}`)
    break
  }

  return arr.join(' ')
}

export const getAvatarIconColorString = (color:ButtonColor) => {
  const arr = []
  switch (color) {
  case 'brand':
    arr.push('bg-brand-lightest')
    arr.push('text-brand-base')
    break
  case 'success':
    arr.push('bg-success-lighter')
    arr.push('text-success-base')
    break
  case 'info':
    arr.push('bg-info-lighter')
    arr.push('text-info-base')
    break
  case 'warning':
    arr.push('bg-warning-lighter')
    arr.push('text-warning-base')
    break
  case 'danger':
    arr.push('bg-danger-lighter')
    arr.push('text-danger-base')
    break
  case 'basic':
    arr.push('bg-basic-lighter')
    arr.push('text-basic-base')
    break
  case 'control':
    arr.push('bg-surface-1')
    arr.push('text-basic-lightest')
    break
  default:
    console.warn(`Need to specify classes for filled/${color}`)
    break
  }

  return arr.join(' ')
}

export const getAvatarInitialsClassesFromProps = ({
  size, color, shape, tw,
}) => {
  const newTw = classNames(
    getAvatarInitialsTextSizeString(size),
    getAvatarBackgroundColorString(color),
    getAvatarSizeString(size),
    getAvatarShapeString(shape),
    tw,
  )

  const { textTw, nonTextTw } = getTextRelatedClasses(newTw)

  return {
    textTw,
    containerTw: nonTextTw,
  }
}

export const getAvatarPlaceholderClassesFromProps = ({
  size, color, shape, tw,
}) => {
  const newTw = classNames(
    // getAvatarInitialsTextSizeString(size),
    getAvatarIconColorString(color),
    getAvatarSizeString(size),
    getAvatarShapeString(shape),
    tw,
  )

  const { textTw, nonTextTw } = getTextRelatedClasses(newTw)

  return {
    textTw,
    containerTw: nonTextTw,
  }
}

export const getAvatarIconSizeNum = (size: AvatarSizeType) => {
  switch (size) {
  case 'tiny':
    return 24 - 4
  case 'small':
    return 32 - 4
  case 'medium':
    return 40 - 4
  case 'large':
    return 48 - 8
  case 'xlarge':
    return 56 - 12
  case 'giant':
    return 64 - 16
  default:
    console.warn(`Need to specify ison size for avatar with size ${size}`)

    return 12
  }
}
