import { useTranslation } from 'react-i18next'
import { ENV } from 'react-native-dotenv'

import {
  SiteLocale, useGetAudiencesBySegmentsDevQuery, useGetAudiencesBySegmentsQuery, useGetEventByIdByAudiencesQuery, useGetEventsByAudiencesQuery,
} from '~/__generated__/graphql.queries'

import { useCustomerIoUserSegments } from './vendors.customerio'

export const useAudiencesByUid = (uid: string) => {
  const { i18n } = useTranslation()

  // 1. obtain segment ids from user id
  const { data: segmentsId } = useCustomerIoUserSegments(uid) // example ['7', '9']

  // 2. obtain datocms audiences ids from customer io segment ids
  const useGenericgetAudienceBySegment = (ENV === 'production' || ENV === 'demo') ? useGetAudiencesBySegmentsQuery : useGetAudiencesBySegmentsDevQuery

  const { data: audiences } = useGenericgetAudienceBySegment({ segmentsId, locale: i18n.language as SiteLocale }, { enabled: !!segmentsId })

  const audiencesId = audiences?.allAudienceSegments.map((item) => item.id) // example ["97439043", "95810924"]
  return audiencesId
}

export const useEventsByUid = (uid: string) => {
  const { i18n } = useTranslation()
  const audiencesId = useAudiencesByUid(uid)

  return useGetEventsByAudiencesQuery({
    audiencesId,
    locale: i18n.language as SiteLocale,
  }, { enabled: !!audiencesId })
}

export const useEventById = (eventId: string, uid: string) => {
  const { i18n } = useTranslation()
  const audiencesId = useAudiencesByUid(uid)

  return useGetEventByIdByAudiencesQuery({
    eventId,
    audiencesId,
    locale: i18n.language as SiteLocale,
  }, { enabled: !!eventId && !!audiencesId })
}
