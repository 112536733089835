import { FC } from 'react'
import { Modal as RnModal, ModalProps as RnModalProps } from 'react-native'

export type ModalProps = Record<string, unknown> & RnModalProps

/**
 * See Overlay for a more advanced component including backdrop
 * See
 * https://necolas.github.io/react-native-web/docs/modal/
 * https://tailwindui.com/components/application-ui/overlays/modals
 */
export const Modal: FC<ModalProps> = (props) => <RnModal {...props} />
