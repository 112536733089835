import {
  useEffect, useState,
} from 'react'
import { STREAM_INSTANCE_ID } from 'react-native-dotenv'
import { StreamChat } from 'stream-chat'

import { useUser } from '~/hooks/user.context'

import { useStreamAuthToken } from './vendors.stream'

export const useChatClient = () => {
  // console.log('-> useChatClient')
  const client = StreamChat.getInstance(STREAM_INSTANCE_ID)
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useUser()
  // console.log('-> useChatClient', user)

  const { token } = useStreamAuthToken()

  useEffect(() => {
    const setupClient = async () => {
      setIsLoading(true)

      await client.connectUser(
        {
          id: user.uid,
          name: user.displayName,
          image: user.photoURL,
        },
        token,
        // client.devToken(user.uid) //for dev mode only
      )
      setIsLoading(false)

    }
    if (client && token) {
      setupClient()
    }
  }, [client, user.displayName, user.photoURL, user.uid, token])

  return { client, isLoading }
}
