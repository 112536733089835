import { FC, memo } from 'react'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

export type EmoticonItemProps = {
  entity: {
    /** The parts of the Name property of the entity (or id if no name) that can be matched to the user input value.
     * Default is bold for matches, but can be overwritten in css.
     * */
    itemNameParts: { match: string; parts: string[] };
    /** Name for emoticon */
    name: string;
    /** Native value or actual emoticon */
    native: string;
  };
};

const UnMemoizedEmoticonItem: FC<EmoticonItemProps> = (props) => {
  const { entity } = props

  const hasEntity = Object.keys(entity).length
  const itemParts = entity?.itemNameParts

  const renderName = () => {
    if (!hasEntity) {
      return null
    }
    return (
      hasEntity
      && itemParts.parts.map((part, i) => (part.toLowerCase() === itemParts.match.toLowerCase() ? (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={`part-${i}`}>
          {part}
        </Text>
      ) : (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={`part-${i}`}>
          {part}
        </Text>
      )))
    )
  }

  return (
    <View>
      <Text>{entity.native}</Text>
      <Text>{renderName()}</Text>
    </View>
  )
}

export const EmoticonItem = memo(UnMemoizedEmoticonItem) as typeof UnMemoizedEmoticonItem
