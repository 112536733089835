import { View } from '~/elements/containers/View'
import { useContentById } from '~/hooks/dato.content'
import { getFullName } from '~/utils/people.helpers'

import { ContentTile } from '../panels/tiles/ContentTile'
import { Attachment } from './Attachment/Attachment'

const ContentAttachement = ({ itemId }) => {

  const { isLoading, data } = useContentById(itemId)

  if (isLoading) {
    return null
  }

  const {
    id, title, coverBackground, coverMain, coverBackgroundColor, coverMainFormat, excerpt, author,
  } = data.content

  return (
    <View tw="mt-2">
      <ContentTile
        testID={id}
        illustration={{
          backgroundColor: coverBackgroundColor,
          background: coverBackground,
          foreground: coverMain,
          format: coverMainFormat,
        }}
        title={title}
        metadata={getFullName(author)}
        description={excerpt}
        orientation="portrait"
        size="medium"
        navigateTo={`/content/${id}`}
      />
    </View>
  )
}

const CustomSingleAttachment = ({ type, itemId, ...props }) => {

  if (type === 'mindday-content') {
    return (
      <ContentAttachement itemId={itemId} />
    )
  }

  if (type.startsWith('mindday-')) {
    console.warn('Unimplemented custom attachment type', type)
  }

  return <Attachment {...props} />
}

export const CustomAttachment = ({ attachments = [], ...props }) => attachments
  .map((att) => (
    <CustomSingleAttachment
      key={`att-${att?.type?.replace('mindday-', '')}-${att?.itemId}`}
      {...att}
      {...props}
    />
  ))
