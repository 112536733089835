import countBy from 'lodash/countBy'
import { useContext } from 'react'

import { meetingStatus, meetingTypes } from '~/constants/firestore.meetings'
import { BeneficiaryContext } from '~/context/BeneficiaryContext'
import { DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { MeetingData } from '~/definitions/firestore.meeting'
import { MeetingTypeType } from '~/definitions/firestore.meeting.properties'

import { useCustomerCampaignByRef } from './firestore.customers.campaigns'
import { useGetMeetingsForTrack } from './firestore.meetings'
import { useUser } from './user.context'

export const useBeneficiary = () => {
  const context = useContext(BeneficiaryContext)
  if (context === undefined) {
    throw new Error('useBeneficiary must be used within a BeneficiaryProvider')
  }

  return context
}

const getCountByType = (data: DocumentSnapshot<MeetingData>[]) => countBy(data, (doc: DocumentSnapshot<MeetingData>) => doc.get('type'))

const getCountByStatus = (data: DocumentSnapshot<MeetingData>[]) => countBy(data, (doc: DocumentSnapshot<MeetingData>) => doc.get('status'))

const hasOnlyOrientationMeeting = (data:DocumentSnapshot<MeetingData>[]) => {
  const countByType = getCountByType(data)
  // console.log('countByType', countByType)
  const countOrientationSessions = countByType[meetingTypes.ORIENTATION]
  // console.log('countOrientationSessions', countOrientationSessions)
  const countOtherSessions = Object.keys(countByType).filter((key) => key !== meetingTypes.ORIENTATION).reduce((acc, key) => acc + countByType[key], 0)

  return countOrientationSessions > 0 && countOtherSessions === 0
}

type UseNextSuggestedMeetingOutput = {
  isLoading:boolean
  error:Error|undefined
  data: {
    type: MeetingTypeType | null,
    isLast?: boolean
  } | undefined
}

/**
 * Based on existing meetings, define what should be the next meeting to take for a given user
 * TODO: end of track considerations
 */
export const useNextSuggestedMeeting = ():UseNextSuggestedMeetingOutput => {

  const { isLoading: userLoading, error: userError, userTrackDoc } = useUser()
  const trackData = userTrackDoc?.data()
  const { isLoading: customerCampaignLoading, error: customerCampaignError, data: customerCampaignDoc } = useCustomerCampaignByRef(trackData?.customerCampaign)

  // We list all non cancelled meetings for that track
  const { isLoading: meetingsLoading, error: meetingsError, data: meetingDocs } = useGetMeetingsForTrack(userTrackDoc.ref)
  // console.log('useGetMeetingsForTrack', data)

  if (userError || meetingsError) {
    return {
      isLoading: false,
      error: userError || customerCampaignError || meetingsError,
      data: undefined,
    }
  }

  if (userLoading || customerCampaignLoading || meetingsLoading) {
    return {
      isLoading: userLoading || meetingsLoading,
      error: undefined,
      data: undefined,
    }
  }

  // console.log('meetings for that track:', data.map((doc) => doc.id))
  const countByStatus = getCountByStatus(meetingDocs)
  // console.log('countByStatus', countByStatus)
  const countByType = getCountByType(meetingDocs)

  // if one meeting is planned already, we return null
  if (countByStatus[meetingStatus.PLANNED] > 0) {
    // console.log('We already have a meeting planned.')

    return {
      isLoading: false,
      error: undefined,
      data: { type: null },
    }
  }

  // If no track or no meeting yet, we suggest an orientation meeting
  if (userTrackDoc === undefined || meetingDocs.length === 0) {
    // console.log('No meeting yet. Will suggest orientation')

    return {
      isLoading: false,
      error: undefined,
      data: { type: meetingTypes.ORIENTATION },
    }
  }

  const hasMinderAssigned = trackData?.minder

  if (!hasMinderAssigned) {
    return {
      isLoading: false,
      error: undefined,
      data: { type: null },
    }
  }

  // if orientation but no further
  if (hasOnlyOrientationMeeting(meetingDocs)) {
    // console.log('Only has orientation done. Will suggest first-session')

    return {
      isLoading: false,
      error: undefined,
      data: { type: meetingTypes.FIRST_SESSION },
    }
  }

  const meetingsCountAgainstPlan = (meetingDocs.length - countByType[meetingTypes.ORIENTATION])
  const plannedMeetingsCount = trackData?.plannedMeetingsCount
  const allowedNbOfMeetings = customerCampaignDoc.data()?.plan.allowedNbOfMeetings
  const isBelowPlannedMeetingsCount = (plannedMeetingsCount === undefined) || (meetingsCountAgainstPlan < plannedMeetingsCount)
  const isBelowAllowedMeetingsCount = (allowedNbOfMeetings === undefined) || (meetingsCountAgainstPlan < allowedNbOfMeetings)

  // user has done orientation and not used all of his credits
  if (isBelowPlannedMeetingsCount && isBelowAllowedMeetingsCount) {

    const res = {
      isLoading: false,
      error: undefined,
      data: { type: meetingTypes.SESSION },
    }

    return res
  }

  // console.log('We\'ve run out of meetings')
  return {
    isLoading: false,
    error: undefined,
    data: { type: null },
  }

}
