import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '~/hooks/mediaQuery'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { BottomTabNavigator } from './BottomTabNavigator'
import { DrawerNavigator } from './DrawerNavigator'

export const MenuNavigator = () => {
  // console.log('-> MenuNavigator')

  const { md } = useMediaQuery()

  useTranslation(['navigation'])

  return (
    <Suspense fallback={<LoadingScreen />}>
      {md ? <DrawerNavigator /> : <BottomTabNavigator />}
    </Suspense>
  )

}
