import {
  FC, memo, useEffect, useState,
} from 'react'
import type { Event } from 'stream-chat'

import { useChatContext } from '../context/ChatContext'
import { useTranslationContext } from '../context/TranslationContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'
import { CustomNotification } from './CustomNotification'

const UnMemoizedConnectionStatus: FC = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>() => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('ConnectionStatus')
  const { t } = useTranslationContext('ConnectionStatus')

  const [online, setOnline] = useState(true)

  useEffect(() => {
    const connectionChanged = ({
      online: onlineStatus = false,
    }: Event<At, Ch, Co, Ev, Me, Re, Us>) => {
      if (online !== onlineStatus) {
        setOnline(onlineStatus)
      }
    }

    client.on('connection.changed', connectionChanged)
    return () => client.off('connection.changed', connectionChanged)
  }, [client, online])

  return (
    <CustomNotification active={!online} type="error">
      {t('Connection failure, reconnecting now...')}
    </CustomNotification>
  )
}

export const ConnectionStatus = memo(
  UnMemoizedConnectionStatus,
) as typeof UnMemoizedConnectionStatus
