import { useTranslation } from 'react-i18next'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'

export const ContentPlayerCoverNav = ({ to, style }) => {
  const { t } = useTranslation(['player'])

  return (
    <View tw="flex-none justify-center" style={style}>
      <View tw="flex flex-row justify-center">
        <Button
          title={t('player:start')}
          to={to}
          hookKey="ArrowRight"
        />
      </View>
    </View>
  )
}
