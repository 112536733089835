/**
 * Transforms design tokens (from Zeroweight) into tailwind values
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tokens = require('./token.json')

const getValue = (obj) => {
  if (obj.value) {
    return obj.value
  }

  return Object.keys(obj).reduce((colAcc, key) => ({ ...colAcc, [key]: getValue(obj[key]) }), {})
}
const colorTokens = Object.keys(tokens)
  .filter((key) => !key.startsWith('text-'))
  .reduce((acc, key) => ({ ...acc, [key]: getValue(tokens[key]) }), {})
// console.log('colorTokens', colorTokens)

module.exports = {
  ...colorTokens,
  transparent: 'transparent',
  black: colorTokens.gray[900],

  // Special colors
  brand: {
    darkest: colorTokens.orange[900],
    darker: colorTokens.orange[700],
    base: colorTokens.orange[500],
    lighter: colorTokens.orange[300],
    lightest: colorTokens.orange[100],
    white: colorTokens.orange[50],
  },
  basic: {
    darkest: colorTokens.gray[900],
    darker: colorTokens.gray[700],
    base: colorTokens.gray[500],
    lighter: colorTokens.gray[300],
    lightest: colorTokens.gray[100],
  },

  // Semantic colors
  info: {
    darker: colorTokens.blue[900],
    base: colorTokens.blue[700],
    lighter: colorTokens.blue[100],
  },
  success: {
    darker: colorTokens.green[700],
    base: colorTokens.green[500],
    lighter: colorTokens.green[100],
  },
  warning: {
    darker: colorTokens.orange[700],
    base: colorTokens.orange[500],
    lighter: colorTokens.orange[100],
  },
  danger: {
    darker: colorTokens.red[700],
    base: colorTokens.red[500],
    lighter: colorTokens.red[100],
  },

  // Surfaces
  surface: {
    1: colorTokens.white,
    2: colorTokens.gray[100],
    3: colorTokens.gray[300],
    4: colorTokens.gray[500],
    brand: colorTokens.orange[50],
  },
}
