import { useAuthUser } from '@react-query-firebase/auth'
import { useFirestoreDocument, UseFirestoreHookOptions } from '@react-query-firebase/firestore'
import { useEffect, useState } from 'react'

import { EventuallyDocumentReference } from '~/definitions/firebase.firestore.types'
import { GetSingleDocQueryResult } from '~/definitions/firestore.queries'
import { UserData } from '~/definitions/firestore.user'
import { getUserRef } from '~/queries/firestore.helpers'

import { auth } from '../navigators.shared/app.firebase.client'
import { getKeysFromRef, withGetDocResultAdapter } from './firestore.hooks.helpers'

export const useCurrentUserRef = ():EventuallyDocumentReference<UserData> => {
  const [userRef, setUserRef] = useState<EventuallyDocumentReference<UserData>>(null)
  const { data: user } = useAuthUser('user', auth)
  useEffect(() => {
    // console.log('Will recompute userRef for ' + user.uid)
    if (user?.uid && user.uid !== userRef?.id) {
      setUserRef(getUserRef(user?.uid))
    }

    // purposly ignored db, userRef?.id
  }, [user?.uid])

  return userRef
}

export const useUserByRef = (ref:EventuallyDocumentReference<UserData>, options: UseFirestoreHookOptions = { subscribe: true }): GetSingleDocQueryResult<UserData> => {
  const keys = getKeysFromRef(ref)
  const res = useFirestoreDocument<UserData>(keys, ref, options)

  return withGetDocResultAdapter(res)

}

export const useUserById = (uid?: string): GetSingleDocQueryResult<UserData> => {
  // console.log('-> useUserById')
  const ref = getUserRef(uid)

  return useUserByRef(ref)
}

export const useCurrentUser = (): GetSingleDocQueryResult<UserData> => {

  const ref = useCurrentUserRef()
  return useUserByRef(ref)
}
