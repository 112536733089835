import {
  getAll, getBoolean, getNumber, getString,
} from 'firebase/remote-config'
import {
  QueryKey,
  useQuery,
} from 'react-query'

import { remoteConfig } from '~/navigators.shared/app.firebase.client.web'

console.log('remoteConfig (web)')

/**
 * Wraps Remote config calls with react-query
 */
export const useRemoteConfigString = (
  key: QueryKey,
  useQueryOptions,
) => useQuery(key, (k) => getString(remoteConfig, k), useQueryOptions)

export const useRemoteConfigNumber = (
  key: QueryKey,
  useQueryOptions,
) => useQuery(key, (k) => getNumber(remoteConfig, k), useQueryOptions)

export const useRemoteConfigBoolean = (
  key: QueryKey,
  useQueryOptions,
) => useQuery(key, (k) => getBoolean(remoteConfig, k), useQueryOptions)

export const useRemoteConfigAll = (
  useQueryOptions,
) => useQuery([], () => getAll(remoteConfig), useQueryOptions)
