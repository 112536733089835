/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { getPathFromState, getStateFromPath } from '@react-navigation/core'
import * as Linking from 'expo-linking'
import { CANONIC_URL } from 'react-native-dotenv'

import { routesConfig } from './routes.config'

export default {
  prefixes: [Linking.createURL('/'), CANONIC_URL],

  // Custom function to get the URL which was used to open the app
  // async getInitialURL() {
  // First, you may want to do the default deep link handling
  // Check if app was opened from a deep link
  // const url = await Linking.getInitialURL()

  // console.log('url', url)

  // if (url != null) {
  // console.log('initial url', url)
  // return url
  // }

  // Next, you would need to get the initial URL from your third-party integration
  // It depends on the third-party SDK you use
  // For example, to get to get the initial URL for branch.io:
  // const params = branch.getFirstReferringParams()

  // return params?.$canonical_url
  // return undefined
  // },

  // Custom function to subscribe to incoming links
  // subscribe() {
  // console.log('subscribe')
  // First, you may want to do the default deep link handling
  // const onReceiveURL = ({ url }: { url: string }) => listener(url)

  // Listen to incoming links from deep linking
  // Linking.addEventListener('url', onReceiveURL)

  // Next, you would need to subscribe to incoming links from your third-party integration
  // For example, to get to subscribe to incoming links from branch.io:
  // branch.subscribe(({ error, params, uri }) => {
  //   if (error) {
  //     console.error('Error from Branch: ' + error)
  //     return
  //   }

  //   if (params['+non_branch_link']) {
  //     const nonBranchUrl = params['+non_branch_link']
  //     // Route non-Branch URL if appropriate.
  //     return
  //   }

  //   if (!params['+clicked_branch_link']) {
  //     // Indicates initialization success and some other conditions.
  //     // No link was opened.
  //     return
  //   }

  //   // A Branch link was opened
  //   const url = params.$canonical_url

  //   listener(url)
  // })
  // )

  // return () => {
  // Clean up the event listeners
  // Linking.removeEventListener('url', onReceiveURL)
  // branch.unsubscribe()
  // }
  // },
  config: routesConfig,
  getStateFromPath: (path, options) => {
    const state = getStateFromPath(path, options)
    // console.log('getStateFromPath', path)

    return state
  },
  getPathFromState: (state, options) => {
    const path = getPathFromState(state, options)
    // console.log('getPathFromState', path)

    return path
  },
}
