import isoLanguages from '@cospired/i18n-iso-languages'
import enLocale from '@cospired/i18n-iso-languages/langs/en.json'
import frLocale from '@cospired/i18n-iso-languages/langs/fr.json'
import { updateDoc } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'

import { View } from '~/elements/containers/View'
import { IconProps } from '~/elements/icons/Icon/IconProps.d'
import ListItem from '~/elements/list/ListItem'
import { useCurrentUserRef } from '~/hooks/firestore.users'
import { supportedLanguages } from '~/i18n/languages'

const locales = {
  en: enLocale,
  fr: frLocale,
}

if (Platform.OS === 'web') {
  supportedLanguages.forEach((lng) => {
    isoLanguages.registerLocale(locales[lng])
  })
}

type SelectLanguageType = (params: {
  hideOverlay: () => void,
// eslint-disable-next-line no-undef
}) => JSX.Element
export const SelectLanguage: SelectLanguageType = ({ hideOverlay }) => {
  const { i18n } = useTranslation(['main'])

  const userRef = useCurrentUserRef()

  const isLanguageActive = (language: string) => language === i18n.language

  const onChangeLanguage = async (language: string) => {
    if (!isLanguageActive(language)) {

      // report in Firestore - could possibly be handle by i18n callbacks but not sure how to get user ref there...
      if (userRef) {
        await updateDoc(userRef, { 'settings.language': language })
      }

      // update UI
      i18n.changeLanguage(language)

      // Close overlay
      hideOverlay()
    }
  }

  const getListItemTitle = (language: string) => isoLanguages.getName(language, i18n.language) || ''

  const getListItemIconProps = (language: string) => (isLanguageActive(language)
    ? {
      name: 'check',
    } as IconProps
    : undefined)

  return (
    <View>
      {supportedLanguages.map((language) => (
        <ListItem
          key={`language-${language}`}
          onPress={() => onChangeLanguage(language)}
          title={getListItemTitle(language)}
          icon={getListItemIconProps(language)}
          tw="mb-2 px-4 md:px-8"
        />
      ))}
    </View>
  )
}
