import { useMediaQuery as useMediaQueryRR } from 'react-responsive'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../tailwind.config.js'

const { theme: { screens } } = resolveConfig(tailwindConfig)

export type WidthMediaQueryType = {
  sm:boolean
   md:boolean
   lg:boolean
   xl:boolean
   xxl:boolean
}

export type HeigthMediaQueryType = {
  smv:boolean
   mdv:boolean
   lgv:boolean
   xlv:boolean
}

export type MediaQueryType = WidthMediaQueryType & HeigthMediaQueryType

/**
 * Breakpoints taken from default Tailwind config
 * https://tailwindcss.com/docs/responsive-design
 * TODO plug values on Tailwind config
 */
export const useMediaQuery = ():MediaQueryType => {
  // console.log('-> useMediaQuery')
  const sm = useMediaQueryRR({ query: `(min-width: ${screens.sm})` })
  const md = useMediaQueryRR({ query: `(min-width: ${screens.md})` })
  const lg = useMediaQueryRR({ query: `(min-width: ${screens.lg})` })
  const xl = useMediaQueryRR({ query: `(min-width: ${screens.xl})` })
  const xxl = useMediaQueryRR({ query: `(min-width: ${screens['2xl']})` })

  // see https://useyourloaf.com/blog/iphone-13-screen-sizes/
  const smv = useMediaQueryRR({ query: '(min-height: 640px)' }) // iPhone 4S resolution (640 x 960)
  const mdv = useMediaQueryRR({ query: '(min-height: 812px)' }) // iPhone X (375 x 812)
  const lgv = useMediaQueryRR({ query: '(min-height: 926px)' }) // iPhone 13 Pro Max (1284 x 2778)
  const xlv = useMediaQueryRR({ query: '(min-height: 1600px)' }) // mac book pro 13" 4th gen (2560 x 1600)

  // console.log(`media queries`, sm, md, lg, xl)
  // console.log('vertical media queries', smv, mdv, lgv, xlv)

  return {
    sm, md, lg, xl, xxl, smv, mdv, lgv, xlv,
  }
}
