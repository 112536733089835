// import '@typeform/embed/build/css/widget.css'

import { createWidget } from '@typeform/embed'
import { FC, useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { MINDDAY_DATO_TYPEFORM_PPTY } from 'react-native-dotenv'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { View } from '~/elements/containers/View'

import { ReaderFooterProps } from '../BottomNavBar'
/**
 * ToolLayout for all Content Tools in DatoCMS
 * So far, the only one in use is typeform wrapped into Web-form - Evaluation - Tool
 * Futurely will grow with more options
 */

type ToolLayoutProps = ReaderFooterProps & {
  item: {
    linkedItem: {
      form: {
        typeform: {
          typeformId: string,
          typeformIdDev: string
        }
      }
    }
  }
}

export const ToolLayout: FC<ToolLayoutProps> = (props) => {

  const vwDimensions = useWindowDimensions()
  const remainingHeight = vwDimensions.height - 68
  // height set as in MeetingSchedulingScreen, bc bottom nav bar's footer height is variable and inside of ContentPlayerScrollView's scope

  const form = props.item.linkedItem.form.typeform
  const formByEnv = form[MINDDAY_DATO_TYPEFORM_PPTY]
  const [isSubmitted, setIsSubmitted] = useState(false)

  const showWidget = () => createWidget(formByEnv, {
    // ref https://github.com/Typeform/embed/tree/main/packages/embed
    container: document.querySelector('#form'),
    onSubmit: () => {
      setIsSubmitted(true)
    },
    inlineOnMobile: true,
  })

  useEffect(() => {
    if (Platform.OS === 'web') {
      showWidget()
    }
  }, [])

  return (
    <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
      <TwoColGridSection tw="h-full">
        <ContentPlayerScrollView shouldFadeContentAbove={false} shouldNavDisplay={isSubmitted}>
          <View nativeID="form" style={{ height: remainingHeight }} />
        </ContentPlayerScrollView>
      </TwoColGridSection>
    </FullOverlayContainer>
  )
}
