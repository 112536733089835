import classNames from 'classnames'
import { CountryCode } from 'libphonenumber-js/max'
import { FC } from 'react'
import { Platform } from 'react-native'

import { DropdownPicker } from '~/elements/input/DropdownPicker'

const countryOptions = ['FR', 'US', 'GB', 'DE', 'IT', 'ES', 'PT', 'NL', 'BE', 'CH'].sort()

type ICCCSelectorProps = {
  value: CountryCode,
  onChange: (countryCode: CountryCode) => void
}

export const InternationalCountryCallingCodeSelector:FC<ICCCSelectorProps> = ({ value, setValue, onChange }) => {
  const pickerPadding = Platform.OS === 'web' ? 'px-3' : 'px-0 w-24'

  return (
    <DropdownPicker
      value={value}
      setValue={setValue}
      onValueChange={onChange}
      // listMode="MODAL"
      items={countryOptions.map((item) => ({ value: item, label: item }))}
      tw={classNames('h-full py-0 border-transparent bg-transparent text-gray-500 text-sm sm:text-base rounded-md', pickerPadding)}
    />
  )
}
