import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { ProfileScreen } from '~/screens/ProfileScreen/ProfileScreen'

import { ProfileStackParamList } from './ProfileStackParamList'

const ProfileStack = createStackNavigator<ProfileStackParamList>()

export const ProfileNavigator = () => {
  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])

  return (
    <ErrorBoundary
      fallback={<Fallback />}
      location="profile"
      >
      <Suspense fallback={<LoadingScreen />}>
        <ProfileStack.Navigator>
          <ProfileStack.Screen
            name="Profile"
            component={ProfileScreen}
            options={{ headerShown: false }}
          />
        </ProfileStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
