import classNames from 'classnames'
import { Timestamp } from 'firebase/firestore'
import upperFirst from 'lodash/upperFirst'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { GradientCard } from '~/components/panels/cards/GradientCard'
import { Timestamp as TimestampType } from '~/definitions/firebase.firestore.types'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useDateFormat } from '~/hooks/dateFns'
import { useIsPast } from '~/hooks/time'
import { addMinutes } from '~/utils/dateFns/common.helpers'

export const EventSelectCard: FC<{
  // key?: string,
  dateTimestamp: TimestampType,
  onPress: () => void,
  isActive: boolean,
  duration: number,
}> = ({
  dateTimestamp,
  onPress,
  isActive,
  duration,
}) => {

  const { i18n } = useTranslation()

  const useSessionDate = (date: TimestampType) => {
    const formattedDate = useDateFormat(date, 'long', i18n.language)

    return upperFirst(formattedDate)
  }
  const useSessionHour = (date: Timestamp) => useDateFormat(date, 'time', i18n.language)

  const title = useSessionDate(dateTimestamp)
  const description = useSessionHour(dateTimestamp)

  const endDate = addMinutes(dateTimestamp, duration)
  const endDateTs = Timestamp.fromDate(endDate)
  const isPast = useIsPast(endDateTs)

  const isActiveTw = isActive
    ? 'from-orange-100 hover:from-orange-300 opacity-70 to-orange-500 hover:to-orange-700 opacity-90'
    : 'from-gray-100 hover:from-orange-300 to-gray-100 hover:to-orange-700'
  const isPastTw = isPast ? 'opacity-30' : ''
  const pressableTw = classNames('my-2 px-6 py-4 font-medium flex-row rounded-lg bg-gradient-to-br', isActiveTw)

  return (
    <GradientCard
      onPress={onPress}
      tw={pressableTw}
      disabled={isPast}
      >
      <View tw={classNames('flex-grow flex-shrink', isPastTw)}>
        <Text tw="text-md font-medium text-base">{title}</Text>
        <Text tw="text-basic-base text-base">{description}</Text>
      </View>

    </GradientCard>
  )
}
