import { useTranslation } from 'react-i18next'

import { RecommendedContentFromScenario } from '~/components/sections/content/RecommendedContent'

const recommendationSettings = {
  key: 'recommended-content',
  nbItems: 8,
  scenario: 'Home_ForYou',
}

export const AuthorContentSection = ({ slug, firstName }) => {

  const { t, i18n } = useTranslation(['track', 'common'])
  const reQLExp = `('wordCount_${i18n.language}' > 0) and ('authorSlug' == "${slug}")`
  // console.log('reQLExp', reQLExp.replace(/\\"/, '"'))
  recommendationSettings.filter = reQLExp.replace(/\\"/, '"')
  recommendationSettings.title = t('track:minder.authorSectionTitle', { firstName })
  recommendationSettings.tileSettings = { size: 'medium' }

  return <RecommendedContentFromScenario {...recommendationSettings} />

}
