import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarWeek, faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutChangeEvent } from 'react-native'

import { GetEventsByAudiencesQuery } from '~/__generated__/graphql.queries'
import { Timestamp } from '~/definitions/firebase.firestore.types'
import { GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDateFormat, useDurationFormat, useMinutesToHours } from '~/hooks/dateFns'

library.add(faCheckCircle, faCalendarWeek)

export type EventCompleteInfoProps = {
  event: GroupMeetingWithSessions | null,
  eventDate: Timestamp | undefined,
  type: 'banner' | 'tile'
}

// Component contains elements that are in common between the event detailed page banner and the events complet tiles carousels
export const EventCompleteInfo: FC<EventCompleteInfoProps> = ({ event, eventDate, type }) => {

  const { i18n } = useTranslation()
  const { t } = useTranslation(['events'])
  const [imgDim, setImageDim] = useState()

  /* eslint no-underscore-dangle: 0 */
  const eventTypeName = event?.model?.__typename
  const dateFormatted = `${useDateFormat(eventDate, 'medium', i18n.language)} - ${useDateFormat(eventDate, 'time', i18n.language)}`
  const durationObject = useMinutesToHours(event?.model?.duration)
  const durationFormatted = `${useDurationFormat({ hours: durationObject.hours }, { format: ['hours'] })
  } ${useDurationFormat({ minutes: durationObject.minutes }, { format: ['minutes'] })}`

  /* eslint no-underscore-dangle: 0 */
  const minderHost = event?.hosts.find((host) => host.__typename === 'ContractorRecord') as GetEventsByAudiencesQuery['allConferenceInstances']['hosts'] | undefined
  const hostName = `${minderHost?.minder?.firstName} ${minderHost?.minder?.lastName}`

  const DateView = useMemo(() => {
    const text = eventDate ? dateFormatted : t('events:event.multiple_slots')

    return (
      <View tw="flex-row justify-center mt-4">
        <Icon name="calendar-week" sizeNum={14} />
        <Text tw="ml-2 font-sans font-normal text-xs leading-relaxed text-gray-700">
          {text}
        </Text>
      </View>
    )
  }, [t, eventDate, dateFormatted])

  if (!event) {
    return null
  }

  const viewTw = `items-center ${type === 'tile' ? 'mb-16' : ''}`

  const typeTw = 'font-sans font-semibold text-xs leading-relaxed mt-2 mb-4 text-gray-700 uppercase'

  const imgTw = `w-32 h-32 ${type === 'tile' ? 'my-4' : 'mb-4'}`

  const onImageContainerResized = (e:LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout
    setImageDim({ height, width })
  }

  return (
    <>
      <View tw={imgTw} onLayout={onImageContainerResized}>
        <Image {...event.model?.illustration?.foreground} style={imgDim} />
      </View>
      <View tw={viewTw}>
        {eventTypeName === 'ConferenceRecord' && <Text tw={typeTw}>{t('events:event_type.conference', { hostName })}</Text>}
        {eventTypeName === 'WorkshopRecord' && <Text tw={typeTw}>{t('events:event_type.workshop', { durationFormatted })}</Text>}
        <Text tw="font-serif font-semibold text-lg leading-snug text-gray-900 text-center" numberOfLines={2}>{event.model?.shortTitle || event.model?.title}</Text>
        {DateView}
      </View>
    </>
  )
}
