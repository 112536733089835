export const getLinearGradientStartEndPoint = (gradientDirection: string, px = 0, py = 0) => {

  switch (gradientDirection) {
  case 'bg-gradient-to-t':
    return {
      startPoint: { x: 0.5, y: 1 - py },
      endPoint: { x: 0.5, y: 0 + py },
    }

  case 'bg-gradient-to-tr':
    return {
      startPoint: { x: 0 + px, y: 1 - py },
      endPoint: { x: 1 - px, y: 0 + py },
    }

  case 'bg-gradient-to-r':
    return {
      startPoint: { x: 0 + px, y: 0.5 },
      endPoint: { x: 1 - px, y: 0.5 },
    }

  case 'bg-gradient-to-br':
    return {
      startPoint: { x: 0 + px, y: 0 + py },
      endPoint: { x: 1 - px, y: 1 - py },
    }

  case 'bg-gradient-to-b':
    return {
      startPoint: { x: 0.5, y: 0 + py },
      endPoint: { x: 0.5, y: 1 - py },
    }

  case 'bg-gradient-to-bl':
    return {
      startPoint: { x: 1 - px, y: 0 + py },
      endPoint: { x: 0 + px, y: 1 - py },
    }

  case 'bg-gradient-to-l':
    return {
      startPoint: { x: 1 - px, y: 0.5 },
      endPoint: { x: 0 + px, y: 0.5 },
    }

  case 'bg-gradient-to-tl':
    return {
      startPoint: { x: 1 - px, y: 1 - py },
      endPoint: { x: 0 + px, y: 0 + py },
    }

  default:
    throw new Error(`unknown gradient class ${gradientDirection}`)
  }
}
