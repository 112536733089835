import { AnimatedView } from '~/elements/containers/AnimatedView'
import { Skeleton } from '~/elements/skeleton/Skeleton'
import { getHexColorFromTwColor } from '~/theme/helpers'

const transition = { opacity: { duration: 300 } }

export const LargeTileItemPlaceholder = (props) => {
  const {
    width, maxWidth, aspectW, aspectH, style,
  } = props

  const containerAspectRatio = aspectW / aspectH

  return (

    <AnimatedView
      transition={{ type: 'timing' }}
      tw="pb-8 px-2"
      animate={{ backgroundColor: getHexColorFromTwColor('surface-1') }}
      style={{
        width,
        maxWidth,
        ...style,
      }}
      >
      <Skeleton
        transition={transition}
        tw="lex-grow w-full rounded-lg mb-2 h-full"
        style={{ aspectRatio: containerAspectRatio }}
      />
      <Skeleton transition={transition} tw="w-full" />

    </AnimatedView>

  )
}

// defaults are roughly taken from Mac App Store tiles
LargeTileItemPlaceholder.defaultProps = {
  maxWidth: 483.5,
  aspectW: 29,
  aspectH: 16,
  background: {
    height: 250,
    width: 250,
  },
  illustration: {
    height: 250,
    width: 250,
  },
}
