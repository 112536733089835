/**
 * Content categories icons
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook, faInstagram, faLinkedin, faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  faBookmark as falBookmark,
  faGlassesRound as falGlassesRound,
  faHeart as falHeart,
  faStar as falStar,
  faToolbox as falToolbox,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faCircle as farCircle,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowRightFromBracket,
  faBookmark,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleInfo,
  faCircleQuestion,
  faEarthAmericas,
  faGlassesRound,
  faHeart,
  faLanguage,
  faLock,
  faPlus,
  faSearch,
  faShieldBlank,
  faStar,
  faThumbsDown,
  faThumbsUp,
  faToolbox,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faArrowRight,
  faArrowLeft,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faPlus,
  faCircleCheck,
  farCircle,
  faSearch,
  faThumbsDown,
  faThumbsUp,
  faCircleInfo,
  faCircleQuestion,
  faLanguage,
  faEarthAmericas,
  faLock,
  faXmark,
  faShieldBlank,
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faArrowRightFromBracket,
  faStar,
  faBookmark,
  faToolbox,
  faGlassesRound,
  faHeart,
  falStar,
  falBookmark,
  falToolbox,
  falGlassesRound,
  falHeart,
)
