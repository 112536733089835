// import { FC, useEffect, useState } from 'react'
import { FC } from 'react'

// import { ChannelMemberAPIResponse, UserResponse } from 'stream-chat/dist/types/types'
import { Avatar } from '~/elements/avatar/Avatar'
import { useDatoImage } from '~/hooks/dato.assets'
// import { useUser } from '~/hooks/user.context'
// import { streamTeams } from '~/vendors/stream/constants'

type ChannelAvatarsProps = {
  channel: any // ChannelPreviewUIComponentProps['channel'],
}
/**
 * This file is mostly commented as we only use care manager as main agent for now
 */
export const ChannelAvatars: FC<ChannelAvatarsProps> = (/* { channel } */) => {

  const { data } = useDatoImage('36369676', {
    width: 40,
    height: 40,
  })

  // const { user } = useUser()

  // const avatarUrl = datoImage.data?.upload?.url

  // type Member = {
  //   avatar?: string,
  // }

  // const [member, setMember] = useState<Pick<UserResponse<Member>, 'avatar'> | null>(null)

  // useEffect(() => {
  //   if (avatarUrl) {
  //     setMember({
  //       avatar: avatarUrl,
  //     })
  //   }
  // }, [avatarUrl])

  // useEffect(() => {

  //   const getChannelMembers = async () => {
  //     const response = await channel.queryMembers({}) as ChannelMemberAPIResponse<Member>

  //     if (response.members) {
  //       const filteredMembers = response.members
  //         // filter to remove current user and any stream/missive teams
  //         .filter((streamMember) => (streamMember.user_id !== user.uid) && !streamTeams.includes(streamMember.user_id))
  //         .map((streamMember) => streamMember.user)

  //       if (filteredMembers.length > 0 && filteredMembers[0]?.avatar) {
  //         setMember(filteredMembers[0])
  //       }
  //     }
  //   }

  //   getChannelMembers()
  // }, [channel, user.uid])

  return <Avatar image={{ ...data?.upload }} color="control" tw="mr-2" />
}
