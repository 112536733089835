import prettybytes from 'pretty-bytes'
import { memo } from 'react'
import type { Attachment } from 'stream-chat'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import { SafeAnchor } from '../SafeAnchor/SafeAnchor'
import type { DefaultAttachmentType } from '../stream.types'

export type FileAttachmentProps<At extends DefaultAttachmentType = DefaultAttachmentType> = {
  attachment: Attachment<At>;
};

const UnMemoizedFileAttachment = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: FileAttachmentProps<At>,
) => {
  const { attachment } = props

  return (
    <View testID="attachment-file">
      {/* <FileIcon big={true} filename={attachment.title} mimeType={attachment.mime_type} size={30} /> */}
      <View>
        <SafeAnchor download href={attachment.asset_url} target="_blank">
          {attachment.title}
        </SafeAnchor>
        {attachment.file_size && Number.isFinite(Number(attachment.file_size)) && (
          <Text>{prettybytes(attachment.file_size)}</Text>
        )}
      </View>
    </View>
  )
}

export const FileAttachment = memo(
  UnMemoizedFileAttachment,
) as typeof UnMemoizedFileAttachment
