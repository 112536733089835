import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import {
  Configure,
  Hits, InstantSearch, SearchBox,
} from '~/components/search'
import { NoHit } from '~/components/search/NoHit'
import { useAlgoliaClient } from '~/hooks/algolia'
import { useCategories } from '~/hooks/dato.content.groups'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { debounceInterval, minQueryLengthToExecuteSearch, searchableAttributesWithLanguagePlaceholder } from './search.params'

type SearchContentBarProps = {
  makeSearchActive: () => void
  makeSearchInactive: () => void
  isSearchActive: boolean
}
export const SearchContentBar: FC<SearchContentBarProps> = ({ makeSearchActive, makeSearchInactive, isSearchActive }) => {

  const [searchState, setSearchState] = useState({})
  // console.log(`searchState`, searchState)

  // const [isSearchActive, setSearchActive] = useState(false)

  const getCategoriesRes = useCategories()
  // console.log(`getCategoriesRes`, getCategoriesRes)

  const categoriesData = getCategoriesRes.data?.allContentGroups

  const algoliaClient = useAlgoliaClient()

  const { i18n } = useTranslation()

  // const toggleModal = () => { setModalOpen(!isModalOpen) }

  if (!algoliaClient) {
    return <LoadingScreen />
  }

  // Avoid initial search - See https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
  const searchClient = {
    ...algoliaClient,
    search(requests) {
      // console.log(`requests`, requests)
      // return null on empty query or if query is shorter than 3 characters
      if (requests.every(({ params }) => !params.query || params.query.length < minQueryLengthToExecuteSearch)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }

      return algoliaClient.search(requests)
    },
  }

  const isQueryValid = searchState?.query?.length >= minQueryLengthToExecuteSearch

  const restrictedSearchableAttributes = searchableAttributesWithLanguagePlaceholder
    .map((searchableAttributeWithLanguagePlaceholder) => searchableAttributeWithLanguagePlaceholder.replace(/language/g, i18n.language))

  const instantSearchTw = isSearchActive ? 'mt-12 md:mt-20' : ''

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="content"
      searchState={searchState}
      onSearchStateChange={setSearchState}
      >
      <BasicSectionContainer tw={instantSearchTw}>
        <Configure clickAnalytics restrictSearchableAttributes={restrictedSearchableAttributes} />

        <SearchBox
          isSearchActive={isSearchActive}
          onFocus={() => makeSearchActive()}
          onCancel={() => makeSearchInactive()}
          delay={debounceInterval}
        />

        {/* {isQueryValid && isSearchActive && (
            <RefinementList
              data={categoriesData}
              query={searchState?.query || ''}
              isSearchActive={isSearchActive}
              attribute="categories.id"
              limit={5}
            />
          )} */}

        {isQueryValid && isSearchActive && <NoHit />}

        {isSearchActive && <Hits categories={categoriesData} />}
      </BasicSectionContainer>
    </InstantSearch>
  )
}
