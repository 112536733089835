import { DarkTheme, DefaultTheme as LightTheme } from '@react-navigation/native'

import { getHexColorFromTwColor } from './helpers'

export const CustomReactNavigationLightTheme = {
  ...LightTheme,
  colors: {
    ...LightTheme.colors,
    primary: getHexColorFromTwColor('brand-base'),
    background: getHexColorFromTwColor('transparent'), // background should be provided at app level
    card: getHexColorFromTwColor('surface-3'),
    text: getHexColorFromTwColor('black'),
    border: getHexColorFromTwColor('transparent'),
    notification: getHexColorFromTwColor('brand-base'),
  },
}

export const CustomReactNavigationDarkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
  },
}
