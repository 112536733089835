import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTailwind } from 'tailwind-rn/dist'

import { Button } from '~/elements/buttons/Button'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDatoSvg } from '~/hooks/dato.assets'
import { cardBasicShadowStyle } from '~/utils/style.helpers'

// Card for both Mindex, Personality Test CTA and CheckUp

type ProfileCardProps = {
  type: 'personality' | 'mindex' | 'checkup'
  to: string
  title: string
  description: string
  imgId: string
  mode?: 'result' | 'cta',
  status?: 'non-init' | 'valid' | 'obsolete',
  score?: number
}

export const ProfileCard: FC<ProfileCardProps> = ({
  type,
  to,
  title,
  description,
  imgId,
  status,
  mode,
  score,
}) => {
  const { t } = useTranslation(['profile'])
  const tailwind = useTailwind()
  const { data: dataImg } = useDatoSvg(imgId)
  const [mindexLevel, setMindexLevel] = useState<null | '1' | '2' | '3' | '4'>(null)

  useEffect(() => {
    if (score) {
      if (score <= 28) {
        setMindexLevel('1')
      }
      if (score > 28 && score <= 50) {
        setMindexLevel('2')
      }
      if (score > 50 && score <= 75) {
        setMindexLevel('3')
      }
      if (score > 75) {
        setMindexLevel('4')
      }
    }
  }, [score])

  const camelCaseStatus = camelCase(status) as 'nonInit' | 'valid' | 'obsolete'
  const mindexCtaText = t(`profile:mindex.cta.${camelCaseStatus}`)

  // passing it as style as it is not properly accounted for otherwise in Native
  const lineHeightStyle = tailwind('leading-6 sm:leading-10')

  return (
    <Pressable tw={classNames('bg-surface-1 rounded-xl p-6 w-full items-center')} to={to} style={cardBasicShadowStyle}>
      <Text tw="font-serif text-base font-semibold sm:text-lg leading-snug text-gray-900">{title}</Text>
      {dataImg && <Image {...dataImg.upload} tw="w-48 object-none" />}

      {type !== 'mindex' && (
        <View tw="w-3/4">
          <Text tw="text-basic-darkest text-center">{description}</Text>
        </View>
      )}

      {type === 'mindex' && (
        <>
          <View tw="w-3/4">
            {status === 'non-init' && <Text tw="text-basic-darkest text-center">{description}</Text>}
            {status !== 'non-init' && (
              <>
                {score
                  && (
                    <View tw="flex-row justify-center w-full mb-2">
                      <Text tw="font-serif font-semibold text-xl sm:text-2xl text-brand-base mr-1 text-center" style={lineHeightStyle}>{score}</Text>
                      <Text tw="font-serif font-semibold text-base sm:text-lg text-black text-center" style={lineHeightStyle}>
                        {t('profile:mindex.scoreReference')}
                      </Text>
                    </View>
)}
                {mindexLevel && <Text tw="text-basic-darkest text-center">{t(`profile:mindex.result.${mindexLevel}`)}</Text>}
              </>
            )}
          </View>
          {mode === 'cta' && <Button tw="mt-4" title={mindexCtaText} />}
        </>
      )}
    </Pressable>
  )
}
