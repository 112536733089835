import classNames from 'classnames'
import { FC } from 'react'
import { Overlay as RnOverlay, OverlayProps as RnOverlayProps } from 'react-native-elements'
import { useTailwind } from 'tailwind-rn'

import { OverlayInnerContainer } from '~/components/layouts/column-sections/OverlayInnerContainer'
import { IconButton } from '~/elements/buttons/IconButton'
import { useMediaQuery } from '~/hooks/mediaQuery'

import { ScrollView } from '../containers/ScrollView'
import { View } from '../containers/View'
import { Text } from '../text/Text'

const xIcon = { variant: 'regular', sizeNum: 13, name: 'xmark' }

export type OverlayProps = FC<BaseOverlayProps> & {
  Title: typeof Title
  Content: typeof Content
  Footer: typeof Footer
}
export type BaseOverlayProps = {
  size?: 'small' | 'medium' | 'large' | 'full'
} & RnOverlayProps
export type OverlayTitleProps = {
  tw?: string,
};
export type OverlayContentProps = {
  tw?: string
  scrollEnabled?: boolean
}
export type OverlayFooterProps = Record<string, unknown>

/**
 * See
 * https://reactnativeelements.com/docs/overlay
 */
const Overlay: OverlayProps = ({ children, ...props }) => {
  const { sm } = useMediaQuery()
  const tailwind = useTailwind()
  const overlayStyleArray = ['p-0']

  if (sm) {
    overlayStyleArray.push('rounded-xl')
  }

  const overlayStyle = overlayStyleArray.join(' ')

  return (
    <RnOverlay
      {...props}
      overlayStyle={tailwind(overlayStyle)}
      >
      <OverlayInnerContainer>
        {/* We give the Overlay's close button the same function as the parent's onBackdropPress, if there is any
        (there is none when we want to force the user to use the overlay, like in content outro rating). */}
        {props.onBackdropPress && (
          <View tw="absolute top-2 right-2 z-10 ">
            <IconButton color="control" size="sm" onPress={() => props.onBackdropPress()} icon={xIcon} />
          </View>
)}
        {children}
      </OverlayInnerContainer>
    </RnOverlay>
  )
}

export const Title: FC<OverlayTitleProps> = ({
  tw,
  children,
}) => (
  <View tw={classNames('w-full pt-2', tw)}>
    <Text tw="text-center font-serif text-xl font-semibold">
      {children}
    </Text>
  </View>
)
Title.displayName = 'Title'

export const Content: FC<OverlayContentProps> = ({ tw, children, scrollEnabled = false }) => {
  if (scrollEnabled) {
    return (
      <ScrollView tw={classNames('w-full grow flex-1', tw)}>
        {children}
      </ScrollView>
    )
  }
  return (<View tw={classNames('w-full grow flex-1', tw)}>{children}</View>)
}

export const Footer: FC<OverlayFooterProps> = ({ children }) => <View tw="w-full mb-2">{children}</View>
Footer.displayName = 'Footer'

Overlay.Title = Title
Overlay.Content = Content
Overlay.Footer = Footer

export default Overlay
