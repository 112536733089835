/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react'

type AppBackgroundContextType = {
    bgColor:string
    setBgColor:(bg:string|undefined) => void
    bgSvgId:string|undefined
    setBgSvgId:(assetId:string|undefined) => void
}

const defaultAppBAckgroundContext = {
  bgColor: 'surface-2',
  setBgColor: () => {},
  bgSvgId: undefined,
  setBgSvgId: () => {},
}

export const AppBackgroundContext = createContext<AppBackgroundContextType>(defaultAppBAckgroundContext)

const defaultBackgroundColor = 'orange-50'
const defaultBackgroundSvgId = '28838982'

export const AppBackgroundContextProvider = ({ children }: {children: ReactNode}) => {
  // console.log('-> AppBackgroundContextProvider')

  const [bgColor, setBgColor] = useState<string>(defaultBackgroundColor)
  const [bgSvgId, setBgSvgId] = useState<string|undefined>(defaultBackgroundSvgId)

  const result = useMemo(() => ({
    bgColor,
    setBgColor: (color:string|undefined) => {
      if (color !== bgColor) {
        setBgColor(color)
      }
    },
    bgSvgId,
    setBgSvgId: (assetId:string|undefined) => {
      if (assetId !== bgSvgId) {
        setBgSvgId(assetId)
      }
    },
  }), [bgColor, bgSvgId])

  return (<AppBackgroundContext.Provider value={result}>{children}</AppBackgroundContext.Provider>)
}

export const useAppBackgroundContext = () => {
  const context = useContext(AppBackgroundContext)
  if (context === undefined) {
    throw new Error('useAppBackgroundContext must be used within a AppBackgroundContextProvider')
  }

  return context
}
