import uniqBy from 'lodash/uniqBy'
import { Dispatch, SetStateAction, useEffect } from 'react'
import type { Channel, Event } from 'stream-chat'

import { useChatContext } from '../../context/ChatContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'
import { moveChannelUp } from '../utils'

export const useMessageNewListener = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    setChannels: Dispatch<SetStateAction<Array<Channel<At, Ch, Co, Ev, Me, Re, Us>>>>,
    lockChannelOrder = false,
    allowNewMessagesFromUnfilteredChannels = true,
  ) => {
  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('useMessageNewListener')

  useEffect(() => {
    const handleEvent = (event: Event<At, Ch, Co, Ev, Me, Re, Us>) => {
      setChannels((channels) => {
        const channelInList = channels.filter((channel) => channel.cid === event.cid).length > 0

        if (!channelInList && allowNewMessagesFromUnfilteredChannels && event.channel_type) {
          const channel = client.channel(event.channel_type, event.channel_id)
          return uniqBy([channel, ...channels], 'cid')
        }

        if (!lockChannelOrder) {
          return moveChannelUp({ channels, cid: event.cid || '' })
        }

        return channels
      })
    }

    client.on('message.new', handleEvent)

    return () => {
      client.off('message.new', handleEvent)
    }
  }, [lockChannelOrder])
}
