import truncate from 'lodash/truncate'
import { connectRefinementList } from 'react-instantsearch-native'

import { Pressable } from '~/elements/containers/Pressable'
import { ScrollView } from '~/elements/containers/ScrollView'
import { Text } from '~/elements/text/Text'

const RefinementItem = ({
  refine, label, title, value, count, isRefined,
}) => {
  // console.log('RefinementItem', label, isRefined)
  let containerTw = 'mt-4 p-2 mr-2 rounded'
  let textTw = ''

  if (isRefined) {
    containerTw += ' bg-brand-lighter'
    textTw = 'text-white'
  } else {
    containerTw += ' bg-gray-300'
    textTw = 'text-gray-900'
  }

  const txt = truncate(title || label, {
    length: 60,
    separator: /,? +/,
    omission: '...',
  })

  return (
    <Pressable
      key={value}
      onPress={() => refine(value)}
      tw={containerTw}
      >
      <Text tw={textTw}>
        {txt}
        {' '}
        (
        {count}
        )
      </Text>
    </Pressable>
  )
}

const RefinementList = ({ items, refine, data }) => {
  // console.log(`RefinementList`, items)

  const nextData = items.map((item) => ({ ...item, ...data?.find((o) => o.id === item.label) }))

  // console.log(`nextData`, nextData)
  return (
    <ScrollView horizontal showsVerticalScrollIndicator={false} tw="flex-row w-full mb-4">
      {nextData.map((item) => <RefinementItem key={item.slug} {...item} refine={refine} />)}
    </ScrollView>
  )
}

export default connectRefinementList(RefinementList)
