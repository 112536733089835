import { FC, memo } from 'react'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

export type CommandItemProps = {
  entity: {
    /** Arguments of command */
    args?: string;
    /** Description of command */
    description?: string;
    /** Name of the command */
    name?: string;
  };
};

const UnMemoizedCommandItem: FC<CommandItemProps> = (props) => {
  const { entity } = props

  return (
    <View>
      <Text>
        {entity.name}
        {' '}
        {entity.args}
      </Text>
      <Text>{entity.description}</Text>
    </View>
  )
}

export const CommandItem = memo(UnMemoizedCommandItem) as typeof UnMemoizedCommandItem
