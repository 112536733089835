import {
  BaseSyntheticEvent, Dispatch, RefObject, SetStateAction,
} from 'react'

import { TextInput } from '~/elements/input/TextInput'

import { useTranslationContext } from '../context/TranslationContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'
import type { ChannelOrUserResponse } from './utils'

export type ChannelSearchFunctionParams<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  setQuery: Dispatch<SetStateAction<string>>;
  setResults: Dispatch<
    SetStateAction<Array<ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>>>
  >;
  setResultsOpen: Dispatch<SetStateAction<boolean>>;
  setSearching: Dispatch<SetStateAction<boolean>>;
};

export type SearchInputProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  channelSearchParams: {
    setQuery: Dispatch<SetStateAction<string>>;
    setResults: Dispatch<
      SetStateAction<ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>[]>
    >;
    setResultsOpen: Dispatch<SetStateAction<boolean>>;
    setSearching: Dispatch<SetStateAction<boolean>>;
  };
  inputRef: RefObject<HTMLInputElement>;
  onSearch: (event: BaseSyntheticEvent) => void;
  query: string;
  searchFunction?: (
    params: ChannelSearchFunctionParams<At, Ch, Co, Ev, Me, Re, Us>,
    event: BaseSyntheticEvent,
  ) => Promise<void> | void;
};

export const SearchInput = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: SearchInputProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    channelSearchParams, inputRef, onSearch, query, searchFunction,
  } = props

  const { t } = useTranslationContext('SearchInput')

  return (
    <TextInput
      onChange={(event: BaseSyntheticEvent) => (searchFunction ? searchFunction(channelSearchParams, event) : onSearch(event))}
      placeholder={t('Search')}
      ref={inputRef}
      type="text"
      value={query}
    />
  )
}
