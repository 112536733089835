export const sections = [

  {
    key: 'picked-content',
    recommendations: {
      scenario: 'Home_Picks',
      count: 1,
      options: {
        filter: undefined,
        booster: undefined,
      },
    },
    accessoryRight: {
      name: 'bell',
      onPress: () => {
        // console.log('Bell pressed')
      },
    },
    tileSettings: {
      size: 'large',
    },
  },
  {
    key: 'popular-content',
    accessoryRight: {
      title: 'More',
      onPress: () => {
        // console.log('More pressed')
      },
    },
    recommendations: {
      count: 5,
      scenario: 'Home_Popular',
      options: {
        filter: undefined,
        booster: undefined,
      },
    },
    tileSettings: {
      // size: 'small',
      orientation: 'landscape',
    },
  },
]
