import { Pressable } from './Pressable'
import { View } from './View'

/**
 * A component that only returns a pressable if we indeed have a target to or a destination
 */

export const EventuallyPressable = ({ to, onPress, ...props }) => {
  // console.log('-> EventuallyPressable')
  if (to || onPress) {
    return <Pressable to={to} onPress={onPress} {...props} />
  }

  return <View {...props} />
}
