import { PlatformPressable } from '@react-navigation/elements'
import { Link } from '@react-navigation/native'
import { View } from 'dripsy'
import {
  ComponentProps, FC, forwardRef, ReactNode,
} from 'react'
import {
  Platform, StyleProp, StyleSheet, ViewStyle,
} from 'react-native'

export const styles = StyleSheet.create({
  button: {
    display: 'flex',
  },
})

type LinkPressableProps = Omit<ComponentProps<typeof PlatformPressable>, 'style'> & {
  style: StyleProp<ViewStyle>
} & {
  to?: string
  children: ReactNode
  onPress?: () => void
}

/**
 * Taken from react-navigation
 * @see https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerItem.tsx
 */
export const LinkPressable:FC<LinkPressableProps> = forwardRef((props, ref) => {
  const {
    children, style, onPress, onLongPress, to, accessibilityRole, ...rest
  } = props
  if (Platform.OS === 'web' && to) {
    // React Native Web doesn't forward `onClick` if we use `TouchableWithoutFeedback`.
    // We need to use `onClick` to be able to prevent default browser handling of links.
    return (
      <Link
        ref={ref}
        {...rest}
        to={to}
        style={[styles.button, style]}
        onPress={(e: any) => {
          if (!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) // ignore clicks with modifier keys
            && (e.button == null || e.button === 0) // ignore everything but left clicks
          ) {
            e.preventDefault()
            onPress?.(e)
          }
        }}
        onLongPress={onLongPress ?? undefined}
        >
        {children}
      </Link>
    )
  }

  return (
    <PlatformPressable
      {...rest}
      accessibilityRole={accessibilityRole}
      onPress={onPress}
      >
      <View ref={ref} style={style}>{children}</View>
    </PlatformPressable>
  )
})
