import { useLinkTo } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDatoSvg } from '~/hooks/dato.assets'
import { useTimeout } from '~/hooks/time'

export const RegistrationConfirmedScreen = () => {
  const { t } = useTranslation(['onboarding'])
  const linkTo = useLinkTo()
  const { data: imageForeground } = useDatoSvg('43718577')

  // redirect to onboarding checklist after 500ms
  useTimeout(() => {
    linkTo('/')
  }, 2500)

  return (
    <FullOverlayContainer tw="justify-center">
      <TwoColGridSection>
        <View tw="text-center items-center">
          {imageForeground && <Image {...imageForeground.upload} tw="w-3/5" />}
          <Text tw="py-8 font-sans text-black font-semibold">{t('onboarding:registrationConfirmed.title')}</Text>
          <Text tw="text-gray-500">{t('onboarding:registrationConfirmed.description')}</Text>
        </View>
      </TwoColGridSection>
    </FullOverlayContainer>
  )
}
