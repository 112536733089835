/* eslint-disable react/no-unstable-nested-components */
import classNames from 'classnames'
import {
  isHeading, isLink,
  isList, isListItem, isParagraph, renderRule,
} from 'datocms-structured-text-utils'
// import { camelCase } from 'lodash'
import { FC } from 'react'
import {
  StructuredText as RnStructuredText,
  StructuredTextPropTypes as RnStructuredTextPropTypes,
} from 'react-datocms'
import { Platform } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { v4 as uuidv4 } from 'uuid'

import { MainColorShade } from '~/elements/colors/ColorShades'

import { Article } from '../containers/Article'
import { View } from '../containers/View'
import { DatoIcon } from '../icons/DatoIcon'
import { Image } from '../images/Image'
import { Text } from './Text'

export type StructuredTextProps = {
  paragraphTw?: string
  headingTw?: string
  categoryColor?: MainColorShade
} & RnStructuredTextPropTypes

const baseParagraphTw = 'font-sans mb-4'

const baseHeadingTw = 'font-sans'

const baseListItemTw = 'font-sans mb-2'

export const renderEventuallyArray = (input, key, format) => {
  if (Array.isArray(input)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return input.map((elm) => renderNativeDastChild(elm, key, format))
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return renderNativeDastChild(input, key, format)

}

const renderTextElement = (txt, key, { isBold }) => {
  if (txt.length === 0) {
    return null
  }
  const twArr = ['']
  if (isBold) {
    twArr.push('font-semibold')
  }
  // console.log('twArr', twArr.join(' '))
  return <Text key={key} tw={twArr.join(' ')}>{txt}</Text>

}

export const renderNativeDastChild = (child, key = uuidv4(), format = {}) => {
  if (typeof child === 'string') {

    return renderTextElement(child, key, format)
  }

  if (child.type === 'br') {
    // console.log('strong child', child)
    return renderTextElement('\n', key, format)
  }

  if (child.type === 'strong') {
    // console.log('strong child', child)
    return renderEventuallyArray(child.props.children, `${key}-${child.key}`, { ...format, isBold: true })
  }

  return renderEventuallyArray(child.props.children, `${key}-${child.key}`, format)
}

/**
 * Wrapper to present Structured Text
 * See https://github.com/datocms/react-datocms#structured-text
 */
export const StructuredText: FC<StructuredTextProps> = ({
  paragraphTw, headingTw, categoryColor, ...props
}) => {
  const tailwind = useTailwind()

  return (
    <Article>
      <RnStructuredText
        customRules={[

          // Paragraph
          renderRule(isParagraph, ({ children, key }) => {
            if (Platform.OS === 'web') {
              return <Text key={key} tw={classNames(baseParagraphTw, paragraphTw)}>{children}</Text>
            }
              // console.log('p', key, children)

              return renderEventuallyArray(children, key, {})

          }),

          // Headings
          renderRule(isHeading, ({ children, node, key }) => <Text key={key} tw={classNames(baseHeadingTw, headingTw)} category={`h${node.level + 1}`}>{children}</Text>),

          // Blockquotes (TODO use native in web, styled in native)
          // renderRule(isBlockquote, ({ children }) => <Text>{children}</Text>),

          // renderRule(isBlockquote, ({ children }) => <Text>{children}</Text>),

          renderRule(isLink, ({ node, key }) => (
            <a key={key} target="_blank" href={node.url} rel="noreferrer" style={tailwind('no-underline')}>
              <Text tw="no-underline text-brand-base">{node.children[0].value}</Text>
            </a>
          )),

          renderRule(isList, ({ children, key }) => (Platform.OS === 'web' ? <ul key={key} className="" style={tailwind('-mt-2')}>{children}</ul> : <View>{children}</View>)),

          renderRule(isListItem, ({ children, key }) => {
            if (Platform.OS === 'web') {
              return <li key={key}>{children}</li>
            }
            // console.log('li', children)
            return null
            return (
              <View key={key} tw="flex-row">
                <Text tw={classNames(baseListItemTw, paragraphTw, 'mr-1')}>{'\u2022'}</Text>
                <Text tw={classNames(baseListItemTw, paragraphTw)}>{children}</Text>
              </View>
            )

          }),

        ]}

        /**
       * Custom renderers for inline blocks
       */
        // eslint-disable-next-line react/jsx-props-no-multi-spaces
        renderBlock={({ record }) => {
          // console.log('inline block', record)
          // eslint-disable-next-line no-underscore-dangle
          switch (record.__typename) {
          case 'ImageRecord':
            return (
              <View tw="my-4">
                <Image {...record.media} />
              </View>
            )
          default:
            return null
          }
        }}
        /**
       * TODO - custom link renderer
       * Perhaps we want to display specific card style rather than simple links
       */
        renderInlineRecord={({ record }) => {
          // eslint-disable-next-line no-underscore-dangle
          switch (record.__typename) {
          case 'IconRecord':
            return <DatoIcon {...record} categoryColor={categoryColor} />
          default:
            return null
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-multi-spaces
        {...props}

      />
    </Article>
  )
}
