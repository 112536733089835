import classNames from 'classnames'
import { FC } from 'react'
import { Platform } from 'react-native'

import { View } from './View'

const baseArticleTw = 'prose contents'

type ArticleProps = {
  tw?:string
}

export const Article:FC<ArticleProps> = ({ children, tw }) => {
  if (Platform.OS === 'web') {
    return <article className={classNames(baseArticleTw, tw)}>{children}</article>
  }

  return <View tw={classNames(baseArticleTw, tw)}>{children}</View>
}
