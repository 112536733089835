import { useTranslation } from 'react-i18next'

import { Badge } from '~/elements/badge/Badge'
import { useGetDurationSinceFirstMeetingWithMinder } from '~/hooks/firestore.meetings'

export const MinderSinceBadge = ({ minder }) => {
  // console.log('-> MyMinderSinceBadge', minder)
  const { isLoading, data: duration } = useGetDurationSinceFirstMeetingWithMinder(minder)
  const { t } = useTranslation(['track'])

  if (isLoading || !duration) {
    return null
  }

  return <Badge value={t('track:minder.yourMinderDuration', { duration })} />

}
