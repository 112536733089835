import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'

import { Pressable } from '../containers/Pressable'
import { Icon } from '../icons/Icon'
import { Text } from '../text/Text'

library.add(faChevronDown, faChevronUp)

type SelectButtonProps ={
    value:boolean,
    setValue:(value:boolean)=>void,
    label:string
}

export const SelectButton:FC<SelectButtonProps> = ({ value, setValue, label }) => (
  <Pressable
    onPress={() => {
            setValue(!value)
    }}
    tw="flex-row p-1 items-center"
    >
    <Text tw="mr-1">{label}</Text>
    <Icon name={`chevron-${value ? 'up' : 'down'}`} variant="solid" />
  </Pressable>
)
