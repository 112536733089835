import upperFirst from 'lodash/upperFirst'
import { useTranslation } from 'react-i18next'

import { List } from '~/elements/list/List'
import { minQueryLengthToExecuteSearch } from '~/screens/ExploreContentScreen/search.params'

import { SearchSectionHeading } from '../headings/SearchSectionHeading'

export const SuggestedSearchQueries = ({ refine, currentRefinement }) => {
  // console.log(`SuggestedSearchQueries `, currentRefinement)
  const { t } = useTranslation(['library'])

  const isQueryValid = currentRefinement?.length >= minQueryLengthToExecuteSearch

  if (isQueryValid) {
    return null
  }

  const suggItems = t('explore.suggest.items', { returnObjects: true }) || {}
  const suggestions = Object.entries(suggItems).map(([key, value]) => ({ key, title: value }))
  // console.log(`suggestions`, suggestions)

  return (
    <>
      <SearchSectionHeading tw="mb-2" title={t('explore.suggest.title')} />
      <List
        listItemStyle="bg-transparent rounded px-0 hover:pl-2"
        listItemTitleStyle="text-gray-500 text-sm"
        data={suggestions.map((item) => ({
          ...item,
          title: upperFirst(item.title),
          onPress: () => {
            refine(item.title)
          },
        }))}
      />
    </>
  )
}
