import { AspectRatio } from '~/elements/images/DoubleSvgImage/DoubleSvgImage.enums'

export const getTwFromAspectRatio = (aspectRatio: AspectRatio) => {
  switch (aspectRatio) {
  case '16:9':
    return 'aspect-w-16 aspect-h-9'
  case '7:5':
    return 'aspect-w-7 aspect-h-5'
  case '4:3':
    return 'aspect-w-4 aspect-h-3'
  case '3:2':
    return 'aspect-w-3 aspect-h-2'
  case '1:1':
    return 'aspect-w-1 aspect-h-1'
  case '2:3':
    return 'aspect-w-2 aspect-h-3'
  case '2:1':
    return 'aspect-w-2 aspect-h-1'
  case '5:6':
    return 'aspect-w-5 aspect-h-6'
  case '15:7':
    return 'aspect-w-15 aspect-h-7'
  case '5:7':
    return 'aspect-w-5 aspect-h-7'
  case '6:7':
    return 'aspect-w-6 aspect-h-7'
  default:
    throw new Error(`unknown aspect ratio ${aspectRatio}`)
  }
}

const aspectRatioPrefixes = ['aspect-w', 'aspect-h']

export const getAspectRatioRelatedClasses = (tw = '') => {
  const arr: Array<string> = tw.split(' ')
  const aspectRatioTwArr = arr.filter((item) => aspectRatioPrefixes.some((key) => (item.startsWith(`${key}-`))))
  const res = aspectRatioTwArr.join(' ')
  return {
    aspectRatioTw: res.length > 0 ? res : undefined,
    nonAspectRatioTw: arr.filter((item) => !aspectRatioTwArr.includes(item)).join(' '),
  }
}

export const computeAspectRatioFromAspecRatioClasses = (aspectRatioTw = '') => {

  let aspectRatio
  if (aspectRatioTw) {
    const widthStr = aspectRatioTw.match(/aspect-w-(\d+)/)?.[0]
    const width = widthStr?.split('-')?.[2]
    const heightStr = aspectRatioTw.match(/aspect-h-(\d+)/)?.[0]
    const height = heightStr?.split('-')?.[2]
    if (height && width) {
      aspectRatio = Math.round((width / height) * 1000) / 1000
    }

  }
  return aspectRatio
}

export const getAspectRatioRelatedStyle = (tw = '') => {
  const { aspectRatioTw, nonAspectRatioTw } = getAspectRatioRelatedClasses(tw)
  return {
    aspectRatio: computeAspectRatioFromAspecRatioClasses(aspectRatioTw),
    nonAspectRatioTw,
  }
}
