import { AnimatedView } from '~/elements/containers/AnimatedView'
import { View } from '~/elements/containers/View'
import { Skeleton } from '~/elements/skeleton/Skeleton'
import { getHexColorFromTwColor } from '~/theme/helpers'

const transition = { opacity: { duration: 300 } }

export const ContentTilePlaceholder = ({ orientation, size }) => {

  if (orientation === 'landscape') {
    return (
      <AnimatedView
        transition={{ type: 'timing' }}
        tw="flex-row rounded-2xl bg-surface-1 p-2 sm:p-4 mb-4"
        animate={{ backgroundColor: getHexColorFromTwColor('surface-1') }}
        >
        <Skeleton transition={transition} tw="w-24 h-24 mr-4" />
        <View tw="flex-col flex-grow">
          <Skeleton transition={transition} tw="w-3/5 h-6 mb-2" />
          <Skeleton transition={transition} tw="w-full h-4 mb-1" />
          <Skeleton transition={transition} tw="w-4/5 h-4 mb-1" />
          <Skeleton transition={transition} tw="w-3/5 h-2 rounded-sm" />
        </View>
      </AnimatedView>
    )
  }

  if (size === 'large') {
    return (
      <AnimatedView
        transition={{ type: 'timing' }}
        tw=" bg-red-100 flex-col w-full rounded-2xl p-2 sm:p-4 mb-4"
        animate={{ backgroundColor: getHexColorFromTwColor('surface-1') }}
        >
        <Skeleton transition={transition} tw="w-full h-96 rounded-xl" />
        <Skeleton transition={transition} tw="w-3/5 h-6 my-2" />
        <Skeleton transition={transition} tw="w-full h-4 mb-1" />
        <Skeleton transition={transition} tw="w-4/5 h-4 mb-1" />
        <Skeleton transition={transition} tw="w-3/5 h-2 rounded-sm" />
      </AnimatedView>

    )
  }

  if (size === 'medium') {
    return (
      <AnimatedView
        transition={{ type: 'timing' }}
        tw="rounded-2xl w-96 p-2 sm:p-4 ml-4 mb-4"
        animate={{ backgroundColor: getHexColorFromTwColor('surface-1') }}
        >
        <Skeleton transition={transition} tw="h-60 rounded-xl" />
        <Skeleton transition={transition} tw="w-3/5 h-6 my-2" />
        <Skeleton transition={transition} tw="w-full h-4 mb-1" />
        <Skeleton transition={transition} tw="w-4/5 h-4 mb-1" />
        <Skeleton transition={transition} tw="w-3/5 h-2 rounded-sm" />
      </AnimatedView>
    )
  }

  if (size === 'small') {
    return (
      <AnimatedView
        transition={{ type: 'timing' }}
        tw="w-32 mb-4 flex-col mx-4 items-center"
        animate={{ backgroundColor: getHexColorFromTwColor('surface-1') }}
        >
        <Skeleton transition={transition} tw="w-24 h-32 rounded-xl" />
        <Skeleton transition={transition} tw="h-4 mt-2" />
      </AnimatedView>

    )
  }

  return null
}

// defaults are roughly taken from Mac App Store tiles
ContentTilePlaceholder.defaultProps = {
  maxWidth: 483.5,
  aspectW: 29,
  aspectH: 16,
  background: {
    height: 250,
    width: 250,
  },
  illustration: {
    height: 250,
    width: 250,
  },
}
