import { InstantSearch } from 'react-instantsearch-native'
import {
  Modal,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { Text } from '~/elements/text/Text'

import RefinementList from './RefinementList'

const styles = StyleSheet.create({
  closeButton: {
    alignItems: 'center',
    marginTop: 20,
  },
  closeButtonText: {
    fontSize: 18,
  },
})

const Filters = ({
  isModalOpen,
  searchState,
  searchClient,
  toggleModal,
  onSearchStateChange,
}) => (
  <Modal animationType="slide" visible={isModalOpen}>
    <SafeAreaView tw="flex-1">
      <InstantSearch
        searchClient={searchClient}
        indexName="devContent"
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        >
        <RefinementList attribute="groups.slug" />
        <TouchableOpacity style={styles.closeButton} onPress={toggleModal}>
          <Text style={styles.closeButtonText}>Close</Text>
        </TouchableOpacity>
      </InstantSearch>
    </SafeAreaView>
  </Modal>
)

// Filters.propTypes = {
//   isModalOpen: PropTypes.bool.isRequired,
//   searchState: PropTypes.object.isRequired,
//   searchClient: PropTypes.object.isRequired,
//   toggleModal: PropTypes.func.isRequired,
//   onSearchStateChange: PropTypes.func.isRequired,
// }

export default Filters
