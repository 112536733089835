import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import { useEffect, useState } from 'react'
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
} from 'react-native-dotenv'
import aa from 'search-insights'

import { useUser } from '~/hooks/user.context'

export const useAlgoliaClient = (): SearchClient | undefined => {
  const [client, setClient] = useState<SearchClient>()

  const { user } = useUser()

  // Load tracking lib on init
  useEffect(() => {
    aa('init', {
      appId: ALGOLIA_APP_ID,
      apiKey: ALGOLIA_SEARCH_KEY,
    })
  }, [])

  useEffect(() => {
    setClient(algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, { headers: { 'X-Algolia-UserToken': user?.uid } }))
  }, [user?.uid])

  return client
}
