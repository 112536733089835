/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

const themeColors = require('./theme/colors')

// console.log('themeColors', themeColors)

module.exports = {
  content: [
    'elements/**/*.(ts|tsx|js|jsx)',
    'components/**/*.(ts|tsx|js|jsx)',
    'screens/**/*.(ts|tsx|js|jsx)',
    'navigators/**/*.(ts|tsx|js|jsx)',
    // 'utils/**/*.(ts|tsx|js|jsx)',
  ],
  theme: {
    fontFamily: {
      sans: ['Poppins'], // 'Inter Var' // , ...defaultTheme.fontFamily.sans
      serif: ['Recoleta'], // ...defaultTheme.fontFamily.serif
    },
    colors: themeColors,
    extend: {
      spacing: {
        62: '15.5rem' /* 248px */,
        78: '19.5rem' /* 312px */,
        96: '24rem' /* 384px */,
        104: '26rem' /* 416px */,
        128: '32rem' /* 512px */,
        144: '36rem' /* 576px */,
        160: '40rem' /* 640px */,
        168: '42rem' /* 672px */,
        192: '48rem' /* 768px */,
        208: '52rem' /* 832px */,
        368: '92rem' /* 1472px */,
      },
      fontSize: {
        xs: '0.625rem', /* 10px */
        sm: '0.875rem', /* 14px */
        base: '1rem', /* 16px */
        lg: '1.25rem', /* 20px */
        xl: '1.5rem', /* 24px */
        '2xl': '2rem', /* 32px */
        '3xl': '2.5rem', /* 40px */
        '4xl': '3rem', /* 48px */
        '5xl': '4rem', /* 64px */
        '6xl': '5rem', /* 80px */
      },
      // Need to rewrite with units for RN
      // TODO in REM ?
      aspectRatio: {
        1: '1px',
        2: '2px',
        3: '3px',
        4: '4px',
        5: '5px',
        6: '6px',
        7: '7px',
        8: '8px',
        9: '9px',
        10: '10px',
        11: '11px',
        12: '12px',
        13: '13px',
        14: '14px',
        15: '15px',
        16: '16px',
      },
      ringColor: {
        DEFAULT: 'transparent',
      },
      minWidth: {
        1: '0.25rem', // 4px
        4: '1rem', // 16px
        8: '2rem', // 32px
        12: '3rem', // 48px
        16: '4rem', // 64px
        24: '6rem', // 96p
        32: '8rem', // 128px
        48: '12rem', // 192px
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
      },
      maxWidth: {
        xlp: '40rem', // 576px (xl-padding with extra 32px to account for card shaddows)
      },
    },

  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
  ],
}
