import { useFirestoreDocument, UseFirestoreHookOptions } from '@react-query-firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import { ConversationData } from '~/definitions/firestore.conversation'
import { GetSingleDocQueryResult } from '~/definitions/firestore.queries'
import { getConversationRef } from '~/queries/firestore.helpers'

import {
  withGetDocResultAdapter,
} from './firestore.hooks.helpers'

export const useConversationById = (
  conversationId: string,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<ConversationData> => {
  const ref = getConversationRef(conversationId)

  const keys = [Collections.CONVERSATIONS, conversationId]
  const res = useFirestoreDocument<ConversationData>(keys, ref, options, { enabled: !!conversationId })
  return withGetDocResultAdapter(res)
}
