import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { StackScreenProps } from '@react-navigation/stack'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { DiscoverStackParamList } from '~/navigators/DiscoverStackParamList'
import { SearchContentBar } from '~/screens/ExploreContentScreen/SearchContentBar'
import { getHexColorFromTwColor } from '~/theme/helpers'

import { EventsScreen } from '../EventsScreen/EventsScreen'
import { ExploreContentScreen } from '../ExploreContentScreen/ExploreContentScreen'

type DiscoverScreenProps = StackScreenProps<DiscoverStackParamList, 'Discover'>

const Tab = createMaterialTopTabNavigator()

export const DiscoverScreen: FC<DiscoverScreenProps> = () => {
  //   console.log('-> DiscoverScreen')
  const { t } = useTranslation(['discover', 'library', 'events'])
  const { md } = useMediaQuery()

  const [isSearchActive, setSearchActive] = useState(false)
  const makeSearchActive = () => setSearchActive(true)
  const makeSearchInactive = () => setSearchActive(false)

  if (md) {
    return null
  }

  return (
    <MainContainer scrollEnabled>

      {!isSearchActive
        && <ScreenHeadingSection tw="mb-4 md:mt-20" title={t('discover:main.heading.title')} />}

      <SearchContentBar makeSearchActive={() => makeSearchActive()} makeSearchInactive={() => makeSearchInactive()} isSearchActive={isSearchActive} />

      {!isSearchActive && (
        <BasicSectionContainer>
          <Tab.Navigator
            screenOptions={{
              tabBarActiveTintColor: getHexColorFromTwColor('black'),
              tabBarInactiveTintColor: getHexColorFromTwColor('gray-500'),
              tabBarLabelStyle: {
                fontSize: 16,
                textTransform: 'none',
              },
              tabBarStyle: {
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                shadowColor: 'transparent',
              },
              tabBarIndicatorStyle: {
                backgroundColor: getHexColorFromTwColor('orange-500'),
                // abandoned: linear-gradient, as neither background nor backgroundImage are tabBarIndicatorStyle's available properties
                height: 6,
                width: 6,
                borderRadius: 50,
                left: '25%',
              },
            }}
            >
            <Tab.Screen name={t('library:main.heading.titleTab')} component={ExploreContentScreen} />
            <Tab.Screen name={t('events:main.heading.title')} component={EventsScreen} />
          </Tab.Navigator>
        </BasicSectionContainer>
)}
    </MainContainer>
  )
}
