import classNames from 'classnames'
import { FC } from 'react'

import { Avatar } from '~/elements/avatar/Avatar'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useMinderBySlug } from '~/hooks/dato.minders'
import { getFullName } from '~/utils/people.helpers'

type MinderMiniProps = {
  /**
   * Minder's slug
   */
  minder: string

  textColor?:string
};

export const MinderMini: FC<MinderMiniProps> = ({ minder, textColor }) => {
  // console.log('minder', minder)
  const { isLoading, data } = useMinderBySlug(minder)

  if (isLoading) {
    // TODO - return skeleton while loading
    return null
  }

  if (!data || data.minder === null) {
    return null
  }

  // console.log('data', data)

  const tColor = `text-${textColor}`

  return (
    <View tw="flex-row items-center">
      <Avatar size="tiny" image={data.minder.picture} tw="border border-white bg-brand-lighter" />
      <Text tw={classNames('ml-2', tColor)}>{getFullName(data.minder)}</Text>
    </View>
  )
}

MinderMini.defaultProps = {
  textColor: 'gray-900',
}
