import {
  UIEvent,
  useCallback, useLayoutEffect, useRef, useState,
} from 'react'

import type { StreamMessage } from '../../context/ChannelStateContext'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'
import { useMessageListScrollManager } from './useMessageListScrollManager'

export type UseScrollLocationLogicParams<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  currentUserId?: string;
  messages?: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>[];
  scrolledUpThreshold?: number;
};

export const useScrollLocationLogic = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    params: UseScrollLocationLogicParams<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const { messages = [], scrolledUpThreshold = 200 } = params

  const [hasNewMessages, setHasNewMessages] = useState(false)
  const [wrapperRect, setWrapperRect] = useState<DOMRect>()

  const closeToBottom = useRef(false)
  const closeToTop = useRef(false)
  const listRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    if (!listRef.current?.scrollTo) {
      return
    }

    listRef.current?.scrollTo({
      top: listRef.current.scrollHeight,
    })
    setHasNewMessages(false)

    // this is hacky and unreliable, but that was the current implementation so not breaking it
    setTimeout(() => {
      listRef.current?.scrollTo({
        top: listRef.current.scrollHeight,
      })
    }, 200)
  }, [listRef])

  useLayoutEffect(() => {
    if (listRef?.current) {
      setWrapperRect(listRef.current.getBoundingClientRect())
      scrollToBottom()
    }
  }, [listRef])

  const updateScrollTop = useMessageListScrollManager({
    messages,
    onScrollBy: (scrollBy) => listRef.current?.scrollBy({ top: scrollBy }),
    scrollContainerMeasures: () => ({
      offsetHeight: listRef.current?.offsetHeight || 0,
      scrollHeight: listRef.current?.scrollHeight || 0,
    }),
    scrolledUpThreshold,
    scrollToBottom,
    showNewMessages: () => setHasNewMessages(true),
  })

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const element = event.target as HTMLDivElement
      const { scrollTop } = element

      updateScrollTop(scrollTop)

      const { offsetHeight } = element
      const { scrollHeight } = element

      closeToBottom.current = scrollHeight - (scrollTop + offsetHeight) < scrolledUpThreshold
      closeToTop.current = scrollTop < scrolledUpThreshold

      if (closeToBottom.current) {
        setHasNewMessages(false)
      }
    },
    [updateScrollTop, closeToTop, closeToBottom, scrolledUpThreshold],
  )

  const onMessageLoadCaptured = useCallback(() => {
    /**
     * A load event (emitted by e.g. an <Image>) was captured on a message.
     * In some cases, the loaded asset is larger than the placeholder, which means we have to scroll down.
     */
    if (closeToBottom.current && !closeToTop.current) {
      scrollToBottom()
    }
  }, [closeToTop, closeToBottom, scrollToBottom])

  return {
    hasNewMessages,
    listRef,
    onMessageLoadCaptured,
    onScroll,
    scrollToBottom,
    wrapperRect,
  }
}
