import noop from 'lodash/noop'
import { useEffect } from 'react'
import { Platform } from 'react-native'

// import { FileUploadButton, ImageDropzone } from 'react-file-utils'
import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Tooltip } from '~/elements/tooltip/Tooltip'

import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete'
import { useComponentContext } from '../context/ComponentContext'
import { useMessageInputContext } from '../context/MessageInputContext'
import { useTranslationContext } from '../context/TranslationContext'
import {
  EmojiIconSmall as DefaultEmojiIcon,
  // FileUploadIcon as DefaultFileUploadIcon,
} from './icons'

export const KEY_CODES = {
  ESC: 27,
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  SPACE: 32,
}

export const EditMessageForm = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>() => {
  // const { acceptedFiles, multipleUploads } = useChannelStateContext<At, Ch, Co, Ev, Me, Re, Us>('EditMessageForm')
  const { t } = useTranslationContext('EditMessageForm')

  const {
    clearEditingState,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    // openEmojiPicker,
    // uploadNewFiles,
  } = useMessageInputContext<At, Ch, Co, Ev, Me, Re, Us, V>('EditMessageForm')

  const {
    EmojiIcon = DefaultEmojiIcon,
    // FileUploadIcon = DefaultFileUploadIcon,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('EditMessageForm')

  useEffect(() => {
    if (Platform.OS === 'web') {
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === KEY_CODES.ESC && clearEditingState) {
          clearEditingState()
        }
      }

      document.addEventListener('keydown', onKeyDown)
      return () => document.removeEventListener('keydown', onKeyDown)
    }
    return noop()
  }, [clearEditingState])

  return (
    <View>
      {/* <ImageDropzone
        accept={acceptedFiles}
        disabled={!isUploadEnabled || maxFilesLeft === 0}
        handleFiles={uploadNewFiles}
        maxNumberOfFiles={maxFilesLeft}
        multiple={multipleUploads}
      > */}
      {/* {isUploadEnabled && <UploadsPreview />} */}
      {/* <EmojiPicker small /> */}
      <ChatAutoComplete />
      <View>
        <View>
          {/* 'str-chat__input-emojiselect' onClick={openEmojiPicker} */}
          <EmojiIcon />
          {isUploadEnabled && (
            <View
              // className='str-chat__fileupload-wrapper'
              testID="fileinput"
              >
              <Tooltip>
                {maxFilesLeft
                  ? t('Attach files')
                  : t("You've reached the maximum number of files")}
              </Tooltip>
              {/* <FileUploadButton
                  accepts={acceptedFiles}
                  disabled={maxFilesLeft === 0}
                  handleFiles={uploadNewFiles}
                  multiple={multipleUploads}
                >
                  'str-chat__input-fileupload'
                  <FileUploadIcon />
                </FileUploadButton> */}
            </View>
          )}
        </View>
        <View>
          <Button
            onPress={() => {
              if (clearEditingState) {
                clearEditingState()
              }
            }}
            title={t('Cancel')}
          />
          <Button onPress={handleSubmit} title={t('Send')} />
        </View>
      </View>
      {/* </ImageDropzone> */}
    </View>
  )
}
