import { FC } from 'react'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'

import { ContentPageHeading, ContentPageParagraph } from '../../elements/text/Player.FontTypes'
import { EvaluationNavBar, NavProps } from './NavBar'

type SectionPageProps = NavProps & {
  title: string
  // theme?: string
}

export const EvaluationPlayerSectionScreen: FC<SectionPageProps> = ({
  title, description, illustration, ...props
}) => (
  <MainContainer scrollEnabled contentContainerTw="items-center bg-yellow-100 h-full">
    <Image {...illustration} tw="w-60 sm:relative md:absolute top-10 md:-top-10 md:-right-10" />
    <BasicSectionContainer tw="items-center">
      <View tw="flex-grow content-center justify-center">
        <View tw="sm:h-128 justify-end pt-16">
          <ContentPageHeading tw="text-brand-base">{title}</ContentPageHeading>
          <ContentPageParagraph tw="text-center">{description}</ContentPageParagraph>
        </View>
      </View>
      <EvaluationNavBar {...props} isDimensionGroup />
    </BasicSectionContainer>
  </MainContainer>
)
