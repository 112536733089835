import { httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'

import { functionsEU } from '~/navigators.shared/app.firebase.client'

import { useUser } from './user.context'

export const useStreamAuthToken = () => {
//   console.log('-> useStreamAuthToken')

  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState<string|undefined>()
  const { user } = useUser()

  useEffect(() => {

    const getStreamAuthToken = async () => {
      setIsLoading(true)
      try {
        // console.log('Will call httpGetStreamAuthToken for user', user.uid)
        const result:{data: string} = await httpsCallable(functionsEU, 'httpGetStreamAuthToken')({ uid: user.uid })
        // console.log('token', result)
        setToken(result.data)
      } catch (error) {
        console.error(error)
        throw error
      }
      setIsLoading(true)
    }

    if (user.uid) {
      // console.log('Will trigger call')
      getStreamAuthToken()
    } else if (token && !user) {
      // user is not logged in, but we have a token > clear it
      setToken(undefined)
    }

    // Purposedly ignored token, user, etc..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid])

  return { isLoading, token }

}
