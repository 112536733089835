import { FC } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'

type ProgressFooterProps = {
  isFirstStep: boolean
  isLastStep: boolean
  onPrevious: (values: FieldValues) => void,
  onPress: (values: FieldValues) => void,
}

export const ProgressFooter: FC<ProgressFooterProps> = ({
  isFirstStep, isLastStep, onPrevious, onPress,
}) => {
  const { formState: { isSubmitting } } = useFormContext()
  const { t } = useTranslation(['forms', 'common'])

  const btnText = isLastStep ? t('forms:submit') : t('common:cta.next')

  const btnsTw = `mt-4 w-full flex-row ${isFirstStep ? 'justify-end' : 'justify-between'}`

  return (
    <View tw="flex-row justify-between mt-8">
      {/* <ProgressBar value={(stepNumber) / totalSteps} variant="determinate" /> */}
      <View tw={btnsTw}>
        {!isFirstStep
          && (
            <Button
              variant="ghost"
              icon={{
              name: 'arrow-left',
              variant: 'regular',
              }}
              disabled={isSubmitting}
              onPress={onPrevious}
            />
)}
        <Button
          color="brand"
          title={btnText}
          disabled={isSubmitting}
          onPress={onPress}
        />
      </View>

    </View>
  )
}
