// Note: this file should be shared with similar file in backend project

// Should be slugs as in Dato CMS: https://cms.mindday.com/editor/item_types/472549/items
export const evaluationTypes = {
  CUSTOMER_REGISTRATION: 'customer-registration',
  MINDERS_SESSION_1_FOLLOWUP: 'minders-session-1-followup',
  USER_REGISTRATION: 'user-registration',
  USER_ORIENTATION: 'user-orientation',
  USER_TRACK_FEEDBACK: 'user-track-feedback',
  USER_SESSION_FEEDBACK: 'user-session-feedback',
  USER_PERSONALITIES: 'user-personalities',
  PHQ_9: 'PHQ-9',
  GAD_7: 'GAD-7',
  WHO_5: 'WHO-5',
  HOW_I_FEEL: 'how-I-feel',
} as const
