import classNames from 'classnames'
import { FC } from 'react'

import { SvgById } from './SvgById'

const flagAssetMapping = {
  small: {
    GBR: '28571466',
    FRA: '28571465',
  },
  medium: {
    GBR: '28571453',
    FRA: '28571452',
  },
  large: {
    GBR: '28570071',
    FRA: '28570070',
  },
} as const

type FlagCountryCodeType = keyof typeof flagAssetMapping.large

type FlagSizeType = 'small'|'medium'|'large'

export type FlagProps = {
    code: FlagCountryCodeType
    size: FlagSizeType
    tw?:string
}

/**
 * Should be base on [React Flagpack](https://flagpack.xyz/)
 * but unfortunately incompatible with RN
 *
 */
export const Flag:FC<FlagProps> = ({ code, size = 'medium', tw }) => {

  const assetId = flagAssetMapping[size][code]

  // object-none so that flags take their original size by default
  return <SvgById assetId={assetId} tw={classNames('object-none', tw)} />
}
