import classNames from 'classnames'
import { FC } from 'react'

import { Button } from '../buttons/Button'
import { ButtonColor } from '../buttons/Button.d'
import { View } from '../containers/View'
import { Text } from '../text/Text'

type ListItemContentProps = {
  eyebrow?: string
  title?: string
  description?: string
  eyebrowTw?: string
  titleStyle?: string
  descriptionTw?: string
  cta?: string
  ctaTw?: string
  ctaColor?: ButtonColor
  containerTw?:string
};
export const ListItemContent: FC<ListItemContentProps> = ({
  eyebrow, title, description, eyebrowTw, titleStyle, descriptionTw, cta, ctaTw, ctaColor, containerTw,
}) => {
  // console.log('-> ListItemContent', title, cta, ctaColor, ctaTw)
  const hasContent = eyebrow || title || description || cta
  if (!hasContent) {
    return null
  }

  return (
    <View tw={classNames('flex-col', containerTw)}>
      {!!eyebrow && <Text tw={classNames('uppercase text-brand-base', eyebrowTw)}>{eyebrow}</Text>}
      {!!title && <Text tw={classNames('text-xl', titleStyle)}>{title}</Text>}
      {!!description && <Text tw={classNames('text-sm', descriptionTw)}>{description}</Text>}
      {!!cta && <View tw="flex-row justify-center md:justify-start"><Button color={ctaColor} title={cta} tw={classNames('mt-8', ctaTw)} /></View>}
    </View>
  )
}
