import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import isEqual from 'lodash/isEqual'
import upperFirst from 'lodash/upperFirst'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from 'react-native'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { MeetingData } from '~/definitions/firestore.meeting'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useMeetingById } from '~/hooks/firestore.meetings'
import { useUser } from '~/hooks/user.context'
import { MyTrackStackParamList } from '~/navigators/MyTrackStackParamList'

import { CalendlyEmbed } from '../MeetingSchedulingScreen/CalendlyEmbed'
import { SchedulingConfirmationScreen } from '../MeetingSchedulingScreen/MeetingSchedulingConfirmationScreen'

export type MeetingReschedulingScreenProps = StackScreenProps<MyTrackStackParamList, 'Rescheduling'>

export const MeetingReschedulingScreen: FC<MeetingReschedulingScreenProps> = ({ route }) => {
//  // console.log('-> MeetingReschedulingScreen')

  const { meetingId, action } = route.params
  const linkTo = useLinkTo()
  const { isLoading, data: meetingDoc } = useMeetingById(meetingId)
  const meetingData = meetingDoc?.data()

  const { user } = useUser()

  const { t } = useTranslation(['track', 'common'])

  const [step, setStep] = useState<'intro' | 'scheduled' | 'date_time_selection' | 'confirmation'>('intro')
  const vwDimensions = useWindowDimensions()
  const [originalData, setOriginalData] = useState<MeetingData['vendors']['calendly']>()
  const [scheduledMeetingData, setScheduledMeetingData] = useState<{
    calendlyMeetingId: string,
  }>()

  // navigate away when update has been recorded
  useEffect(() => {
    const calendlyData = meetingData?.vendors.calendly
    if (originalData && !isEqual(meetingData?.vendors.calendly, originalData)) {
      // console.log('catched meeting updated in db...')
      // console.log('new update detected')
      // console.log('originalData', originalData)
      // console.log('calendlyData', calendlyData)
      setOriginalData(calendlyData) // Needed to avoid infinite loop
      linkTo('/track')
    }
    // purposly ignored linkTo, originalData
  }, [meetingData])

  // store initial value temporarily
  useEffect(() => {
    const calendlyData = meetingData?.vendors.calendly
    if (calendlyData && !originalData) {
      // console.log('Will store current value')
      setOriginalData(calendlyData)
    }
    // TODO cleanup function

    // purposly ignored originalData
  }, [meetingData])

  if (isLoading || !meetingData) {
    return null
  }

  // console.log('Original meeting data', data.data())

  // console.log('step', step)
  // console.log('meetingData', meetingData)
  const calendlyUrlAction = action === 'cancel' ? 'cancellations' : 'reschedulings'
  const url = `https://calendly.com/${calendlyUrlAction}/${meetingData.vendors.calendly.inviteeId}`

  // console.log('url', url)

  // console.log('scheduling url', url)

  if (step === 'intro') {
    return (
      <MainContainer>
        <BasicSectionContainer tw="">
          <View tw="mb-8 mt-4 md:mt-20">
            <SectionHeading title={upperFirst(t(`track:rescheduling.${action}`))} />
            <Text tw="mb-16 mt-16">{t('track:rescheduling.warning', { action: t(`track:rescheduling.${action}`) })}</Text>
          </View>
          <Button title={`${t(`track:rescheduling.${action}`)} ${t('common:glossary.session', { count: 1 })}`} onPress={() => setStep('date_time_selection')} />
        </BasicSectionContainer>
      </MainContainer>
    )
  }

  if (step === 'scheduled' && scheduledMeetingData) {
    return (
      <SchedulingConfirmationScreen calendlyMeetingId={scheduledMeetingData.calendlyMeetingId} />
    )
  }

  const remainingHeight = vwDimensions.height - 68 // tabBarHeight

  return (
    <MainContainer>
      <BasicSectionContainer style={{ height: remainingHeight }}>
        <View tw="mt-8 md:mt-20">
          <Text>{ t(`track:scheduling.${step}`)}</Text>
        </View>
        <CalendlyEmbed
          setStep={setStep}
          url={url}
          style={{ height: remainingHeight - 64 }}
          user={user}
          setMeetingData={setScheduledMeetingData}
          eventId={meetingData.type}
        />
      </BasicSectionContainer>
    </MainContainer>
  )
}
