import Color from 'color'

import {
  getTwNoPrefix, InteractionState, keepApplicableInteractionClass,
} from './tw.breakpoints.helpers'

const gradientRelatedPrefixes = ['bg-gradient-to', 'from', 'to', 'via']

export const hasGradientClasses = (tw = '') => tw
  .split(' ')
  .filter((item) => gradientRelatedPrefixes
    .some((prefix) => getTwNoPrefix(item)
      .startsWith(`${prefix}-`)))
  .length > 0

export const getGradientRelatedClasses = (tw = '') => {
  // console.log('-> getGradientRelatedClasses', tw)
  const arr: Array<string> = tw.split(' ')
  const gradientTwArr = arr.filter((item) => gradientRelatedPrefixes.some((prefix) => getTwNoPrefix(item).startsWith(`${prefix}-`)))
  // console.log('gradientTwArr', gradientTwArr.join(' '))

  // We also add opacity classes immediately following gradient related classes

  gradientTwArr.slice(1).forEach((key) => {
    // console.log(`evaluating opacity for ${key}`)
    const idx = arr.findIndex((item) => item === key)
    if (idx > -1) {
      const nextKey = arr[idx + 1]
      if (nextKey?.startsWith('opacity-')) {
        const currPos = gradientTwArr.findIndex((item) => item === key)
        gradientTwArr.splice(currPos, 0, nextKey)
      }
    }
  })

  // console.log('gradientTwArr with opacity', gradientTwArr.join(' '))

  return {
    gradientTw: gradientTwArr.join(' '),
    nonGradientTw: arr.filter((item) => !gradientTwArr.includes(item)).join(' '),
  }
}
// TODO media queries

export const getGradientDirection = (tw = '') => {
  const arr: Array<string> = tw.split(' ')
  const gradientTwArr = arr.filter((item) => getTwNoPrefix(item).startsWith('bg-gradient-'))
  if (gradientTwArr.length === 0) {
    return undefined
  }

  return getTwNoPrefix(gradientTwArr[0])
}

// We do not use theme colors but specific mapping here
const mapBaseGradientColor = (color: string) => {
  switch (color) {
  case 'orange-100':
    return Color.rgb(255, 231, 171)

    // orange-100 / hover
  case 'orange-300':
    return Color.rgb(249, 173, 139)

  case 'orange-500':
    return Color.rgb(255, 150, 90)

    // orange-500 / hover
  case 'orange-700':
    return Color.rgb(183, 79, 31)

  case 'gray-500':
    return Color.rgb(209, 209, 209)
  case 'gray-300':
    return Color.rgb(173, 173, 173)
  case 'gray-100':
    return Color.rgb(249, 250, 250)
  default:
    throw new Error(`Unknown color ${color}`)
  }
}

const getGradientGenericColor = (tw = '', is: InteractionState, pos) => {
  const arr = tw.split(' ')
  const arrFrom = arr.filter((key) => getTwNoPrefix(key).startsWith(`${gradientRelatedPrefixes[pos]}-`))
  const applicableValue = keepApplicableInteractionClass(arrFrom, is)
  if (!applicableValue) {
    return undefined
  }

  const baseColor = applicableValue.replace(`${gradientRelatedPrefixes[pos]}-`, '')
  // console.log('baseColor', baseColor)

  // convert to color
  const { r, g, b } = mapBaseGradientColor(baseColor).object()

  // Eventually add opacity
  let opacity = 1
  const idx = arr.findIndex((key) => key === applicableValue)
  const opacityCandidate = arr[idx + 1]
  // console.log('opacityCandidate', opacityCandidate)

  if (opacityCandidate?.startsWith('opacity-')) {
    opacity = (Number(opacityCandidate.replace('opacity-', '')) / 100)
  }

  const color = `rgba(${r},${g},${b},${opacity})`
  // console.log('color', color)
  return color

}

// returns rgba color string
export const getGradientStartColor = (tw = '', is: InteractionState) => getGradientGenericColor(tw, is, 1)

export const getGradientEndColor = (tw = '', is: InteractionState) => getGradientGenericColor(tw, is, 2)

export const getGradientViaColor = (tw = '', is: InteractionState) => getGradientGenericColor(tw, is, 3)
