import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import slugify from 'slugify'

import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { Text } from '~/elements/text/Text'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { getNearestAspectRatio } from '~/theme/helpers'
import { shadowLightStyle } from '~/theme/shadows'

import { ContentTilePlaceholder } from './ContentTilePlaceholder'
import { ContentTileProps, TileSize } from './ContentTileProps.d'

const defaultTitleClass = 'font-serif text-base font-semibold text-gray-900 '
const defaultDescriptionClass = 'font-sans font-normal text-sm text-gray-700 leading-relaxed '
const defaultMdClass = 'font-sans font-normal text-sm text-gray-500 leading-normal '

/**
 *
 * See https://ux.stackexchange.com/questions/54289/difference-between-tiles-and-cards
 */
export const ContentTile: FC<ContentTileProps> = ({
  testID,
  title,
  description,
  metadata,
  illustration,
  orientation = 'portrait',
  size = 'medium',
  nbContent,
  isFirst,
  isLast,
  onPress,
  navigateTo,
}) => {

  const { sm } = useMediaQuery()
  const { t } = useTranslation(['common'])

  // console.log('-> ContentTile', title)
  // console.log('illustration', illustration)
  // console.log('description', description)

  // placeholder while we load title
  if (!title) {
    return <ContentTilePlaceholder orientation={orientation} size={size} />
  }

  const aspects: Record<TileSize, [number, number]> = {
    small: [5, 6],
    medium: [32, 24],
    large: [328, 160],
  }

  const [aspectW, aspectH] = orientation === 'landscape' ? [1, 1] : aspects[size]

  const containerClass = ['flex mb-4']
  const imgClass = ['rounded-lg']
  const textContainerClass = []
  let titleClass = defaultTitleClass
  let descriptionClass = defaultDescriptionClass
  let mdClass = defaultMdClass

  let titleNbLines = 1
  let descriptionNbLines = 3

  if (orientation === 'landscape') {
    imgClass.push('w-24 h-24')
    containerClass.push('w-full bg-surface-1  border border-gray-100 flex-row p-4 rounded-2xl mb-4')
    textContainerClass.push('ml-4 flex-1')
    titleClass += ' '
    descriptionClass += ' mt-1'
    mdClass += ' leading-none mt-1'
    titleNbLines = 2
    descriptionNbLines = 4
  } else {
    imgClass.push('max-w-full')
    containerClass.push('flex-col')
    textContainerClass.push('mt-4')

    if (size === 'small') {
      // const arClasses = getTwFromAspectRatio(aspectRatio)
      // console.log('arClasses', arClasses)
      imgClass.push('w-32 rounded-xl')
      textContainerClass.push('text-center')
      titleClass += ' font-sans text-sm leading-tight font-normal text-gray-500'
      descriptionClass = 'hidden'
      titleNbLines = 3
      mdClass = 'hidden'

      containerClass.push(classNames('w-32 items-center'))

      // General margin between cards is 2 except for first and last item
      containerClass.push(isFirst ? 'ml-4' : '')
      containerClass.push(isLast ? 'mr-4' : 'mr-2')

    }
    if (size === 'medium') {
      titleClass += ' font-serif text-base sm:text-lg leading-tight'
      descriptionClass += ' leading-normal'
      containerClass.push('max-w-sm bg-surface-1 border border-gray-100 p-4 rounded-2xl h-full')
      titleNbLines = 2

      // to avoid mx-4 direcly in the containerClass which created an undesired ml-4 on the first card
      containerClass.push(isLast ? '' : 'mr-8')

      // from sm breakpoint, no need for first/last margins
      if (sm && isFirst) {
        containerClass.push('ml-0')
      }
      if (sm && isLast) {
        containerClass.push('mr-0')
      }
    }
    if (size === 'large') {
      containerClass.push('p-4 w-full bg-surface-1 border border-gray-100 rounded-2xl')
      titleClass += 'font-serif text-base font-semibold sm:text-lg leading-snug'
      descriptionClass += ''
      titleNbLines = 3
    }

    // add small margin between text and title
    descriptionClass += ' mt-2'
  }

  // console.log('Pressable tw', containerClass)
  // console.log('DoubleSvg tw', imgClass)
  // console.log(`textContainerClass`, textContainerClass)

  return (
    <Pressable
      testID={testID || slugify(navigateTo)}
      to={navigateTo}
      onPress={onPress}
      tw={containerClass.join(' ')}
      style={size === 'small' ? undefined : shadowLightStyle}
      >
      <>
        {illustration && (
          <DoubleSvgImage
            tw={imgClass.join(' ')}
            aspectRatio={getNearestAspectRatio(aspectW / aspectH)}
            background={illustration.background}
            foreground={illustration.foreground}
            backgroundColor={illustration.backgroundColor}
            >
            {nbContent && (
              <View
                tw="p-1 bg-surface-1 opacity-70 font-semibold text-center absolute bottom-2 left-2 right-2 rounded-full"
                >
                <Text>{`${nbContent} ${t('common:glossary.article', { count: nbContent })}`}</Text>
              </View>
            )}
          </DoubleSvgImage>
        )}

        <View tw={textContainerClass.join(' ')}>
          {!!title && <Text tw={titleClass} numberOfLines={titleNbLines}>{title}</Text>}
          {!!metadata && <Text tw={mdClass} numberOfLines={1}>{metadata}</Text>}
          {!!description && <Text tw={descriptionClass} numberOfLines={descriptionNbLines}>{description}</Text>}
        </View>
      </>
    </Pressable>
  )
}
