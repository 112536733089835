import * as Crypto from 'expo-crypto'

// encode to hex as it will be used as docId
export const hashEmail = (email) => {

  if (!email) {
    return undefined
  }

  return Crypto.digestStringAsync(
    Crypto.CryptoDigestAlgorithm.SHA256,
    email,
    { encoding: Crypto.CryptoEncoding.HEX },
  )
}
