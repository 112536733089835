import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'

import { useCurrentUserTrackOrientationMeeting } from '~/hooks/firestore.meetings'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { MyTrackStackParamList } from '~/navigators/MyTrackStackParamList'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { MyTrackEmptyScreen } from '../MyTrackEmptyScreen/MyTrackEmptyScreen'
import { MyTrackDesktopScreen } from './MyTrackDesktopScreen'
import { MyTrackMobileScreen } from './MyTrackMobileScreen'

export type MyTrackScreenProps = StackScreenProps<MyTrackStackParamList, 'MyTrack'>

export const MyTrackScreen: FC<MyTrackScreenProps> = () => {
  // console.log('-> MyTrackScreen')

  const { lg } = useMediaQuery()

  const {
    isLoading: isLoadingCurrentUserTrackOrientationMeeting,
    data: currentUserTrackOrientationMeetingData,
  } = useCurrentUserTrackOrientationMeeting()

  if (isLoadingCurrentUserTrackOrientationMeeting) {
    return <LoadingScreen />
  }

  const hasBookedOrientationMeeting = !!currentUserTrackOrientationMeetingData

  if (!hasBookedOrientationMeeting) {
    return <MyTrackEmptyScreen />
  }

  const trackId = currentUserTrackOrientationMeetingData.data()?.userTrack.id

  if (lg) {
    return <MyTrackDesktopScreen trackId={trackId} />
  }

  return <MyTrackMobileScreen trackId={trackId} />
}
