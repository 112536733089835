/**
 * A custom drawer item taken from react navigation 6; that hides label on smaller screens
 * https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerItem.tsx
 */

import { useTheme } from '@react-navigation/native'
import Color from 'color'
import {
  StyleSheet,
  Text,
  View,
} from 'react-native'
// import * as Reanimated from 'react-native-reanimated'
import { useTailwind } from 'tailwind-rn'

import { LinkPressable } from '~/elements/buttons/LinkPressable'
import { useMediaQuery } from '~/hooks/mediaQuery'

import { EventuallyGradientView } from '../../../elements/containers/EventuallyGradientView'
import { DrawerItemProps } from './DrawerItemProps'

const containerTwBase = 'overflow-hidden'
const wrapperTwBase = 'flex-row justify-center p-2'
const labelTwBase = 'flex-1'
// const buttonTwBase = 'flex'

/**
  * A component used to show an action item with an icon and a label in a navigation drawer.
  */
const DrawerItem = (props: DrawerItemProps) => {
  // console.log('DrawerItem', props)

  // console.log('Is reanimated configured ?', Reanimated.isConfigured())

  const { colors } = useTheme()

  const { lg } = useMediaQuery()
  const tailwind = useTailwind()

  const {
    icon,
    testID,
    label,
    labelStyle,
    to,
    focused = false,
    activeTintColor = colors.primary,
    inactiveTintColor = Color(colors.text).alpha(0.68).rgb().string(),
    // activeBackgroundColor = Color(colors.primary).alpha(0.10).rgb().string(),
    // inactiveBackgroundColor = 'transparent',
    style,
    onPress,
    pressColor,
    pressOpacity,
    ...rest
  } = props

  const { borderRadius = 4 } = StyleSheet.flatten(style || {})
  const color = focused ? activeTintColor : inactiveTintColor
  // const backgroundColor = focused
  //   ? activeBackgroundColor
  //   : inactiveBackgroundColor

  const iconNode = icon ? icon({ size: 20, focused, color }) : null

  // console.log('testID', testID)

  return (

    <>
      {/* Add separator over Settings */}
      {testID.startsWith('Preferences') && !focused && (
        <View style={{
          ...tailwind('bg-basic-lighter mt-4 w-full lg:w-auto lg:ml-1.5 lg:w-2/5'),
          height: StyleSheet.hairlineWidth,
        }}
        />
      )}
      <EventuallyGradientView
        collapsable={false}
        {...rest}
        focused={focused}
        style={[tailwind(containerTwBase), { borderRadius }, style]}
        >
        <LinkPressable
          onPress={onPress}
          style={[tailwind(wrapperTwBase), { borderRadius }]}
          accessibilityRole="button"
          accessibilityState={{ selected: focused }}
          pressColor={pressColor}
          pressOpacity={pressOpacity}
          to={to}
          >
          <>
            {iconNode}
            {lg && (
              <View
                style={[
                  tailwind(labelTwBase),
                  {
                    marginLeft: iconNode ? 16 : 0,
                  // marginVertical: 5
                  },
                ]}
                >
                {typeof label === 'string' ? (
                  <Text
                    numberOfLines={1}
                    style={[
                      {
                        color,
                        fontWeight: '500',
                      },
                      labelStyle,
                    ]}
                    >
                    {label}
                  </Text>
                ) : (
                  label({ color, focused })
                )}
              </View>
            )}
          </>
        </LinkPressable>
      </EventuallyGradientView>
    </>
  )
}

export default DrawerItem
