import { useMemo } from 'react'

import type { MessageInputContextValue } from '../../context/MessageInputContext'
import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../../stream.types'

export const useCreateMessageInputContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>(
    value: MessageInputContextValue<At, Ch, Co, Ev, Me, Re, Us, V>,
  ) => {
  const {
    additionalTextareaProps,
    attachments,
    autocompleteTriggers,
    clearEditingState,
    closeCommandsList,
    closeEmojiPicker,
    closeMentionsList,
    cooldownInterval,
    cooldownRemaining,
    disabled,
    disableMentions,
    doFileUploadRequest,
    doImageUploadRequest,
    emojiIndex,
    emojiPickerIsOpen,
    emojiPickerRef,
    errorHandler,
    fileOrder,
    fileUploads,
    focus,
    grow,
    handleChange,
    handleEmojiKeyDown,
    handleSubmit,
    imageOrder,
    imageUploads,
    insertText,
    isUploadEnabled,
    keycodeSubmitKeys,
    maxFilesLeft,
    maxRows,
    mentionAllAppUsers,
    mentioned_users,
    mentionQueryParams,
    message,
    noFiles,
    numberOfUploads,
    onPaste,
    onSelectEmoji,
    onSelectUser,
    openCommandsList,
    openEmojiPicker,
    openMentionsList,
    overrideSubmitHandler,
    parent,
    publishTypingEvent,
    removeFile,
    removeImage,
    setCooldownRemaining,
    setText,
    showCommandsList,
    showMentionsList,
    text,
    textareaRef,
    uploadFile,
    uploadImage,
    uploadNewFiles,
    useMentionsTransliteration,
  } = value

  const editing = message?.editing
  const fileUploadsValue = Object.entries(fileUploads)
    // eslint-disable-next-line
    .map(([_, value]) => value.state)
    .join()
  const imageUploadsValue = Object.entries(imageUploads)
    // eslint-disable-next-line
    .map(([_, value]) => value.state)
    .join()
  const mentionedUsersLength = mentioned_users.length
  const parentId = parent?.id

  const messageInputContext: MessageInputContextValue<At, Ch, Co, Ev, Me, Re, Us, V> = useMemo(
    () => ({
      additionalTextareaProps,
      attachments,
      autocompleteTriggers,
      clearEditingState,
      closeCommandsList,
      closeEmojiPicker,
      closeMentionsList,
      cooldownInterval,
      cooldownRemaining,
      disabled,
      disableMentions,
      doFileUploadRequest,
      doImageUploadRequest,
      emojiIndex,
      emojiPickerIsOpen,
      emojiPickerRef,
      errorHandler,
      fileOrder,
      fileUploads,
      focus,
      grow,
      handleChange,
      handleEmojiKeyDown,
      handleSubmit,
      imageOrder,
      imageUploads,
      insertText,
      isUploadEnabled,
      keycodeSubmitKeys,
      maxFilesLeft,
      maxRows,
      mentionAllAppUsers,
      mentioned_users,
      mentionQueryParams,
      message,
      noFiles,
      numberOfUploads,
      onPaste,
      onSelectEmoji,
      onSelectUser,
      openCommandsList,
      openEmojiPicker,
      openMentionsList,
      overrideSubmitHandler,
      parent,
      publishTypingEvent,
      removeFile,
      removeImage,
      setCooldownRemaining,
      setText,
      showCommandsList,
      showMentionsList,
      text,
      textareaRef,
      uploadFile,
      uploadImage,
      uploadNewFiles,
      useMentionsTransliteration,
    }),
    [
      cooldownInterval,
      cooldownRemaining,
      editing,
      emojiPickerIsOpen,
      fileUploadsValue,
      imageUploadsValue,
      isUploadEnabled,
      mentionedUsersLength,
      parentId,
      publishTypingEvent,
      showCommandsList,
      showMentionsList,
      text,
    ],
  )

  return messageInputContext
}
