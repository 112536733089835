import omit from 'lodash/omit'

import { Icon } from '~/elements/icons/Icon'

/**
 * An icon with gradient.
 * A bit of a trick here as it's gradient for SVG that we need to declare beforehand
 * TODO native version
 * Note: fill-gradient-linear is defined in our css file
 */
export const BottomTabNavigatorIcon = ({ focused, ...rest }) => (
  <Icon
    {...omit(rest, ['size', 'color'])}
    variant={focused ? 'solid' : 'regular'}
    sizeNum={18}
    className={focused ? 'fill-gradient-linear' : 'text-gray-500'}
  />
)
