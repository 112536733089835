import { FC } from 'react'
import { NativeSafeAreaViewProps, SafeAreaView as RnSafeAreaView } from 'react-native-safe-area-context'
import { useTailwind } from 'tailwind-rn'

type SafeAreaViewProps = {
tw?: string
} & NativeSafeAreaViewProps

/**
 *
 * Beware, default for SafeAreaProvider is to have flex:1
 */
export const SafeAreaView:FC<SafeAreaViewProps> = ({ tw = '', style, ...props }) => {
  const tailwind = useTailwind()
  return (<RnSafeAreaView {...props} style={{ ...tailwind(tw), ...style }} />)
}
