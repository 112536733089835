import { Listbox } from '@headlessui/react'
import {
  FC, Fragment, useState,
} from 'react'

import { SelectButton } from '../buttons/SelectButton'
import { View } from '../containers/View'
import { Text } from '../text/Text'
import { DropdownPickerProps } from './DropdownPicker'

/**
 * Actually the HeadlessUI ListBox
 * @see https://headlessui.dev/react/listbox
 */
export const DropdownPicker:FC<DropdownPickerProps> = ({
  placeholder, value, items, setValue, tw = '', // containerTw = '', disabledTw = '', textTw = '', labelTw = '',
}) => {
  console.log('-> DropdownPicker (web)')
  const [isVisible, setIsVisible] = useState(false)

  const btnLabel = items.find((item) => item.value === value)?.label || placeholder

  return (
    <Listbox
      as={Fragment}
      value={value}
      onChange={(val) => {
        setIsVisible(!isVisible)
        setValue(val)
      }}
      >
      <View tw={tw}>
        <Listbox.Button as={Fragment}>
          <SelectButton value={isVisible} setValue={setIsVisible} label={btnLabel} />
        </Listbox.Button>
        {isVisible && (
          <Listbox.Options as={Fragment} static>
            <View tw="flex-col">
              {items.map((item) => (
                <Listbox.Option
                  as={Fragment}
                  key={item.value?.toString()}
                  value={item.value}
                  disabled={item.disabled}
                  >
                  <View tw="">
                    <Text>{item.label}</Text>
                  </View>
                </Listbox.Option>
        ))}
            </View>
          </Listbox.Options>
)}
      </View>
    </Listbox>
  )
}
