import { useFirestoreDocument, UseFirestoreHookOptions, useFirestoreQuery } from '@react-query-firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import { evaluationTypes } from '~/constants/firestore.evaluations'
import { EvaluationType } from '~/definitions/firestore.evaluation.properties'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import {
  getAllEvaluationsQuery, getUserEvaluationsByTypeQuery, latestEvaluationsByTypeQuery, nextEvaluationsByTypeQuery,
} from '~/queries/firestore.evaluations'
import { getEvaluationRef } from '~/queries/firestore.helpers'

import {
  ArrayDocsQueryResult, GetSingleDocQueryResult, HasDocQueryResult,
} from '../definitions/firestore.queries'
import {
  withArrayDocQueryResultAdapter, withGetDocResultAdapter, withHasResultAdapter, withSingleDocQueryResultAdapter,
} from './firestore.hooks.helpers'
import { useCurrentUserRef } from './firestore.users'
import { useNow } from './time'

/**
 * This hook listen for a new evaluation being created
 * @returns undefined if none yet or that evaluation doc if newly created
 */
export const useCurrentUserNewEvaluation = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<EvaluationData> => {
  const userRef = useCurrentUserRef()
  // console.log('-> useCurrentUserNewEvaluation', slug, userRef?.path)
  const now = useNow()
  const queryVar = { userRef, now, slug }
  const queryOpt = { limit: 1 }
  const query = nextEvaluationsByTypeQuery(queryVar, queryOpt)
  const keys = [Collections.EVALUATIONS, queryVar, queryOpt]
  const res = useFirestoreQuery<EvaluationData>(keys, query, options)

  return withSingleDocQueryResultAdapter(res)
}

export const useEvaluationById = (
  testId: string,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<EvaluationData> => {
  const ref = getEvaluationRef(testId)
  // console.log('ref.path', ref.path)
  const keys = [Collections.EVALUATIONS, testId]
  const res = useFirestoreDocument<EvaluationData>(keys, ref, options, { enabled: !!testId })
  return withGetDocResultAdapter(res)
}

/**
 * Direct wrap of the query with no adapter
 */
const useQueryCurrentUserLatestEvaluationByType = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
) => {
  const userRef = useCurrentUserRef()
  const queryVar = { userRef, slug }
  const queryOpt = { limit: 1 }
  const query = latestEvaluationsByTypeQuery(queryVar, queryOpt)
  const keys = [Collections.EVALUATIONS, queryVar, queryOpt]
  return useFirestoreQuery<EvaluationData>(keys, query, options)
}

export const useGetCurrentUserLatestEvaluationByType = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
): GetSingleDocQueryResult<EvaluationData> => {
  const res = useQueryCurrentUserLatestEvaluationByType(slug, options)

  // We add the adapter to return single item only
  return withSingleDocQueryResultAdapter(res)
}

export const useCurrentUserAllEvaluations = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<EvaluationData> => {
  const userRef = useCurrentUserRef()
  const queryVar = { userRef }
  const query = getAllEvaluationsQuery(queryVar)
  const keys = [Collections.EVALUATIONS, queryVar]
  const res = useFirestoreQuery<EvaluationData>(keys, query, options)

  return withArrayDocQueryResultAdapter(res)
}

/**
 * Fetch all evaluations for a given type
 * Direct wrap of the query with no adapter
 * TODO: optionally filter by active track
 */
const useQueryCurrentUserGenericTypeEvaluations = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
) => {
  const userRef = useCurrentUserRef()
  const queryVar = { userRef, slug }
  const query = getUserEvaluationsByTypeQuery(queryVar)
  const keys = [Collections.EVALUATIONS, queryVar]
  return useFirestoreQuery<EvaluationData>(keys, query, options)
}

/**
 * We add the adapter to return single item only on top of useQueryCurrentUserGenericTypeEvaluations
 */
const useGetCurrentUserGenericTypeEvaluations = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<EvaluationData> => {
  const res = useQueryCurrentUserGenericTypeEvaluations(slug, options)

  return withArrayDocQueryResultAdapter(res)
}

export const useGetCurrentUserFeelingsEvaluations = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<EvaluationData> => useGetCurrentUserGenericTypeEvaluations(evaluationTypes.HOW_I_FEEL, options)

export const useGetCurrentUserPersonalityEvaluations = (
  options: UseFirestoreHookOptions = { subscribe: true },
): ArrayDocsQueryResult<EvaluationData> => useGetCurrentUserGenericTypeEvaluations(evaluationTypes.USER_PERSONALITIES, options)

/**
 * We add the adapter to return existence boolean only on top of useQueryCurrentUserGenericTypeEvaluations
 */
const useHasCurrentUserGenericTypeEvaluation = (
  slug: EvaluationType,
  options: UseFirestoreHookOptions = { subscribe: true },
): HasDocQueryResult => {
  const res = useQueryCurrentUserGenericTypeEvaluations(slug, options)

  return withHasResultAdapter(res)
}

/**
 * Evaluate if current user already has a "HOW I FEEL" evaluation recorded
 */
export const useHasCurrentUserFeelingsEvaluation = (
  options: UseFirestoreHookOptions = { subscribe: true },
): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.HOW_I_FEEL, options)

/**
 * Evaluate if current user already has a "PERSONALITY TEST" evaluation recorded
 */
export const useHasCurrentUserPersonalityEvaluation = (
  options: UseFirestoreHookOptions = { subscribe: true },
): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.USER_PERSONALITIES, options)

/**
 * Evaluate if current user already has a "GAD-7" evaluation recorded
 */
export const useHasCurrentUserGadEvaluation = (
  options: UseFirestoreHookOptions = { subscribe: true },
): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.GAD_7, options)

/**
  * Evaluate if current user already has a "PHQ-9" evaluation recorded
  */
export const useHasCurrentUserPhqEvaluation = (
  options: UseFirestoreHookOptions = { subscribe: true },
): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.PHQ_9, options)
