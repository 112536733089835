import omit from 'lodash/omit'
import sum from 'lodash/sum'
import { useTranslation } from 'react-i18next'

import { SiteLocale, useAllContentQuery, useContentByIdQuery } from '~/__generated__/graphql.queries'
import { computeReadMetadata, ContentContextType } from '~/utils/content.helpers'

export const useContentById = (contentId: string) => {
  const { i18n } = useTranslation()
  const queryVar = { contentId, locale: i18n.language as SiteLocale }
  return useContentByIdQuery(queryVar)
}

export const useAllContents = () => {
  const { i18n } = useTranslation()
  const queryVar = { locale: i18n.language as SiteLocale }
  return useAllContentQuery(queryVar)
}

export const useFormattedContentById = (contentId: string): ContentContextType => {
  // console.log('-> useFormattedContentById', contentId)
  const { data, isLoading, error } = useContentById(contentId)

  if (data) {
    // console.log('data.content', data.content)
    const res = {
      data: {
        ...omit(data.content, ['coverMain', 'coverBackground', 'coverBackgroundColor', 'coverMainFormat']),
        illustration: {
          foreground: data.content?.coverMain,
          background: data.content?.coverBackground,
          backgroundColor: data.content?.coverBackgroundColor,
          format: data.content?.coverMainFormat,
        },
      },
      isLoading,
      error,
    }

    const metadata = computeReadMetadata(res.data.content)

    // console.log('metadata', metadata)
    const toc = res.data.content.map((item, index) => ({ ...item, metadata: metadata[index] }))
    // console.log('toc', toc)

    res.data = {
      ...res.data,
      toc,
      metadata: {
        words: sum(metadata.map((item) => item.words)),
        minutes: sum(metadata.map((item) => item.minutes)),
        seconds: sum(metadata.map((item) => item.seconds)),
      },
    }

    return res
  }

  return { data: null, isLoading, error }

}
