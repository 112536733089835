import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useState } from 'react'
import { GetChannelTypeResponse } from 'stream-chat'

import { useChatContext } from '~/components/chat/context/ChatContext'

import { QueryChannelsResultType } from './QueryChannelsResultType'
import { useIsMounted } from './useIsMounted'
import { useUser } from './user.context'

const mapChannel = (channel: GetChannelTypeResponse, uid:string):QueryChannelsResultType => ({
  ...channel,
  id: channel.id,
  title: channel.data.name,
  category: channel.data.category,
  members: Object.keys(channel.state.members).filter((slug) => slug !== uid),
  typing: channel.state.typing,
  nbMessages: channel.state.messages.length,
  lastMessage: channel.state.last_message_at,
})

type UseGenericChannels = (status?: 'active' | 'inactive') => {
  isLoading: boolean,
  channels: QueryChannelsResultType,
}

export const useGenericChannels: UseGenericChannels = (status) => {
  const { user } = useUser()
  const { client } = useChatContext()
  const [channels, setChannels] = useState<QueryChannelsResultType>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMounted = useIsMounted()

  useFocusEffect(useCallback(() => {

    const filter = { type: 'messaging', members: { $in: [user?.uid] } }
    if (status) {
      filter.status = { $eq: status }
    }
    const listActiveChannels = async () => {
      setIsLoading(true)
      const res = await client.queryChannels(filter)
      // console.log('activeChannelsResult', res)
      if (isMounted()) {
        setChannels(res.map((c) => mapChannel(c, user?.uid)))
        setIsLoading(false)
      }
    }

    listActiveChannels()

  }, [client, status, user?.uid]))

  return {
    isLoading,
    channels,
  }
}
