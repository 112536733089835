// import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import { ContentPlayerHeader } from '~/elements/headers/ContentPlayerHeader'
import ChapterPage from '~/screens/ContentPlayerScreen/CoreScreen/ContentPlayerCoreScreen'
import ContentPlayerCoverScreen from '~/screens/ContentPlayerScreen/CoverScreen/ContentPlayerCoverScreen'
import ContentPlayerOutroScreen from '~/screens/ContentPlayerScreen/OutroScreen/ContentPlayerOutroScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { ContentPlayerDrawerParamList } from './ContentPlayerDrawerParamList.d'

export const ContentStack = createStackNavigator<ContentPlayerDrawerParamList>()

export const ContentPlayerNavigator = () => {
  // console.log('-> ContentPlayerNavigator')
  const { t } = useTranslation(['player', 'common'])
  const CoverHeader = useCallback(() => <CloseFullOverlayHeader label={t('common:cta.later')} headerRightTw="mr-6 md:mr-4" />, [t])

  return (
    <Suspense fallback={<LoadingScreen />}>
      <ContentStack.Navigator>
        <ContentStack.Screen
          name="Cover"
          component={ContentPlayerCoverScreen}
          options={{
            headerShown: true,
            header: CoverHeader,
          }}
        />
        <ContentStack.Screen
          name="Chapter"
          component={ChapterPage}
          options={{
            headerShown: true,
            headerTransparent: true,
            header: ContentPlayerHeader,
          }}
        />
        <ContentStack.Screen
          name="Outro"
          component={ContentPlayerOutroScreen}
          options={{
            headerShown: false,
          }}
        />
      </ContentStack.Navigator>
    </Suspense>
  )
}
