import { StackScreenProps } from '@react-navigation/stack'
import { openBrowserAsync } from 'expo-web-browser'
import { Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { EventSelectCard } from '~/components/forms/selects/EventSelectCard'
import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Timestamp as TimestampType } from '~/definitions/firebase.firestore.types'
import { GroupMeetingSessionDataExtended, GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import Overlay from '~/elements/overlays/Overlay'
import { Text } from '~/elements/text/Text'
import { useDateFormat } from '~/hooks/dateFns'
import { useEventById } from '~/hooks/dato.events'
import { useEventWithSessions } from '~/hooks/firestore.events'
import { useIsPast, useIsWithinInterval } from '~/hooks/time'
import { useUser } from '~/hooks/user.context'
import { functionsEU } from '~/navigators.shared/app.firebase.client'
import { EventsStackParamList } from '~/navigators/EventsStackParamList'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { addMinutes, subMinutes } from '~/utils/dateFns/common.helpers'

import { EventBannerInfo } from './EventBannerInfo'

type EventScreenProps = StackScreenProps<EventsStackParamList, 'Event'>

// Restrict registration when of a multiple session has exceeded limit of participants
export const EventScreen: FC<EventScreenProps> = ({ route }) => {
  const eventId = route.params?.id

  const { user } = useUser()
  const { i18n } = useTranslation()
  const { t } = useTranslation(['events'])

  const {
    data: eventFromDato,
  } = useEventById(eventId, user.uid)
  const eventInstance = eventFromDato?.conferenceInstance || eventFromDato?.workshopInstance
  const {
    data: eventWithSessions,
  } = useEventWithSessions(eventInstance)

  // console.log("event with sessions", eventWithSessions)

  const [event, setEvent] = useState<GroupMeetingWithSessions | null>(null)
  const [eventSession, setEventSession] = useState<GroupMeetingSessionDataExtended | null>(null)
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState<boolean>(false)
  const [eventDate, setEventDate] = useState<TimestampType | undefined>()
  const [tenMinutesBeforeDate, setTenMinutesBeforeDate] = useState<Date>(new Date()) // now moment only to initialize state
  const [endOfSessionDate, setEndOfSessionDate] = useState<Date>(new Date())
  const [endOfLastSessionDate, setEndOfLastSessionDate] = useState<Date>(new Date())

  /* eslint no-underscore-dangle: 0 */
  const eventTypeName = event?.model?.__typename
  const duration = event?.model?.duration || eventWithSessions?.model?.duration
  const timeDistance = useDateFormat(eventDate, 'ago', i18n.language)

  useEffect(() => {
    if (eventWithSessions) {
      setEvent(eventWithSessions)

      const registeredSession = eventWithSessions.sessions.find((session) => session.isRegistered)
      const currentEventSession = registeredSession || eventWithSessions.sessions[0]

      if (registeredSession || eventWithSessions.sessions.length === 1) {
        setEventSession(currentEventSession)
      } else {
        setEventSession(null)
      }

      if (eventWithSessions.sessions.length === 1 || !!registeredSession) {
        setEventDate(currentEventSession.startAt)
      } else {
        setEventDate(undefined) // when multiple slot and user did not register to any
      }
    }
  }, [eventWithSessions])

  useEffect(() => { // to set the "join the session" cta time interval, when user already registered to a specific session
    if (eventDate) {
      setTenMinutesBeforeDate(subMinutes(eventDate, 10))
      setEndOfSessionDate(addMinutes(eventDate, duration))
    }
  }, [eventDate, duration])

  const interval = { start: tenMinutesBeforeDate, end: endOfSessionDate }
  const canUserConnectToSession = useIsWithinInterval(new Date(), interval)

  useEffect(() => { // to set the isPast condition to display either replay button or register (with also countdown/join) button
    // has to work whether user registered or not so it needs to include the case of eventDate undefined (meaning multiple slots)
    // which is why we directly take the eventWithSessions
    if (eventWithSessions) {
      const lastSessionIndex = eventWithSessions.sessions.length - 1
      const lastSessionStartDateTs = eventWithSessions.sessions[lastSessionIndex].startAt
      setEndOfLastSessionDate(addMinutes(lastSessionStartDateTs, duration)) // returns Date type again
    }
  }, [eventWithSessions, duration])

  const endDateTs = Timestamp.fromDate(endOfLastSessionDate)
  const isPast = useIsPast(endDateTs)

  // ###

  const handleEventSessionRegistration = useCallback(async (
    callableFunctionName: 'httpRegisterToGroupMeetingSession' | 'httpUnregisterFromGroupMeetingSession',
  ) => {
    if (event && eventSession) {
      try {
        const data = {
          groupMeetingId: eventSession.parentId,
          groupMeetingSessionId: eventSession.id,
        }
        await httpsCallable(functionsEU, callableFunctionName)(data)
      } catch (unregisterError) {
        console.error(unregisterError)
      }
    }
  }, [event, eventSession])

  const registerToEventSession = useCallback(
    async () => {
      await handleEventSessionRegistration('httpRegisterToGroupMeetingSession')
      setIsRegisterModalVisible(false)
    },
    [handleEventSessionRegistration],
  )

  const unregisterFromEventSession = useCallback(
    async () => handleEventSessionRegistration('httpUnregisterFromGroupMeetingSession'),
    [handleEventSessionRegistration],
  )

  const isEventSessionSelected = useCallback(
    (
      session: GroupMeetingSessionDataExtended,
    ) => session.id === eventSession?.id,
    [eventSession],
  )

  const setEventSessionOnPress = useCallback(
    (newEventSession: GroupMeetingSessionDataExtended) => {
      if (isEventSessionSelected(newEventSession)) {
        setEventSession(null)
      } else {
        setEventSession(newEventSession)
      }
    },
    [isEventSessionSelected],
  )

  const setIsRegisterModalVisibleCallback = useCallback(
    (registerModalVisible: boolean) => {
      if (!registerModalVisible) {
        setEventSession(null)
      }
      setIsRegisterModalVisible(registerModalVisible)
    },
    [setIsRegisterModalVisible],
  )

  // ###

  const ReplayButtonView = useMemo(() => { // only after the end of all the sessions of the conference event
    // even though the event will be on events screen only when replay is available, keep the two button states, in case user kept event screen url

    if (!(eventTypeName === 'ConferenceRecord' && isPast)) {
      return null
    }

    const replayLink = eventSession?.replay_link
    const isReplayAvailable = !!replayLink

    const title = isReplayAvailable
      ? t('events:event.see_replay')
      : t('events:event.replay_not_available')

    const onPress = isReplayAvailable
      ? () => openBrowserAsync(replayLink)
      : undefined

    return (
      <View tw="flex-col items-center -mt-4 sm:mt-4">
        <Button disabled={!isReplayAvailable} title={title} onPress={onPress} />
      </View>
    )
  }, [
    t,
    eventSession,
    eventTypeName,
    isPast,
  ])

  const RegistrationButtonView = useMemo(() => { // only until the end of all the sessions of the event
    let title: string
    let onPress: () => void
    let color = 'basic'

    const roomLink = eventSession?.room_link || ''

    if (!event || isPast) {
      return null
    }

    if (eventSession?.isRegistered) {
      title = canUserConnectToSession ? t('events:cta.join') : `${t('events:cta.available')} ${timeDistance}`
      onPress = canUserConnectToSession ? () => openBrowserAsync(roomLink) : undefined
      color = canUserConnectToSession ? 'brand' : 'basic'

    } else if (event.sessions.length > 1) { // user not registered + more than one slot = open the slot picking modal
      title = t('events:event.select_slot')
      onPress = () => setIsRegisterModalVisibleCallback(true)

    } else { // user not registered + only one slot = register directly
      title = t('events:event.register')
      onPress = registerToEventSession
    }

    return (
      <View tw="flex-col items-center -mt-4 sm:mt-4">
        <Button color={color} title={title} onPress={onPress} />
        {/* TODO ideally disable only when there is countdown */}
      </View>
    )
  }, [
    registerToEventSession,
    setIsRegisterModalVisibleCallback,
    t,
    event,
    eventSession,
    isPast,
    canUserConnectToSession,
    timeDistance,
  ])

  if (!event) {
    return (
      <BasicSectionContainer tw="mt-20">
        <LoadingScreen />
      </BasicSectionContainer>
    )
  }

  const getContent = () => {
    const modelKeys: Array<'description'|'objectives'|'targetParticipants'|'preRequisites'> = [
      'description',
      'objectives',
      'targetParticipants',
      'preRequisites',
    ]

    return modelKeys.map((modelKey) => {
      if (event.model && !event.model[modelKey]) {
        return null
      }

      return (
        <>
          <SectionHeading title={t(`events:event.${modelKey}`)} />
          <Text tw="text-sm leading-relaxed text-gray-700" markdown>{event.model[modelKey]}</Text>
        </>
      )
    })
  }

  return (
    <MainContainer scrollEnabled>

      <BasicSectionContainer tw="md:mt-20 px-0 md:px-8">
        <EventBannerInfo event={event} eventDate={eventDate} />
        {ReplayButtonView}
        {RegistrationButtonView}
      </BasicSectionContainer>

      <BasicSectionContainer>
        {getContent()}
        {eventSession?.isRegistered && !isPast && (
          <View tw="flex-col items-center mt-8">
            <Button variant="outline" title={t('events:event.unregister')} onPress={unregisterFromEventSession} />
          </View>
        )}
      </BasicSectionContainer>

      <Overlay
        isVisible={isRegisterModalVisible}
        onBackdropPress={() => setIsRegisterModalVisibleCallback(false)}
        >
        <Overlay.Content scrollEnabled tw="mt-4 px-4 md:px-6 lg:px-12">
          <SectionHeading
            tw="mb-2"
            title={t('events:event.select_slot')}
            description={t('events:event.select_slot_description')}
          />
          {
            event?.sessions.map((session) => (
              <EventSelectCard
                key={session.id}
                onPress={() => setEventSessionOnPress(session)}
                isActive={isEventSessionSelected(session)}
                dateTimestamp={session.startAt}
                duration={duration}
              />
            ))
          }
        </Overlay.Content>
        <Overlay.Footer>
          <View tw="py-4 px-4 md:px-6 lg:px-12">
            <Button
              size="l"
              title={t('events:event.register')}
              disabled={!eventSession}
              onPress={registerToEventSession}
            />
          </View>
        </Overlay.Footer>
      </Overlay>

    </MainContainer>
  )
}
