import { useTranslation } from 'react-i18next'

import { SiteLocale, useAllEnabledEvaluationsQuery, useEvaluationModelQuery } from '~/__generated__/graphql.queries'

export const useAllEnabledEvaluations = () => {
  const { i18n } = useTranslation()

  return useAllEnabledEvaluationsQuery({
    locale: i18n.language as SiteLocale,
  })
}

export const useEvaluationModelBySlug = (slug:string) => {
  const { i18n } = useTranslation()

  return useEvaluationModelQuery({
    locale: i18n.language as SiteLocale,
    slug,
  }, { enabled: !!slug })
}
