import classNames from 'classnames'
import { FC } from 'react'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { View } from '~/elements/containers/View'

type FullOverlayContainerProps = {
  tw?: string
  innerContainerTw?:string
}

export const FullOverlayContainer: FC<FullOverlayContainerProps> = ({
  tw, children, innerContainerTw,
}) => {
  const newTw = classNames('md:flex-row md:justify-center min-h-full', tw)
  // console.log(newTw)

  return (
    <SafeAreaView tw="flex-1">
      <View
        testID="main-container"
        tw={newTw}
        >
        <View tw={classNames('w-full md:max-w-6xl md:flex-row', innerContainerTw)}>
          {children}
        </View>
      </View>
    </SafeAreaView>
  )
}
