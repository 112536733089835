import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { View } from '~/elements/containers/View'
import { SvgById } from '~/elements/images/SvgById'
import { Text } from '~/elements/text/Text'

export const MyTrackNotAllowedScreen = () => {
  const { t } = useTranslation(['track'])

  return (
    <MainContainer scrollEnabled tw="">
      <BasicSectionContainer tw="">
        <View tw="flex-col items-center">
          <SvgById assetId="29307768" tw="py-12 w-full" />
          <Text tw="text-center text-xl mb-2 font-semibold font-serif">{t('track:not_allowed.heading')}</Text>
          <Text tw="text-center text-gray-500 mb-2">{t('track:not_allowed.description')}</Text>
        </View>
      </BasicSectionContainer>
    </MainContainer>
  )
}
