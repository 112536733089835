/* eslint-disable import/no-duplicates */

/**
 * A proxy to use DateFns on Timestamps
 * Ideally, all used datefns functions should be proxied here
 */

import { Locale } from 'date-fns'
import dfAddMinutes from 'date-fns/addMinutes'
import dfCompareAsc from 'date-fns/compareAsc'
import dfCompareDesc from 'date-fns/compareDesc'
import dfFormat from 'date-fns/format'
import dfGetYear from 'date-fns/getYear'
import dfIntervalToDuration from 'date-fns/intervalToDuration'
import dfIsAfter from 'date-fns/isAfter'
import dfIsBefore from 'date-fns/isBefore'
import dfIsToday from 'date-fns/isToday'
import dfIsWithinInterval from 'date-fns/isWithinInterval'
import dfSubMinutes from 'date-fns/subMinutes'

import { Timestamp } from '~/definitions/firebase.firestore.types'

/**
 * Is the first date after the second one?
 */
export const isAfter = (ts:Timestamp, tsToCompare:Timestamp) => dfIsAfter(ts.toDate(), tsToCompare.toDate())

/**
 * Is the first date before the second one?
 */
export const isBefore = (ts:Timestamp, tsToCompare:Timestamp) => dfIsBefore(ts.toDate(), tsToCompare.toDate())

export const getYear = (ts:Timestamp) => dfGetYear(ts.toDate())

/**
 * Order dates
 */
export const compareAsc = (ts:Timestamp, tsToCompare:Timestamp) => dfCompareAsc(ts.toDate(), tsToCompare.toDate())
export const compareDesc = (ts:Timestamp, tsToCompare:Timestamp) => dfCompareDesc(ts.toDate(), tsToCompare.toDate())

/**
 * Check if a given date is today, or in a given interval of time
 */
export const isToday = (ts:Timestamp) => dfIsToday(ts.toDate())

export type Interval = {
  start: Date,
  end: Date
}
export const isWithinInterval = (momentToCompare:Date, interval:Interval) => dfIsWithinInterval(momentToCompare, interval)

/**
 * Sub or add minutes to a given date
 */
export const subMinutes = (ts:Timestamp, amount:number) => dfSubMinutes(ts.toDate(), amount)
export const addMinutes = (ts:Timestamp, amount:number) => dfAddMinutes(ts.toDate(), amount)

/**
 * Format
 */
export const format = (date:Timestamp, fmt: string, options?:{
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
}) => dfFormat(date.toDate(), fmt, options)

/**
 * interval to duration
 */
export const intervalToDuration = ({ start, end }:{start:Timestamp, end:Timestamp}) => ({
  ...dfIntervalToDuration({ start: start.toDate(), end: end.toDate() }),
  isFuture: isBefore(start, end),
  isPast: !isBefore(start, end),
})
