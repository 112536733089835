import { useContractorBySlug } from './dato.contractors'
import { useUser } from './user.context'

export type StringQueryHookResult = {
  isLoading: boolean
  error: Error | null
  data: string | null
}

export const useCurrentUserMinderSlug = ():StringQueryHookResult => {
  // console.log('useCurrentUserMinderSlug')
  const { isLoading, error, userTrackDoc } = useUser()

  return {
    isLoading,
    error,
    data: userTrackDoc?.data() ? userTrackDoc.get('minder') : undefined,
  }
}

export const useCurrentUserMinder = () => {
  // console.log('useCurrentUserMinder')
  const { isLoading: currentUserMinderSlugLoading, error: currentUserMinderSlugError, data: currentUserMinderSlug } = useCurrentUserMinderSlug()
  const contractorBySlugRes = useContractorBySlug(currentUserMinderSlug)

  return {
    isLoading: currentUserMinderSlugLoading || contractorBySlugRes.isLoading,
    error: currentUserMinderSlugError || contractorBySlugRes.error,
    data: contractorBySlugRes.data,
  }
}
