import { useResponsiveHeight } from 'react-native-responsive-dimensions'

import { ContentPlayerView } from '~/components/layouts/containers/ContentPlayerView'
import { View } from '~/elements/containers/View'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { getNearestAspectRatio } from '~/theme/helpers'

import { getBestTextColor } from '../../../utils/text.accessibility.helpers'
// import { ContentAuthorBox } from './ContentAuthorBox'
import { ContentCoverDesktopTitleBox } from './ContentCoverDesktopTitleBox'

export const ContentCoverMobileScreen = ({
  id, title, illustration,
}) => {

  const height = useResponsiveHeight(75) // 75% of screen height
  const textColor = getBestTextColor(illustration.backgroundColor || 'surface-1')

  return (
    <ContentPlayerView
      tw="px-4 pb-24"
      style={{ height, maxHeight: 700 }}
      cardTw={`flex-col justify-between bg-${illustration.backgroundColor || 'surface-1'}`}
      to={`/content/${id}/page/1`}
      >
      <View style={{ minHeight: 200 }} tw="flex-col justify-between px-4 pt-8">
        <ContentCoverDesktopTitleBox title={title} containerTw="md:px-24" textTw={`text-center text-${textColor}`} />
        {/* {author && author.picture && author.picture.responsiveImage && (
          <ContentAuthorBox author={author} metadata={metadata} tw="justify-center mt-16" backgroundColor={coverBackgroundColor} />
        )} */}
      </View>
      <DoubleSvgImage
        aspectRatio={getNearestAspectRatio(6 / 7)}
        {...illustration}
        tw="w-full rounded-b-2xl"
      />
    </ContentPlayerView>
  )
}
