import { memo, useState } from 'react'
import type { Attachment } from 'stream-chat'

import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

import { useTranslationContext } from '../context/TranslationContext'
import type { DefaultAttachmentType } from '../stream.types'
import { ModalComponent as ModalWrapper } from './ModalWrapper'

export type GalleryProps<At extends DefaultAttachmentType = DefaultAttachmentType> = {
  images:
    | {
        image_url?: string | undefined;
        thumb_url?: string | undefined;
      }[]
    | Attachment<At>[];
};

const UnMemoizedGallery = <At extends DefaultAttachmentType = DefaultAttachmentType>(
  props: GalleryProps<At>,
) => {
  const { images } = props

  const [index, setIndex] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  const { t } = useTranslationContext('Gallery')

  const toggleModal = (selectedIndex: number) => {
    if (modalOpen) {
      setModalOpen(false)
    } else {
      setIndex(selectedIndex)
      setModalOpen(true)
    }
  }

  // const formattedArray = useMemo(
  //   () =>
  //     images.map((image) => ({
  //       source: image.image_url || image.thumb_url || '',
  //     })),
  //   [images],
  // );

  const renderImages = images.slice(0, 3).map((image, i) => (
    <Pressable
      data-testid="gallery-image"
      // eslint-disable-next-line react/no-array-index-key
      key={`gallery-image-${i}`}
      onPress={() => toggleModal(i)}
      >
      <Image url={image.image_url || image.thumb_url} />
    </Pressable>
  ))

  return (
    <View tw={`str-chat__gallery ${images.length > 3 ? 'str-chat__gallery--square' : ''}`}>
      {renderImages}
      {images.length > 3 && (
        <View
          onClick={() => toggleModal(3)}
          style={{
            backgroundImage: `url(${images[3].image_url})`,
          }}
          >
          <Text>{t('{{ imageCount }} more', { imageCount: images.length - 3 })}</Text>
        </View>
      )}
      <ModalWrapper
        // images={formattedArray}
        index={index}
        modalIsOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
      />
    </View>
  )
}

/**
 * Displays images in a simple responsive grid with a light box to view the images.
 */
export const Gallery = memo(UnMemoizedGallery) as typeof UnMemoizedGallery
