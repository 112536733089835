import omit from 'lodash/omit'
import snakeCase from 'lodash/snakeCase'

type TrackingEventDefinition = {
  key: string
  event: string
  description?: string
  definition?: string
  category?: string
  integrations?: Record<Destinations, boolean>
}

export const trackingEvents: Array<TrackingEventDefinition> = [
  {
    key: 'new-user',
    event: 'Signed Up',
    definition: 'https://segment.com/docs/connections/spec/b2b-saas/#signed-up',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'login',
    event: 'Signed In',
    definition: 'https://segment.com/docs/connections/spec/b2b-saas/#signed-in',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'logout',
    event: 'Signed Out',
    definition: 'https://segment.com/docs/connections/spec/b2b-saas/#signed-out',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'content-searched',
    event: 'Contents Searched',
    description: 'User searched for contents',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: true,
    },
  },
  {
    key: 'search',
    event: 'Content List Viewed',
    description: 'User viewed a content list or category',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: true,
    },
  },
  {
    key: 'content-list-filtered',
    event: 'Content List Filtered',
    description: 'User filtered a content list or category',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: false,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: true,
    },
  },
  {
    key: 'content-bookmarked',
    event: 'Content Added to Bookmarks',
    description: 'User added a content to the bookmark list',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': true,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'content-unbookmarked',
    event: 'Content Removed from Bookmarks',
    description: 'User removed a content from the bookmark list',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'content-shared',
    event: 'Content Shared',
    description: 'Shared a content with one or more friends',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'content-reviewed',
    event: 'Content Reviewed',
    description: 'User reviewed a content',
    category: 'content-discovery',
    integrations: {
      // All: false,
      BigQuery: true,
      Mixpanel: true,
      'Recombee AI': true,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
  {
    key: 'playing-started',
    event: 'Content Viewed',
    description: 'User viewed a content details',
    category: 'content-playing',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': true,
      'Customer.io Actions': true,
      Algolia: true,
    },
  },
  {
    key: 'playing-paused',
    event: 'Content Paused',
    category: 'content-playing',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'playing-resumed',
    event: 'Content Resumed',
    category: 'content-playing',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'playing-progressed',
    event: 'Content Progressed',
    category: 'content-playing',
    integrations: {
      // All: false,
      BigQuery: false,
      Mixpanel: true,
      'Recombee AI': true,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'conversation-created',
    event: 'Conversation Created',
    description: 'User created a conversation',
    category: 'chat',
    integrations: {
      // All: false,
      BigQuery: true,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'conversation-archived',
    event: 'Conversation Archived',
    description: 'User archived a conversation',
    category: 'chat',
    integrations: {
      // All: false,
      BigQuery: true,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'conversation-unarchived',
    event: 'Conversation Unarchived',
    description: 'User unarchived a conversation',
    category: 'chat',
    integrations: {
      // All: false,
      BigQuery: true,
      Mixpanel: true,
      'Recombee AI': false,
      'Customer.io Actions': false,
      Algolia: false,
    },
  },
  {
    key: 'affiliation-viewed',
    event: 'Affiliation Viewed',
    description: 'User viewed an affiliation',
    integrations: {
      // All: false,
      BigQuery: true,
      Mixpanel: true,
      'Recombee AI': true,
      'Customer.io Actions': true,
      Algolia: false,
    },
  },
]

export type TrackingEventName = typeof trackingEvents[number]['event']
export type TrackingEventKey = typeof trackingEvents[number]['key']

export const getEventByKey = (eventKey: TrackingEventKey): Omit<TrackingEventDefinition, 'key'> => omit(trackingEvents.find((item) => item.key === eventKey), 'key')

// Destination connected to our app as a Segment Source
export type Destinations = 'Algolia' | 'Recombee AI' | 'Customer.io Actions' | 'Mixpanel' | 'BigQuery' // 'All' |

// const allButKnown = {
//   // All: true,
//   Mixpanel: false,
//   'Recombee AI': false,
//   'Customer.io Actions': false,
//   Algolia: false,
// }

// const explicit = {
//   // All: false,
//   Mixpanel: false,
//   'Recombee AI': false,
//   'Customer.io Actions': false,
//   Algolia: false,
// }

export const defaultIntegrations = {
  // All: true,
  Mixpanel: false,
  'Recombee AI': false,
  'Customer.io Actions': false,
  Algolia: false,
}

export const trackingEventKeys = trackingEvents.map((item) => item.key).reduce((acc, key) => ({ ...acc, [snakeCase(key).toUpperCase()]: key }), {} as {
  [key: string]: string,
})
