import { yupResolver } from '@hookform/resolvers/yup'
import { useLinkTo } from '@react-navigation/native'
import { useFunctionsCall } from '@react-query-firebase/functions'
import { FC, useEffect, useState } from 'react'
import {
  FieldValues, FormProvider, useForm, useWatch,
} from 'react-hook-form'

import { View } from '~/elements/containers/View'
import { functionsEU } from '~/navigators.shared/app.firebase.client'

import { Field } from './input-groups/Field'
import { ProgressFooter } from './ProgressFooter'
// import { SubInputElement } from './SubInputElement'

type RequestData = {
  formId:string
  values:Record<string, any>
  context:Record<string, any>
};

type ResponseData = {
  status: number;
  data: {
    name: string;
  };
};

type WizardProps = {
  labelTw?: string
  steps: Array<any>
  initialValues: FieldValues
  onSubmit: (values: FieldValues) => void
  tw?: string
  validationSchema?: any
  style?: any
  mapDataBeforeSubmission?: (values: FieldValues) => Omit<RequestData, 'formId'>,
  redirectOnSuccess:string
}

/**
 * Wizard is a single Formik instance whose children are each page of the
 * multi-step form. The form is submitted on each forward transition (can only
 * progress with valid input), whereas a backwards step is allowed with
 * incomplete data. A snapshot of form state is used as initialValues after each
 * transition. Each page has an optional submit handler, and the top-level
 * submit is called when the final page is submitted.
 */
/**
 * TODO - update this documentation comment now we do not use formik anymore
 */
export const Wizard: FC<WizardProps> = ({
  labelTw,
  steps,
  initialValues,
  tw,
  validationSchema,
  style,
  mapDataBeforeSubmission,
  redirectOnSuccess,
}) => {
  // console.log('-> Wizard')

  const [stepNumber, setStepNumber] = useState(0)
  const linkTo = useLinkTo()

  // used to measure time to complete
  const [landedAt, setLandedAt] = useState<number>()
  const mutation = useFunctionsCall<RequestData, ResponseData>(functionsEU, 'httpFormEndpoint')

  const step = steps[stepNumber]
  // console.log('current step', step)
  const totalSteps = steps.length
  const isFirstStep = stepNumber === 0
  const isLastStep = stepNumber === totalSteps - 1

  // const { t } = useTranslation(['forms'])

  useEffect(() => {
    if (!landedAt) {
      setLandedAt(new Date().getTime())
    }
  }, [landedAt])

  const previous = () => {
    setStepNumber(stepNumber - 1)
  }

  const next = () => {
    setStepNumber(stepNumber + 1)
  }

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    shouldUseNativeValidation: true,
    resolver: yupResolver(validationSchema),
  })

  const currentStepValue = useWatch({ control: methods.control, name: step.name })
  // console.log('currentStepValue', currentStepValue)

  // redirect on submit being successful
  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      linkTo(redirectOnSuccess)
    }
  }, [methods.formState.isSubmitSuccessful])

  const submitData = (values:FieldValues) => {
    // console.log('submit values', values)
    const data = mapDataBeforeSubmission(values)
    // console.log('submit data', data)
    return new Promise((resolve, reject) => {
      mutation.mutate(
        data,
        {
          onSuccess: () => resolve(mutation.data),
          onError: () => reject(mutation.error),
        },
      )
    })
  }

  const onPressNextOrSubmit = async () => {

    // console.log('onPressNextOrSubmit for', step.name)

    // Submit on last step
    if (isLastStep) {
      // console.log('last step; will submit response')

      // add metadata to the response
      const metadata = {
        landedAt,
        submittedAt: new Date().getTime(),
      }
      methods.setValue('metadata', metadata)

      methods.handleSubmit(submitData)()
    } else {
      // Validate and go to next step
      const stepValidationResult = await methods.trigger(step.name)

      // Move next step unless errors
      if (stepValidationResult) {
        // console.log('step valid; will move to next one')
        next()
      }
      // console.log('invalid step')
    }

    return true
  }

  const currentStepError = methods.formState.errors[step.name]
  // console.log('currentStepError', currentStepError)

  return (
    <FormProvider {...methods}>
      <View tw={tw} style={style}>
        <View tw="p-4 bg-surface-1 rounded-2xl">
          <Field labelTw={labelTw} {...step} helper={step.help} align="left" onEnterKeyPress={onPressNextOrSubmit} />
        </View>
        <View tw="mt-8">
          {currentStepValue !== '' && !currentStepError
            && (
              // <SubInputElement isLastStep={isLastStep} onPress={onPressNextOrSubmit} />
              <ProgressFooter
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                onPress={onPressNextOrSubmit}
                onPrevious={previous}
              />
            )}
        </View>
      </View>
    </FormProvider>
  )
}
