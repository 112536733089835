import { LinearGradient } from 'expo-linear-gradient'
import { MotiTransitionProp } from 'moti'
import { memo } from 'react'
import { StyleSheet } from 'react-native'

import { AnimatedView } from '../containers/AnimatedView'

export const AnimatedGradient = memo(
  ({
    measuredWidth,
    colors,
    backgroundSize,
    transition = {},
  }: {
      measuredWidth: number
      colors: string[]
      backgroundSize: number,
      transition?: MotiTransitionProp
    }) => (
      <AnimatedView
        style={StyleSheet.absoluteFillObject}
        from={{ opacity: 0 }}
        transition={{ type: 'timing', duration: 200 }}
        animate={measuredWidth ? { opacity: 1 } : undefined}
        >
        <AnimatedView
          style={{
          ...StyleSheet.absoluteFillObject,
          width: measuredWidth * backgroundSize,
          }}
          from={{ translateX: 0 }}
          animate={
          measuredWidth ? { translateX: -measuredWidth * (backgroundSize - 1) } : undefined
        }
          transition={{
          loop: true, delay: 200, type: 'timing', duration: 3000, ...(transition as any),
          }}
          >
          <LinearGradient
            colors={colors}
            start={[0.1, 1]}
            end={[1, 1]}
            style={StyleSheet.absoluteFillObject}
          />
        </AnimatedView>
      </AnimatedView>
  ),
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
)
