import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

export const useAxiosGet = <T>(
  url: string,
  axiosOptions?: AxiosRequestConfig,
  reactQueryOptions ?: UseQueryOptions,
):UseQueryResult<any, unknown> => useQuery<T, Error>(
  ['axios-get', url],
  () => axios.get<T>(url, axiosOptions),
  {
    ...reactQueryOptions,
    retry: false,
    enabled: !!url,
    select: (res: AxiosResponse) => res.data,
  },
)
